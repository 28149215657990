import {Component, EventEmitter, forwardRef, Optional, Output} from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BaseControlComponentDirective } from '../base-control-component.directive';

@Component({
    selector: 'tp-checkbox',
    templateUrl: './tp-checkbox.component.html',
    styleUrls: ['./tp-checkbox.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TpCheckboxComponent),
            multi: true,
        },
    ],
})
export class TpCheckboxComponent extends BaseControlComponentDirective {
    @Output() valueChanged: EventEmitter<any> = new EventEmitter();
    constructor(
        @Optional()  protected readonly controlContainer: ControlContainer,
        protected readonly translateService: TranslateService
    ) {
        super(controlContainer, translateService)
    }
    onChange(e): void {
        this.valueChanged.emit(e?.value ? e.value : e);
    }
}