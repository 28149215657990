import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TpCurrencyDropdownModule } from 'app/components/tp-currency-dropdown/tp-currency-dropdown.module';
import { TpDropdownModule } from 'app/components/tp-dropdown/tp-dropdown.module';
import { TpServiceLanguageDropdownModule } from 'app/components/tp-service-language-dropdown/tp-service-language-dropdown.module';
import { TpServicesDropdownModule } from 'app/components/tp-services-dropdown/tp-services-dropdown.module';
import { TpTextBoxModule } from 'app/components/tp-text-box/tp-text-box.module';
import { TpUnitsDropdownModule } from 'app/components/tp-units-dropdown/tp-units-dropdown.module';
import { ServicePriceModalComponent } from 'app/main/sharedComponents/resource-service-price-modal/service-price-modal.component';

@NgModule({
    declarations: [
        ServicePriceModalComponent,
    ],
    imports: [
        CommonModule,
        TranslateModule,
        ReactiveFormsModule,
        TpTextBoxModule,
        TpServicesDropdownModule,
        TpDropdownModule,
        TpServiceLanguageDropdownModule,
        TpCurrencyDropdownModule,
        TpUnitsDropdownModule,
    ],
    exports: [
        ServicePriceModalComponent,
    ],
})
export class ResourceServicePriceModalModule {
}