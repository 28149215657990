import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { TranslateModule } from '@ngx-translate/core'
import { MatTableModule } from '@angular/material/table'

import { FormatNumberPipeModule } from 'app/pipes/format-number-pipe/format-number-pipe.module'
import { FormatNumberWithCurrencyPipeModule } from 'app/pipes/format-number-with-currency-pipe/format-number-with-currency-pipe.module'
import { MyDocumentsTableComponent } from './my-documents-table.component'



@NgModule({
    declarations: [MyDocumentsTableComponent],
    imports: [
        CommonModule,
        TranslateModule,
        MatTableModule,
        FormatNumberPipeModule,
        FormatNumberWithCurrencyPipeModule,
        
    ],
    exports: [MyDocumentsTableComponent],
})
export class MyDocumentsTableModule {}