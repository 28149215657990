<ng-container *ngIf="{dateFormat: dateFormat$ | async} as subs">
    <ng-container *ngIf="control; then reactive; else nonReactive"></ng-container>
    <ng-template #reactive>
        <mat-form-field class="w-100 calendar-form-field" [appearance]="appearance">
            <mat-label>{{label}}</mat-label>
            <input matInput [ngxMatDatetimePicker]="picker" placeholder="{{label}}" [formControl]="control" [disabled]="disabled" [required]="required">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #picker [showSpinners]="showSpinners" [showSeconds]="showSeconds"
                                     [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi"
                                     [color]="color" [enableMeridian]="enableMeridian" [hideTime]="!showTime">
            </ngx-mat-datetime-picker>

            <ng-container *ngIf="filteredErrors?.length > 0; then backendErrors; else frontendErrors"></ng-container>
            <ng-template #backendErrors>
                <mat-error *ngIf="filteredErrors[0] as err">
                    <span style="color: red">
                        <ng-container *ngIf="useTranslation">
                            {{ err.errorCode | translate: err.errorParameters }}
                        </ng-container>
                        <ng-container *ngIf="!useTranslation">
                            {{ err.errorDescription }}
                        </ng-container>
                    </span>
                </mat-error>
            </ng-template>

            <ng-template #frontendErrors>
                <mat-error *ngIf="showFormError">
                    <span style="color: red">{{ formErrors[0] }}</span>
                </mat-error>
            </ng-template>
        </mat-form-field>
    </ng-template>
    <ng-template #nonReactive>
        <mat-form-field class="w-100 calendar-form-field" [appearance]="appearance">
            <mat-label>{{label}}</mat-label>
            <input matInput [ngxMatDatetimePicker]="picker" placeholder="{{label}}" [(ngModel)]="latestWrittenValue" (ngModelChange)="writeValue($event)" [ngModelOptions]="{ standalone: true }" [disabled]="disabled">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #picker [showSpinners]="showSpinners" [showSeconds]="showSeconds"
                                     [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi"
                                     [color]="color" [enableMeridian]="enableMeridian" [hideTime]="!showTime">
            </ngx-mat-datetime-picker>
            <ng-container *ngIf="filteredErrors?.length > 0; then backendErrors; else frontendErrors"></ng-container>
            <ng-template #backendErrors>
                <mat-error *ngIf="filteredErrors[0] as err">
                    <span style="color: red">
                        <ng-container *ngIf="useTranslation">
                            {{ err.errorCode | translate: err.errorParameters }}
                        </ng-container>
                        <ng-container *ngIf="!useTranslation">
                            {{ err.errorDescription }}
                        </ng-container>
                    </span>
                </mat-error>
            </ng-template>

            <ng-template #frontendErrors>
                <mat-error *ngIf="showFormError">
                    <span style="color: red">{{ formErrors[0] }}</span>
                </mat-error>
            </ng-template>
        </mat-form-field>
    </ng-template>
    
<!--    <ng-container *ngIf="control; then reactive; else nonReactive"></ng-container>-->
<!--    -->
<!--    <ng-template #reactive>-->
<!--        <ejs-datepicker-->
<!--            *ngIf="!showTime"-->
<!--            [formControl]="control"-->
<!--            [placeholder]="label"-->
<!--            [readonly]="readOnly"-->
<!--            [required]="required"-->
<!--            [enabled]="!readOnly"-->
<!--            [openOnFocus]="true"-->
<!--            [floatLabelType]="'Auto'"-->
<!--            [showClearButton]="true"-->
<!--            cssClass="e-filled"-->
<!--            [format]="subs.dateFormat"-->
<!--        ></ejs-datepicker>-->
<!--    -->
<!--        <ejs-datetimepicker-->
<!--            *ngIf="showTime"-->
<!--            [formControl]="control"-->
<!--            [placeholder]="label"-->
<!--            [readonly]="readOnly"-->
<!--            [enabled]="!readOnly"-->
<!--            [required]="required"-->
<!--            [openOnFocus]="true"-->
<!--            [step]="15"-->
<!--            [floatLabelType]="'Auto'"-->
<!--            [showClearButton]="true"-->
<!--            cssClass="e-filled"-->
<!--            [format]="subs.dateFormat"-->
<!--        ></ejs-datetimepicker>-->
<!--    </ng-template>-->
<!--    -->
<!--    <ng-template #nonReactive>-->
<!--        <ejs-datepicker-->
<!--            *ngIf="!showTime"-->
<!--            [(ngModel)]="latestWrittenValue" (ngModelChange)="writeValue($event)"-->
<!--            [ngModelOptions]="{ standalone: true }"-->
<!--            [placeholder]="label"-->
<!--            [readonly]="readOnly"-->
<!--            [required]="required"-->
<!--            [enabled]="!readOnly"-->
<!--            [openOnFocus]="true"-->
<!--            [floatLabelType]="'Auto'"-->
<!--            [showClearButton]="true"-->
<!--            cssClass="e-filled"-->
<!--            [format]="subs.dateFormat"-->
<!--        ></ejs-datepicker>-->
<!--    -->
<!--        <ejs-datetimepicker-->
<!--            *ngIf="showTime"-->
<!--            [(ngModel)]="latestWrittenValue" (ngModelChange)="writeValue($event)"-->
<!--            [ngModelOptions]="{ standalone: true }"-->
<!--            [placeholder]="label"-->
<!--            [readonly]="readOnly"-->
<!--            [enabled]="!readOnly"-->
<!--            [required]="required"-->
<!--            [openOnFocus]="true"-->
<!--            [step]="15"-->
<!--            [floatLabelType]="'Auto'"-->
<!--            [showClearButton]="true"-->
<!--            cssClass="e-filled"-->
<!--            [format]="subs.dateFormat"-->
<!--        ></ejs-datetimepicker>-->
<!--    </ng-template>-->
</ng-container>