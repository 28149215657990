import {
    ResponseServiceGridData
} from "../../main/admin/manage-services/models/response-models/response-service-grid-data";
import {ResponseServiceData} from "../../main/admin/manage-services/models/response-models/response-service-data";
import {ServiceDropDownItem} from "../models/dropdowns/service-dropdown-item";
import {ServiceTypes} from "../../utills/enums/service-types";

export const mapServiceDataToServiceGridData = (serviceData: ResponseServiceData) : ResponseServiceGridData => {
    return <ResponseServiceGridData>{
        id: serviceData.id,
        description: serviceData.description,
        insertDate: serviceData.insertDate,
        insertUserId: serviceData.insertUserId,
        displayOrder: serviceData.displayOrder,
        remarks: serviceData.remarks,
        isActive: serviceData.isActive,
        companyBranchId: serviceData.companyBranchId,
        code: serviceData.code,
        sectorId: serviceData.sectorId,
        unitId: serviceData.unitId,
        clientPortal: serviceData.clientPortal,
        serviceTypeId: serviceData.serviceTypeId,
        isWorkflowStep: serviceData.isWorkflowStep,
        capacityPerHour: serviceData.capacityPerHour,
        sectorName: serviceData.sectorName,
    }
}
export const mapServiceDataToDropdown = (serviceData: ResponseServiceData): ServiceDropDownItem => {
    return <ServiceDropDownItem>{id: serviceData.id, name: serviceData.description, isActive: serviceData.isActive, isWorkflowStep: serviceData.isWorkflowStep};
}
export const mapServiceGridDataToDropdown = (serviceData: ResponseServiceGridData): ServiceDropDownItem => {
    return {
        id: serviceData.id,
        name: serviceData.description,
        isActive: serviceData.isActive,
        isWorkflowStep: serviceData.isWorkflowStep,
        serviceTypeId: serviceData.serviceTypeId ?? ServiceTypes.NoLanguage
    };
}