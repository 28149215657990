import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GlobalConfiguration } from 'app/models/general/global-configuration';
import { RefDataService } from 'app/services/general/refData.service';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import {DateService} from "../../services/date/date.service";

@Pipe({
    name: 'formatDate',
})
export class FormatDatePipe implements PipeTransform {
    constructor(
        private translateService: TranslateService,
        private refDataService: RefDataService,
        private globalConfiguration:GlobalConfiguration,
        private dateService: DateService
    ) {
    }
    
    transform(value: Date | string, format = '', includeTime: boolean = false, timeZone: boolean = true): Observable<string> {
        if (value == null) {
            return of('---');
        } else {
            const dt = moment(timeZone ? this.dateService.toTimeZone(value) : value);
            if (dt.year() < 1950) return of('---');
            
            if (format) {
                return of(dt.format(format));
            } else {
                const format = this.globalConfiguration?.companyBranchSetting?.dateFormat?.toUpperCase()  || 'MM/DD/YYYY';
                const timeFormat = (includeTime) ? ' HH:mm' : '';
                return of(dt.format(format + timeFormat));
            }
        }
    }
}