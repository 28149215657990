import { Pipe, PipeTransform } from '@angular/core';
import { UserService } from 'app/services/general/user.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
    name: 'roleName',
})
export class RoleNamePipe implements PipeTransform {
    constructor(private userService: UserService) {
    }
    
    transform(value: string): Observable<string> {
        return this.userService.getRoles().pipe(
            map((roles) => {
                return roles?.find((f) => f.roleId == value)?.roleName || '---';
            }),
        );
    }
}