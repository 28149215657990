import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FileTypes} from "../../../utills";
import {DropDownItem} from "../../../models/general/dropdownItem.model";
import {PostFile} from "../../../models/general/system-models/post-file";

@Component({
  selector: 'tp-file-tabs',
  templateUrl: './file-tabs.component.html',
  styleUrls: ['./file-tabs.component.scss']
})
export class FileTabsComponent implements OnInit {
    @Output() activeTabChange: EventEmitter<FileTypes> = new EventEmitter<FileTypes>();
    @Input() set displayTabs(displayFileTabs: (keyof typeof FileTypes)[]) {
        if(displayFileTabs){
            this._displayTabs = displayFileTabs;
            this.displayingTabs();
        }
    }
    get displayTabs(): (keyof typeof FileTypes)[] { return this._displayTabs };
    @Input() files: PostFile[] = [];
    private _displayTabs: (keyof typeof FileTypes)[] = ['Source', 'Reference'];
    public activeTab: FileTypes;
    public tabs: DropDownItem[] = [];
    public tabOptions: DropDownItem[] = [];
    constructor() {
        const statusTypes = Object.values(FileTypes);
        this.tabOptions = statusTypes.slice(0, statusTypes.length / 2).map(key => <DropDownItem>{id: FileTypes[key], name: key });
        this.displayingTabs();
    }
    ngOnInit(): void {}
    /**
     * @function displayingTabs
     * @description init displaying file tabs
     * @return void
     * */
    displayingTabs(): void {
        this.tabs = this.tabOptions.filter(tab => this.displayTabs.includes(tab.name as keyof typeof FileTypes));
        this.changeActiveTab(FileTypes[this.tabs[0].name]);
    }
    /**
     * @function changeActiveTab
     * @description on change active tab
     * @param {FileTypes} activeTab
     * @return void
     * */
    changeActiveTab(activeTab: FileTypes): void {
        this.activeTab = activeTab;
        this.activeTabChange.emit(this.activeTab);
    }
    /**
     * @function isTabActive
     * @param {FileTypes} tab
     * @description check if tab is active
     * @return boolean
     * */
    isTabActive(tab: FileTypes): boolean {
        return (tab === this.activeTab);
    }

    /**
     * @function countFileType
     * @param {FileTypes} tab
     * @description return count of specific files type
     * @return number
     * */
    countTabTypeFiles(tab: FileTypes): number{
        return (this.files?.length > 0) ? this.files.filter(file => file.fileTypeId == tab).length : 0;
    }
}