import { PostCompanyBranch } from 'app/main/admin/models/post-models/post-company-branch';
import { TpPaymentTermTypes } from '../../components/tp-payment-terms/tp-payment-terms.component';
import {ResponseCompanyBranchFinanceData} from "../../main/admin/models/response-models/response-company-branch-finance-data";

export class CompanySettingModel {
    companyId: string;
    companyName: string;
    companyLogo: string;
    recordsPerPage: number;
    dateFormat: string;
    timeZone: string;
    defaultLanguage: string;
    startOfWeek: number;
    baseCurrency: string;
    taxRates: string;
    discountRates: string;
    noOfDecimals: number;
    noOfDecimalsForRate?: number;
    branches: BranchWebModel[];
    additionalTax2Text: string;
    additionalTax2Enabled: boolean;
    tax1Label: string;
    tax2Rates: string;
    displayCurrencyAfter: boolean;
    additionalTax3Text: string;
    additionalTax3Enabled: boolean;
    displayLeadingZero: boolean;
    thousandsSeparator: string;
    decimalSeparator: string;
    showTimeInProject: boolean;
    financialYearStartingMonth: number;
    
    baseUnit1: string;
    baseUnit1Value: number;
    baseUnit2: string;
    baseUnit2Value: number;

    clientDefaultPaymentTermsType: TpPaymentTermTypes;
    clientDefaultPaymentTermsDate: number;

    resourceDefaultPaymentTermsType: TpPaymentTermTypes;
    resourceDefaultPaymentTermsDate: number;
}

export class BranchWebModel {
    branchId: string;
    branchLogo: string;
    branchName: string;
    dateFormat: string;
    
}

export class ResponseCompanyBranch {
    public id?: number;
    public description?: string | null;
    public insertDate?: string | null;
    public insertUserId?: number | null;
    public remarks?: string | null;
    public isActive?: boolean | null;
    public companyId?: number | null;
    public supervisorPersonId?: number | null;
    public supervisorPersonName?: string | null;
    public address?: string | null;
    public city?: string | null;
    public zipCode?: string | null;
    public countryId?: number | null;
    public countryName?: string | null;
    public currencyId?: number | null;
    public currencyCode?: string | null;
    public currencySymbol?: string | null;
    public currencyName?: string | null;
    public timezoneOffsetDescription?: string | null;
    public timezoneOffsetId?: number | null;
    public weekStartDay?: string | null;
    public workingHourRange?: string | null;
    public cultureInfoLocaleId?: number | null;
    public cultureInfoLocaleCode?: string | null;
    public noOfDecimals?: number | null;
    public thousandSeparator?: string | null;
    public dateFormat?: string | null;
    public decimalSeparator?: string | null;
    public isCentral?: boolean | null;
    public companyLogo?: string;
    public state?: string;
    public phone1?: string;
    public phone2?: string;
    public bccAddress?: string;
    public address2?: string;
    public dateFormatTypeId?: number;
    public startTimeTypeId?: number;
    public startTimeValue: string;
    public endTimeTypeId?: number;
    public endTimeValue: string;
    public languageId?: number;
    public companyBranchFinanceId?: number;
    public companyBranchFinanceData?: ResponseCompanyBranchFinanceData;
    public prefix: string;
    enableClientRequestFlow?:boolean;
    businessRegistration?:string;
}


export class ResponseCompany {
    id?: number;
    name?: string;
    insertDate?: Date;
    displayOrder?: number;
    remarks?: string;
    isActive?: boolean;
    logoPath?: string;
    websiteUrl?: string;
    address?: string;
    address2?: string;
    city?: string;
    zipCode?: string;
    signaturePath?: string;
    phone1?: string;
    phone2?: string;
    email?: string;
    state?: string;
    addressFormat?: string;
    customOutgoingMailSettings?: boolean;
    backgroundLogoPath?: string;
    countryId?: number;
    countryDescription?: string;
    companyLogoSize?: string;
    backgroundLogoSize?: string;
    companyLogoName?: string;
    backgroundLogoName?: string;
    useGlobalNumbering?:boolean;
    companyBranch?: ResponseCompanyBranch;
    enableClientRequestFlow?:boolean;
}

export class PostCompany {
    id?: number;
    name?: string;
    insertDate?: string;
    insertUserId?: number;
    displayOrder?: number;
    remarks?: string;
    isActive?: boolean;
    websiteUrl?: string;
    address?: string;
    city?: string;
    zipCode?: string;
    signaturePath?: string;
    address2?: string;
    state?: string;
    countryId?: number;
    phone1?: string;
    phone2?: string;
    email?: string;
    addressFormat?: string;
    useGlobalNumbering?:boolean;
    customOutgoingMailSettings?: boolean;
    backgroundLogo?: File;
    companyLogo?: File;
    backgroundLogoPath?: string;
    companyLogoPath?: string;
}

export class PutCompany extends PostCompany {
    id?: number;
    companyBranch?: PostCompanyBranch | null;
    companyBranchJSON?: string | null;
}