export class CatCountAnalysisDetails {
    words: number = null;
    discount: number = null;
    discounted: number = 0;
}

export class CatCountAnalysis {
    contextMatch: CatCountAnalysisDetails = new CatCountAnalysisDetails();
    xtranslated: CatCountAnalysisDetails = new CatCountAnalysisDetails();
    repetitions: CatCountAnalysisDetails = new CatCountAnalysisDetails();
    crossFileRepetitions: CatCountAnalysisDetails = new CatCountAnalysisDetails();
    match101: CatCountAnalysisDetails = new CatCountAnalysisDetails();
    match100: CatCountAnalysisDetails = new CatCountAnalysisDetails();
    match9599: CatCountAnalysisDetails = new CatCountAnalysisDetails();
    match8594: CatCountAnalysisDetails = new CatCountAnalysisDetails();
    match7584: CatCountAnalysisDetails = new CatCountAnalysisDetails();
    match5074: CatCountAnalysisDetails = new CatCountAnalysisDetails();
    noMatch: CatCountAnalysisDetails = new CatCountAnalysisDetails();
}