import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {AppConfigService} from "../../../../services/app.settings.service";
import {Observable} from "rxjs";
import {BaseResultModel} from "../../../../models";
import {ResponseWorkflowData} from "../models/response-workflow-data";
import {StatusTypes} from "../../../../utills";
import {PostWorkflow} from "../models/post-workflow";
import {PutWorkflow} from "../models/put-workflow";
import {DropDownItem} from "../../../../models/general/dropdownItem.model";

@Injectable({
  providedIn: 'root'
})
export class WorkflowService {
  private readonly _uri: string = '';
  private endpoint =  '/system';
  constructor(
      private http: HttpClient,
      private configService: AppConfigService,
  ) {
    if (configService) {
      this._uri = configService.getAPIUrl();
    }
  }

  getWorkflows(status: StatusTypes = StatusTypes.all): Observable<BaseResultModel<ResponseWorkflowData[]>> {
    return this.http.get<BaseResultModel<ResponseWorkflowData[]>>(
        this._uri + this.endpoint + '/workflowStep/ ' + status + '/status'
    );
  }
  getWorkflowsDropdown(status: StatusTypes = StatusTypes.all): Observable<BaseResultModel<DropDownItem[]>> {
    return this.http.get<BaseResultModel<DropDownItem[]>>(
        this._uri + this.endpoint + '/workflowStep/status/ ' + status + '/dropdown'
    );
  }
  add(payload: PostWorkflow): Observable<BaseResultModel<ResponseWorkflowData>> {
    return this.http.post<BaseResultModel<ResponseWorkflowData>>(
        this._uri + this.endpoint + '/workflowStep/add',
        payload
    );
  }
  update(payload: PutWorkflow): Observable<BaseResultModel<ResponseWorkflowData>> {
    return this.http.put<BaseResultModel<ResponseWorkflowData>>(
        this._uri + this.endpoint + '/workflowStep/update',
        payload
    );
  }
  delete(ids: number[]): Observable<BaseResultModel<boolean>> {
    return this.http.delete<BaseResultModel<boolean>>(
        this._uri + this.endpoint + '/workflowStep/delete',
        {
          params: new HttpParams({fromObject:{'workflowStepIds': ids}})
        }
    );
  }
}
