import { Inject, Injectable } from '@angular/core';
import {
    CompanyEventModel,
    CompanyEventTypes,
    HolidayCompanyEventText,
} from 'app/main/admin/company-events/models/company-event.model';
import { CompanyEventsSearchCriteriaModel } from 'app/main/admin/company-events/models/company-events-search-criteria.model';
import { AdminCompanyEventsService } from 'app/main/admin/company-events/services/admin-company-events.service';
import { GlobalHolidayModel, SearchResult } from 'app/models/general';
import { Constants } from 'app/shared.constants';
import { getYear } from 'date-fns';
import { StorageService } from 'ngx-webstorage-service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { CORE_SESSION_STORAGE } from '../storage/tp-storage.service.ts';

@Injectable({
    providedIn: 'root',
})
export class GlobalHolidaysService {
    
    constructor(private adminCompanyEventsService: AdminCompanyEventsService,
                @Inject(CORE_SESSION_STORAGE) private sessionStorage: StorageService) {
    }
    
    getGlobalHolidays(): Observable<GlobalHolidayModel[]> {
        const persistedData = this.sessionStorage.get(
            Constants.GLOBAL_HOLIDAYS_STORAGE_KEY,
        ) as string;
        if (persistedData) {
            return of(<GlobalHolidayModel[]>JSON.parse(persistedData));
        } else {
            return of([] as GlobalHolidayModel[]);
        }
    }
    
    refreshGlobalHolidays(): Observable<void> {
        const thisYear = getYear(Date.now());
        return this.adminCompanyEventsService.searchCompanyEvents({
            showAll: true,
            searchCriteria: {
                startDate: new Date(thisYear - 1, 0, 0, 0, 0, 0, 0),
                endDate: new Date(thisYear - 1, 0, 0, 0, 0, 0, 0),
                eventTypes: [Object.values(CompanyEventTypes).find(value => value.text === HolidayCompanyEventText).numValue],
            } as CompanyEventsSearchCriteriaModel,
        }).pipe(map((holidays: SearchResult<CompanyEventModel>) => {
            const mapHolidaysDates = (holidays: CompanyEventModel[]): GlobalHolidayModel[] => holidays?.map(holiday => ({
                id: holiday.id,
                holidayName: holiday.eventName,
                startDate: new Date(holiday.startDate),
                endDate: new Date(holiday.endDate),
            } as GlobalHolidayModel)) || [];
            
            this.sessionStorage.set(
                Constants.GLOBAL_HOLIDAYS_STORAGE_KEY,
                JSON.stringify(mapHolidaysDates(holidays.result)),
            );
        }));
    }
}