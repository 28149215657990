import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CompanyAnnouncementModel } from "app/models/general/messages.model";
import { AppConfigService } from "app/services/app.settings.service";
import { environment } from "environments/environment";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root',
})

export class CompanyAnnouncementService {
    private _uri = ''

    constructor(
        private http: HttpClient,
        configService: AppConfigService,
    ) {
        this._uri = configService.getAPIUrl()
    }

    get(): Observable<CompanyAnnouncementModel[]> {
        return this.http.get<CompanyAnnouncementModel[]>(environment.apiUrl + '/system/company/announcements');
    }

    update(payload: CompanyAnnouncementModel[]): Observable<CompanyAnnouncementModel[]> {
        return this.http.put<CompanyAnnouncementModel[]>(environment.apiUrl + '/system/company/announcements', payload);
    }
}