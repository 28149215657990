import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TpDropdownModule } from 'app/components/tp-dropdown/tp-dropdown.module'
import { TpUnitsDropDownComponent } from 'app/components/tp-units-dropdown/tp-units-dropdown.component'

@NgModule({
    declarations: [TpUnitsDropDownComponent],
    imports: [CommonModule, ReactiveFormsModule, TpDropdownModule, FormsModule, TranslateModule],
    exports: [TpUnitsDropDownComponent],
})
export class TpUnitsDropdownModule {}