
export class UserData {
    id?:number;
    userName?:string;
    password?:string;
    insertDate?:Date;
    insertUserId?:number;
    companyBranchId?:number;
    personId?:number;
    userRoleId?:number;
    lastLoginDate?:Date;
    isActive?:boolean;
    userRoleDescription?:string;
    lastChangeDate?:Date;
    lastChangeUserId?:number;
    lastChangeUserName:string;
    personName?:string;
    companyBranchDescription?:string;
    projectManagerId?:number;
    clientId: number;
    clientContactId: number;
    resourceId: number;
    portalId: number;
    imagesOverText?:boolean;
    personData?: any;
    portalUrl?:string;
}

export class ResponseSamlUser {
    name?:string;
    fName ?:string;
    lName ?:string;
    email ?:string;
    collectCode ?:string;
    collect2Code ?:string;
    paymentCode ?:string;
    departments ?:OrganizationDepartment[];
    newPassword ?:string;
    mainDomainName ?:string;
}

export class PostUserRegister {
    email?: string;
    password?: string;
    token?: string;
    name?:string;
}

export class PostIdpUser extends PostUserRegister {
    name?:string;
    fName ?:string;
    lName ?:string;
    email ?:string;
    collectCode ?:string;
    collect2Code ?:string;
    paymentCode ?:string;
    departments ?:OrganizationDepartment[];
    password ?:string;
    mainDomainName ?:string;
}

export class Affilitation {
    affiliationType: string;
    affiliationId: string;
}

export class OrganizationDepartment {
    departmentName?: string;
    departmentArea?: string;
    affiliationInfo?: Affilitation;
    phoneNumber?: string;
    location?: string;
    specialCode?: string;
}