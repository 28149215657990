import {Injectable} from '@angular/core';
import {DropDownGroupBy, DropDownGroupedBy, DropDownItem} from "../../models/general/dropdownItem.model";
import {TranslateService} from "@ngx-translate/core";
import {FindResourceDropdownItem} from "../../main/pm/resources/models/find-resource-dropdown-item";
import {WorkflowDropdownItem, WorkflowDropdownItemEntity} from "../../models/dropdowns/workflow-dropdown-item";

@Injectable({
    providedIn: 'root'
})
export class PredefinedDropdownGroupService {

    constructor(
        private translateService: TranslateService
    ) {
    }

    dropdownGroupByActive(): DropDownGroupBy {
        return {
            status: true,
            toggle: false,
            hasMultiple: false,
            toggleLabelAsync: this.translateService.stream('tp-show-inactive', {defaultText: 'Show inactive'}),
            group: (item: DropDownItem) => {
                if (item.isActive == true) return 'active';
                else if (item.isActive == false) return 'inactive';
                else return 'unknown';
            },
            label: (group: DropDownGroupedBy, groups: DropDownGroupedBy[]) => {
                const activeLabel = this.translateService.stream('tp-active', {defaultText: 'Active'});
                const inactiveLabel = this.translateService.stream('tp-inactive', {defaultText: 'Inactive'});
                switch (group.group) {
                    case 'active':
                        return {display: groups.length > 1, textAsync: activeLabel};
                    case 'inactive':
                        return {display: true, textAsync: inactiveLabel};
                }
            },
            display: function (group: DropDownGroupedBy) {
                switch (group.group) {
                    case 'inactive':
                        return this.toggle;
                    default:
                        return true;
                }
            }
        };
    }

    dropdownGroupByRate(): DropDownGroupBy {
        return {
            status: true,
            toggle: true,
            hasMultiple: false,
            toggleLabelAsync: this.translateService.stream('tp-show-unmatched', {defaultText: 'Show unmatched'}),
            group: (item: FindResourceDropdownItem) => {
                if (item.rate != null) return 'matched';
                else if (item.rate == null) return 'unmatched';
                else return 'unknown';
            },
            label: (group: DropDownGroupedBy, groups: DropDownGroupedBy[]) => {
                const matchedLabel = this.translateService.stream('tp-matched', {defaultText: 'Matched'});
                const unmatchedLabel = this.translateService.stream('tp-unmatched', {defaultText: 'Unmatched'});
                switch (group.group) {
                    case 'matched':
                        return {display: groups.length > 1, textAsync: matchedLabel};
                    case 'unmatched':
                        return {display: true, textAsync: unmatchedLabel};
                }
            },
            display: function (group: DropDownGroupedBy) {
                switch (group.group) {
                    case 'unmatched':
                        return this.toggle;
                    default:
                        return true;
                }
            }
        };
    }
    dropdownGroupByWorkflow(): DropDownGroupBy {
        return {
            status: true,
            toggle: true,
            hasMultiple: false,
            toggleLabelAsync: this.translateService.stream('tp-show-steps', {defaultText: 'Show steps'}),
            group: (item: WorkflowDropdownItem) => {
                if (item.entity === WorkflowDropdownItemEntity.Workflow) return WorkflowDropdownItemEntity.Workflow;
                else if (item.entity === WorkflowDropdownItemEntity.WorkflowStep) return WorkflowDropdownItemEntity.WorkflowStep;
                else return 'unknown';
            },
            label: (group: DropDownGroupedBy, groups: DropDownGroupedBy[]) => {
                const matchedLabel = this.translateService.stream('tp-associated-workflows', {defaultText: 'Associated workflows'});
                const unmatchedLabel = this.translateService.stream('tp-workflow-steps', {defaultText: 'Workflow Steps'});
                switch (group.group) {
                    case WorkflowDropdownItemEntity.Workflow:
                        return {display: groups.length > 1, textAsync: matchedLabel};
                    case WorkflowDropdownItemEntity.WorkflowStep:
                        return {display: true, textAsync: unmatchedLabel};
                }
            },
            display: function (group: DropDownGroupedBy) {
                switch (group.group) {
                    case WorkflowDropdownItemEntity.WorkflowStep:
                        return this.toggle;
                    default:
                        return true;
                }
            }
        };
    }
}
