import { Component, forwardRef, Input, OnChanges, OnInit, Optional, SimpleChanges } from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from "@angular/forms";
import { BaseControlComponentDirective } from "../base-control-component.directive";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, combineLatest, Observable, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import {
    WorkflowDropdownItem,
    WorkflowDropdownItemEntity,
    WorkflowDropdownValue
} from "../../models/dropdowns/workflow-dropdown-item";
import { serviceDropdownEntity, workflowDropdownEntity } from "../../store/reducers";
import { Store } from "@ngrx/store";
import { State } from "../../store";
import { PredefinedDropdownGroupService } from "../../services/predefined/predefined-dropdown-group.service";
import { RefDataService } from '../../services/general/refData.service';
import { DropDownItem } from '../../models/general/dropdownItem.model';

@Component({
    selector: 'tp-workflow-dropdown',
    templateUrl: './workflow-dropdown.component.html',
    styleUrls: ['./workflow-dropdown.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => WorkflowDropdownComponent),
            multi: true,
        },
    ],
})
export class WorkflowDropdownComponent extends BaseControlComponentDirective implements OnInit, OnChanges {
    @Input() set value(value: WorkflowDropdownValue) {
        this.workflowValue$.next(value);
    }

    @Input() selectedServiceId: number;
    workflowValue$ = new BehaviorSubject<WorkflowDropdownValue>({ workflows: [], workflowSteps: [] });
    workflowOptions$: Observable<WorkflowDropdownItem[]>;
    workflowDropdownItems: DropDownItem[] = []; // Local storage for dropdown items
    serviceDropdownItems: DropDownItem[] = []; // Local storage for dropdown items

    dropdownGroupByWorkflow = this.predefinedDropdownGroupService.dropdownGroupByWorkflow();

    constructor(
        @Optional() protected readonly controlContainer: ControlContainer,
        protected readonly translateService: TranslateService,
        private refDataService: RefDataService,
        private store: Store<State>,
        private predefinedDropdownGroupService: PredefinedDropdownGroupService
    ) {
        super(controlContainer, translateService);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.selectedServiceId && this.selectedServiceId != null && this.selectedServiceId !== 0) {
            this.loadDataFromServer();
        } else {
            this.initializeWorkflowOptions();
        }
    }

    ngOnInit() {
        if (this.selectedServiceId != null && this.selectedServiceId !== 0) {
            this.loadDataFromServer();
        } else {
            this.initializeWorkflowOptions();
        }
    }

    loadDataFromServer() {
        this.refDataService.getDropdownServicesWorkflowStepsByServiceId(this.selectedServiceId).subscribe(data => {
            this.workflowDropdownItems = data.response.filter(item => item.useAsDefaultFilter === true);
            this.serviceDropdownItems = data.response.filter(item => item.useAsDefaultFilter === false);
            this.initializeWorkflowOptions(); // After loading data, initialize options
        });
    }

    initializeWorkflowOptions() {
        const workflows$ = of(this.workflowDropdownItems.map(item => this.mapToWorkflowItem(item)));

        const serviceSteps$ = of(this.serviceDropdownItems.map(item => this.mapToWorkflowItem(item)));

        this.workflowOptions$ = combineLatest([
            workflows$,
            serviceSteps$,
            this.workflowValue$
        ]).pipe(
            map(([workflows, workflowSteps, value]) => {
                const allItems = [...workflows, ...workflowSteps];

                if (value) {
                    const selectedItems = allItems.filter(item => {
                        return value.workflows.includes(item.entityId) ||
                            value.workflowSteps.includes(item.entityId);
                    });

                    if (selectedItems.length > 0) {
                        this.control.setValue(selectedItems, { emitEvent: false });
                    }
                }

                return allItems;
            })
        );
    }

    mapToWorkflowItem(item: DropDownItem): WorkflowDropdownItem {
        return {
            ...item,
            id: Math.random().toString(16).slice(2),
            entityId: item.id,
            entity: item.useAsDefaultFilter ? WorkflowDropdownItemEntity.Workflow : WorkflowDropdownItemEntity.WorkflowStep
        };
    }

    onOption(e: WorkflowDropdownItem) {
        const workflows: WorkflowDropdownItem[] = this.control.value;
        if (e.entity === WorkflowDropdownItemEntity.Workflow) {
            if (workflows?.length > 0) {
                this.control.setValue(
                    workflows.filter(
                        workflow => workflow.entity === WorkflowDropdownItemEntity.WorkflowStep || workflow.id === e.id
                    ),
                    { emitEvent: false }
                );
            }
        }
    }

    getWorkflowValue(): WorkflowDropdownValue {
        const workflowItems: WorkflowDropdownItem[] = this.control.value ?? [];
        const workflowSteps = workflowItems.filter(
            workflowItem => workflowItem.entity === WorkflowDropdownItemEntity.WorkflowStep
        ).map(
            workflowItem => workflowItem.entityId
        );
        const workflows = workflowItems.filter(
            workflowItem => workflowItem.entity === WorkflowDropdownItemEntity.Workflow
        ).map(
            workflowItem => workflowItem.entityId
        );
        return { workflows, workflowSteps };
    }
}
