import { Injectable } from '@angular/core';
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class DataService {

    hideDropdown: boolean = true;

    refreshProjectFiles$ = new Subject<boolean>();

    constructor() { }
}
