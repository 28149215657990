export enum RoleTypes{
    Administrator = 1,
    ProjectManager = 2,
    Accountant = 3,
    Resource = 4,
    Client = 5,
    ResourceManager = 6,
    CommsManager = 7,
    OpsManager = 8,
    LinguisticManager = 9,
    Manager = 10,
    QualityManager = 11,
    InHouseResource = 14,
    ClientContact = 15,
}