import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ResponseResourceJobGridData} from 'app/main/resource-view/models/response-models/response-resource-job-grid-data';
import {BaseResultModel, PriceListModel, SearchCriteriaModel} from 'app/models';
import {DropDownOptionsModel, FilePreviewModel, TimeFrameEnumForTotals, TotalsModel} from 'app/models/general';
import {DropDownItem} from 'app/models/general/dropdownItem.model';
import {ResourceSearch} from 'app/models/general/system-models/search-models/resource/resource-search';
import {SearchParameter} from 'app/models/general/system-models/search-models/search-parameter';
import {SearchResult} from 'app/models/general/system-models/search-models/search-result';
import {AppConfigService} from 'app/services/app.settings.service';
import {ApirequestService, HttpVerb} from 'app/services/general/api-service.service';
import {CORE_SESSION_STORAGE} from 'app/services/storage/tp-storage.service.ts';
import {Constants} from 'app/shared.constants';
import {environment} from 'environments/environment';
import {StorageService} from 'ngx-webstorage-service';
import {Observable, Subject} from 'rxjs';
import {map, startWith, tap} from 'rxjs/operators';
import {
    ResourceAgreedRateRequestModel,
    ResourceAgreedRateResponseModel,
    ResourceCatCountModel,
} from '../../resources/models';
import {PostResource} from '../../resources/models/post-models/post-resource';
import {
    ResponseResourceData,
    ResponseResourceJobContacted
} from '../../resources/models/response-models/response-resource-data';
import {ResponseResourceGridData} from '../../resources/models/response-models/response-resource-grid-data';
import {StatusTypes} from "../../../../utills";
import {ResponseUserGridData} from 'app/main/admin/models/user.model';
import {FindResourceSearch} from "../../resources/models/find-resource-search";
import {ResponseResourceServiceRateData} from "../../resources/models/response-models/response-resource-service-rate-data";
import {FindResourceDropdownItem} from "../../resources/models/find-resource-dropdown-item";
import {ResponseResourceJobEstimationData} from "../../resources/models/response-models/response-resource-job-estimation-data";
import {ResponseClientPricingData} from "../../clients/models/client-pricing";
import {PostResourceServiceRate} from "../../resources/models/post-models/post-resource-service-rate";
import { ResponseResourcePaymentTerm } from '../../resources/models/response-models/response-resource-payment-terms';
import { ResponseResourceJobCatCount } from 'app/models/general/resource/response-models/response-resource-job-cat-count';
import { PostResourceJobCatCount } from 'app/models/general/resource/post-models/post-resource-job-cat-count';
import { PutResourceJobCatCount } from 'app/models/general/resource/put-models/put-resource-job-cat-count';
import {
    PostResourceCatCountDiscounts,
    ResponseResourceCatCountDiscounts
} from "../../resources/models/resourceCatCount.model";
import {ResponseResourceJobTaskDataGrid} from "../../projects/models/project.model";
import {ResponseEmailTemplateData} from "../../../admin/email-templates/models/response-email-template-data";

export interface ResourceSearchCriteria {
    resources?: string[],
    resourceFirstName?: string,
    resourceLastName?: string,
    countries?: string[],
    state?: string,
    city?: string,
    keyword?: string,
    showDeleted?: boolean,
    dateAddedFrom?: Date,
    dateAddedTo?: Date,
    lastModifiedFrom?: Date,
    lastModifiedTo?: Date,
    industrySectors?: string[],
    services?: string[],
    tags?: string[],
    clientExperience?: string[],
    availabilityFrom?: Date,
    availabilityTo?: Date,
    softwares?: string[],
    resourceTypes?: string[],
    showActiveOnly?: boolean,
    showCertified?: boolean,
    showTested?: boolean,
    maximumRate?: number,
    serviceIdForRate?: string,
    nativeLanguages?: string[],
    teamMemberships?: string[],
    showAvailableOnly?: boolean,
    ratingGreaterThan?: number,
    statuses?: string[],
    gender?: string,
    lastJob?: string
}

@Injectable({
    providedIn: 'root',
})
export class ResourceService {

    private readonly _uri: string = '';
    private endpoint = '/resource';

    private refreshRefResourceObservers$ = new Subject();

    constructor(
        private http: HttpClient,
        configService: AppConfigService,
        private translate: TranslateService,
        @Inject(CORE_SESSION_STORAGE) private sessionStorage: StorageService,
        private api:ApirequestService
    ) {
        if (configService) {
            this._uri = configService.getAPIUrl();
        }
    }

    search(model: SearchCriteriaModel<ResourceSearch>,): Observable<BaseResultModel<SearchResult<ResponseResourceGridData>>> {
        return this.http.post<BaseResultModel<SearchResult<ResponseResourceGridData>>>(`${this._uri}${this.endpoint}/search`, model);
    }

    searchWithClientId(model: SearchParameter<ResourceSearch>): Observable<BaseResultModel<SearchResult<ResponseResourceGridData>>> {
        return this.api.ApiRequest<BaseResultModel<SearchResult<ResponseResourceGridData>>>(environment.apiUrl + this.endpoint + '/client/search',model,new HttpHeaders(),HttpVerb.post,'json');
    }
    /**
     * @function getResourceByIdGridData
     * @param id: number
     * @description get resource grid data based on resource id
     * @return Observable<BaseResultModel<ResponseResourceGridData>>
     */
    getResourceByIdGridData(id: number): Observable<BaseResultModel<ResponseResourceGridData>> {
        return this.http.get<BaseResultModel<ResponseResourceGridData>>(`${this._uri}${this.endpoint}/${id}/grid`);
    }
    /**
     * @function getCapacityPerDayByResourceId
     * @param id: number
     * @description get capacity per day base on resource id
     * @return Observable<BaseResultModel<ResponseResourceGridData>>
     */
    getCapacityPerDayByResourceId(id: number): Observable<BaseResultModel<ResponseResourceGridData>> {
        return this.http.get<BaseResultModel<ResponseResourceGridData>>(`${this._uri}${this.endpoint}/${id}/resourceavailability`);
    }

    searchDropdown(model: FindResourceSearch): Observable<BaseResultModel<FindResourceDropdownItem[]>> {
        return this.http.post<BaseResultModel<FindResourceDropdownItem[]>>(`${this._uri}${this.endpoint}/findResource/dropdown`, model,);
    }

    getResourcesDropdown(statusId: StatusTypes = StatusTypes.active): Observable<BaseResultModel<DropDownItem[]>> {
        return this.http.get<BaseResultModel<DropDownItem[]>>(`${this._uri}${this.endpoint}/status/${statusId}/dropdown`);
    }

    getResourceJobsDropdown(statusId: StatusTypes = StatusTypes.active): Observable<BaseResultModel<DropDownItem[]>> {
        return this.http.get<BaseResultModel<DropDownItem[]>>(this._uri + '/resourceJob/'+ statusId + '/dropdown');
    }
    /**
     * @function getResourceJobDropdownByResourceId
     * @param {number} resourceId
     * @description api call - get active resource jobs for a resource
     * @return Observable<BaseResultModel<DropDownItem[]>>
     */
    getResourceJobDropdownByResourceId(resourceId: number): Observable<BaseResultModel<DropDownItem[]>> {
        return this.http.get<BaseResultModel<DropDownItem[]>>(this._uri + '/resourceJob/dropdown/'+ resourceId + '/active');
    }
    /**
     * @function getAssignedResourceJobDropdownByResourceId
     * @param {number} resourceId
     * @param {string} dateTime
     * @description api call - get assigned resource jobs for a resource
     * @return  Observable<BaseResultModel<DropDownItem[]>>
     */
    getAssignedResourceJobDropdownByResourceId(resourceId: number, dateTime: string): Observable<BaseResultModel<DropDownItem[]>> {
        return this.http.get<BaseResultModel<DropDownItem[]>>(this._uri + '/resourceJob/dropdown/'+ resourceId + '/active/assigned/' + dateTime);
    }
    /**
     * @function getResourceJobEstimation
     * @param {number} resourceId
     * @param {number} resourceJobId
     * @param {number} plannedHours
     * @description api call - calculate resource job estimation
     * @return ResponseResourceJobEstimationData
     */
    getResourceJobEstimation(resourceId: number, resourceJobId:number, plannedHours: number): Observable<BaseResultModel<ResponseResourceJobEstimationData>> {
        return this.http.get<BaseResultModel<ResponseResourceJobEstimationData>>(this._uri + '/resourceJob/estimation/'+ resourceId + '/' + resourceJobId + '/' + plannedHours);
    }

    getActiveResourcesWithCurrencyDropdown(): Observable<BaseResultModel<DropDownItem[]>> {
        return this.http.get<BaseResultModel<DropDownItem[]>>(`${this._uri}${this.endpoint}/with-currency/dropdown`);
    }

    getContactedResourcesForJob(resourceJobId: number): Observable<BaseResultModel<ResponseResourceJobContacted[]>> {
        return this.api.ApiRequest<BaseResultModel<ResponseResourceJobContacted[]>>(this._uri + '/resourceJob/' + resourceJobId + '/notified-resources','',new HttpHeaders(),HttpVerb.get,'json');
    }

    assignResource(resourceJobId: number,resourceId:number): Observable<BaseResultModel<FindResourceDropdownItem>> {
        return this.api.ApiRequest<BaseResultModel<FindResourceDropdownItem>>(this._uri + '/resourceJob/' + resourceJobId + '/resource/' + resourceId + '/assign-resource','',new HttpHeaders(),HttpVerb.post,'json');
    }

    getResourceServicesById(resourceId: number): Observable<BaseResultModel<ResponseResourceServiceRateData[]>> {
        const endpoint = `${this._uri}${this.endpoint}/serviceRate/resource/${resourceId}`;
        return this.http.get<BaseResultModel<ResponseResourceServiceRateData[]>>(`${endpoint}`);
    }

    saveResourceServices(services: ResponseResourceServiceRateData[]): Observable<BaseResultModel<ResponseResourceServiceRateData[]>> {
        return this.http.put<BaseResultModel<ResponseResourceServiceRateData[]>>(`${this._uri}${this.endpoint}/serviceRate/bulkUpdate`, services);
    }

    findResourcesForResourceJobDropdown(search: FindResourceSearch, statusId: StatusTypes = StatusTypes.active): Observable<BaseResultModel<DropDownItem[]>> {
        return this.http.get<BaseResultModel<DropDownItem[]>>(this._uri + this.endpoint + /status/ + statusId + '/' + search.serviceId + '/' + search.unitId + '/' +search.tagCategoryId + '/' + search.sourceLanguageId + '/' +search.targetLanguageId +  '/dropdown');

    }

    /**
     * @description sends invitation email to selected resources to accept or reject a job
     * @param resourceIds number[]
     * @param resourceJobId number
     * @param template ResponseEmailTemplateData
     * @returns BaseResultModel<boolean>
     */
     sendResourceJobInvitationJson(resourceIds: number[], resourceJobId: number, template: ResponseEmailTemplateData = null): Observable<BaseResultModel<boolean>> {
         const useDefaultTemplate = {customTemplate: null};
         return this.http.post(environment.apiUrl + this.endpoint + '/save-send/job/' + resourceJobId, template ?? useDefaultTemplate, {
             params: new HttpParams({fromObject: {postResources: resourceIds}})
         });
    }

     /**
     * @description sends invitation email to selected resources to accept or reject a job
     * @param resourceIds number[]
     * @param resourceJobId number
     * @param formdata FormData
     * @returns BaseResultModel<boolean>
     */
     sendResourceJobInvitation(resourceIds: number[], resourceJobId: number, formdata:FormData=null): Observable<BaseResultModel<boolean>> {
        const useDefaultTemplate = {customTemplate: null};
        return this.http.post(environment.apiUrl + this.endpoint + '/save-send/job/' + resourceJobId, formdata, {
            params: new HttpParams({fromObject: {postResources: resourceIds}})
        });
   }

    updateResource(formData): Observable<BaseResultModel<ResponseResourceData>> {
        const endpoint = `${this._uri}${this.endpoint}`;
        return this.http.put<BaseResultModel<ResponseResourceData>>(`${endpoint}`, formData);
    }

    addResource(formData): Observable<BaseResultModel<ResponseResourceData>> {
        const endpoint = `${this._uri}${this.endpoint}/data`;
        return this.http.post<BaseResultModel<ResponseResourceData>>(`${endpoint}`, formData);
    }
    /**
     * @function addResourceWithFiles
     * @description add resource with files
     * @param formData:FormData
     * @returns BaseResultModel<ResponseResourceData>
     */
    addResourceWithFiles(formData: FormData): Observable<BaseResultModel<ResponseResourceData>> {
        const endpoint = `${this._uri}${this.endpoint}/add/with-files`;
        return this.http.post<BaseResultModel<ResponseResourceData>>(`${endpoint}`, formData);
    }

    addResourceFromUserId(id: number): Observable<BaseResultModel<number>> {
        return this.api.ApiRequest<BaseResultModel<number>>(this._uri + this.endpoint + '/from-user',id, new HttpHeaders(), HttpVerb.post, 'json');
    }

    validateResource(model: PostResource): Observable<any> {
        const endpoint = `${this._uri}${this.endpoint}/validateResource`;
        return this.http.post<any>(`${endpoint}`, model);
    }

    /**
     * @description returns all data for one response based on submitted Id
     * @param id:number
     * @returns BaseResultModel<ResponseResourceData>
     */
    getResource(id: number): Observable<BaseResultModel<ResponseResourceData>> {
        return this.api.ApiRequest<BaseResultModel<ResponseResourceData>>(environment.apiUrl + this.endpoint + '/' + id,'',new HttpHeaders(),HttpVerb.get,'json');
    }

    deleteResources(ids: number[]): Observable<any> {
        const qString = `ids=${ids.join('&ids=')}`;
        const endpoint = `${this._uri}${this.endpoint}?${qString}`;
        return this.http.delete<null>(`${endpoint}`);
    }

    updateResourcesLastContactDate(ids: number[]): Observable<any> {
        let params = new HttpParams();
        ids.forEach(id => {
            params = params.append(environment.apiConstants.bulkIdsQueryParam, id.toString());
        });
        return this.http.post<null>(`${this._uri}${this.endpoint}/updateLastContactDate`, {}, { params });
    }

    addResourceRates(model: PostResourceServiceRate[]):Observable<BaseResultModel<ResponseResourceServiceRateData[]>> {
        return this.api.ApiRequest<BaseResultModel<ResponseResourceServiceRateData[]>>(environment.apiUrl + this.endpoint + '/serviceRate/multiple',model,new HttpHeaders(),HttpVerb.post,'json');
    }

    updateResourceRates(model: PostResourceServiceRate[]): Observable<BaseResultModel<ResponseResourceServiceRateData[]>> {
        return this.api.ApiRequest<BaseResultModel<ResponseResourceServiceRateData[]>>(environment.apiUrl + this.endpoint + '/serviceRate/bulkUpdate',model,new HttpHeaders(),HttpVerb.put,'json');
    }
    updateResourceRate(model: PostResourceServiceRate): Observable<BaseResultModel<ResponseResourceServiceRateData[]>> {
        return this.api.ApiRequest<BaseResultModel<ResponseResourceServiceRateData[]>>(environment.apiUrl + this.endpoint + '/serviceRate/update',model,new HttpHeaders(),HttpVerb.put,'json');
    }
    deleteResourceRatesByIds(ids: number[]): Observable<BaseResultModel<boolean>> {
        return this.http.post<BaseResultModel<boolean>>(environment.apiUrl + this.endpoint + '/serviceRate/delete', ids);
    }
    /**
     * @function requestBakingDetailsByResourceId
     * @param id: number
     * @description request baking details
     * @return Observable<BaseResultModel<boolean>>
     */
    requestBakingDetailsByResourceId(id: number): Observable<BaseResultModel<boolean>> {
        return this.http.post<BaseResultModel<boolean>>(`${this._uri}${this.endpoint}/bankingDetails/${id}/send`,{});
    }

    saveResourceCatCount(payload: PostResourceCatCountDiscounts): Observable<BaseResultModel<ResponseResourceCatCountDiscounts>> {
        return payload.id ? this.updateResourceCatCount(payload) : this.addResourceCatCount(payload);
    }
    addResourceCatCount(payload: PostResourceCatCountDiscounts): Observable<BaseResultModel<ResponseResourceCatCountDiscounts>> {
        const endpoint = `${this._uri}${this.endpoint}/cat-count`;
        return this.http.post<BaseResultModel<ResponseResourceCatCountDiscounts>>(`${endpoint}`, payload);
    }
    updateResourceCatCount(payload: PostResourceCatCountDiscounts): Observable<BaseResultModel<ResponseResourceCatCountDiscounts>> {
        const endpoint = `${this._uri}${this.endpoint}/cat-count`;
        return this.http.put<BaseResultModel<ResponseResourceCatCountDiscounts>>(`${endpoint}`, payload);
    }

    getRefResource(): Observable<DropDownOptionsModel[]> {
        return this.refreshRefResourceObservers$.pipe(startWith(undefined))
            .pipe(map(() => {
                const persistedData: string = this.sessionStorage.get(
                    Constants.RESOURCE_STORAGE_KEY,
                ) as string;
                if (persistedData) {
                    return <DropDownOptionsModel[]>JSON.parse(persistedData);
                } else {
                    return <DropDownOptionsModel[]>[];
                }
            }));
    }

    refreshRefResource(): Observable<void> {
        return this.http.get<DropDownOptionsModel[]>(`${this._uri}/api/resource/refResources`)
            .pipe(map((result) => {
                this.sessionStorage.set(Constants.RESOURCE_STORAGE_KEY, JSON.stringify(result));
                this.refreshRefResourceObservers$.next();
                return;
            }));
    }

    getAgreedRate(
        model: ResourceAgreedRateRequestModel,
    ): Observable<ResourceAgreedRateResponseModel> {
        const endpoint = `${this._uri}${this.endpoint}/agreedRate`;
        return this.http.post<ResourceAgreedRateResponseModel>(
            `${endpoint}`,
            model,
        );
    }

    saveAgreedRate(resourceId: string, model: PriceListModel): Observable<any> {
        const endpoint = `${this._uri}${this.endpoint}/${resourceId}/savePrice`;
        return this.http.put<PriceListModel>(`${endpoint}`, model);
    }

    getCatCount(id: string): Observable<ResourceCatCountModel> {
        const endpoint = `${this._uri}${this.endpoint}/${id}/catCount`;
        return this.http.get<ResourceCatCountModel>(`${endpoint}`);
    }

    getResourceLogins(
        resourceId: number,
    ): Observable<BaseResultModel<ResponseUserGridData[]>> {
        const endpoint = `${this._uri}${this.endpoint}/${resourceId}/userlogins`;
        return this.http.get<BaseResultModel<ResponseUserGridData[]>>(
            `${endpoint}`,
        );
    }

    deleteResourceLogin(resourceId: string, userId: string): Observable<any> {
        const endpoint = `${this._uri}${this.endpoint}/${resourceId}/${userId}/resourceLogin`;
        return this.http.delete<any>(`${endpoint}`);
    }

    exportResourceGrid<T>(search: SearchCriteriaModel<T>, previewType: string): Observable<FilePreviewModel> {
        const endpoint = `${this._uri}${this.endpoint}/export/${previewType}`;

        return this.http.post<FilePreviewModel>(`${endpoint}`, search);
    }

    getNoOfActiveResources(timeFrame: TimeFrameEnumForTotals): Observable<TotalsModel> {
        const endpoint = `${this._uri}${this.endpoint}/activeResources?timeFrameFiltersForTotals=${timeFrame}`;
        return this.http.get<TotalsModel>(`${endpoint}`);
    }

    getNoOfNewResources(timeFrame: TimeFrameEnumForTotals): Observable<TotalsModel> {
        const endpoint = `${this._uri}${this.endpoint}/newResources?timeFrameFiltersForTotals=${timeFrame}`;
        return this.http.get<TotalsModel>(`${endpoint}`);
    }

    getNoOfResourceJobs(timeFrame: TimeFrameEnumForTotals): Observable<TotalsModel> {
        const endpoint = `${this._uri}${this.endpoint}/resourceJobCount?timeFrameFiltersForTotals=${timeFrame}`;
        return this.http.get<TotalsModel>(`${endpoint}`);
    }

    getAvgResourceJobSize(timeFrame: TimeFrameEnumForTotals): Observable<TotalsModel> {
        const endpoint = `${this._uri}${this.endpoint}/averageJobSize?timeFrameFiltersForTotals=${timeFrame}`;
        return this.http.get<TotalsModel>(`${endpoint}`);
    }

    /***
     * @description updates multiple client jobs in db
     * @param model: ResponseProjectDetailJobData[]
     * @returns Observable<BaseResultModel<boolean>>
     */
     getDropDownResources(statusId:number): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + '/resource/status/' + statusId + '/dropdown','',new HttpHeaders(),HttpVerb.get,'json');
    }

    /***
     * @description updates multiple client jobs in db
     * @param model: ResponseProjectDetailJobData[]
     * @returns Observable<BaseResultModel<boolean>>
     */
    updateBulkResourceJobs(model: ResponseResourceJobGridData[]): Observable<BaseResultModel<boolean>> {
        const requestModel = {
            ResourceJobs: model
        }
        return this.api.ApiRequest<BaseResultModel<boolean>>(environment.apiUrl + '/resourcejob/bulkUpdate',requestModel,new HttpHeaders(),HttpVerb.put,'json');
    }

    markResourceJobsAsComplete(ids: number[]): Observable<BaseResultModel<boolean>> {
        const model = {
            ResourceJobIds: ids
        }
        return this.api.ApiRequest<BaseResultModel<boolean>>(environment.apiUrl + '/resourcejob/markAsComplete', model, new HttpHeaders(),HttpVerb.put,'json');
    }

    addResourceJobTask(data): Observable<BaseResultModel<ResponseResourceJobTaskDataGrid>> {
        return this.api.ApiRequest<BaseResultModel<ResponseResourceJobTaskDataGrid>>(environment.apiUrl + '/resourceJob/resourceJobTask',data,new HttpHeaders(),HttpVerb.post,'json');
    }

    updateResourceJobTask(data): Observable<BaseResultModel<ResponseResourceJobTaskDataGrid>> {
        return this.api.ApiRequest<BaseResultModel<ResponseResourceJobTaskDataGrid>>(environment.apiUrl + '/resourceJob/resourceJobTask',data,new HttpHeaders(),HttpVerb.put,'json');
    }

    deleteResourceJobTask(ids: number[]): Observable<BaseResultModel<boolean>> {
         return this.http.delete<BaseResultModel<boolean>>(environment.apiUrl + '/resourceJob/resourceJobTask/delete',{params: new HttpParams().set('ids',`${ids}`)})
    }

    updateResourceJobStatus(data): Observable<BaseResultModel<boolean>> {
        return this.api.ApiRequest<BaseResultModel<boolean>>(environment.apiUrl + '/resourceJob/changeResourceJobStatus',data,new HttpHeaders(),HttpVerb.put,'json');
    }

    /**
     * @description returns registered payment terms for a specific user
     * @param resourceId 
     * @returns BaseResultModel<ResponseResourcePaymentTerm>
     */
    getResourcePaymentTerms(resourceId:number): Observable<BaseResultModel<ResponseResourcePaymentTerm>> {
        return this.api.ApiRequest<BaseResultModel<ResponseResourcePaymentTerm>>(environment.apiUrl + '/resource/' + resourceId + '/payment-terms','',new HttpHeaders(),HttpVerb.get,'json');
    }

    /***
     * @description gets stored resource job cat count figures
     * @param resourceJobId: number
     * @returns BaseResultModel<ResponseResourceJobCatCount>
     */
    getResourceJobCatCount(resourceJobId: number): Observable<BaseResultModel<ResponseResourceJobCatCount>> {
        return this.api.ApiRequest<BaseResultModel<ResponseResourceJobCatCount>>(environment.apiUrl + '/resourcejob/id/' + resourceJobId + '/cat-count','',new HttpHeaders(),HttpVerb.get,'json');        
    }    

    /***
     * @description stores new resource job cat count figures
     * @param model: PostResourceJobCatCount
     * @returns BaseResultModel<ResponseResourceJobCatCount>
     */
    addResourceJobCatCount(model: PostResourceJobCatCount): Observable<BaseResultModel<ResponseResourceJobCatCount>> {
        return this.api.ApiRequest<BaseResultModel<ResponseResourceJobCatCount>>(environment.apiUrl + '/resourcejob/cat-count',model,new HttpHeaders(),HttpVerb.post,'json');        
    }
    
    /***
     * @description updates a resource job cat count figures
     * @param model: PutResourceJobCatCount
     * @returns BaseResultModel<ResponseResourceJobCatCount>
     */
    updateResourceJobCatCount(model: PutResourceJobCatCount): Observable<BaseResultModel<ResponseResourceJobCatCount>> {
        return this.api.ApiRequest<BaseResultModel<ResponseResourceJobCatCount>>(environment.apiUrl + '/resourcejob/cat-count',model,new HttpHeaders(),HttpVerb.put,'json');        
    }

    deleteResourceFile(fileId: number): Observable<BaseResultModel<string>> {
        return this.api.ApiRequest<BaseResultModel<string>>(environment.apiUrl + this.endpoint + '/file/' + fileId ,'',new HttpHeaders(),HttpVerb.delete,'json');
    }
}