import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClientContractModel, PostClientContractFile } from 'app/main/pm/clients/shared/client-contracts/client-contract.model';
import { BaseResultModel } from 'app/models';
import { AppConfigService } from 'app/services/app.settings.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ClientContractsService {

    private readonly _uri: string;

    constructor(private http: HttpClient, configService: AppConfigService) {
        this._uri = configService.getAPIUrl() + environment.clientContracts.apiSection;
    }

    getClientContracts(clientId: string, includeContractsAvailableToOtherClients: boolean = false): Observable<BaseResultModel<ClientContractModel[]>> {
        return this.http.get<BaseResultModel<ClientContractModel[]>>(`${this._uri}/clientContract/${clientId}/client`, {
            params: includeContractsAvailableToOtherClients
                ? new HttpParams().set('includeContractsAvailableToOtherClients', includeContractsAvailableToOtherClients.toString())
                : undefined,
        });
    }

    saveClientContract(creditNote: ClientContractModel): Observable<ClientContractModel> {
        if (creditNote?.id) {
            return this.http.put<ClientContractModel>(`${this._uri}/clientContract/update`, creditNote);
        } else {
            return this.http.post<ClientContractModel>(`${this._uri}/clientContract/add`, creditNote);
        }
    }

    deleteClientContract(contractIds: string[]): Observable<null> {
        let params = new HttpParams();
        contractIds.forEach(id => {
            params = params.append('clientContractIds', id.toString());
        });
    
        return this.http.delete<null>(`${this._uri}/clientContract/delete`, {
            params: params
        });
    }

    getClientContract(contractId: string): Observable<ClientContractModel> {
        return this.http.get<ClientContractModel>(`${this._uri}/clientContract/${contractId}`);
    }

    
    uploadClientContractFile(payload: PostClientContractFile): Observable<BaseResultModel<string>> {
        return this.http.post<BaseResultModel<string>>(this._uri + '/clientContract/file', payload);
    }

}