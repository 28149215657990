
export function removeNullProperties<T>(object: T, removeEmptyString: boolean = false): T {
    Object.keys(object).forEach(key => {
        if (object[key] === null || object[key] === undefined || (removeEmptyString && object[key] === "")) {
            delete object[key];
        }
    });

    return object;
}
