import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CatCountAnalysisComponent } from './cat-count-analysis.component';
import { CatCountAnalysisModalComponent } from './cat-count-analysis-modal/cat-count-analysis-modal.component';
import {TpModalModule} from "../../../../components/tp-modal/tp-modal.module";
import {TpButtonModule} from "../../../../components/tp-button/tp-button.module";
import {TranslateModule} from "@ngx-translate/core";
import {TpTextBoxModule} from "../../../../components/tp-text-box/tp-text-box.module";
import {ReactiveFormsModule} from "@angular/forms";

@NgModule({
    declarations: [
        CatCountAnalysisComponent,
        CatCountAnalysisModalComponent
    ],
    exports: [
        CatCountAnalysisModalComponent
    ],
    imports: [
        CommonModule,
        TpModalModule,
        TpButtonModule,
        TranslateModule,
        TpTextBoxModule,
        ReactiveFormsModule
    ]
})
export class CatCountAnalysisModule { }