import { Injectable } from "@angular/core";
import { GlobalConfiguration } from "app/models/general/global-configuration";
import { Observable, of } from "rxjs";
import {PortalTypes} from "../../../utills/enums/portal-types";
import {TpAuthService} from "../../auth/tp-auth.service";

@Injectable()
export class PermissionHelper {
    constructor(
        private globalConfiguration:GlobalConfiguration,
        private authService: TpAuthService
    ){}
    /***
     * @function ValidatePermission
     * @param systemPermissionId: number
     * @param portal: PortalTypes | 'all' = 'all'
     * @description validate permission
     */
    ValidatePermission(systemPermissionId: number, portal: PortalTypes | 'all' = 'all'): boolean {
        let res: boolean = true;
        let checkPortal: boolean = true;
        if(portal !== 'all') checkPortal = this.authService.validatePortal(portal);
        if(this.globalConfiguration.currentUser.permissions?.length > 0 && systemPermissionId && checkPortal) {
            res = this.globalConfiguration.currentUser.userPermissions?.includes(systemPermissionId);
        } else if(systemPermissionId && checkPortal) res = false;
        return res;
    }
    /***
     * @function ValidatePermissions
     * @param systemPermissionIds: number[]
     * @param every: boolean = false
     * @description validate permissions
     */
    ValidatePermissions(systemPermissionIds: number[], every: boolean = false): boolean {
        let res: boolean = true;
        if (systemPermissionIds?.length > 0) {
            const filteredPermissionIds = systemPermissionIds.filter(permission => permission);
            if(filteredPermissionIds.length > 0) {
                if(!every) res = filteredPermissionIds.some(permission => this.ValidatePermission(permission));
                else res = filteredPermissionIds.every(permission => this.ValidatePermission(permission));
            }
        }
        return res;
    }
    /***
     * @function ValidatePermissionObservable
     * @param systemPermissionId: number
     * @description validate permission observable
     */
     ValidatePermissionObservable(systemPermissionId: number): Observable<boolean>{
        return of(this.ValidatePermission(systemPermissionId));
    }

     /***
     * @function ValidatePermissionObservable
     * @param systemPermissionId: number
     * @description validate permission observable
     */
     ValidatePermissionsObservable(systemPermissionIds: number[]): Observable<boolean>{
        return of(this.ValidatePermissions(systemPermissionIds));
    }
}