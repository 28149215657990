import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatMenuModule } from '@angular/material/menu'
import { TranslateModule } from '@ngx-translate/core'
import { TpDropdownModule } from 'app/components/tp-dropdown/tp-dropdown.module'
import { TpMultiselectDropdownModule } from 'app/components/tp-multiselect-dropdown/tp-multiselect-dropdown.module'
import { TpProjectStatusDropDownComponent } from 'app/components/tp-project-status-dropdown/tp-project-status-dropdown.component'

@NgModule({
    declarations: [TpProjectStatusDropDownComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TpDropdownModule,
        TranslateModule,
        MatMenuModule,
        TpMultiselectDropdownModule,
        MatFormFieldModule,
    ],
    exports: [TpProjectStatusDropDownComponent],
})
export class TpProjectStatusDropdownModule {}