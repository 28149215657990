import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(items: any[], value: number | string, field: string = 'fileTypeId'): any[] {
      if (!items) return [];
      if (!value || items.length == 0) return items;
      return items.filter(item => item[field] == value);
  }
}