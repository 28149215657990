import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { TpCheckboxModule } from "app/components/tp-checkbox/tp-checkbox.module";
import { TpDropdownModule } from "app/components/tp-dropdown/tp-dropdown.module";
import { TpRadioButtonModule } from "app/components/tp-radio-button/tp-radio-button.module";
import { TpTextBoxModule } from "app/components/tp-text-box/tp-text-box.module";
import { PersonAddressComponent } from "./person-address.component";

@NgModule({
    declarations: [PersonAddressComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        TpDropdownModule,
        TpTextBoxModule,
        TranslateModule,
        TpRadioButtonModule,
        TpCheckboxModule
    ],
    exports: [
        PersonAddressComponent,
    ],
})
export class PersonAddressModule {
}