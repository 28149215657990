<span class="currency-notification-icon">
    <mat-icon class="no-rate" matTooltip="No Price" *ngIf="!hasRate(); else rateCost">info</mat-icon>
    <ng-template #rateCost>
        <mat-icon *ngIf="isCustomPrice()" class="custom-price" [matTooltip]="getTextPriceDirection()">
            info
        </mat-icon>
        <mat-icon *ngIf="isCompanyPrice()" class="company-price" [matTooltip]="getTextPriceDirection() + getCurrencyLabel()">
            info
        </mat-icon>
        <mat-icon *ngIf="isClientPrice()" class="client-price" [matTooltip]="getTextPriceDirection() + getCurrencyLabel()">
            info
        </mat-icon>
    </ng-template>
</span>