import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FileStatusTypes, PostFile} from "../../../models/general/system-models/post-file";
import {FileExtensionTypes, FileTypes} from "../../../utills/enums/file-types";
import {animate, query, stagger, style, transition, trigger} from "@angular/animations";
import {AppConfigService} from "../../../services/app.settings.service";
import {BaseResultModel} from "../../../models";
import {HttpClient} from "@angular/common/http";
import {combineLatest, Subject} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {NotificationService} from "../../../services/notification.service";
import {UtilsService} from "../../../services/utils";

@Component({
    selector: 'tp-file-list',
    templateUrl: './file-list.component.html',
    styleUrls: ['./file-list.component.scss'],
    animations: [
        trigger('fileListAnimation', [
            transition('* => *', [ // each time the binding value changes
                query(':leave', [
                    style({opacity: 1, transform: 'translateX(0px)'}),
                    stagger(200, [
                        animate('0.3s', style({opacity: 0 , transform: 'translateX(100px)'}))
                    ])
                ],{optional: true}),
                query(':enter', [
                    style({opacity: 0,  transform: 'translateX(100px)'}),
                    stagger(200, [
                        animate('0.3s', style({opacity: 1, transform: 'translateX(0px)'}))
                    ])
                ],{optional: true})
            ])
        ])
    ]
})

export class FileListComponent implements OnInit, OnDestroy {
    @Output() onDeleteFile: EventEmitter<{file:PostFile, index: number}> = new EventEmitter<{file:PostFile, index: number}>();
    @Output() successfullyDeleted: EventEmitter<{file:PostFile, index: number}> = new EventEmitter<{file:PostFile, index: number}>();
    @Output() onClickFile: EventEmitter<PostFile> = new EventEmitter<PostFile>();
    @Input() set files(files: PostFile[]){
        if (files) {
            this._files = files;
            this.fileIcons();
            this.handleProgressBar();
        } else {
             this._files = [];
        }
    }
    get files(): PostFile[] {return this._files}
    @Output() filesChange: EventEmitter<PostFile[]> = new EventEmitter<PostFile[]>();
    @Output() selectedFilesChange: EventEmitter<PostFile[]> = new EventEmitter<PostFile[]>();
    @Input() fileType: FileTypes = FileTypes.General;
    @Input() endpoint: string = '';
    @Input() allowDeleting: boolean = true;
    @Input() allowDownloading: boolean = true;
    @Input() allowSelecting: boolean = false;
    private _files: PostFile[] = [];
    private uri: string;
    public animating: boolean = false;
    readonly FileStatusTypes = FileStatusTypes;
    private onDestroy$: Subject<void> = new Subject<void>();
    constructor(
        private cdr: ChangeDetectorRef,
        private configService: AppConfigService,
        private http: HttpClient,
        private translateService: TranslateService,
        private notificationService: NotificationService,
        private utilsService: UtilsService
    ) {
        this.uri = this.configService.getAPIUrl();
    }

    ngOnInit(): void {}
    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    /**
     *@function DeleteFile
     * @param {PostFile} file
     * @param {number} index
     * @description delete file handler
     * @return void
     * */
    deleteFile(file: PostFile, index: number): void {
        if(!this.allowDeleting) return;
        this.onDeleteFile.emit({file, index});
        const title$ = this.translateService.stream('tp-delete', { defaultText: 'Delete' });
        const text$ = this.translateService.stream('tp-are-you-sure-you-want-to-delete-this-file?', { defaultText: 'Are you sure you want to delete this file?' });
        const yes$ = this.translateService.stream('tp-yes', { defaultText: 'Yes' });
        const no$ = this.translateService.stream('tp-no', { defaultText: 'No' });
        combineLatest([title$, text$, yes$, no$]).subscribe(
            ([title, text, yes, no]) => {
                const model = {
                    title: title,
                    text: text,
                    confirmButtonText: yes,
                    cancelButtonText: no,
                    confirmClicked: () => {
                        if (file?.id && file?.fileId) {
                            this.http.delete<BaseResultModel<boolean>>(this.uri + this.endpoint + '/' + file.id).subscribe(result => {
                                if (result.status) {
                                    this.notificationService.showSuccess('','File deleted successfully');
                                    this.successfullyDeleted.emit({file, index});
                                    this.spliceFile(file);
                                }
                            });
                        } else {
                            this.successfullyDeleted.emit({file, index});
                            this.spliceFile(file);
                        }
                    },
                };
                this.notificationService.showConfirmCancelDialog(model);
            },
        );
    }
    /**
     * @function spliceFile
     * @param {PostFile} file
     * @description remove file from array by given file object
     * @return void
     * */
    spliceFile(file: PostFile): void {
        const index = this.files.indexOf(file);
        if (index > -1) {
            this._files.splice(index,1);
            this._files = [...this._files];
            this.filesChange.emit(this._files);
            this.animating = true;
            setTimeout(() => {
                this.animating = false;
            },500);
        }
    }
    /**
     * @function clickFile
     * @param {PostFile} file
     * @description on clicking file content handler
     * @return void
     * */
    clickFile(file: PostFile): void {
        this.onClickFile.emit(file);
        if(this.allowDownloading){
            if (file?.fileId) {
                this.http.get<Blob>(this.uri + this.endpoint + '/' + file.fileId, {responseType: 'blob' as 'json'}).subscribe(result => {
                    if (result) FileListComponent.downloadFile(URL.createObjectURL(result), file.description);
                });
            } else if(file?.file) FileListComponent.downloadFile(URL.createObjectURL(file.file), file.description);
        }
    }
    /**
     * @function downloadFile
     * @param {any} href
     * @param {string} fileName
     * @description download the file
     * @return void
     * */
    private static downloadFile(href: string, fileName: string): void{
        let link = document.createElement('a');
        link.href = href;
        link.download = fileName;
        link.click();
        link.remove();
    }
    /**
     * @function uploadedSize
     * @param {PostFile} file
     * @description get already uploaded size
     * @return number
     * */
    uploadedSize(file: PostFile): number {
        if(file.fileId) return file.size
        else if(!file?.loaded) return 0;
        else if(file?.loaded && file?.loaded < 100) return Number((file.size * (file.loaded / 100)).toFixed(2));
        else return file.size;
    }
    /**
     * @function handleProgressBar
     * @description handle upload progress bar
     * @return void
     * */
    handleProgressBar(): void {
        this.files.filter(file => file.fileTypeId === this.fileType).forEach(file => {
            if (!file?.loaded && !file.fileId) {
                if(!file.progress) {
                    const progressTimeOut = setInterval(() => {
                        if (!file?.loaded) file['loaded'] = 0;
                        file.loaded += 5;
                        if (file.loaded == 100) clearInterval(progressTimeOut);
                        this.cdr.detectChanges();
                    }, 100);
                }
            } else {
                file.loaded = 100;
            }
        });
    }
    onSelectedFilesChange(): void {
        const selectedFiles = this.files.filter(file => file?.selected);
        this.selectedFilesChange.emit(selectedFiles ?? []);
    }
    fileIcons(): void {
        this.files.map(file => {
            const fExtension = this.utilsService.getFileExtensionByName(file.description).toLowerCase();
            switch (fExtension){
                case FileExtensionTypes.Xlsx.toLowerCase():
                case FileExtensionTypes.Xls.toLowerCase():
                case FileExtensionTypes.Csv.toLowerCase():
                    file.icon = 'fa-file-excel';
                    break;
                case FileExtensionTypes.Pptx.toLowerCase():
                case FileExtensionTypes.Ppt.toLowerCase():
                    file.icon = 'fa-file-powerpoint';
                    break;
                case FileExtensionTypes.Doc.toLowerCase():
                case FileExtensionTypes.Docx.toLowerCase():
                    file.icon = 'fa-file-word';
                    break;
                case FileExtensionTypes.Rar.toLowerCase():
                case FileExtensionTypes.Zip.toLowerCase():
                    file.icon = 'fa-file-zipper';
                    break;
                default:
                    file.icon = 'fa-file-lines';
            }
        });
    }
}