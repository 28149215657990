import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { TranslateModule } from '@ngx-translate/core'
import { TpDropdownModule } from 'app/components/tp-dropdown/tp-dropdown.module'
import { TpMultiselectDropdownModule } from 'app/components/tp-multiselect-dropdown/tp-multiselect-dropdown.module'
import { TpServicesDropDownComponent } from 'app/components/tp-services-dropdown/tp-services-dropdown.component'

@NgModule({
    declarations: [TpServicesDropDownComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TpDropdownModule,
        TpMultiselectDropdownModule,
        TranslateModule,
    ],
    exports: [TpServicesDropDownComponent],
})
export class TpServicesDropdownModule {}