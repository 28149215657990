import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
// *******************************************************************************
// Libs
import { TranslateModule } from '@ngx-translate/core';
import { LayoutResponsiveSidenavComponent } from 'app/layout/layout-responsive-sidenav/layout-responsive-sidenav.component';
import { NavigationBuilderService } from 'app/services/navigation/navigation-builder.service';
import { MyProfileModule } from 'app/main/common/my-profile/my-profile.module';
import { CreateProjectModule } from 'app/main/pm/projects/shared/create-project/create-project.module';
// *******************************************************************************
// Layouts
import { LayoutFooterComponent } from './layout-footer/layout-footer.component';

// *******************************************************************************
// Components
import { LayoutNavbarComponent } from './layout-navbar/layout-navbar.component';
// *******************************************************************************
// Services
import { TpModalModule } from 'app/components/tp-modal/tp-modal.module';
import { TpButtonModule } from 'app/components/tp-button/tp-button.module';
import { ClientNamePipeModule } from 'app/pipes/client-name/client-name-pipe.module';
import {CreateEditQuoteModule} from "../main/pm/quotes/shared/create-edit-quote/create-edit-quote.module";
import {FormsModule} from "@angular/forms";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatButtonModule} from "@angular/material/button";
import { PermissionHelper } from 'app/services/helpers/global-scope/permission-helper';
import {SideNavModule} from "../components/sidenav/sidenav.module";
import {ActionsModule} from "../components/actions/actions.module";
import {SupportLinksModule} from "../components/actions/support-links/support-links.module";
import {SocialLinksModule} from "../components/actions/social-links/social-links.module";
import {IntegrationsModalModule} from "../main/admin/integrations/integrations-modal/integrations-modal.module";
import {
    CheckPermissionsDirectiveModule
} from "../directives/check-permission-directive/check-permission-directive-module";
import {MatIconModule} from "@angular/material/icon";
import {MatTooltipModule} from "@angular/material/tooltip";
import {UserNotesModule} from "../main/admin/users/shared/user-notes/user-notes.module";
import {LanguagesModule} from "../components/languages/languages.module";
import {ComponentsModule} from "../components/components.module";
// *******************************************************************************
//

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        TranslateModule,
        MatMenuModule,
        FlexModule,
        MyProfileModule,
        CreateProjectModule,
        ClientNamePipeModule,
        TpModalModule,
        TpButtonModule,
        CreateEditQuoteModule,
        FormsModule,
        MatSidenavModule,
        MatButtonModule,
        SideNavModule,
        ActionsModule,
        SupportLinksModule,
        SocialLinksModule,
        IntegrationsModalModule,
        CheckPermissionsDirectiveModule,
        MatIconModule,
        MatTooltipModule,
        UserNotesModule,
        LanguagesModule,
        ComponentsModule,
    ],
    declarations: [
        LayoutResponsiveSidenavComponent,
        LayoutNavbarComponent,
        LayoutFooterComponent,
    ],
    exports: [
        LayoutResponsiveSidenavComponent,
    ],
    providers: [
        NavigationBuilderService,
        PermissionHelper
    ],
})
export class LayoutModule {
}