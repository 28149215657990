<div class="stars-line"
     [class.cursor-pointer]="!readonly"
     (mouseleave)="readonly ? noop() : onStarsUnhover()">
    <span class="star zero-star"
          [ngStyle]="starSize()"
          aria-hidden="true"
          (click)="onZeroStarClick()"
          (mousemove)="readonly ? noop() : onZeroStarHover()">
    </span>
    <div *ngFor="let star of editableStars"
         [ngStyle]="starPadding()"
         (click)="readonly ? noop() : onStarClick($event, star)"
         (mousemove)="readonly ? noop() : onStarHover($event, star)">
        <span
            class="star"
            [ngClass]="star.classname"
            [ngStyle]="starSize()"
            aria-hidden="true">
        </span>
    </div>
</div>