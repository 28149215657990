export class PostCatToolAnalysis {
    csvFile?: File;
    catToolId?: number;
    clientId?: number;
    resourceId?: number;
}

export class PostCatToolSetting {
    catToolName?: string;
    insertDate?: string;
    insertUserId?: number;
    delimiter?: string;
    wordsColumn?: number;
    rowsToSkip?: number;
    columnName?: string;
    direction?:string;
}

export class ResponseCatToolSetting extends PostCatToolSetting {
    catToolId?: number;
    id?: number;
    wordsCount?: number;
}

export class ResponseCatToolAnalysis extends ResponseCatToolSetting {
    discountPercentage?: number;
    discountedWordCount?: number;
}

export class ResponseCatToolAnalysisResult {
    analysis?: ResponseCatToolAnalysis[];
    totalWordCount?: number;
    totalDiscountedWordCount?: number;
}