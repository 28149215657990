import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { TpButtonModule } from 'app/components/tp-button/tp-button.module';
import { TpCategoryDropdownModule } from 'app/components/tp-category-dropdown/tp-category-dropdown.module';
import { TpCountryDropdownModule } from 'app/components/tp-country-dropdown/tp-country-dropdown.module';
import { TpCurrencyDropdownModule } from 'app/components/tp-currency-dropdown/tp-currency-dropdown.module';
import { TpDatePickerModule } from 'app/components/tp-date-picker/tp-date-picker.module';
import { TpDropdownModule } from 'app/components/tp-dropdown/tp-dropdown.module';
import { TpGridModule } from 'app/components/tp-grid/tp-grid.module';
import { TpInvoicingRadioModule } from 'app/components/tp-invoicing-radio/tp-invoicing-radio.module';
import { TpModalModule } from 'app/components/tp-modal/tp-modal.module';
import { TpPaymentTermsModule } from 'app/components/tp-payment-terms/tp-payment-terms.module';
import { TpSalespersonsDropdownModule } from 'app/components/tp-salesperson-dropdown/tp-salespersons-dropdown.module';
import { TpTextAreaModule } from 'app/components/tp-text-area/tp-text-area.module';
import { TpTextBoxModule } from 'app/components/tp-text-box/tp-text-box.module';
import { TpToggleModule } from 'app/components/tp-toggle/tp-toggle.module';
import { ContactModalModule } from 'app/main/sharedComponents/contact-modal/contact-modal.module';
import { EditClientContactDetailComponent } from 'app/main/sharedComponents/edit-client/edit-client-contact-detail/edit-client-contact-detail.component';
import { EditClientComponent } from 'app/main/sharedComponents/edit-client/edit-client.component';
import { ResourceServicePriceModalModule } from 'app/main/sharedComponents/resource-service-price-modal/resource-service-price-modal.module';
import { FormatNumberWithCurrencyPipeModule } from 'app/pipes/format-number-with-currency-pipe/format-number-with-currency-pipe.module';
import { ServiceNamePipeModule } from 'app/pipes/service-name-pipe/service-name-pipe.module';
import { UnitNamePipeModule } from 'app/pipes/unit-name-pipe/unit-name-pipe.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TpCheckboxModule } from '../../../components/tp-checkbox/tp-checkbox.module';
import { TpGridSearchWrapperModule } from '../../../components/tp-grid-search-wrapper/tp-grid-search-wrapper.module';
import { TpPaymentTypeDropdownModule } from '../../../components/tp-payment-type-dropdown/tp-payment-type-dropdown.module';
import { TpServiceLanguageDropdownModule } from '../../../components/tp-service-language-dropdown/tp-service-language-dropdown.module';
import { TpSliderRangeModule } from '../../../components/tp-slider-range/tp-slider-range.module';
import { TpUnitsDropdownModule } from '../../../components/tp-units-dropdown/tp-units-dropdown.module';
import { ClientSearchModule } from '../client-search/client-search.module';
import { PaperworkTemplateSelectorModule } from '../paperwork-template-selector/paperwork-template-selector.module';
import { ClientLoginsComponent } from './client-logins/client-logins.component';
import { EditPriceListComponent } from './edit-price-list/edit-price-list.component';
import { ImportPricesFromAnotherClientModalComponent } from './edit-price-list/import-prices-from-another-client-modal/import-prices-from-another-client-modal.component';
import { PersonContactModule } from '../person-contact/person-contact.module';
import { PersonAddressModule } from '../person-address/person-address.module';
import {MatBadgeModule} from "@angular/material/badge";
import {TpAutocompleteDropdownModule} from "../../../components/tp-autocomplete-dropdown/tp-autocomplete-dropdown.module";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {
    CheckPermissionsDirectiveModule
} from "../../../directives/check-permission-directive/check-permission-directive-module";
import {ComponentsModule} from "../../../components/components.module";
import {FlexModule} from "@angular/flex-layout";
import {UserLoginsModule} from "../../admin/users/shared/user-logins/user-logins.module";

@NgModule({
    declarations: [
        EditClientComponent,
        EditClientContactDetailComponent,
        EditPriceListComponent,
        ClientLoginsComponent,
        ImportPricesFromAnotherClientModalComponent,
    ],
    imports: [
        CommonModule,
        NgxSpinnerModule,
        ReactiveFormsModule,
        MatTabsModule,
        TranslateModule,
        MatExpansionModule,
        TpTextBoxModule,
        TpDatePickerModule,
        TpToggleModule,
        TpCountryDropdownModule,
        MatButtonModule,
        TpCategoryDropdownModule,
        TpDropdownModule,
        TpSalespersonsDropdownModule,
        TpCurrencyDropdownModule,
        TpPaymentTermsModule,
        TpInvoicingRadioModule,
        TpTextAreaModule,
        TpModalModule,
        ServiceNamePipeModule,
        UnitNamePipeModule,
        FormatNumberWithCurrencyPipeModule,
        TpGridModule,
        TpButtonModule,
        ResourceServicePriceModalModule,
        ContactModalModule,
        TpCheckboxModule,
        TpGridSearchWrapperModule,
        MatCardModule,
        ClientSearchModule,
        FormsModule,
        TpServiceLanguageDropdownModule,
        TpUnitsDropdownModule,
        TpSliderRangeModule,
        PaperworkTemplateSelectorModule,
        TpPaymentTypeDropdownModule,
        PersonContactModule,
        PersonAddressModule,
        MatTooltipModule,
        MatBadgeModule,
        TpAutocompleteDropdownModule,
        DragDropModule,
        CheckPermissionsDirectiveModule,
        ComponentsModule,
        FlexModule,
        UserLoginsModule,
    ],
    exports: [
        EditClientComponent,
        EditClientContactDetailComponent,
        EditPriceListComponent,
        EditPriceListComponent,
    ],
})
export class EditClientModule {
}