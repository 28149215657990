export class EntityPermission{
    view: boolean = false;
    add: boolean = false;
    edit: boolean = false;
    delete: boolean = false;
    status: boolean = false;
    export: boolean = false;
    duplicate: boolean = false;
    copy: boolean = false;
    move: boolean = false;
    constructor() {}
}