import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { TranslateModule } from '@ngx-translate/core'
import { TpDropdownModule } from 'app/components/tp-dropdown/tp-dropdown.module'
import { TpStartTimeComponent } from 'app/components/tp-start-time/tp-start-time.component'

@NgModule({
    declarations: [TpStartTimeComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TpDropdownModule,
        TranslateModule,
    ],
    exports: [TpStartTimeComponent],
})
export class TpStartTimeModule {}