import {Directive, EventEmitter, Input, OnDestroy, OnInit, Optional, Output, ViewChild} from '@angular/core';
import {ControlContainer, ControlValueAccessor, FormControl, FormControlDirective, FormGroupDirective, NgForm, ValidatorFn,} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { TpValidationError } from 'app/models';
import * as _ from 'lodash';
import {BehaviorSubject, Subject} from 'rxjs';
import {MatFormFieldAppearance} from "@angular/material/form-field/form-field";

export const RequiredObjectValidator: ValidatorFn = (control) => {
    if (typeof control.value !== 'object' || !control.value) {
        return {
            [BaseControlFormErrorNames.requiredObject]: true,
        };
    } else {
        return null;
    }
};

export enum BaseControlFormErrorNames {
    required = 'required',
    minLength = 'minLength',
    maxLength = 'maxLength',
    email = 'email',
    exists = 'exists',
    matDatetimePickerParse = 'matDatetimePickerParse',
    requiredObject = 'requiredObject',
    requiredTrue = 'requiredTrue',
}

export class DefaultErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(
        control: FormControl | null,
        form: FormGroupDirective | NgForm | null,
    ): boolean {
        const isSubmitted = form && form.submitted;
        return !!(
            control &&
            control.invalid &&
            (control.dirty || control.touched || isSubmitted)
        );
    }
}

@Directive()
export abstract class BaseControlComponentDirective implements ControlValueAccessor, OnInit, OnDestroy {
    @Output() markEditing: EventEmitter<any> = new EventEmitter<any>();
    @Output() closeEditing: EventEmitter<any> = new EventEmitter<any>();
    @Output() clickingOutside: EventEmitter<any> = new EventEmitter<any>();
    protected _label: string;
    @Input() enableInlineEditing: boolean = false;
    @Input() public inlineEditingStatus: boolean = false;
    @Input() required: boolean;
    @Input() appearance: MatFormFieldAppearance = 'fill';
    @Input() set disabled(value: boolean){
        this._disabled = value;
        if (this.control) {
            (this.disabled) ? this.control.disable() : this.control.enable();
        }
    };
    get disabled(): boolean {return this._disabled};
    private _disabled: boolean;
    latestWrittenValue: any;
    latestWrittenValueBeforeEditing: any;
    get useGenericLabelForErrors(): boolean {
        return this._useGenericLabelForErrors;
    }
    @Input() set useGenericLabelForErrors(value: boolean) {
        this._useGenericLabelForErrors = value;
        this.refreshValidationMessages();
    }
    @Input() set label(value: string) {
        this._label = value;
        this.refreshValidationMessages();
    }
    get label(): string {
        return this._label;
    }
    protected _readOnly = false;
    @Input()
    public set readOnly(value: boolean) {
        this._readOnly = value;
        this.setDisabledState(value);
    }
    public get readOnly(): boolean {
        return this._readOnly;
    }
    _customErrorMessages: Map<string, () => string>;
    @Input() set customErrorMessages(value: Map<string, () => string>) {
        this._customErrorMessages = value;
        this.refreshValidationMessages();
    }
    get customErrorMessages(): Map<string, () => string> {
        return this._customErrorMessages;
    }
    get fields(): string[] {
        return this._fields;
    }
    @Input() set fields(value: string[]) {
        this._fields = value;
        this.refreshBackendErrors();
    }
    get errors(): TpValidationError[] {
        return this._errors;
    }
    @Input() set errors(value: TpValidationError[]) {
        this._errors = value;
        this.refreshBackendErrors();
    }
    get useTranslation(): boolean {
        return this._useTranslation;
    }
    @Input() set useTranslation(value: boolean) {
        this._useTranslation = value;
        this.refreshBackendErrors();
    }
    @ViewChild(FormControlDirective, { static: true })
    formControlDirective: FormControlDirective;
    @Input() formControl: FormControl;
    @Input() formControlName: string;
    get control(): FormControl {
        return this.formControl ?? (this.formControlName ? (this.controlContainer?.control?.get(this.formControlName) as FormControl) : null);
    }
    /**
     * @function isInlineEditing
     * @description check is inline editing is enabled and editing status
     * @return boolean
     * */
    get isInlineEditing(): boolean {
        return (this.enableInlineEditing) ? this.inlineEditingStatus : true;
    }
    errorMessages: Map<string, () => string> = new Map<string, () => string>();
    filteredErrors: TpValidationError[] = [];
    protected readonly onDestroy$ = new Subject();
    protected _errors: TpValidationError[];
    protected _fields: string[] = [];
    protected _useTranslation = true;
    protected _useGenericLabelForErrors = false;
    public writeValue$: BehaviorSubject<any> = new BehaviorSubject<any>(false);

    protected constructor(
        @Optional() protected readonly controlContainer: ControlContainer,
        protected readonly translateService: TranslateService
    ) {
    }

    protected nonReactiveFormOnChange;
    protected nonReactiveFormOnTouched;

    ngOnInit() { }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
    /**
     * @function onEditing
     * @param event
     * @description on start editing inline field
     * @return void
     * */
    onEditing(event): void {
        this.inlineEditingStatus = true;
        this.latestWrittenValueBeforeEditing = this.getValue();
    }
    /**
     * @function onClickOutside
     * @param event
     * @description clicking outside the input disable inline editing
     * @return void
     * */
    onClickOutside(event): void {
        this.clickingOutside.emit(true);
        this.inlineEditingStatus = false;
    }
    /**
     * @function onMarkEditing
     * @param event
     * @description saving standalone input
     * @return void
     * */
    onMarkEditing(event): void {
        event.stopPropagation();
        this.onClickOutside(event);
        this.markEditing.emit(this.getValue());
    }
    /**
     * @function onCloseEditing
     * @param event
     * @description close editing field and not saving changes
     * @return void
     * */
    onCloseEditing(event): void {
        event.stopPropagation();
        this.writeValue(this.latestWrittenValueBeforeEditing);
        this.onClickOutside(event);
        this.closeEditing.emit(this.getValue());
    }

    registerOnTouched(fn: any): void {
        this.formControlDirective?.valueAccessor.registerOnTouched(fn);
        this.nonReactiveFormOnTouched = fn;
    }

    registerOnChange(fn: any): void {
        this.formControlDirective?.valueAccessor.registerOnChange(fn);
        this.nonReactiveFormOnChange = fn;
    }
    /**
     * @function getValue
     * @description get control value
     * @return any
     * */
    getValue(): any {
        return (this.control) ? this.control.value : this.latestWrittenValue;
    }

    writeValue(obj: any): void {
        if (this.disabled && this.control) this.control.disable();
        if (this.latestWrittenValue == null && obj == null) {
            return;
        }  
        
        this.latestWrittenValue = obj;

        if (this.control) {
            this.formControlDirective?.valueAccessor.writeValue(obj);
        } else if (this.nonReactiveFormOnChange) {
            this.nonReactiveFormOnChange(obj);
        }
        this.writeValue$.next(obj);
    }

    setDisabledState(isDisabled: boolean): void {
        this.formControlDirective?.valueAccessor.setDisabledState(isDisabled);
        this._readOnly = isDisabled;
    }

    refreshBackendErrors() {
        if (this._errors?.length > 0 && this._fields?.length > 0) {
            this.filteredErrors = this._errors
                .filter(e => _.intersection(e.errorFields, this._fields)?.length > 0)
                .map(e => {
                    if (!e.errorParameters) {
                        e.errorParameters = {
                            defaultText: e.errorDescription?.replace('\'', ''),
                        };
                    } else {
                        e.errorParameters.defaultText = e.errorDescription?.replace(
                            '\'',
                            '',
                        );
                    }
                    return e;
                });
        } else {
            this.filteredErrors = [];
        }
    }

    get invalid(): boolean {
        return this.control?.invalid || false;
    }

    get showFormError(): boolean {
        if (!this.control) {
            return false;
        }

        const { dirty, touched } = this.control;

        return this.invalid && (dirty || touched);
    }

    get formErrors(): Array<string> {
        if (!this.control) {
            return [];
        }

        const { errors } = this.control;
        return Object.keys(errors ?? {}).map((key) =>
            this.errorMessages.has(key)
                ? this.errorMessages.get(key)()
                : <string>errors[key] ?? key,
        );
    }

    protected refreshValidationMessages() {
        const label = !this.useGenericLabelForErrors && this.label
            ? this.label
            : this.translateService.instant('tp-this-field', {
                defaultText: 'This field',
            });

        this.errorMessages.set(
            BaseControlFormErrorNames.required,
            () => this.label + ' ' +
                `${this.translateService.instant(
                    'tp-is-required',
                    {
                        defaultText: 'is required.',
                    },
                )}`,
        );

        this.errorMessages.set(
            BaseControlFormErrorNames.requiredTrue,
            () => this.label + ' ' +
                `${this.translateService.instant(
                    'tp-is-required-to-be-true',
                    {
                        defaultText: 'is required to be true',
                    },
                )}`,
        );

        this.errorMessages.set(
            BaseControlFormErrorNames.requiredObject,
            () => this.label + ' ' +
                `${this.translateService.instant(
                    'tp-is-required',
                    {
                        defaultText: 'is required.',
                    },
                )}`,
        );

        this.errorMessages.set(
            BaseControlFormErrorNames.email,
            () => this.label + ' ' +
                `${this.translateService.instant(
                    'tp-has-to-be-an-email',
                    {
                        defaultText: 'has to be an email.',
                    },
                )}`,
        );
        this.errorMessages.set(
            BaseControlFormErrorNames.exists,
            () => this.label + ' ' +
                `${this.translateService.instant(
                    'tp-already-exists',
                    {
                        defaultText: 'already exists.',
                    },
                )}`,
        );

        this.errorMessages.set(
            BaseControlFormErrorNames.matDatetimePickerParse,
            () => this.label + ' ' +
                `${this.translateService.instant(
                    'tp-has-to-be-a-date',
                    {
                        defaultText: 'has to be a date.',
                    },
                )}`,
        );

        this.errorMessages.set(
            BaseControlFormErrorNames.minLength,
            () => this.label + ' ' +
                `${this.translateService.instant(
                    'tp-needs-to-be-longer',
                    {
                        defaultText: 'needs to be longer.',
                    },
                )}`,
        );

        this.errorMessages.set(
            'maxlength',
            () => this.label + ' ' +
                `${this.translateService.instant(
                    'tp-needs-to-be-shorter',
                    {
                        defaultText: 'needs to be shorter.',
                    },
                )}`,
        );

        this.errorMessages.set(
            'pattern',
            () => this.label + ' ' +
                `${this.translateService.instant(
                    'tp-must-contain-at-least-one-uppercase-letter,-one-number-and-one-special-character',
                    {
                        defaultText: 'must contain at least one uppercase letter, one number and one special character.',
                    },
                )}`,
        );

         this.errorMessages.set(
            'mustMatch',
            () => this.label + ' ' +
                `${this.translateService.instant(
                    'tp-passwords-must-match',
                    {
                        defaultText: 'Passwords must match.',
                    },
                )}`,
        );

        this.customErrorMessages?.forEach((value, key) => {
            this.errorMessages.set(key, value);
        });
    }

}