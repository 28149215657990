import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidenavComponent } from './sidenav.component';
import {RouterModule} from "@angular/router";
import {TpDropdownModule} from "../tp-dropdown/tp-dropdown.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {TpButtonModule} from "../tp-button/tp-button.module";
import {MatMenuModule} from "@angular/material/menu";
import {MatBadgeModule} from "@angular/material/badge";
import {MatIconModule} from "@angular/material/icon";
@NgModule({
    declarations: [
        SidenavComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        TpDropdownModule,
        FormsModule,
        ReactiveFormsModule,
        TpButtonModule,
        MatMenuModule,
        MatBadgeModule,
        MatIconModule,
    ],
    exports: [
        SidenavComponent
    ]
})
export class SideNavModule { }