import {AbstractControl, FormGroup, ValidationErrors} from '@angular/forms';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {BaseResultModel} from "../../../models";
export const EmailPattern = '^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]';
export const PasswordPattern = '/(?=.*?[#?!@$%^&*-])(?=.*?[0-9])(?=.*?[A-Z])/';
export function ValidateForm(form: FormGroup): boolean {
    if (form.valid && !form.disabled) {
        return true;
    } else {
        if (!form.valid) {
            form.markAllAsTouched();
        }
        return false;
    }
}

export function emailValidator(control: AbstractControl): ValidationErrors{
    if(control.value) return control.value.match(EmailPattern) ? null : {email: true}
    else return null;
}

export function passwordValidator(control: AbstractControl): ValidationErrors{
    if(control.value) return control.value.match(PasswordPattern) ? null : {password: true}
    else return null;
}

export function userExistsValidator(control: AbstractControl, currentUsername: string, http: HttpClient): void{
    if(control.hasError('exists')) {
        control.setErrors({exists: null});
        control.updateValueAndValidity();
    }
    if(control.valid && control.value != currentUsername) {
        http.get<BaseResultModel<boolean>>(environment.apiUrl + '/user/' + control.value + '/checkUsernameValidity').subscribe(result => {
            if (!result.response) control.setErrors({exists: true});
        });
    }
}

export class CustomValidators {
    constructor() {}
  
    static passwordMatchValidator(control: AbstractControl) {
        const password: string = control.get('password').value; // get password from our password form control
        const confirmPassword: string = control.get('confirmPassword').value; // get password from our confirmPassword form control
        // compare is the password math
        if (password !== confirmPassword) {
          // if they don't match, set an error in our confirmPassword form control
          control.get('confirmPassword').setErrors({ mustMatch: true });
        }
      }
  }