import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { TpStarRatingComponent } from './tp-star-rating.component'

@NgModule({
    declarations: [TpStarRatingComponent],
    imports: [
        CommonModule
    ],
    exports: [TpStarRatingComponent],
})
export class TpStarRatingModule {}