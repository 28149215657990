import {Overlay} from '@angular/cdk/overlay';
import {
    ChangeDetectionStrategy,
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    Directive,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    TemplateRef,
    Type,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation,
} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {TpComponentService} from 'app/components/tp-modal/tp-component.service';
import {MatBottomSheet, MatBottomSheetRef} from "@angular/material/bottom-sheet";
import {ModalTypes} from "../../utills";
import {MatDialogConfig} from "@angular/material/dialog/dialog-config";
import {ScreenBreakpointsService, ScreenSizeTypes} from "../../services/general/screen-breakpoints.service";

export enum ModalSizes {
    auto = 'auto',
    extraSmall = 'extraSmall',
    small = 'small',
    medium = 'medium',
    large = 'large',
    extraLarge = 'extraLarge',
    fullExtraLarge = 'fullExtraLarge'
}

@Directive({
    selector: '[appComponentContainer]',
})
export class AppComponentDirective implements OnDestroy {
    constructor(
        private readonly vc: ViewContainerRef,
        private readonly componentService: TpComponentService,
    ) {
        componentService.createContainer(vc);
    }
    ngOnDestroy(): void {
        this.componentService.destroyContainer(this.vc);
    }
}

@Component({
    selector: 'tp-modal',
    templateUrl: './tp-modal.component.html',
    styleUrls: ['./tp-modal.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TpModalComponent {
    @ViewChild('template') template: TemplateRef<any>;
    @Input() modalClass = 'modal-lg modal-info';
    @Input() showCloseIcon = true;
    @Input() width = '80%';
    @Input() height = '90%';
    @Input() showFooter = true;
    @Output() cancelClicked: EventEmitter<any> = new EventEmitter();
    @Output() hidden: EventEmitter<any> = new EventEmitter();
    @Input() sidenav: boolean = false;
    @Input() modalType: keyof typeof ModalTypes = ModalTypes.Dialog;
    @Input() set modalConfig(config: MatDialogConfig){
       this._modalConfig = config;
    }
    get modalConfig (): MatDialogConfig { return this._modalConfig };
    private _modalConfig: MatDialogConfig = {maxHeight: '95vh', maxWidth: '100vh'};
    isShowing = false;
    modalRef: MatDialogRef<any, any>;
    bottomSheetRef: MatBottomSheetRef<any, any>;
    dynamicComponentLoad = true;
    vc: ViewContainerRef;
    scrolled: boolean = false;
    tmpMaxHeight: string | number;
    constructor(
        public readonly dialog: MatDialog,
        private readonly overlay: Overlay,
        private readonly cfr: ComponentFactoryResolver,
        private readonly componentService: TpComponentService,
        private matBottomSheet: MatBottomSheet,
        private screenBreakpointsService: ScreenBreakpointsService,
    ) {
        this.screenBreakpointsService.currentScreenSize$.subscribe(screenSizeType => {
            if(screenSizeType === ScreenSizeTypes.Small) {
                this.tmpMaxHeight = this.modalConfig.maxHeight;
                this._modalConfig.maxHeight = '100vh';
            }
            else if(this.tmpMaxHeight) this._modalConfig.maxHeight = this.tmpMaxHeight;
        });
    }
    openSidenav(): void{
       document.getElementById('open-modal-drawer').click();
    }
    onScroll(event): void{
        if (this.scrolled) {
            this.scrolled = !(event.srcElement.scrollTop == 0);
            return;
        }
        this.scrolled = (event.srcElement.scrollTop >= 20);
    }
    public show(): void {
        this.dynamicComponentLoad = false;
        this.isShowing = true;
        this.componentService.clearContainerListeners();
        this.modalRef = this.dialog.open(
            this.template,
            {...this.getLayoutObject(this.modalClass), ...this.modalConfig},
        );
        this.modalRef.keydownEvents().subscribe((e: KeyboardEvent) => {
            if (e.code === 'esc') {
                this.modalRef.close();
            }
        });
    }
    public showComponents<T extends any>(
        component: Type<T>,
        onComponentCreated: (ref: ComponentRef<T>) => void,
        modalSize?: ModalSizes,
    ): any {
        this.dynamicComponentLoad = true;
        this.isShowing = true;
        this.componentService.clearContainerListeners();
        this.componentService.registerCreateContainerListener((container) => {
            this.vc = container;
            const comp = this.cfr.resolveComponentFactory<T>(component);
            const ref = this.vc.createComponent<T>(comp);
            ref.changeDetectorRef.detectChanges();
            if (onComponentCreated != null) {
                onComponentCreated(ref);
            }
        });
        this.componentService.registerDestroyContainerListener(() => {
            this.vc = undefined;
        });
        (!this.isBottomSheet()) ? this.showDialog(modalSize) : this.showBottomSheet(modalSize);
    }
    private showDialog(modalSize?: ModalSizes): void {
        this.modalRef = this.dialog.open(
            this.template,
            {...this.getLayoutObject(modalSize || this.modalClass), ...this.modalConfig},
        );
        this.modalRef.keydownEvents().subscribe((e) => {
            if (e.keyCode === 27) {
                this.modalRef.close();
            }
        });
    }
    private showBottomSheet(modalSize?: ModalSizes): void {
       this.bottomSheetRef = this.matBottomSheet.open(this.template, {...this.getLayoutObject(modalSize || this.modalClass), ...this.modalConfig});
    }
    getLayoutObject(layoutClass: string): any {
        let panelClass: string = '';
        if(this.isRightSideDialog()) panelClass = 'dialog-right-side-pane';
        else if (layoutClass.includes(ModalSizes.extraSmall)) panelClass = 'modal-xs';
        else if (layoutClass.includes(ModalSizes.small)) panelClass = 'modal-sm';
        else if (layoutClass.includes(ModalSizes.large)) panelClass = 'modal-lg';
        else if (layoutClass.includes(ModalSizes.extraLarge)) panelClass = 'modal-xl';
        else if (layoutClass.includes(ModalSizes.fullExtraLarge)) panelClass = 'modal-full-xl';
        else if (layoutClass.includes(ModalSizes.auto)) panelClass = '';
        else panelClass = 'modal-md';
        return {
            scrollStrategy: this.overlay.scrollStrategies.block(),
            disableClose: !(this.isBottomSheet()) && !(this.isRightSideDialog()),
            panelClass,
            height: 'auto',
        };
    }
    public hide(): void {
        if (!this.isShowing) return;
        this.componentService.clearContainerListeners();
        (!this.isBottomSheet()) ? this.modalRef?.close() : this.bottomSheetRef.dismiss();
        this.hidden.emit();
        this.isShowing = false;
        setTimeout(() => {
            if (document.getElementsByClassName('modal-opened-with').length === 0) {
                document.getElementsByClassName('cdk-overlay-container')[0]?.classList.remove('mt-modal-container');
            }
        }, 100);
    }
    private isDialog(): boolean{
        return (this.modalType === ModalTypes.Dialog);
    }
    private isRightSideDialog(): boolean{
        return (this.modalType === ModalTypes.DialogRightSide);
    }
    private isLeftSideDialog(): boolean{
        return (this.modalType === ModalTypes.DialogLeftSide);
    }
    private isBottomSheet(): boolean{
        return (this.modalType === ModalTypes.BottomSheet);
    }
}