import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TpDropdownModule } from '../tp-dropdown/tp-dropdown.module';
import { TpPaymentTypeDropdownComponent } from './tp-payment-type-dropdown.component';

@NgModule({
    declarations: [TpPaymentTypeDropdownComponent],
    imports: [
        CommonModule,
        TpDropdownModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    exports: [TpPaymentTypeDropdownComponent],
})
export class TpPaymentTypeDropdownModule {
}