import { Injectable } from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as ClientActions from "../../actions/client-actions/client.actions";
import {catchError, concatMap, map, share} from "rxjs/operators";
import {of} from "rxjs";
import {ClientNotesService} from "../../../clients/services/client-notes.service";
import {NotificationService} from "../../../../../services/notification.service";
import {clientNotesEntity} from "../../reducers";
@Injectable()
export class ClientNoteEffects {
    getClientNotes$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ClientActions.getClientNotes),
            concatMap((action) =>
                this.clientNoteService.getClientNotes(action.clientId).pipe(
                    map(response => {
                        return clientNotesEntity.actions.upsertMany({entities: response.response});
                    }),
                    catchError(error => {
                        return of(clientNotesEntity.actions.updateAdditional({updates:{error}}));
                    })
                )
            ),
            share()
        );
    });
    addClientNote$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ClientActions.addClientNote),
            concatMap((action) =>
                this.clientNoteService.addClientNote(action).pipe(
                    map(response => {
                        this.notificationService.showSuccess('Saved', 'Client Note Saved Successfully');
                        return clientNotesEntity.actions.addOne({entity: response.response});
                    }),
                    catchError(error => {
                        return of(clientNotesEntity.actions.updateAdditional({updates:{error}}));
                    })
                )
            ),
            share()
        );
    });
    updateClientNote$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ClientActions.updateClientNote),
            concatMap((action) =>
                this.clientNoteService.updateClientNote(action).pipe(
                    map(response => {
                        this.notificationService.showSuccess('Saved', 'Client Note Saved Successfully');
                        return clientNotesEntity.actions.updateOne({update:{ id: response.response.id, changes: response.response }});
                    }),
                    catchError(error => {
                        return of(clientNotesEntity.actions.updateAdditional({updates:{error}}));
                    })
                )
            ),
            share()
        );
    });
    deleteClientNote$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ClientActions.deleteClientNote),
            concatMap((action) =>
                this.clientNoteService.deleteClientNote(action.id).pipe(
                    map(data => {
                        this.notificationService.showSuccess('Deleted', 'Client Note Deleted Successfully');
                        return clientNotesEntity.actions.removeOne({id: action.id});
                    }),
                    catchError(error => {
                        return of(clientNotesEntity.actions.updateAdditional({updates:{error}}));
                    })
                )
            ),
            share()
        );
    });
    constructor(
        private actions$: Actions,
        private clientNoteService: ClientNotesService,
        private notificationService: NotificationService
    ) {}

}