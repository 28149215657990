import {createReducer, on } from '@ngrx/store';
import {UnitModel} from "../../../admin/unit/models";
import {createEntity, EntityAdditional, entityInitial} from "../../../../store/models";
import {pmPortalStateFeatureSelector} from "../features";
export const unitsFeatureKey = 'units';

export const unitsEntity = createEntity<UnitModel, EntityAdditional>(
    pmPortalStateFeatureSelector,
    unitsFeatureKey,
    entityInitial({}),
);