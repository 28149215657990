import { Pipe, PipeTransform } from '@angular/core';
import { CommonService } from 'app/services/general/common.service';
import { RefDataService } from 'app/services/general/refData.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
    name: 'serviceLanguageName',
})
export class ServiceLanguageNamePipe implements PipeTransform {
    constructor(
        private readonly refDataService: RefDataService) {
    }
    
    transform(value: string): Observable<string> {
        return this.refDataService.getServiceLanguages().pipe(
            map((languages) => {
                const language = languages?.response.find((f) => f.name == value);
                return language?.name || '---';
            }),
        );
    }
}