import { createAction, props } from '@ngrx/store';
import {PostClientContact, ResponseClientPOCData} from "../../../clients/models/client.model";
import {EntityAdditional} from "../../../../../store/models";
export enum ClientContactActionTypes {
    addClientContact = '[ClientContact / Api] Add Client Contact',
    getClientContact = '[ClientContact / Api] Get Client Contact',
    updateClientContact = '[ClientContact / Api] Update Client Contact',
    deleteClientContact = '[ClientContact / Api] Delete Client Contact',
}

export const addClientContact = createAction(
    ClientContactActionTypes.addClientContact,
    props<PostClientContact>()
);
export const getClientContact = createAction(
    ClientContactActionTypes.getClientContact,
    props<{id: number}>()
);
export const updateClientContact = createAction(
    ClientContactActionTypes.updateClientContact,
    props<ResponseClientPOCData>()
);
export const deleteClientContact = createAction(
    ClientContactActionTypes.deleteClientContact,
    props<{ids: number[]}>()
);