import { Action, Selector, State, StateContext, StateToken } from "@ngxs/store";
import { LoadRefData, ProjectSecondaryStatus, ProjectStatus, QuoteJobSecondaryStatus, QuoteJobStatus, QuoteSecondaryStatus, QuoteStatus } from "./ref-data.actions";
import { Injectable } from "@angular/core";
import { RefDataService } from "app/services/general/refData.service";
import { DropDownItem } from "app/models/general/dropdownItem.model";

export const RefDataStateToken =  new StateToken<RefDataStateModel>('refDataState');

export interface RefDataStateModel {
    isLoaded : boolean;
    projectStatus: DropDownItem[];
    projectSecondaryStatus: DropDownItem[];
    quoteStatus: DropDownItem[];
    quoteSecondaryStatus: DropDownItem[];
    quoteJobStatus: DropDownItem[];
    quoteJobSecondaryStatus: DropDownItem[];
}

const defaults: RefDataStateModel = {
    isLoaded : false,
    projectStatus: [],
    projectSecondaryStatus: [],
    quoteStatus: [],
    quoteSecondaryStatus: [],
    quoteJobStatus: [],
    quoteJobSecondaryStatus: [],
}

@State<RefDataStateModel>({
    name: RefDataStateToken,
    defaults: defaults,
})

@Injectable()
export class RefDataState {
    constructor(private _refDataService: RefDataService) {}

    @Selector()
    static GetProjectStatus(state: RefDataStateModel) {

        return state.projectStatus;
    }

    @Selector()
    static GetProjectSecondaryStatus(state: RefDataStateModel) {
        return state.projectSecondaryStatus;
    }

    @Selector()
    static GetQuoteStatus(state: RefDataStateModel) {
        return state.quoteStatus;
    }

    @Selector()
    static GetQuoteSecondaryStatus(state: RefDataStateModel) {
        return state.quoteSecondaryStatus;
    }

    @Selector()
    static GetQuoteJobSecondaryStatus(state: RefDataStateModel) {
        return state.quoteJobSecondaryStatus;
    }

    @Selector()
    static GetQuoteJobStatus(state: RefDataStateModel) {
        return state.quoteJobStatus;
    }

    @Action(LoadRefData)
    LoadRefData(ctx: StateContext<RefDataStateModel>, action: LoadRefData) {
        const state = ctx.getState();
        if(action.forceRefresh || !state.isLoaded) 
        {  
            this._refDataService.getAllRefData().subscribe((res) => {

                res.projectSecondaryStatus.forEach(element => {
                    if(element.useAsDefaultFilter == true)
                        element.isSelected = true;
                });


                ctx.setState({
                    ...state,
                    isLoaded: true,
                    projectSecondaryStatus: res.projectSecondaryStatus,
                    quoteSecondaryStatus: res.quoteSecondaryStatus, 
                    projectStatus: res.projectStatus,
                    quoteStatus: res.quoteStatus,
                    quoteJobStatus: res.quoteJobStatus,
                    quoteJobSecondaryStatus: res.quoteJobSecondaryStatus,
                });
            });
        }
    }

    @Action(ProjectStatus)
    ProjectStatus(ctx: StateContext<RefDataStateModel>, action: ProjectStatus) {
        const state = ctx.getState();
        this._refDataService.getProjectStatuses().subscribe((res) => {
            ctx.setState({
                ...state,
                projectStatus: res.response,
            });
        });
    }

    @Action(ProjectSecondaryStatus)
    ProjectSecondaryStatus(ctx: StateContext<RefDataStateModel>, action: ProjectSecondaryStatus) {
        const state = ctx.getState();
        this._refDataService.getProjectSecondaryStatuses().subscribe((res) => {
            ctx.setState({
                ...state,
                projectSecondaryStatus: res.response,
            });
        });
    }

    @Action(QuoteStatus)
    QuoteStatus(ctx: StateContext<RefDataStateModel>, action: QuoteStatus) {
        const state = ctx.getState();
        this._refDataService.getQuoteStatuses().subscribe((res) => {
            ctx.setState({
                ...state,
                quoteStatus: res.response,
            });
        });
    }

    @Action(QuoteSecondaryStatus)
    QuoteSecondaryStatus(ctx: StateContext<RefDataStateModel>, action: QuoteSecondaryStatus) {
        const state = ctx.getState();
        this._refDataService.getQuoteSecondaryStatuses().subscribe((res) => {
            ctx.setState({
                ...state,
                quoteSecondaryStatus: res.response,
            });
        });
    }

    @Action(QuoteJobStatus)
    QuoteJobStatus(ctx: StateContext<RefDataStateModel>, action: QuoteJobStatus) {
        const state = ctx.getState();
        this._refDataService.getQuoteJobStatuses().subscribe((res) => {
            ctx.setState({
                ...state,
                quoteJobStatus: res.response,
            });
        });
    }
    
    @Action(QuoteJobSecondaryStatus)
    QuoteJobSecondaryStatus(ctx: StateContext<RefDataStateModel>, action: QuoteJobSecondaryStatus) {
        const state = ctx.getState();
        this._refDataService.getQuoteJobSecondaryStatuses().subscribe((res) => {
            ctx.setState({
                ...state,
                quoteJobSecondaryStatus: res.response,
            });
        });
    }
}
