import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { TpButtonModule } from 'app/components/tp-button/tp-button.module';
import { TpModalModule } from 'app/components/tp-modal/tp-modal.module';
import { TpRichTextBoxModule } from 'app/components/tp-rich-textbox/tp-rich-text-box.module';
import { TpTextAreaModule } from 'app/components/tp-text-area/tp-text-area.module';
import { TpTextBoxModule } from 'app/components/tp-text-box/tp-text-box.module';
import { SendEmailLinkComponent } from 'app/main/sharedComponents/tp-send-email/send-email-link/send-email-link.component';
import { TpSendEmailComponent } from 'app/main/sharedComponents/tp-send-email/tp-send-email.component';
import { EmailManagerService } from 'app/services/general/email-manager.service';
import { SendEmailModalComponent } from './send-email-modal/send-email-modal.component';
import {MatTooltipModule} from "@angular/material/tooltip";
import {DragAndDropFileModule} from "../../../components/drag-and-drop-file/drag-and-drop-file.module";
import {FileListModule} from "../file-list/file-list.module";

@NgModule({
    declarations: [
        TpSendEmailComponent,
        SendEmailModalComponent,
        SendEmailLinkComponent,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TranslateModule,
        TpTextBoxModule,
        TpTextAreaModule,
        TpRichTextBoxModule,
        TpModalModule,
        TpButtonModule,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        DragAndDropFileModule,
        FileListModule,
    ],
    providers: [
        EmailManagerService,
    ],
    exports: [
        TpSendEmailComponent,
        SendEmailModalComponent,
        SendEmailLinkComponent,
    ],
})
export class TpSendEmailModule {
}