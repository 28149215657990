import { DropDownOptionsModel } from 'app/models/general';
import { PostPersonAddress } from 'app/models/general/person/post-models/post-person-address';
import { PostPersonBankingDetail } from 'app/models/general/person/post-models/post-person-banking-detail';
import { PostPersonContact } from 'app/models/general/person/post-models/post-person-contact';
import { ResponsePersonData } from 'app/models/general/person/response-models/response-person-data';
import { PostUser } from './models/post-user.model';
import {ResponseUserFile} from "./response-models/response-user-file";
import { DropDownItem } from 'app/models/general/dropdownItem.model';
import { ResponseResourceData } from 'app/main/pm/resources/models/response-models/response-resource-data';

export class UserModel {
    userId: number;
    userName: string;
    password: string;
    confirmPassword: string;
    email: string;
    isDisabled: boolean;
    lastLoginDate: Date;
    linkedEntityId: number;
    comment: string;
    fname: string;
    lname: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
    country: string;
    wWWAddress: string;
    phone1: string;
    phone2: string;
    phone3: string;
    fax: string;
    userSignature: string;
    languagePreference: string;
    roleIds: string[];
    updatePassword: UpdatePasswordModel[];
    userPreferences: UserPreferencesModel;
    linkedEntity: DropDownOptionsModel;
    
    constructor() {
        this.userId = null;
        this.userName = null;
        this.email = null;
        this.isDisabled = null;
        this.lastLoginDate = null;
        this.comment = null;
        this.fname = null;
        this.lname = null;
        this.address1 = null;
        this.address2 = null;
        this.city = null;
        this.state = null;
        this.zip = null;
        this.country = null;
        this.wWWAddress = null;
        this.phone1 = null;
        this.phone2 = null;
        this.phone3 = null;
        this.fax = null;
        this.password = null;
        this.confirmPassword = null;
        this.userSignature = null;
        this.languagePreference = null;
        this.linkedEntityId = null;
        this.roleIds = [];
        this.updatePassword = [];
        this.linkedEntity = null;
    }
}

export class UserSearch {
    keyWord?: string;
    showDeleted?: boolean;
    userName?: string;
    roleIds?: number[];
}

export class UserGridData {
    id?: number;
    userName?: string;
    passWord?: string;
    userEmail?: string;
    insertDate?: Date;
    insertUserId?: number;
    companyBranchId?: number;
    personId?: number;
    personName?: string;
    userRoleId?: number;
    userRoleDescription?: string;
    lastLoginDate?: Date;
    isActive?: boolean;
}

export class RoleModel {
    roleIds: string;
    
    constructor() {
        this.roleIds = null;
    }
}

export class UpdatePasswordModel {
    newPassword?: string;
    verifyPassword?: string;
    userId?:number;
}

export class UserPreferencesModel {
    isBlockViewDefaultOnProjects: boolean;
    projectBlockViewSettings: BlockViewSettingsModel[];
    isBlockViewDefaultOnQuotes: boolean;
    quoteBlockViewSettings: BlockViewSettingsModel[];
}

export class BlockViewSettingsModel {
    status: string;
    order: number;
    isVisible: boolean;
}


export class UserTeamMembership {
    userId: string;
    firstName: string;
    lastName: string;
    email: string;
    teamMemberships: string[];
}

export class PutUser {
    id?: number;
    password?: string;
    insertDate?: string;
    insertUserId?: number;
    personId?: number;
    userRoleId?: number;
    lastLoginDate?: string;
    isActive?: boolean;
    lName?: string;
    fName?: string;
    companyBranchId?: number;
    personTypeId?: number;
    isPersonActive?: boolean;
    avatar?: File;
    imagesOverText?: boolean;
    avatarPath?: string;
    personContact?: PostPersonContact[];
    personAddress?: PostPersonAddress[];
    personBankingDetails?: PostPersonBankingDetail[];
    personContactsJSON?:string;
    personAddressesJSON?:string;
    personBankingDetailsJSON?:string;
    userPermissionsJSON?:string;
    nativeLanguageId: number;
}

export class ResponseUserData extends PostUser {
    userRoleDescription?: string;
    lastChangeDate?: Date;
    lastChangeUserId?: number;
    lastChangeUserName?: string;
    CompanyBranchDescription?: string;
    personName?: string;
    userEmail?: string;
    avatarPath?: string;
    portalUrl?:string;
    portalId?:number;
    personData?: ResponsePersonData;
    resourceId?: number;
    clientId?: number;
    clients?: DropDownItem[];
    clientContactId?: number;
    permissions?:ResponseUserPermission[];
    userFiles?: ResponseUserFile[];
    resourceData?: ResponseResourceData;
    hasUserBasicData?: boolean;
    nativeLanguageId?: number;
    nativeLanguageDescription?: string;
}

export class ResponseUserPermission {
    id?: number;
    insertDate?: Date;
    insertUserId?: number;
    userId?: number;
    systemPermissionId?: number;
    description?: string;
}

export class ResponseUserGridData {
    id?: number;
    userName?: string;
    password?: string;
    userEmail?: string;
    insertDate?: Date;
    insertUserId?: number;
    companyBranchId?: number;
    personId?: number;
    personName?: string;
    userRoleId?: number;
    userRoleDescription?: string;
    lastLoginDate?: Date;
    isActive?: boolean;
    resourcePMIsActive?: boolean;
    imagesOverText?: boolean;
    portalId: number;
    clientId: number;
    resourceId: number;
    clientContactId: number;
}

    export class PostSetUserStatusAndAvatar {
        userId?: number;
        isActive?: boolean;
        imageOverText?: boolean;
    }