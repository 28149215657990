import { NgModule } from '@angular/core';
import { RoleNamePipe } from 'app/pipes/role-name-pipe/role-name.pipe';
import { SafePipe } from 'app/pipes/safe-pipe/safe-pipe';
import { UserService } from 'app/services/general/user.service';

@NgModule({
    declarations: [RoleNamePipe],
    providers: [RoleNamePipe],
    exports: [RoleNamePipe],
})
export class RoleNamePipeModule {
}