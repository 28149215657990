import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {AppConfigService} from "../../../../services/app.settings.service";
import {Observable} from "rxjs";
import {BaseResultModel} from "../../../../models";
import {ResponseCatCountDiscounts} from "../../cat-count/models/response-models/response-cat-count-discounts";
import {PostCatCountDiscounts} from "../../cat-count/models/post-models/post-cat-count-discounts";
import {ResponseClientCatCountDiscounts} from "../../../pm/clients/models/clientCatCount.model";
import {ResponseResourceCatCountDiscounts} from "../../../pm/resources/models/resourceCatCount.model";
@Injectable({
  providedIn: 'root'
})
export class CatCountService {
    private uri: string = '';
    private endpoint: string = '/system/cat-count';
    constructor(
        private http: HttpClient,
        private configService: AppConfigService,
    ) {
        if (configService) this.uri = configService.getAPIUrl();
    }
    /**
     * @function getSystemClientCatCountDiscounts
     * @description get system client cat count discounts
     * @return Observable<BaseResultModel<ResponseCatCountDiscounts>>
     * */
    getSystemClientCatCountDiscounts(): Observable<BaseResultModel<ResponseCatCountDiscounts>> {
        return this.http.get<BaseResultModel<ResponseCatCountDiscounts>>(this.uri + this.endpoint + '/client');
    }
    /**
     * @function saveSystemClientCatCountDiscounts
     * @param payload: PostCatCountDiscounts
     * @description save system client cat count discounts
     * @return Observable<BaseResultModel<ResponseCatCountDiscounts>>
     * */
    saveSystemClientCatCountDiscounts(payload: PostCatCountDiscounts): Observable<BaseResultModel<ResponseCatCountDiscounts>> {
        return this.http.post<BaseResultModel<ResponseCatCountDiscounts>>(this.uri + this.endpoint + '/client', payload);
    }
    /**
     * @function getSystemResourceCatCountDiscounts
     * @description get system resource cat count discounts
     * @return Observable<BaseResultModel<ResponseCatCountDiscounts>>
     * */
    getSystemResourceCatCountDiscounts(): Observable<BaseResultModel<ResponseCatCountDiscounts>> {
        return this.http.get<BaseResultModel<ResponseCatCountDiscounts>>(this.uri + this.endpoint + '/resource');
    }
    /**
     * @function saveSystemResourceCatCountDiscounts
     * @param payload: PostCatCountDiscounts
     * @description save system resource cat count discounts
     * @return Observable<BaseResultModel<ResponseCatCountDiscounts>>
     * */
    saveSystemResourceCatCountDiscounts(payload: PostCatCountDiscounts): Observable<BaseResultModel<ResponseCatCountDiscounts>> {
        return this.http.post<BaseResultModel<ResponseCatCountDiscounts>>(this.uri + this.endpoint + '/resource', payload);
    }
    /**
     * @function applyCatCountDiscountsToAllResources
     * @param payload: PostCatCountDiscounts
     * @description save cat count discounts to all resources
     * @return Observable<BaseResultModel<ResponseCatCountDiscounts>>
     * */
    applyCatCountDiscountsToAllResources(payload: PostCatCountDiscounts): Observable<BaseResultModel<ResponseCatCountDiscounts>> {
        return this.http.post<BaseResultModel<ResponseCatCountDiscounts>>(this.uri + '/resource/cat-count/apply/all', payload);
    }
    /**
     * @function applyCatCountDiscountsToAllClients
     * @param payload: PostCatCountDiscounts
     * @description save cat count discounts to all clients
     * @return Observable<BaseResultModel<ResponseCatCountDiscounts>>
     * */
    applyCatCountDiscountsToAllClients(payload: PostCatCountDiscounts): Observable<BaseResultModel<ResponseCatCountDiscounts>> {
        return this.http.post<BaseResultModel<ResponseCatCountDiscounts>>(this.uri + '/client/cat-count/apply/all', payload);
    }
    /**
     * @function getCatCountDiscountsByClientId
     * @param clientId: number
     * @description get cat count discounts by client id
     * @return Observable<BaseResultModel<ResponseClientCatCountDiscounts>>
     * */
    getCatCountDiscountsByClientId(clientId: number): Observable<BaseResultModel<ResponseClientCatCountDiscounts>> {
        return this.http.get<BaseResultModel<ResponseClientCatCountDiscounts>>(this.uri + '/client/' + clientId + '/cat-count');
    }
    /**
     * @function getCatCountDiscountsByResourceId
     * @param resourceId: number
     * @description get cat count discounts by resource id
     * @return Observable<BaseResultModel<ResponseResourceCatCountDiscounts>>
     * */
    getCatCountDiscountsByResourceId(resourceId: number): Observable<BaseResultModel<ResponseResourceCatCountDiscounts>> {
        return this.http.get<BaseResultModel<ResponseResourceCatCountDiscounts>>(this.uri + '/resource/' + resourceId + '/cat-count');
    }
}