import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    Output, ViewChild
} from '@angular/core';
import {ControlContainer, ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {MatSelect} from '@angular/material/select';
import {TranslateService} from '@ngx-translate/core';
import {DropDownGroupBy, DropDownItem} from 'app/models/general/dropdownItem.model';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {BaseControlComponentDirective} from '../base-control-component.directive';
import _ from "lodash";
import {CdkVirtualScrollViewport} from "@angular/cdk/scrolling";
import {groupDropdownOptions} from "../../services/helpers/dropdown-helpers";
import {DataService} from "../../services/data/data.service";
import {PredefinedDropdownGroupService} from "../../services/predefined/predefined-dropdown-group.service";

@Component({
    selector: 'tp-dropdown',
    templateUrl: './tp-dropdown.component.html',
    styleUrls: ['./tp-dropdown.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TpDropDownComponent),
            multi: true,
        },
    ],
})
export class TpDropDownComponent extends BaseControlComponentDirective implements OnInit, OnDestroy, ControlValueAccessor {

    @ViewChild('cdkVirtualScroll') private cdkVirtualScroll: CdkVirtualScrollViewport;
    @Output() valueChanged: EventEmitter<any> = new EventEmitter();
    @Output() onClickOption: EventEmitter<any> = new EventEmitter();

    @Input() set options(value: DropDownItem[]) {
        this._options = _.cloneDeep(value) ?? [];
        this.filterOptions();
        this.areAllSelected();
    }
    @Input() public set initialValue(value: any) {
        if (!this.initialValueSet) {
            this.initialValueSet = true;
            this.control?.setValue(value);
            this.areAllSelected();
        }
    }
    @Input() multiple: boolean = false;
    @Input() set groupBy(groupBy: DropDownGroupBy){
        this._groupBy = groupBy;
    }
    get groupBy(): DropDownGroupBy{
        return this._groupBy;
    }
    private _groupBy: DropDownGroupBy = this.predefinedDropdownGroupService.dropdownGroupByActive();
    @Input() selectAll: boolean = true;
    @Input() none: boolean = true;
    @Input() search: boolean = true;
    @Input() emitValueElseWholeOption: boolean = true;
    @Input() set excludeIds(ids: number[]) {
        (ids?.length > 0) ? this._excludeIds = ids : this._excludeIds = [];
        this.filterOptions();
        this.areAllSelected();
    }
    get excludeIds(): number[] {return this._excludeIds};
    _excludeIds: number[] = [];

    get options(): DropDownItem[] { return this._options; }
    get selectedOptions(): DropDownItem[] { return this._selectedOptions; }

    private _options: DropDownItem[] = [];
    public _selectedOptions: DropDownItem[] = [];
    protected _onDestroy = new Subject<void>();
    public initialValueSet: boolean;
    public filterControl: FormControl = new FormControl();
    public filteredOptions: DropDownItem[] = [];
    public allowVirtualOptionsLimit: number = 20;
    public allowVirtualOptions: boolean = false;
    public selectedAll: boolean = false;
    constructor(
        @Optional() protected readonly controlContainer: ControlContainer,
        protected readonly translateService: TranslateService,
        private dataService: DataService,
        private predefinedDropdownGroupService: PredefinedDropdownGroupService
    ) {
        super(controlContainer, translateService);
    }
    
    ngOnInit(): void {
        this.filterControl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => { this.filterOptions(); });
        if (this.control) this.control.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(value => { this.refreshSelectedOptions(value) });
    }
    ngOnDestroy() {
        super.ngOnDestroy();
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    public objectComparisonFunction = function( option, value ) : boolean {
        return option?.id === value?.id;
    }

    public valueComparisonFunction = function( option, value ) : boolean {
        return option === value;
    }

    filterOptions(): void {
        this.filteredOptions = [];
        if (this._options && this._options.length > 0) {
            if(this.control?.value) this.refreshSelectedOptions(this.control.value);
            let search = this.filterControl.value;
            const options = this._options.filter(option => {
                return !search ? true : option?.name?.toLowerCase()?.indexOf(search?.toLowerCase()) > -1
            });
            const filteredOptions = this.excludeOptions(options);
            if(this.groupBy.status) {
                const groupDropdown = groupDropdownOptions(filteredOptions, this.groupBy);
                this.filteredOptions = groupDropdown.options;
            } else this.filteredOptions = filteredOptions;
            this.allowVirtualOptions = (this.filteredOptions.length >= this.allowVirtualOptionsLimit);
        } else this.allowVirtualOptions = false;
        if(this.groupBy.status){
            const groupDropdown = groupDropdownOptions(this.options, this.groupBy);
            this.groupBy.hasMultiple = groupDropdown.hasMultiGroups;
        }
        this.areAllSelected();
    }

    onActiveChange(): void {
        this.filterOptions();
    }
    onSearchKeyDown(event: KeyboardEvent): void {
        event.stopPropagation();
    }
    /**
     * @function refreshSelectedOptions
     * @param value
     * @description refresh selected options
     * @return void
     * */
    private refreshSelectedOptions(value): void {
        if (!this.emitValueElseWholeOption) {
            if (this.multiple) this._selectedOptions = value ? this.options.filter(option => value.map(item => item.id).includes(option.id)) : [];
            else this._selectedOptions = value ? this.options.filter(option => option.id === value.id) : [];
        } else {
            if (this.multiple) this._selectedOptions = value ? this.options.filter(option => value.includes(option.id)) : [];
            else this._selectedOptions = value ? this.options.filter(option => option.id === value) : [];
        }
    }
    private excludeOptions(options: DropDownItem[]): DropDownItem[] {
        return options.filter(option => !this.excludeIds.includes(option.id));
    }

    displayFn(val?: string): string | undefined {
        if (this._options) {
            const data = this._options.filter((f) => f.name == val);
            return data != null && data.length > 0 ? data[0].name : val.toString();
        } 
        else {
            return val.toString();
        }
    }

    onClickedOutside(event: any, dropdown: MatSelect): void {
        if(event.path){
            if (
                !(
                    event.path[0].className === 'mat-button-wrapper' ||
                    !!event.path.find(
                        (elem) => elem.className === 'cdk-overlay-pane',
                    ) ||
                    event.path[0].className === 'mat-option-text' ||
                    event.path[0].className ===
                    'cdk-overlay-backdrop cdk-overlay-transparent-backdrop' ||
                    event.path[0].className === 'center-block mat-button'
                )
            ) {
                dropdown.close();
            }
        }        
    }
    openChange(e): void{
        if (e && this.cdkVirtualScroll) this.cdkVirtualScroll.checkViewportSize();
        this.dataService.hideDropdown = !e;
        this.filterControl.setValue(null);
    }
    onSelect(e): void {
        this.valueChanged.emit(e.value);
    }
    clickOption(event): void {
        this.onClickOption.emit(event);
        if(this.multiple) this.areAllSelected();
    }
    onSelectAll(): void {
        this.selectedAll = !this.selectedAll;
        let value = this.selectedAll ? this.filteredOptions.map(option => this.emitValueElseWholeOption ? option.id : option) : null;
        if (this.control) this.control.setValue(value);
        else this.writeValue(value);
        this.valueChanged.emit(value);
        this.areAllSelected();
    }
    areAllSelected(): void{
        if(this.multiple) this.selectedAll = (this.filteredOptions.length === this.getValue()?.length);
    }
    writeValue(obj: any): void {
        super.writeValue(obj);
        this.areAllSelected();
    }

    getBgColorForStatusTag(rgb: string) {
        const colors = ['color'];
  
        // Getting the index of "(" and ")" 
        // by using the indexOf() method
        const colorArr = rgb?.slice(
            rgb.indexOf("(") + 1, 
            rgb.indexOf(")")
        ).split(", ");
        
        const obj: any = {};
        
        // Insert the values into obj
        colorArr?.forEach((k, i) => {
            obj[colors[i]] = k
        });
        
        if (!obj.color) {
            return;
        }

        return `rgba(${obj.color},0.2)`
    }

}