<div class="dropdown-container w-100">
    <ng-container *ngIf="control; then reactive; else nonReactive"></ng-container>
    <ng-template #reactive>
        <mat-form-field class="example-full-width" [appearance]="appearance" (clickOutside)="onClickedOutside($event, dropdown)">
            <mat-label [class.font-weight-semibold]="required && !readOnly">{{label}}</mat-label>
            <mat-select #matSelect [disableRipple]="true" #dropdown [disabled]="disabled" [formControl]="control" [required]="required" [multiple]="multiple" (selectionChange)="onSelect($event)"
                        [compareWith]="(emitValueElseWholeOption) ? valueComparisonFunction : objectComparisonFunction" [disableOptionCentering]="true" (openedChange)="openChange($event)"
                        (click)="$event.stopPropagation()">

                <div class="dropdown-search p-2 border-bottom d-flex align-items-center justify-content-between" *ngIf="search">
                    <div class="w-100 cursor-pointer tp-dropdown-option-search" matRipple (click)="$event.stopPropagation()">
                        <input tpAutoFocus [allowAutofocus]="matSelect.panelOpen" (keydown)="onSearchKeyDown($event)" type="text" class="p-2 h-100 w-100 border-0 text-color" placeholder="{{'tp-search' | translate :'{defaultText: \'Search\'}'}}" [formControl]="filterControl">
                    </div>
                    <div class="pl-2" (click)="$event.stopPropagation()" *ngIf="groupBy.status && groupBy.hasMultiple">
                        <tp-toggle [(ngModel)]="groupBy.toggle" (ngModelChange)="onActiveChange()" [label]="groupBy.toggleLabelAsync | async"></tp-toggle>
                    </div>
                </div>
                <div class="tp-dropdown custom-scrollbar" *ngIf="!allowVirtualOptions; else virtualOptions">
                    <mat-option matRipple *ngIf="!required && none && !multiple" [value]="null" (click)="clickOption(null); $event.stopPropagation()">
                        {{'tp-none' | translate : '{defaultText: \'None\'}'}}
                    </mat-option>
                    <div *ngIf="multiple && options.length > 0 && selectAll" class="py-2 px-3 w-100 cursor-pointer color-background-hover" matRipple (click)="$event.stopPropagation(); onSelectAll()">
                        <mat-checkbox class="dropdown-select-all-checkbox" (click)="$event.stopPropagation()" [checked]="selectedAll" (change)="onSelectAll()">
                            {{'tp-select-all' | translate :'{defaultText: \'Select all\'}'}}
                        </mat-checkbox>
                    </div>
                    <div *ngFor="let option of filteredOptions">
                        <mat-option matRipple *ngIf="!option.isGroup" (click)="clickOption(emitValueElseWholeOption ? option.id : option); $event.stopPropagation()"
                                    [value]="emitValueElseWholeOption ? option.id : option" [style]="option.cssStyles ? option.cssStyles : ''"
                                    [style.color]="option?.icon ? option?.icon + ' !important' : null" [ngStyle]="{'background-color': getBgColorForStatusTag(option?.icon) }">
                            <ng-container [ngTemplateOutlet]="optionTemplate" [ngTemplateOutletContext]="{$implicit: option}"></ng-container>
                        </mat-option>
                        <div class="py-2 px-3" *ngIf="option.isGroup">
                            <span class="font-weight-bold">{{option.textAsync | async}}</span>
                        </div>
                    </div>
                </div>
                <ng-template #virtualOptions>
                    <cdk-virtual-scroll-viewport #cdkVirtualScroll itemSize="30" class="tp-dropdown-virtual-viewport custom-scrollbar">
                        <mat-option matRipple *ngIf="!required && none && !multiple" [value]="null" (click)="clickOption(null); $event.stopPropagation()">
                            {{'tp-none' | translate : '{defaultText: \'None\'}'}}
                        </mat-option>
                        <div *ngIf="multiple && options.length > 0 && selectAll" class="py-2 px-3 w-100 cursor-pointer color-background-hover" matRipple (click)="$event.stopPropagation(); onSelectAll()">
                            <mat-checkbox class="dropdown-select-all-checkbox" (click)="$event.stopPropagation()" [checked]="selectedAll" (change)="onSelectAll()">
                                {{'tp-select-all' | translate :'{defaultText: \'Select all\'}'}}
                            </mat-checkbox>
                        </div>
                        <div *cdkVirtualFor="let option of filteredOptions">
                            <mat-option matRipple *ngIf="!option.isGroup" (click)="clickOption(emitValueElseWholeOption ? option.id : option); $event.stopPropagation()"
                                        [value]="emitValueElseWholeOption ? option.id : option" [style]="option.cssStyles ? option.cssStyles : ''"
                                        [style.color]="option?.icon ? option?.icon + ' !important' : null" [ngStyle]="{'background-color': getBgColorForStatusTag(option?.icon) }">
                                <ng-container [ngTemplateOutlet]="optionTemplate" [ngTemplateOutletContext]="{$implicit: option}"></ng-container>
                            </mat-option>
                            <div class="py-2 px-3" *ngIf="option.isGroup">
                                <span class="font-weight-bold">{{option.textAsync | async}}</span>
                            </div>
                        </div>
                    </cdk-virtual-scroll-viewport>
                </ng-template>
                <div>
                    <mat-option class="d-none" [hidden]="true" (click)="clickOption(emitValueElseWholeOption ? option.id : option); $event.stopPropagation()"
                                *ngFor="let option of selectedOptions"
                                [value]="emitValueElseWholeOption ? option.id : option"
                                [style.color]="option?.icon ? option?.icon + ' !important' : null"
                                [ngStyle]="{'background-color': getBgColorForStatusTag(option?.icon) }">
                        <ng-container *ngIf="option.textAsync">
                            {{ option.textAsync | async }}
                        </ng-container>
                        <ng-container *ngIf="!option.textAsync">
                            {{ option.name }}
                        </ng-container>
                    </mat-option>
                </div>
            </mat-select>

            <ng-container *ngIf="filteredErrors?.length > 0;then backendErrors;else frontendErrors"></ng-container>
            <ng-template #backendErrors>
                <mat-error *ngIf="filteredErrors[0] as err">
                <span style="color: red">
                    <ng-container *ngIf="useTranslation">
                        {{ err.errorCode | translate: err.errorParameters }}
                    </ng-container>
                    <ng-container *ngIf="!useTranslation">
                        {{ err.errorDescription }}
                    </ng-container>
                </span>
                </mat-error>
            </ng-template>
            <ng-template #frontendErrors>
                <mat-error *ngIf="showFormError">
                    <span style="color: red">{{ formErrors[0] }}</span>
                </mat-error>
            </ng-template>
        </mat-form-field>
    </ng-template>

    <ng-template #nonReactive>
        <mat-form-field class="example-full-width" [appearance]="appearance" (clickOutside)="onClickedOutside($event, dropdown)">
            <mat-label [class.font-weight-semibold]="required && !readOnly">{{label}}</mat-label>
            <mat-select #dropdown [(ngModel)]="latestWrittenValue" (ngModelChange)="writeValue($event)" [ngModelOptions]="{ standalone: true }" [required]="required"
                        [multiple]="multiple" [disabled]="disabled" (selectionChange)="onSelect($event)" [disableOptionCentering]="true"
                        [compareWith]="(emitValueElseWholeOption) ? valueComparisonFunction : objectComparisonFunction">
                <div class="dropdown-search p-2 border-bottom d-flex align-items-center justify-content-between" *ngIf="search">
                    <div class="w-100 cursor-pointer tp-dropdown-option-search" matRipple (click)="$event.stopPropagation()">
                        <input tpAutoFocus type="text" class="p-2 h-100 w-100 border-0 color-background-content text-color" placeholder="{{'tp-search' | translate :'{defaultText: \'Search\'}'}}" [formControl]="filterControl">
                    </div>
                    <div class="pl-2" (click)="$event.stopPropagation()" *ngIf="groupBy.status && groupBy.hasMultiple">
                        <tp-toggle [(ngModel)]="groupBy.toggle" (ngModelChange)="onActiveChange()" [label]="groupBy.toggleLabelAsync | async"></tp-toggle>
                    </div>
                </div>
                <div class="tp-dropdown custom-scrollbar">
                    <mat-option *ngIf="!required && none && !multiple" [value]="null" (click)="clickOption(null)">
                        {{'tp-none' | translate : '{defaultText: \'None\'}'}}
                    </mat-option>

                    <div *ngIf="multiple && options.length > 0 && selectAll" class="py-2 px-3 w-100 cursor-pointer color-background-hover" matRipple (click)="$event.stopPropagation(); onSelectAll()">
                        <mat-checkbox class="dropdown-select-all-checkbox" (click)="$event.stopPropagation()" [checked]="selectedAll" (change)="onSelectAll()">
                            {{'tp-select-all' | translate :'{defaultText: \'Select all\'}'}}
                        </mat-checkbox>
                    </div>
                    <div *ngFor="let option of filteredOptions">
                        <mat-option *ngIf="!option.isGroup" (click)="clickOption(emitValueElseWholeOption ? option.id : option); $event.stopPropagation()"
                                    [value]="emitValueElseWholeOption ? option.id : option" [style]="option.cssStyles ? option.cssStyles : ''">
                            <ng-container [ngTemplateOutlet]="optionTemplate" [ngTemplateOutletContext]="{$implicit: option}"></ng-container>
                        </mat-option>
                        <div class="py-2 px-3" *ngIf="option.isGroup">
                            <span class="font-weight-bold">{{option.textAsync | async}}</span>
                        </div>
                    </div>
                </div>
            </mat-select>

            <ng-container *ngIf="filteredErrors?.length > 0;then backendErrors;else frontendErrors"></ng-container>
            <ng-template #backendErrors>
                <mat-error *ngIf="filteredErrors[0] as err">
                <span style="color: red">
                    <ng-container *ngIf="useTranslation">
                        {{ err.errorCode | translate: err.errorParameters }}
                    </ng-container>
                    <ng-container *ngIf="!useTranslation">
                        {{ err.errorDescription }}
                    </ng-container>
                </span>
                </mat-error>
            </ng-template>
            <ng-template #frontendErrors>
                <mat-error *ngIf="showFormError">
                    <span style="color: red">{{ formErrors[0] }}</span>
                </mat-error>
            </ng-template>
        </mat-form-field>
    </ng-template>
    <ng-template #optionTemplate let-option>
        <div class="d-flex justify-content-between">
            <div>
                <ng-container *ngIf="option.textAsync">
                    {{ option.textAsync | async }}
                </ng-container>
                <ng-container *ngIf="!option.textAsync">
                    {{ option.name }}
                </ng-container>
                <span *ngIf="option.suffix">{{option.suffix}}</span>
            </div>
            <div>
                <tp-star-rating *ngIf="option.rating || option.rating == 0"
                                [readonly]="true"
                                [initialStars]="option.rating"
                                [maxStars]="5"
                                [animation]="false">
                </tp-star-rating>
            </div>
        </div>
    </ng-template>

</div>