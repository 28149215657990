import { OverlayModule } from '@angular/cdk/overlay'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexModule } from '@angular/flex-layout'
import { MatButtonModule } from '@angular/material/button'
import { MatDialogModule } from '@angular/material/dialog'
import { MatIconModule } from '@angular/material/icon'
import {
    AppComponentDirective,
    TpModalComponent,
} from 'app/components/tp-modal/tp-modal.component'
import { TpButtonModule } from '../tp-button/tp-button.module'
import {MatSidenavModule} from "@angular/material/sidenav";
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from "@angular/material/bottom-sheet";
import {MatTooltipModule} from "@angular/material/tooltip";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
    declarations: [TpModalComponent, AppComponentDirective],
    imports: [
        CommonModule,
        MatDialogModule,
        OverlayModule,
        MatIconModule,
        FlexModule,
        MatButtonModule,
        MatSidenavModule,
        MatTooltipModule,
        TranslateModule
    ],
    exports: [TpModalComponent],
    providers: [
        { provide: MatBottomSheetRef, useValue: {} },
        { provide: MAT_BOTTOM_SHEET_DATA, useValue: {} }
    ],
})
export class TpModalModule {}