<tp-dropdown
        [formControl]="control"
        [emitValueElseWholeOption]="false"
        [groupBy]="dropdownGroupByWorkflow"
        [multiple]="true"
        [selectAll]="false"
        [options]="workflowOptions$ | async"
        label="{{'tp-workflow-steps' | translate: '{ defaultText : \'Workflow Steps\'}'}}"
        (onClickOption)="onOption($event)">
</tp-dropdown>
