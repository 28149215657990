import {EntitySearchAdditional} from "../models";
import {SearchCriteriaModel} from "../../models";
export const mapSearchProps = (action: SearchCriteriaModel<any>, count: number): EntitySearchAdditional<any> => {
   return {
       criteria: action,
       isLoading: false,
       count,
       firstLoad: true,
       currentPage: (action.skip + action.take) / action.take
    }
}