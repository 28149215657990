import { Pipe, PipeTransform } from '@angular/core';
import {characterLimitMedium} from "../../utills";

@Pipe({
    name: 'characterLimit',
})
export class CharacterLimitPipe implements PipeTransform {
    transform(value: string, count = characterLimitMedium): string {
        let result = value;
        if (value && value.length > count) {
            result = value.slice(0, count) + '...';
        }
        return result;
    }
}