import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { TpColorPickerComponent } from 'app/components/tp-color-picker/tp-color-picker.component'
import { ColorPickerModule } from 'ngx-color-picker'

@NgModule({
    declarations: [TpColorPickerComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,

        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        ColorPickerModule,
    ],
    exports: [TpColorPickerComponent],
})
export class TpColorPickerModule {}