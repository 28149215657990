import { createAction, props } from '@ngrx/store';
import {UnitModel} from "../../../admin/unit/models";
export enum UnitActionTypes {
    getUnits = '[Unit] Data',
    getUnit = '[Unit] Get Unit Data',
    addUnit = '[Unit] Add Unit',
    updateUnit = '[Unit] Update Unit',
    deleteUnit = '[Unit] Delete Unit',
}
export const getUnits = createAction(
    UnitActionTypes.getUnits
);
export const getUnit = createAction(
    UnitActionTypes.getUnit,
    props<{id: number}>()
);
export const addUnit = createAction(
    UnitActionTypes.addUnit,
    props<UnitModel>()
);
export const updateUnit = createAction(
    UnitActionTypes.updateUnit,
    props<UnitModel>()
);
export const deleteUnit = createAction(
    UnitActionTypes.deleteUnit,
    props<{id: number}>()
);