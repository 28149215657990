import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServiceDescriptionPipe } from './service-description.pipe';

@NgModule({
    declarations: [
        ServiceDescriptionPipe
    ],
    exports: [
        ServiceDescriptionPipe
    ],
    imports: [
        CommonModule
    ],
    providers: [
        ServiceDescriptionPipe
    ]
})
export class ServiceDescriptionModule { }