import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientNotesIconComponent } from './client-notes-icon.component';
import {MatIconModule} from "@angular/material/icon";
import {MatTooltipModule} from "@angular/material/tooltip";
@NgModule({
    declarations: [
        ClientNotesIconComponent
    ],
    imports: [
        CommonModule,
        MatIconModule,
        MatTooltipModule
    ],
    exports: [
        ClientNotesIconComponent
    ]
})
export class ClientNotesIconModule { }