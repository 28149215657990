import { Injectable } from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as ClientActions from "../../actions/client-actions/client.actions";
import {catchError, concatMap, map, share} from "rxjs/operators";
import {of} from "rxjs";
import {ClientService} from "../../../services/clients/client.service";
import {NotificationService} from "../../../../../services/notification.service";
@Injectable()
export class ClientFileEffects {

  constructor(
      private actions$: Actions,
      private clientService: ClientService,
      private notificationService: NotificationService
  ) {}

}