import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatRadioModule } from '@angular/material/radio'
import { TranslateModule } from '@ngx-translate/core'
import { TpPaymentTermsComponent } from 'app/components/tp-payment-terms/tp-payment-terms.component'

@NgModule({
    declarations: [TpPaymentTermsComponent],
    imports: [
        CommonModule,
        TranslateModule,
        ReactiveFormsModule,

        MatRadioModule,
        FormsModule,
    ],
    exports: [TpPaymentTermsComponent],
})
export class TpPaymentTermsModule {}