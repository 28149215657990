import {Component, ComponentRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ModalTypes, SystemCatCountDiscountsTypes} from "../../../../../utills";
import {TpModalComponent} from "../../../../../components/tp-modal/tp-modal.component";
import {CatCountAnalysisComponent} from "../cat-count-analysis.component";
import {ResponseCatCountDiscounts} from "../../models/response-models/response-cat-count-discounts";
import { ResponseCatToolAnalysisResult } from '../../models/cat-tool-analysis';
import { ResponseQuoteJobCatCount } from 'app/models/general/quote/response-models/response-quote-job-cat-count';
@Component({
  selector: 'tp-cat-count-analysis-modal',
  templateUrl: './cat-count-analysis-modal.component.html',
  styleUrls: ['./cat-count-analysis-modal.component.scss']
})
export class CatCountAnalysisModalComponent implements OnInit {
    @ViewChild('modal') modal: TpModalComponent;
    @Output() saved = new EventEmitter<any>();
    @Output() outCatCountFigures = new EventEmitter<ResponseCatToolAnalysisResult>();
    @Input() discounts: ResponseCatCountDiscounts;
    @Input() clientId: number;
    @Input() resourceId: number;
    @Input() clientJobId: number;
    @Input() resourceJobId: number;
    @Input() quoteJobId: number;
    @Input() analysisResult:ResponseCatToolAnalysisResult;
    @Input() catCountFigures:ResponseQuoteJobCatCount;
    @Input() system: keyof typeof SystemCatCountDiscountsTypes
    public modalComponentRef: ComponentRef<CatCountAnalysisComponent>;
    ModalTypes = ModalTypes;
    constructor() { }

    ngOnInit(): void {}
    
    /**
     * @function show
     * @description show can count analysis side modal
     * @return void
     * */
    show(analysis: number = null): void {
        this.modal.showComponents(
            CatCountAnalysisComponent, (component) => {
                this.modalComponentRef = component;
                component.instance.system = this.system;
                
                if(this.resourceId){
                    component.instance.resourceId=this.resourceId;
                } 

                if(this.clientId){
                    component.instance.clientId=this.clientId;
                } 

                if(this.discounts){
                    component.instance.discounts=this.discounts;
                } 

                if(this.analysisResult){
                    component.instance.analysisResult=this.analysisResult;
                } 

                if(this.catCountFigures){
                    component.instance.catCountFigures=this.catCountFigures;
                }                
            }
        );
    }
    /**
     * @function hide
     * @description hide modal
     * @return void
     * */
    hide(): void {
        this.modal.hide();
        this.modalComponentRef = null;
    }
    /**
     * @function save
     * @description on save analysis
     * @return void
     * */
    save(): void {
        const analysisResult = this.modalComponentRef.instance.getCatAnalysisResult();
        this.hide();
        this.saved.emit(analysisResult);
    }
    /**
     * @description performs new cat count analysis process
     */
    newAnalysis():void{
        const analysisResult = this.modalComponentRef.instance.getCleanCatAnalysisResult();
        this.hide();
        this.saved.emit(analysisResult);
    }
}