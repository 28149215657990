import {Component, Input, OnInit} from '@angular/core';
import {JobPriceDirection} from "../../quotes/shared/quote-job/quote-job.component";
import {JobPriceData} from "../../../../models/general/quote/post-models/post-quote-job";

@Component({
  selector: 'tp-currency-notification-icon',
  templateUrl: './currency-notification-icon.component.html',
  styleUrls: ['./currency-notification-icon.component.scss']
})
export class CurrencyNotificationIconComponent implements OnInit {

    @Input() jobPriceData: JobPriceData;

    public JobPriceDirection = JobPriceDirection;

    constructor() { }

    ngOnInit(): void { }

    hasRate(): boolean{
        return !!(this.jobPriceData?.rate);
    }

    isCurrencyMatching(): boolean{
        return (this.jobPriceData.isCurrencyMatching);
    }

    isRateConverted(): boolean{
        return (this.jobPriceData.isConverted);
    }

    isCompanyPrice(): boolean {
        return (this.jobPriceData.direction === JobPriceDirection.servicePriceList);
    }
    isClientPrice(): boolean {
        return (this.jobPriceData.direction === JobPriceDirection.clientPriceList);
    }
    isCustomPrice(): boolean {
       return (this.jobPriceData.direction === JobPriceDirection.custom);
    }

    getCurrencyLabel(): string {
        switch (true) {
            case !this.isCurrencyMatching() && this.isRateConverted():
                return ': ' + this.jobPriceData.conversion.baseRate + ' (' + this.jobPriceData.conversion.base + ') ' +
                    ' - Converted to: ' +  this.jobPriceData.conversion.targetRate + ' (Selected currency: ' + this.jobPriceData.conversion.target + ')';
            default:
                return '';
        }
    }

    getTextPriceDirection(): string{
        if (this.jobPriceData.direction === JobPriceDirection.custom) {
            return 'Custom Price';
        } else if (this.jobPriceData.direction === JobPriceDirection.clientPriceList) {
            return 'Client Price ';
        } else if (this.jobPriceData.direction === JobPriceDirection.servicePriceList) {
            return 'Company Price';
        } else{
            return '';
        }
    }

}