import {Directive, ElementRef, Input} from '@angular/core';

@Directive({
  selector: '[tpAutoClick]'
})
export class AutoClickDirective {
    @Input() public allowAutoClick: boolean = true;
    constructor(public element: ElementRef<HTMLElement>) {}
    ngAfterViewInit(): void {
        if (this.allowAutoClick) {
            setTimeout(() => this.element.nativeElement.click(), 0);
        }
    }

}