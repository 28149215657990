import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Optional, Output } from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BaseControlComponentDirective, DefaultErrorStateMatcher } from '../base-control-component.directive';
import { UtilsService } from 'app/services/utils';

export interface TpTextBoxPrefixOrSuffixDescriptor {
    content: string
    isIcon?: boolean
}

@Component({
    selector: 'tp-text-box',
    templateUrl: './tp-text-box.component.html',
    styleUrls: ['./tp-text-box.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TpTextBoxComponent),
            multi: true,
        },
    ],
})
export class TpTextBoxComponent extends BaseControlComponentDirective implements OnInit, OnDestroy {
    errorStateMatcher = new DefaultErrorStateMatcher();
    @Input() addBgTheme: boolean;
    @Input() hint:boolean=false;
    @Input() hintText:string='';
    @Input() autoComplete: string = 'off';
    @Output() keydown_enter = new EventEmitter();
    _inputType: string;
    @Input() set inputType(value: string) {
        this._inputType = value;
    }
    get inputType(): string {
        return this._inputType;
    }
    private _prefix: TpTextBoxPrefixOrSuffixDescriptor;
    @Input() public set prefix(value: TpTextBoxPrefixOrSuffixDescriptor) {
        this._prefix = value;
    }
    public get prefix(): TpTextBoxPrefixOrSuffixDescriptor {
        return this._prefix;
    }
    private _suffix: TpTextBoxPrefixOrSuffixDescriptor;
    @Input() public set suffix(value: TpTextBoxPrefixOrSuffixDescriptor) {
        this._suffix = value;
    }
    public get suffix(): TpTextBoxPrefixOrSuffixDescriptor {
        return this._suffix;
    }
    constructor(
        @Optional() protected readonly controlContainer: ControlContainer,
        protected readonly translateService: TranslateService
    ) {
        super(controlContainer, translateService);
    }

    emitEvent(): void {
        if (this.keydown_enter) {
            this.keydown_enter.emit();
        }
    }
}