import { InjectionToken, Inject, Injectable } from '@angular/core'
import { StorageService } from 'ngx-webstorage-service'

export const CORE_LOCAL_STORAGE = new InjectionToken<StorageService>(
    'CORE_LOCAL_STORAGE'
)
export const CORE_SESSION_STORAGE = new InjectionToken<StorageService>(
    'CORE_SESSION_STORAGE'
)

@Injectable()
export class TPStorageService  {
    constructor(
        @Inject(CORE_SESSION_STORAGE) private sessionStorage: StorageService,
        @Inject(CORE_LOCAL_STORAGE) private localStorage: StorageService
    ) {}

    readLocalStorage(key: string): string {
        return this.localStorage.get(key)
    }
    removeLocalStorage(key: string): void {
        return this.localStorage.remove(key)
    }
    writeLocalStorage(key: string, data: string): void {
        return this.localStorage.set(key, data)
    }
    clearLocalStorage(){
        this.localStorage.clear();
    }

    read(key: string): string {
        return this.sessionStorage.get(key)
    }

    removeItem(key: string): void {
        return this.sessionStorage.remove(key)
    }

    write(key: string, data: string): void {
        return this.sessionStorage.set(key, data)
    }

    clear(){
        this.sessionStorage.clear();
    }
}