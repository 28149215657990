import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { TranslateModule } from '@ngx-translate/core';
import { TpCountryDropdownModule } from 'app/components/tp-country-dropdown/tp-country-dropdown.module';
import { TpLanguagesSupportedDropdownModule } from 'app/components/tp-languages-supported-dropdown/tp-languages-supported-dropdown.module';
import { TpTextAreaModule } from 'app/components/tp-text-area/tp-text-area.module';
import { TpTextBoxModule } from 'app/components/tp-text-box/tp-text-box.module';
import { LeadPriorityDropdownModule } from 'app/main/pm/crm/shared/lead-priority-dropdown/lead-priority-dropdown.module';
import { LeadService } from 'app/main/pm/services/leads/lead.service';
import { LeadContactModalComponent } from 'app/main/sharedComponents/lead-contact-modal/lead-contact-modal.component';
import { LeadSearchModule } from 'app/main/sharedComponents/lead-search/lead-search.module';
import { PersonAddressModule } from '../person-address/person-address.module';

@NgModule({
    declarations: [LeadContactModalComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        LeadSearchModule,
        TpTextBoxModule,
        TranslateModule,
        TpLanguagesSupportedDropdownModule,
        TpTextAreaModule,
        MatExpansionModule,
        TpCountryDropdownModule,
        MatCardModule,
        LeadPriorityDropdownModule,
        PersonAddressModule
    ],
    providers: [
        LeadService,
    ],
    exports: [
        LeadContactModalComponent,
    ],
})
export class LeadContactModalModule {
}