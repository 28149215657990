import { createAction, props } from '@ngrx/store';
import {SearchCriteriaModel} from "../../../../../models";
import {PostClient, ResponseClientData} from "../../../clients/models/client.model";
import {ClientSearchCriteria} from "../../../clients/models";
import {ResponseClientNoteData} from "../../../clients/models/response-models/response-client-note-data";
import {PostClientNote} from "../../../clients/models/post-models/post-client-note";
import {PostAvatar} from "../../../../../models/post-models/post-avatar";
import {ClientStatusTypes} from "../../../clients/utils";
import {DropDownItem} from "../../../../../models/general/dropdownItem.model";
export enum ClientActionTypes {
    searchClientGrid = '[Client / Api] Search Client Grid',
    getClient = '[Client / Api] Get Client Data',
    getClientInfo = '[Client / Api] Get Client Info',
    addClient = '[Client / Api] Add Client',
    updateClient = '[Client / Api] Update Client',
    deleteClient = '[Client / Api] Delete Client',
    updateClientAvatar = '[Client / Api] Update Client Avatar',
    changeClientStatus = '[Client / Api] Change Client Status',
    getClientNotes = '[Client / Api] Get Client Notes',
    addClientNote = '[Client / Api] Add Client Note',
    updateClientNote = '[Client / Api] Update Client Note',
    deleteClientNote = '[Client / Api] Delete Client Note',
}
export const searchClientGrid = createAction(
    ClientActionTypes.searchClientGrid,
    props<SearchCriteriaModel<ClientSearchCriteria>>()
);
export const getClient = createAction(
    ClientActionTypes.getClient,
    props<{id: number}>()
);
export const getClientInfo = createAction(
    ClientActionTypes.getClientInfo,
    props<{id: number}>()
);
export const addClient = createAction(
    ClientActionTypes.addClient,
    props<PostClient>()
);
export const updateClient = createAction(
    ClientActionTypes.updateClient,
    props<ResponseClientData>()
);
export const deleteClient = createAction(
    ClientActionTypes.deleteClient,
    props<{ids: number[]}>()
);
export const updateClientAvatar = createAction(
    ClientActionTypes.updateClientAvatar,
    props<PostAvatar>()
);
export const changeClientStatus = createAction(
    ClientActionTypes.changeClientStatus,
    props<{clientIds: number[], statusId: ClientStatusTypes, clientsDropdown?: DropDownItem[]}>()
);
export const getClientNotes = createAction(
    ClientActionTypes.getClientNotes,
    props<{clientId: number}>()
);
export const addClientNote = createAction(
    ClientActionTypes.addClientNote,
    props<PostClientNote>()
);
export const updateClientNote = createAction(
    ClientActionTypes.updateClientNote,
    props<PostClientNote>()
);
export const deleteClientNote = createAction(
    ClientActionTypes.deleteClientNote,
    props<ResponseClientNoteData>()
);