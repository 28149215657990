import { Injectable } from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as ResourceActions from "../../actions/resource-actions/resource.actions";
import {catchError, concatMap, map, share} from "rxjs/operators";
import {resourceNotesEntity} from "../../reducers";
import {of} from "rxjs";
import {NotificationService} from "../../../../../services/notification.service";
import {ResourceNoteService} from "../../../resources/services/resource-note.service";

@Injectable()
export class ResourceNoteEffects {
    getResourceNotes$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ResourceActions.getResourceNotes),
            concatMap((action) =>
                this.resourceNoteService.getResourceNotes(action.resourceId).pipe(
                    map(response => {
                        return resourceNotesEntity.actions.upsertMany({entities: response.response});
                    }),
                    catchError(error => {
                        return of(resourceNotesEntity.actions.updateAdditional({updates:{error}}));
                    })
                )
            ),
            share()
        );
    });
    addResourceNote$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ResourceActions.addResourceNote),
            concatMap((action) =>
                this.resourceNoteService.addResourceNote(action).pipe(
                    map(response => {
                        this.notificationService.showSuccess('Saved', 'Resource Note Saved Successfully');
                        return resourceNotesEntity.actions.addOne({entity: response.response});
                    }),
                    catchError(error => {
                        return of(resourceNotesEntity.actions.updateAdditional({updates:{error}}));
                    })
                )
            ),
            share()
        );
    });
    updateResourceNote$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ResourceActions.updateResourceNote),
            concatMap((action) =>
                this.resourceNoteService.updateResourceNote(action).pipe(
                    map(response => {
                        this.notificationService.showSuccess('Saved', 'Resource Note Saved Successfully');
                        return resourceNotesEntity.actions.updateOne({update:{ id: response.response.id, changes: response.response }});
                    }),
                    catchError(error => {
                        return of(resourceNotesEntity.actions.updateAdditional({updates:{error}}));
                    })
                )
            ),
            share()
        );
    });
    deleteResourceNote$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ResourceActions.deleteResourceNote),
            concatMap((action) =>
                this.resourceNoteService.deleteResourceNote(action.id).pipe(
                    map(data => {
                        this.notificationService.showSuccess('Deleted', 'Resource Note Deleted Successfully');
                        return resourceNotesEntity.actions.removeOne({id: action.id});
                    }),
                    catchError(error => {
                        return of(resourceNotesEntity.actions.updateAdditional({updates:{error}}));
                    })
                )
            ),
            share()
        );
    });
    constructor(
        private actions$: Actions,
        private resourceNoteService: ResourceNoteService,
        private notificationService: NotificationService
    ) {}

}
