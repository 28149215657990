import { NgModule } from '@angular/core';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { DateToDiffFromNowPipe } from 'app/pipes/date-to-diff-from-now/date-to-diff-from-now.pipe';


@NgModule({
    declarations: [DateToDiffFromNowPipe],
    imports: [
        TranslateModule,
    ],
    providers: [TranslatePipe, DateToDiffFromNowPipe],
    exports: [
        DateToDiffFromNowPipe,
    ],
})
export class DateToDiffFromNowPipeModule {
}