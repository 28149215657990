
<div class="modal-container" *ngIf="!sidenav">
    <ng-template #template>
        <ng-container *ngTemplateOutlet="modal"></ng-container>
    </ng-template>
</div>

<div class="modal-container" *ngIf="sidenav">
    <ng-template #template>
        <mat-drawer-container>
            <mat-drawer #drawer mode="over" position="end">
                <ng-content select="[sidenav]"></ng-content>
            </mat-drawer>
            <ng-container *ngTemplateOutlet="modal"></ng-container>
            <a href="javascript:" id="open-modal-drawer" (click)="drawer.open()"></a>
        </mat-drawer-container>
    </ng-template>
</div>

<ng-template #modal>
    <div class="modal-opened-with" *ngIf="isShowing">
        <div #headerWrapper class="modal-header py-3 custom-header" [ngClass]="{'scrolled': scrolled}" fxLayout="row" fxLayoutAlign="space-between canter">
            <ng-content select="[header]" fxFlex></ng-content>
            <div>
                <button mat-icon-button *ngIf="showCloseIcon" (click)="cancelClicked.emit(); hide()"
                        type="button" aria-label="Close"
                        matTooltip="{{'tp-close' | translate:'{defaultText:\'Close\'}'}}">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </div>
        <div class="modal-body modal-background-style custom-scrollbar py-3" (scroll)="onScroll($event)"
             [ngStyle]="{'max-height': 'calc(' + modalConfig.maxHeight + ' - ' + (headerWrapper.getBoundingClientRect().height + footerWrapper.getBoundingClientRect().height) + 'px)'}">
            <ng-content *ngIf="isShowing && !dynamicComponentLoad" select="[body]"></ng-content>
            <ng-container appComponentContainer #appComponent></ng-container>
        </div>
        <div #footerWrapper [ngClass]="{'p-3': showFooter}">
            <ng-content *ngIf="showFooter" select="[footer]"></ng-content>
        </div>
    </div>
</ng-template>