import {Component, EventEmitter, forwardRef, Input, Optional, Output} from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'
import { BaseControlComponentDirective } from '../base-control-component.directive'
import {MatSlideToggleChange} from "@angular/material/slide-toggle";

@Component({
    selector: 'tp-toggle',
    templateUrl: './tp-toggle.component.html',
    styleUrls: ['./tp-toggle.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TpToggleComponent),
            multi: true,
        },
    ],
})
export class TpToggleComponent extends BaseControlComponentDirective {
    @Output() valueChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() classes: string = '';
    constructor(
        @Optional()  protected readonly controlContainer: ControlContainer,
        protected readonly translateService: TranslateService
    ) {
        super(controlContainer, translateService)
    }
    onChange(event: MatSlideToggleChange): void {
        this.valueChanged.emit(event.checked);
    }
}