import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TpDropdownModule } from 'app/components/tp-dropdown/tp-dropdown.module'
import { TpMultiselectDropdownModule } from 'app/components/tp-multiselect-dropdown/tp-multiselect-dropdown.module'
import { TpRobertWorkflowDropdownComponent } from './tp-robert-workflow-dropdown.component';


@NgModule({
    declarations: [TpRobertWorkflowDropdownComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TpDropdownModule,
        TpMultiselectDropdownModule,
        FormsModule,
        TranslateModule,
    ],
    exports: [TpRobertWorkflowDropdownComponent],
})
export class TpRobertWorkflowDropdownModule {}