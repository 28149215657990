import {Action, combineReducers, createFeatureSelector, createReducer, createSelector, on} from '@ngrx/store';
import {createEntity, EntityAdditional, entityInitial} from "../models";
import {DropDownItem} from "../../models/general/dropdownItem.model";
import {globalStateFeatureSelector} from "../features";
import {ServiceDropDownItem} from "../models/dropdowns/service-dropdown-item";
import {TemplateDropdownItem} from "../models/dropdowns/template-dropdown-item";
import {TagDropdownItem} from "../models/dropdowns/tag-dropdown-item";
import {ResourceDropDownItem} from "../models/dropdowns/resource-dropdown-item";
import {BranchDropDownItem} from "../models/dropdowns/branch-dropdown-item";
import {MimeTypeDropDownItem} from "../models/dropdowns/mime-type-dropdown-item";

export const projectManagerDropdownFeatureKey = 'projectManagerDropdown';
export const languageDropdownFeatureKey = 'languageDropdown';
export const currencyDropdownFeatureKey = 'currencyDropdown';
export const countryDropdownFeatureKey = 'countryDropdown';
export const quoteStatusDropdownFeatureKey = 'quoteStatusDropdown';
export const projectStatusDropdownFeatureKey = 'projectStatusDropdown';
export const clientStatusDropdownFeatureKey = 'clientStatusDropdown';
export const jobStatusDropdownFeatureKey = 'jobStatusDropdown';
export const quoteJobStatusDropdownFeatureKey = 'quoteJobStatusDropdown';
export const invoiceStatusDropdownFeatureKey = 'invoiceStatusDropdown';
export const tagDropdownFeatureKey = 'tagDropdown';
export const templateDropdownFeatureKey = 'templateDropdown';
export const resourceTypeDropdownFeatureKey = 'resourceTypeDropdown';
export const resourceTeamDropdownFeatureKey = 'resourceTeamDropdown';
export const genderDropdownFeatureKey = 'genderDropdown';
export const roleDropdownFeatureKey = 'roleDropdown';
export const clientDropdownFeatureKey = 'clientDropdown';
export const resourceDropdownFeatureKey = 'resourceDropdown';
export const serviceDropdownFeatureKey = 'serviceDropdown';
export const serviceTypeDropdownFeatureKey = 'serviceTypeDropdown';
export const workflowDropdownFeatureKey = 'workflowDropdown';
export const unitDropdownFeatureKey = 'unitDropdown';
export const purchaseOrderStatusDropdownFeatureKey = 'purchaseOrderStatusDropdown';
export const userDropdownFeatureKey = 'userDropdown';
export const branchDropdownFeatureKey = 'branchDropdown';
export const mimeTypeDropdownFeatureKey = 'mimeTypeDropdown';

export const projectManagerDropdownEntity = createEntity<DropDownItem, EntityAdditional>(
    globalStateFeatureSelector,
    projectManagerDropdownFeatureKey,
    entityInitial({sort: {property: 'name'}}),
);
export const languageDropdownEntity = createEntity<DropDownItem, EntityAdditional>(
    globalStateFeatureSelector,
    languageDropdownFeatureKey,
    entityInitial({sort: {property: 'name'}}),
);
export const currencyDropdownEntity = createEntity<DropDownItem, EntityAdditional>(
    globalStateFeatureSelector,
    currencyDropdownFeatureKey,
    entityInitial({sort: {property: 'name'}}),
);
export const countryDropdownEntity = createEntity<DropDownItem, EntityAdditional>(
    globalStateFeatureSelector,
    countryDropdownFeatureKey,
    entityInitial({sort: {property: 'name'}}),
);
export const quoteStatusDropdownEntity = createEntity<DropDownItem, EntityAdditional>(
    globalStateFeatureSelector,
    quoteStatusDropdownFeatureKey,
    entityInitial({sort: {property: 'name'}}),
);
export const projectStatusDropdownEntity = createEntity<DropDownItem, EntityAdditional>(
    globalStateFeatureSelector,
    projectStatusDropdownFeatureKey,
    entityInitial({sort: {property: 'name'}}),
);
export const clientStatusDropdownEntity = createEntity<DropDownItem, EntityAdditional>(
    globalStateFeatureSelector,
    clientStatusDropdownFeatureKey,
    entityInitial({sort: {property: 'name'}}),
);
export const jobStatusDropdownEntity = createEntity<DropDownItem, EntityAdditional>(
    globalStateFeatureSelector,
    jobStatusDropdownFeatureKey,
    entityInitial({sort: {property: 'name'}}),
);
export const quoteJobStatusDropdownEntity = createEntity<DropDownItem, EntityAdditional>(
    globalStateFeatureSelector,
    quoteJobStatusDropdownFeatureKey,
    entityInitial({sort: {property: 'name'}}),
);
export const invoiceStatusDropdownEntity = createEntity<DropDownItem, EntityAdditional>(
    globalStateFeatureSelector,
    invoiceStatusDropdownFeatureKey,
    entityInitial({sort: {property: 'name'}}),
);
export const tagDropdownEntity = createEntity<TagDropdownItem, EntityAdditional>(
    globalStateFeatureSelector,
    tagDropdownFeatureKey,
    entityInitial({sort: {property: 'name'}}),
);
export const templateDropdownEntity = createEntity<TemplateDropdownItem, EntityAdditional>(
    globalStateFeatureSelector,
    templateDropdownFeatureKey,
    entityInitial({sort: {property: 'name'}}),
);
export const resourceTypeDropdownEntity = createEntity<DropDownItem, EntityAdditional>(
    globalStateFeatureSelector,
    resourceTypeDropdownFeatureKey,
    entityInitial({sort: {property: 'name'}}),
);
export const resourceTeamDropdownEntity = createEntity<DropDownItem, EntityAdditional>(
    globalStateFeatureSelector,
    resourceTeamDropdownFeatureKey,
    entityInitial({sort: {property: 'name'}}),
);
export const genderDropdownEntity = createEntity<DropDownItem, EntityAdditional>(
    globalStateFeatureSelector,
    genderDropdownFeatureKey,
    entityInitial({sort: {property: 'name'}}),
);
export const roleDropdownEntity = createEntity<DropDownItem, EntityAdditional>(
    globalStateFeatureSelector,
    roleDropdownFeatureKey,
    entityInitial({sort: {property: 'name'}}),
);
export const clientDropdownEntity = createEntity<DropDownItem, EntityAdditional>(
    globalStateFeatureSelector,
    clientDropdownFeatureKey,
    entityInitial({sort: {property: 'name'}}),
);
export const resourceDropdownEntity = createEntity<ResourceDropDownItem, EntityAdditional>(
    globalStateFeatureSelector,
    resourceDropdownFeatureKey,
    entityInitial({sort: {property: 'name'}}),
);
export const serviceDropdownEntity = createEntity<ServiceDropDownItem, EntityAdditional>(
    globalStateFeatureSelector,
    serviceDropdownFeatureKey,
    entityInitial({sort: {property: 'name'}}),
);
export const serviceTypeDropdownEntity = createEntity<DropDownItem, EntityAdditional>(
    globalStateFeatureSelector,
    serviceTypeDropdownFeatureKey,
    entityInitial({sort: {property: 'name'}}),
);
export const workflowDropdownEntity = createEntity<DropDownItem, EntityAdditional>(
    globalStateFeatureSelector,
    workflowDropdownFeatureKey,
    entityInitial({sort: {property: 'name'}}),
);
export const unitDropdownEntity = createEntity<DropDownItem, EntityAdditional>(
    globalStateFeatureSelector,
    unitDropdownFeatureKey,
    entityInitial({sort: {property: 'name'}}),
);
export const purchaseOrderStatusDropdownEntity = createEntity<DropDownItem, EntityAdditional>(
    globalStateFeatureSelector,
    purchaseOrderStatusDropdownFeatureKey,
    entityInitial({sort: {property: 'name'}}),
);
export const userDropdownEntity = createEntity<DropDownItem, EntityAdditional>(
    globalStateFeatureSelector,
    userDropdownFeatureKey,
    entityInitial({sort: {property: 'name'}}),
);
export const branchDropdownEntity = createEntity<BranchDropDownItem, EntityAdditional>(
    globalStateFeatureSelector,
    branchDropdownFeatureKey,
    entityInitial({sort: {property: 'name'}}),
);
export const mimeTypeDropdownEntity = createEntity<MimeTypeDropDownItem, EntityAdditional>(
    globalStateFeatureSelector,
    mimeTypeDropdownFeatureKey,
    entityInitial({sort: {property: 'name'}}),
);