import {ActionReducer, ActionReducerMap, createFeatureSelector, createSelector, MetaReducer} from '@ngrx/store';
import { environment } from '../../environments/environment';
import {routerReducer} from "@ngrx/router-store";
import {RouterReducerState} from "@ngrx/router-store/src/reducer";
import {globalStateFeatureKey} from "./features";
import {globalReducer, GlobalState} from "./reducers";
import {Logout} from "./actions";
export * from './features';
export interface State {
    routerState: RouterReducerState,
    [globalStateFeatureKey]: GlobalState
}

export const reducers: ActionReducerMap<State> = {
    routerState: routerReducer,
    [globalStateFeatureKey]: globalReducer
};

export function logout(reducer: ActionReducer<any>): ActionReducer<any> {
    return function (state, action) {
        return reducer(action.type === Logout.type ? undefined : state, action);
    }
}

export const metaReducers: MetaReducer<State>[] = !environment.production ? [logout] : [logout];