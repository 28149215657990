import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TpDropdownModule } from 'app/components/tp-dropdown/tp-dropdown.module';
import { PaperworkTemplateSelectorComponent } from 'app/main/sharedComponents/paperwork-template-selector/paperwork-template-selector.component';


@NgModule({
    declarations: [PaperworkTemplateSelectorComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TpDropdownModule,
    ],
    exports: [
        PaperworkTemplateSelectorComponent,
    ],
})
export class PaperworkTemplateSelectorModule {
}