import { Pipe, PipeTransform } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResourceNamePipe } from './resource-name-pipe';

@Pipe({
    name: 'resourcesNames',
})
export class ResourcesNamesPipe implements PipeTransform {
    constructor(private readonly resourceNamePipe: ResourceNamePipe) {
    }

    transform(resourceIds: string[]): Observable<string> {
        if (resourceIds?.length > 0) {
            return combineLatest(resourceIds?.map(s => this.resourceNamePipe.transform(Number(s))))
                .pipe(map(categoryNames => categoryNames.join(', ')));
        } else {
            return of('---');
        }
    }
}