import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import {FormsModule, ReactiveFormsModule} from '@angular/forms'
import { TranslateModule } from '@ngx-translate/core'
import { TpDropdownModule } from 'app/components/tp-dropdown/tp-dropdown.module'
import { TpTimeComponent } from 'app/components/tp-time/tp-time.component'
import {TimePickerModule} from "@syncfusion/ej2-angular-calendars";
import {MatFormFieldModule} from "@angular/material/form-field";

@NgModule({
    declarations: [TpTimeComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TpDropdownModule,
        TranslateModule,
        TimePickerModule,
        MatFormFieldModule,
        FormsModule
    ],
    exports: [TpTimeComponent],
})
export class TpTimeModule {}