import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JobCombinationsGridComponent } from './job-combinations-grid/job-combinations-grid.component';
import {TpGridModule} from "../../../components/tp-grid/tp-grid.module";
import {TpTextBoxModule} from "../../../components/tp-text-box/tp-text-box.module";
import {TpUnitsDropdownModule} from "../../../components/tp-units-dropdown/tp-units-dropdown.module";
import {FormsModule} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import { CurrencyNotificationIconComponent } from './currency-notification-icon/currency-notification-icon.component';
import {MatIconModule} from "@angular/material/icon";
import {MatTooltipModule} from "@angular/material/tooltip";
import {FlexModule} from "@angular/flex-layout";
import {TpButtonModule} from "../../../components/tp-button/tp-button.module";
import {TpDropdownModule} from "../../../components/tp-dropdown/tp-dropdown.module";
import {FormatNumberPipeModule} from "../../../pipes/format-number-pipe/format-number-pipe.module";

@NgModule({
    declarations: [JobCombinationsGridComponent, CurrencyNotificationIconComponent],
    exports: [
        JobCombinationsGridComponent,
        CurrencyNotificationIconComponent
    ],
    imports: [
        CommonModule,
        TpGridModule,
        TpTextBoxModule,
        TpUnitsDropdownModule,
        FormsModule,
        TranslateModule,
        MatIconModule,
        MatTooltipModule,
        FlexModule,
        TpButtonModule,
        TpDropdownModule,
        FormatNumberPipeModule
    ]
})
export class SharedModule { }