<div class="rte-form">
    <ng-container *ngIf="control; then reactive; else nonReactive"></ng-container>
    <ng-template #reactive>
        <quill-editor #editor [ngClass]="{'editor-with-toolbar': showToolBar}" (onSelectionChanged)="onSelectionChanged.emit($event)"
            [formControl]="control"
            [required]="required"
            [modules]="modules"
            [format]="format"
            [class]="cssClass"
            [placeholder]="label"
            [readOnly]="readOnly">
            <div *ngIf="customToolbar" quill-editor-toolbar>
                <ng-content></ng-content>
            </div>
        </quill-editor>
    </ng-template>
    <ng-template #nonReactive>
        <quill-editor #editor [ngClass]="{'editor-with-toolbar': showToolBar}" (onSelectionChanged)="onSelectionChanged.emit($event)"
            [(ngModel)]="latestWrittenValue" (ngModelChange)="writeValue($event)"
            [ngModelOptions]="{ standalone: true }"
            [required]="required"
            [format]="format"
            [modules]="modules"
            [class]="cssClass"
            [placeholder]="label"
            [readOnly]="readOnly">
        </quill-editor>
    </ng-template>
    <ng-container *ngIf="filteredErrors?.length > 0; then backendErrors; else frontendErrors"></ng-container>
    <ng-template #backendErrors>
        <mat-error *ngIf="filteredErrors[0] as err">
            <span style="color: red">
                <ng-container *ngIf="useTranslation">
                    {{ err.errorCode | translate: err.errorParameters }}
                </ng-container>
                <ng-container *ngIf="!useTranslation">
                    {{ err.errorDescription }}
                </ng-container>
            </span>
        </mat-error>
    </ng-template>
    <ng-template #frontendErrors>
        <mat-error *ngIf="showFormError">
            <span style="color: red">{{ formErrors[0] }}</span>
        </mat-error>
    </ng-template>
</div>