import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IntegrationsPageComponent } from './integrations-page.component';
import {SideNavModule} from "../../../../components/sidenav/sidenav.module";
import {IntegrationCardsModule} from "../integration-cards/integration-cards.module";
@NgModule({
    declarations: [
        IntegrationsPageComponent
    ],
    exports: [
        IntegrationsPageComponent
    ],
    imports: [
        CommonModule,
        SideNavModule,
        IntegrationCardsModule
    ],
})
export class IntegrationsPageModule { }