import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {DropDownItem} from "../../../../models/general/dropdownItem.model";
import {PostFile} from "../../../../models/general/system-models/post-file";
import { RefDataService } from 'app/services/general/refData.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { BaseResultModel } from 'app/models';
import { PostCatToolAnalysis } from '../models/cat-tool-analysis';
import { FormControl } from '@angular/forms';
import { NotificationService } from 'app/services/notification.service';
import {FileAutoUpload} from "../../../../components/drag-and-drop-file/drag-and-drop-file.component";

@Component({
  selector: 'tp-cat-count-analyse',
  templateUrl: './cat-count-analyse.component.html',
  styleUrls: ['./cat-count-analyse.component.scss']
})
export class CatCountAnalyseComponent implements OnInit {

    @Output() outFiles=new EventEmitter<PostFile[]>();
    @Output() outCatAnalysis=new EventEmitter<PostCatToolAnalysis>();

    private obs$:Subject<void>=new Subject<void>();

    analysisType:FormControl=new FormControl();
    
    catTools: DropDownItem[] = [];
    files: PostFile[] = [];
    autoUpload: Partial<FileAutoUpload> = { status: false };

    constructor(
      private _refDataService:RefDataService,
      private _notificationService:NotificationService
    ) 
    { }

    ngOnInit(): void {
      this._refDataService.getCatTools()
      .pipe(takeUntil(this.obs$))
      .subscribe(
        (res:BaseResultModel<DropDownItem[]>)=>{
          if(res.status){
            this.catTools=res.response;
          }
        }
      );
    }

    ngOnDestory():void{
      this.obs$.next();
      this.obs$.complete();
    }

    saveFiles(){
      this.outFiles.emit(this.files);
    }

    getSelectedFiles(e:PostFile[]){
      this.files=[];
      
      if(e){
        this.files=[...this.files,e[0]];

        var file:File=this.files[0].file;

        //check file type
        if(!this.allowedFormats(file.name)){
          this._notificationService.showError('Only .csv and .xlsx files are allowed to be uploaded','Warning');
          return;
        }
        
        if(this.analysisType.value){
          var postCatAnalysis:PostCatToolAnalysis=<PostCatToolAnalysis>{
            csvFile: this.files[0].file,
            catToolId: Number(this.analysisType.value),
            clientId:0,
            resourceId:0
          };

          this.outCatAnalysis.emit(postCatAnalysis);
          this.outFiles.emit(this.files);
        }     
      }      
         
    }

    getSelectedCatTool(e:number){
      if(e){        

        this.analysisType.setValue(e);
        //check if user has uploaded csv file
        if(this.files?.length>0){
          //check file type
          if(!this.allowedFormats(this.files[0]?.file.name)){
            this._notificationService.showError('Only .csv files are allowed to be uploaded','Warning');
            return;
          }

          var postCatAnalysis:PostCatToolAnalysis=<PostCatToolAnalysis>{
            csvFile: this.files[0].file,
            catToolId: Number(e),
            clientId:0,
            resourceId:0
          };
  
          this.outCatAnalysis.emit(postCatAnalysis);
          this.outFiles.emit(this.files);
        } 
      }
    }

    getExtension(filename) {
      var parts = filename.split('.');
      return parts[parts.length - 1];
    }
    
    allowedFormats(filename) {
      var ext = this.getExtension(filename);
      switch (ext.toLowerCase()) {
        case 'csv':
          return true;
        case 'xlsx':
            return true;
      }
      return false;
    }

}