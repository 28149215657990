import { Component, Input, Output, EventEmitter } from '@angular/core'
import {ThemePalette} from "@angular/material/core";

export enum ButtonTypes {
    cancel = 'cancel',
    submit = 'submit',
    smallPrimary = 'smallPrimary',
    smallSuccess = 'smallSuccess',
    outline = 'outline',
    secondary = 'secondary',
    dropdown = 'dropdown',
    add = 'add',
    icon = 'icon',
    reset_pass = 'reset_pass'
}

@Component({
    selector: 'tp-button',
    templateUrl: './tp-button.component.html',
    styleUrls: ['./tp-button.component.scss'],
})
export class TpButtonComponent {
    @Output() buttonClicked = new EventEmitter()

    @Input() showMarginRightForCancelButton = true;
    @Input() buttonWidth = 0;
    @Input()
    set disabledTooltipContent(value: string) {
        this._disabledTooltipContent = value
    }

    get disabledTooltipContent(): string {
        return this._disabledTooltipContent
    }

    @Input()
    set label(value: string) {
        this._label = value
    }

    get label(): string {
        return this._label
    }

    @Input() set iconClass(value: string) {
        this._iconClass = value
    }
    get iconClass(): string {
        return this._iconClass
    }
    _iconClass: string;
    @Input()
    set buttonStyle(
        value:
            | ButtonTypes
            | 'cancel'
            | 'submit'
            | 'smallPrimary'
            | 'smallSuccess'
            | 'outline'
            | 'secondary'
            | 'dropdown'
            | 'add'
            | 'icon'
            | 'reset_pass'
    ) {
        this._style = value;
        if(this._style === ButtonTypes.smallSuccess || this._style === ButtonTypes.submit || this._style === ButtonTypes.smallPrimary) this.color = 'primary';
    }
    get buttonStyle() {
        return this._style;
    }

    @Input()
    public set disabled(value: boolean) {
        this._disabled = value
    }

    public get disabled(): boolean {
        return this._disabled
    }

    @Input() set color(value: ThemePalette) {
        this._color = value;
    }

    public get color(): ThemePalette {
        return this._color
    }
    private _color: ThemePalette;

    _label: string = null;
    _style:
        | ButtonTypes
        | 'cancel'
        | 'submit'
        | 'smallPrimary'
        | 'smallSuccess'
        | 'outline'
        | 'secondary'
        | 'dropdown'
        | 'icon'
        | 'add' 
        | 'reset_pass'= ButtonTypes.submit
        
    _disabled = false
    _disabledTooltipContent: string;

    buttonType = ButtonTypes

    emitEvent(e): void {
        if (this.buttonClicked) {
            this.buttonClicked.emit(e)
        }
    }

    getIconClass(): object{
        return {[this.iconClass]: true};
    }

}