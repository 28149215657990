import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexModule } from '@angular/flex-layout'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatRadioModule } from '@angular/material/radio'
import { TranslateModule } from '@ngx-translate/core'
import { TpRadioButtonComponent } from 'app/components/tp-radio-button/tp-radio-button.component'

@NgModule({
    declarations: [TpRadioButtonComponent],
	imports: [
		CommonModule,
		TranslateModule,
		ReactiveFormsModule,

		MatRadioModule,
		FlexModule,
		MatFormFieldModule,
		FormsModule,
	],
    exports: [TpRadioButtonComponent],
})
export class TpRadioButtonModule {}