import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { TranslateModule } from '@ngx-translate/core'
import { MatTableModule } from '@angular/material/table'
import { TpUserPermissionListComponent } from "./tp-user-permission-list.component";
import { TpUnderlineDropdownModule } from '../tp-underline-dropdown/tp-underline-dropdown.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TpToggleModule } from '../tp-toggle/tp-toggle.module';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FlexModule } from '@angular/flex-layout';
import { MatExpansionModule } from '@angular/material/expansion';
import { TpCheckboxModule } from '../tp-checkbox/tp-checkbox.module';

@NgModule({
    declarations: [TpUserPermissionListComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        MatTableModule,
        MatSlideToggleModule,
        TpToggleModule,
        MatExpansionModule,
        FlexModule,
        MatTableModule,
        TpUnderlineDropdownModule,
        TpCheckboxModule
    ],
    exports: [TpUserPermissionListComponent],
})

export class TpUserPermissionListModule {}