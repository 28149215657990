<div class="mat-form-field">
    <mat-checkbox *ngIf="control" [formControl]="control" [required]="required" [disabled]="disabled" (change)="onChange($event)">
        {{ label }}
    </mat-checkbox>
    <mat-checkbox *ngIf="!control" [(ngModel)]="latestWrittenValue" (ngModelChange)="writeValue($event); onChange($event)"
                  [ngModelOptions]="{ standalone: true }" [required]="required" [disabled]="disabled">
        {{ label }}
    </mat-checkbox>
    <ng-container *ngIf="filteredErrors?.length > 0;then backendErrors;else frontendErrors"></ng-container>
    <ng-template #backendErrors>
        <mat-error *ngIf="filteredErrors[0] as err">
            <span style="color: red">
                <ng-container *ngIf="useTranslation">
                    {{ err.errorCode | translate: err.errorParameters }}
                </ng-container>
                <ng-container *ngIf="!useTranslation">
                    {{ err.errorDescription }}
                </ng-container>
            </span>
        </mat-error>
    </ng-template>
    <ng-template #frontendErrors>
        <mat-error *ngIf="showFormError">
            <span style="color: red">{{ formErrors[0] }}</span>
        </mat-error>
    </ng-template>
</div>