import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatButtonModule } from '@angular/material/button'
import { MatTooltipModule } from '@angular/material/tooltip'
import { TpButtonComponent } from 'app/components/tp-button/tp-button.component'
import {MatIconModule} from "@angular/material/icon";

@NgModule({
    declarations: [TpButtonComponent],
    imports: [CommonModule, MatButtonModule, MatTooltipModule, MatIconModule],
    exports: [TpButtonComponent],
})
export class TpButtonModule {}