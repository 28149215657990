<pagination-template
    class="w-100"
    #paginator="paginationApi"
    [id]="name"
    (pageChange)="pageChanged($event,selectedCountOfItems)"
>
    <div
        class="w-100"
        fxLayout="row"
        fxLayoutAlign="end center"
        fxLayoutGap="10px"
    >
        <ul class="pagination pagination-sm">
            <li class="page-item">
                <a
                    *ngIf="!paginator.isFirstPage()"
                    (click)="paginator.previous()"
                    class="page-link cursor-pointer"
                >
                    «
                </a>
            </li>
            
            <li
                *ngFor="let page of paginator.pages"
                [ngClass]="{
                    'page-item': true,
                    active: paginator.getCurrent() === page.value
                }"
            >
                <a
                    class="page-link cursor-pointer"
                    (click)="paginator.setCurrent(page.value)"
                    *ngIf="paginator.getCurrent() !== page.value"
                >
                    {{ page.label }}
                </a>
                <a
                    class="page-link cursor-pointer"
                    (click)="paginator.setCurrent(page.value)"
                    *ngIf="paginator.getCurrent() == page.value"
                >
                    {{ page.label }}
                </a>
            </li>
            
            <li class="page-item">
                <a
                    *ngIf="!paginator.isLastPage()"
                    [routerLink]=""
                    class="page-link cursor-pointer"
                    (click)="paginator.next()"
                >
                    »
                </a>
            </li>
        </ul>
        
        <div style="width: 70px">
            <tp-dropdown
                [(ngModel)]="selectedCountOfItems"
                [ngModelOptions]="{ standalone: true }"
                (valueChanged)="pageChanged(1,$event)"
                [label]="'tp-items' | translate: '{ defaultText: \'Items\' }'"
                [none]="false"
                [options]="dropdownOptions">
            </tp-dropdown>
        </div>
    </div>
</pagination-template>