
<tp-grid [tableConfig]="combinationJobsConfig" [allowPaging]="false"
         [totalCount]="isDirectionQuoteJobs ? _quoteJobs?.length || 0 : clientJobs?.length || 0"
         [tableContent]="isDirectionQuoteJobs() ? _quoteJobs : clientJobs" id="project-jobs-grid">

    <ng-template #mainGridTemplate let-item let-rowData="rowData">
        <ng-container [ngSwitch]="item.dataField">

            <ng-container *ngSwitchCase="'serviceDescription'">
                <span>{{rowData.serviceDescription}}</span>
            </ng-container>

            <ng-container *ngSwitchCase="'volume'">
                <div  style="width: 5rem">
                    <tp-text-box [ngModelOptions]="{standalone: true}" maxLength="200" [errors]="validationErrors" [fields]="['Volume']"
                                 [label]="'tp-volume' | translate: '{ defaultText : \'Volume\'}'" [(ngModel)]="rowData.volume" (input)="calculateTotal(rowData)">
                    </tp-text-box>
                </div>
            </ng-container>

            <ng-container *ngSwitchCase="'unit'">
                <div style="width: 10em">
                    <tp-dropdown [(ngModel)]="rowData.unitId" (onClickOption)="isDirectionQuoteJobs() ? onChangedUnit(rowData,$event) : onChangedClientJobUnit(rowData, $event)" [ngModelOptions]="{standalone: true}"  [errors]="validationErrors" [fields]="['UnitId']" [required]="true"
                                  label="{{'tp-unit' | translate: '{ defaultText : \'Unit\'}'}}" [options]="_units">
                    </tp-dropdown>
                </div>
            </ng-container>

            <ng-container *ngSwitchCase="'rate'">

                <div class="" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
<!--                    <span>{{rowData?.currencySymbol}}</span>-->
                    <div style="width: 5rem">
                        <tp-text-box [ngModelOptions]="{standalone: true}" [errors]="validationErrors" [fields]="['UnitRate']" inputType="number" [prefix]="{ content: rowData?.currencySymbol }"
                                     [label]="'tp-rate' | translate: '{ defaultText : \'Rate\'}'" [(ngModel)]="rowData.rate" (input)="isDirectionQuoteJobs() ? onChangeRate(rowData, $event) : onChangeClientJobRate(rowData, $event)">
                        </tp-text-box>
                    </div>
                    <span class="font-weight-bold mr-0 mat-form-field">
                        {{'tp-total' | translate : '{defaultText:\'Total\'}'}} <br>
                        {{rowData?.currencySymbol}} {{rowData.totalAmount | formatNumber | async}}
                    </span>
                    <tp-currency-notification-icon class="ml-2 mr-0 line-height-1" [jobPriceData]="rowData?.priceData"> </tp-currency-notification-icon>
                    <tp-button buttonStyle="icon" iconClass="ion-ios-add-circle" class="mat-form-field add-button-color"
                               (buttonClicked)="addToServicePrice(rowData)" [label]="'+'" [disabled]="
                                    (
                                    !isPositiveRate(rowData.rate) ||
                                    this.isClientPrice(rowData?.priceData?.direction) ||
                                    !rowData?.priceData?.isCurrencyMatchingWithClientCurrency
                                    )"
                               [matTooltip]="('tp-add-this-rate-to-the-selected-client-price-list' | translate : {defaultText: 'Add this rate to the selected client price list.'}) + ((!rowData?.priceData?.isCurrencyMatchingWithClientCurrency && !this.isClientPrice(rowData?.priceData?.direction)) ? ('tp-note:-unmatched-with-client-currency' | translate : {defaultText: 'Note: Unmatched with client currency.'}) : '')">
                    </tp-button>
                </div>
            </ng-container>

            <ng-container *ngSwitchCase="'vat'">
                <div style="width: 5rem">
                    <tp-text-box [ngModelOptions]="{standalone: true}" [errors]="validationErrors"
                                 [fields]="['UnitRate']" inputType="number"
                                 [label]="'tp-vat-(%)' | translate: '{ defaultText : \'VAT (%)\'}'"
                                 [(ngModel)]="rowData.vat" (input)="calculateTotal(rowData)">
                    </tp-text-box>
                </div>
            </ng-container>

            <ng-container *ngSwitchCase="'discount'">
                <div style="width: 5rem">
                    <tp-text-box [ngModelOptions]="{standalone: true}" [errors]="validationErrors" [fields]="['UnitRate']" inputType="number"
                                 [label]="'tp-dis/sur-(%)' | translate: '{ defaultText : \'Dis/Sur (%)\'}'" [(ngModel)]="rowData.discountOrSurcharge" (input)="onChangeDiscountOrSurcharge(rowData)">
                    </tp-text-box>
                </div>
            </ng-container>

            <ng-container *ngSwitchDefault>---</ng-container>
        </ng-container>
    </ng-template>
</tp-grid>