export type CompanyEventType = {
    text: string;
    numValue: number;
    stringValue: string;
}

export const HolidayCompanyEventText = 'Holiday';

export const CompanyEventTypes: Record<string, CompanyEventType> = {
    '1': {
        text: HolidayCompanyEventText,
        numValue: 1,
        stringValue: '1',
    },
};

export enum RecurringFrequencies {
    Daily = 'DAILY',
    Weekly = 'WEEKLY',
    Monthly = 'MONTHLY',
    Quarterly = 'QUARTERLY',
    Biannual = 'BIANNUAL',
    Annually = 'ANNUALLY'
}

export interface CompanyEventModel {
    id?: string;
    eventName: string;
    eventType: number;
    
    startDate: Date;
    endDate: Date;
    
    reminderInMinutesBeforeDate: number;
    
    isRecurring: boolean;
    recurringFrequency: RecurringFrequencies;
    recurringUntil: Date;
}