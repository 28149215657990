import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TpAutocompleteDropdownModule } from 'app/components/tp-autocomplete-dropdown/tp-autocomplete-dropdown.module';
import { TpDropdownModule } from 'app/components/tp-dropdown/tp-dropdown.module';
import { ClientContactSelectorComponent } from 'app/main/sharedComponents/client-contact-selector/client-contact-selector.component';


@NgModule({
    declarations: [ClientContactSelectorComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TranslateModule,
        TpDropdownModule,
    ],
    exports: [
        ClientContactSelectorComponent,
    ],
})
export class ClientContactSelectorModule {
}