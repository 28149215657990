import { NgModule } from '@angular/core'
import { MatFormFieldModule } from '@angular/material/form-field'
import { TranslateModule } from '@ngx-translate/core'
import { TpRichTextBoxComponent } from 'app/components/tp-rich-textbox/tp-rich-textbox.component'
import { TpCommonModule } from 'app/tp-common.module'
import { QuillModule } from 'ngx-quill'

@NgModule({
    declarations: [TpRichTextBoxComponent],
    imports: [TpCommonModule, QuillModule, MatFormFieldModule, TranslateModule],
    exports: [TpRichTextBoxComponent],
})
export class TpRichTextBoxModule {}