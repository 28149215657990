import { Pipe, PipeTransform } from '@angular/core';
import { ResourceService } from 'app/main/pm/services/resources/resource.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
    name: 'resourceName',
})
export class ResourceNamePipe implements PipeTransform {
    constructor(private resourceService: ResourceService) {
    }
    
    transform(resourceId: number): Observable<string> {
        return this.resourceService.getRefResource().pipe(
            map((resources) => {
                const resource = resources?.find((f) => f.value == resourceId);
                return resource?.text || '---';
            }),
        );
    }
}