import { NgModule } from '@angular/core'
import { MatIcon, MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common'
import { FlexModule } from '@angular/flex-layout'
import { TranslateModule } from '@ngx-translate/core'
import { FormatNumberPipeModule } from 'app/pipes/format-number-pipe/format-number-pipe.module'
import { FormatNumberWithCurrencyPipeModule } from 'app/pipes/format-number-with-currency-pipe/format-number-with-currency-pipe.module'
import { TpTimeframeValueChangeBoxComponent } from './tp-timeframe-value-change-box.component'
import {TpUnderlineDropdownModule} from "../tp-underline-dropdown/tp-underline-dropdown.module";
import {FormsModule} from "@angular/forms";
import {MatTooltipModule} from "@angular/material/tooltip";

@NgModule({
    declarations: [TpTimeframeValueChangeBoxComponent],
    imports: [
        CommonModule,
        TranslateModule,
        FormatNumberPipeModule,
        FormatNumberWithCurrencyPipeModule,
        FlexModule,
        MatIconModule,
        TpUnderlineDropdownModule,
        FormsModule,
        MatTooltipModule,
    ],
    exports: [TpTimeframeValueChangeBoxComponent],
})
export class TpTimeframeValueChangeBoxModule {}