import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CatCountAnalyseComponent } from './cat-count-analyse.component';
import {TpDropdownModule} from "../../../../components/tp-dropdown/tp-dropdown.module";
import {TranslateModule} from "@ngx-translate/core";
import { CatCountAnalyseModalComponent } from './cat-count-analyse-modal/cat-count-analyse-modal.component';
import {TpModalModule} from "../../../../components/tp-modal/tp-modal.module";
import {TpButtonModule} from "../../../../components/tp-button/tp-button.module";
import {DragAndDropFileModule} from "../../../../components/drag-and-drop-file/drag-and-drop-file.module";
import {FileListModule} from "../../../sharedComponents/file-list/file-list.module";

@NgModule({
    declarations: [
        CatCountAnalyseComponent,
        CatCountAnalyseModalComponent
    ],
    exports: [
        CatCountAnalyseModalComponent
    ],
    imports: [
        CommonModule,
        TpDropdownModule,
        TranslateModule,
        TpModalModule,
        TpButtonModule,
        DragAndDropFileModule,
        FileListModule
    ]
})
export class CatCountAnalyseModule { }