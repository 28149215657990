import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { TranslateModule } from '@ngx-translate/core'
import { TpDatePickerComponent } from 'app/components/tp-date-picker/tp-date-picker.component'
import {DatePickerModule, DateRangePickerModule, DateTimePickerModule} from "@syncfusion/ej2-angular-calendars";

@NgModule({
    declarations: [TpDatePickerComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        TranslateModule,
        FormsModule,
        DatePickerModule,
        DateTimePickerModule,
        DateRangePickerModule,
    ],
    exports: [TpDatePickerComponent],
})
export class TpDatePickerModule {}