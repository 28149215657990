export class Constants {
    static readonly REFDATA_STORAGE_KEY = 'refData';
    static readonly CURRENCYSYMBOL_STORAGE_KEY = 'refCurrency';
    static readonly LANGUAGES_SUPPORTED_STORAGE_KEY = 'refLanguagesSupported';
    static readonly CURRENCY_SYMBOLS_STORAGE_KEY = 'refCurrencySymbols';
    static readonly SERVICES_STORAGE_KEY = 'refServices';
    static readonly UNITS_STORAGE_KEY = 'refUnits';
    static readonly CAT_TOOLS = 'refCatTools';
    static readonly CATEGORIES_STORAGE_KEY = 'refCategories';
    static readonly COUNTRIES_STORAGE_KEY = 'refCountries';
    static readonly SERVICELANGUAGES_STORAGE_KEY = 'refServiceLanguages';
    static readonly SALES_PERSONS_KEY = 'refSalesPersons';
    static readonly PROJECT_MANAGERS_KEY = 'refProjectManager';
    static readonly PROJECT_STATUS_STORAGE_KEY = 'refProjectStatuses';
    static readonly QUOTE_STATUS_STORAGE_KEY = 'refQuoteStatuses';
    static readonly RESOURCE_STORAGE_KEY = 'refResources';
    static readonly ROLE_STORAGE_KEY = 'refRole';
    static readonly CURRENT_USER_STORAGE_KEY = 'currentUser';
    static readonly CURRENT_USER_TEAM_MEMBERSHIP_STORAGE_KEY = 'currentUserTeamMembership';
    
    static readonly COMPANY_SETTING_STORAGE_KEY = 'refCompanySetting';
    static readonly CLIENT_STORAGE_KEY = 'refClients';
    static readonly LEADS_STORAGE_KEY = 'refLeads';
    
    static readonly USER_STORAGE_KEY = 'refUsers';

    static readonly GLOBAL_HOLIDAYS_STORAGE_KEY = 'refGlobalHolidays';

    static readonly EMAIL_LISTS_STORAGE_KEY = 'refEmailLists';
}