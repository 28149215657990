import { Injectable } from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as ClientContactActions from "../../actions/client-actions/client-contact.actions";
import {catchError, concatMap, filter, map, mergeMap, share, switchMap, withLatestFrom} from "rxjs/operators";
import {of} from "rxjs";
import {NotificationService} from "../../../../../services/notification.service";
import {ClientService} from "../../../services/clients/client.service";
import {clientContactsEntity, clientsEntity} from "../../reducers";
import {Store} from "@ngrx/store";
import {State} from "../../../../../store";

@Injectable()
export class ClientContactEffects {
    addClientContact$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ClientContactActions.addClientContact),
            concatMap((action) =>
                this.clientService.addNewClientPOC(action).pipe(
                    map(response => {
                        this.notificationService.showSuccess('Saved', 'Client Contact Saved Successfully');
                        return clientContactsEntity.actions.addOne({entity: response.response});
                    }),
                    catchError(error => {
                        return of(clientContactsEntity.actions.updateAdditional({updates:{error}}));
                    })
                )
            ),
            share()
        );
    });
    getClientContact$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ClientContactActions.getClientContact),
            mergeMap((action) => of(action).pipe(withLatestFrom(this.store.select(clientContactsEntity.selectors.selectById(action.id))))),
            filter(([action, client]) => !(client)),
            switchMap(([action, clientContact]) =>
                this.clientService.getClientContactById(action.id).pipe(
                    map(response => {
                        return clientContactsEntity.actions.upsertOne({entity: response.response});
                    }),
                    catchError(error => {
                        return of(clientContactsEntity.actions.updateAdditional({updates:{error}}));
                    })
                )
            ),
            share()
        );
    });
    updateClientContact$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ClientContactActions.updateClientContact),
            concatMap((action) =>
                this.clientService.updateClientPOC(action).pipe(
                    map(response => {
                        this.notificationService.showSuccess('Saved', 'Client Contact Saved Successfully');
                        return clientContactsEntity.actions.updateOne({update:{id: response.response.id, changes: response.response}});
                    }),
                    catchError(error => {
                        return of(clientContactsEntity.actions.updateAdditional({updates:{error}}));
                    })
                )
            ),
            share()
        );
    });
    deleteClientContact$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ClientContactActions.deleteClientContact),
            concatMap((action) =>
                this.clientService.deleteClientPOCById(action.ids).pipe(
                    map(data => {
                        this.notificationService.showSuccess('Deleted', 'Client Contact Deleted Successfully');
                        return clientContactsEntity.actions.removeMany({ids: action.ids});
                    }),
                    catchError(error => {
                        return of(clientContactsEntity.actions.updateAdditional({updates:{error}}));
                    })
                )
            ),
            share()
        );
    });

    constructor(
        private actions$: Actions,
        private notificationService: NotificationService,
        private clientService: ClientService,
        private store: Store<State>
    ) {}

}