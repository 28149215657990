import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { interval, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ReadThemeService {
    
    private navbarBg = 'navbar-theme';
    private sidenavBg = 'sidenav-theme';
    private footerBg = 'footer-theme';
    
    private clearRefreshNavSideAndFooter$ = new Subject();
    
    constructor(private titleService: Title) {
    }
    
    setupPeriodicRefresh(): void {
        this.clearRefreshNavSideAndFooter$.next();
        interval(1000).pipe(takeUntil(this.clearRefreshNavSideAndFooter$)).subscribe(() => {
            this.refreshNavbarSidenavAndFooterBg();
        });
    }
    
    teardown(): void {
        this.clearRefreshNavSideAndFooter$.next();
        this.clearRefreshNavSideAndFooter$.complete();
    }
    
    
    // Set page title
    set pageTitle(value: string) {
        this.titleService.setTitle(`${value}`);
    }
    
    // Check for RTL layout
    get isRTL() {
        return document.documentElement.getAttribute('dir') === 'rtl' ||
            document.body.getAttribute('dir') === 'rtl';
    }
    
    // Check if IE10
    get isIE10() {
        return typeof document['documentMode'] === 'number' && document['documentMode'] === 10;
    }
    
    // Layout navbar color
    get layoutNavbarBg() {
        return this.navbarBg || 'navbar-theme';
    }
    
    // Layout sidenav color
    get layoutSidenavBg() {
        return this.sidenavBg || 'sidenav-theme';
    }
    
    // Layout footer color
    get layoutFooterBg() {
        return this.footerBg || 'footer-theme';
    }
    
    refreshNavbarSidenavAndFooterBg() {
        this.navbarBg = localStorage.getItem('themeSettingsNavbarBg');
        this.sidenavBg = localStorage.getItem('themeSettingsSidenavBg');
        this.footerBg = localStorage.getItem('themeSettingsFooterBg');
    }
    
    // Animate scrollTop
    scrollTop(to: number, duration: number, element = document.scrollingElement || document.documentElement) {
        if (element.scrollTop === to) {
            return;
        }
        const start = element.scrollTop;
        const change = to - start;
        const startDate = new Date().getDate();
        
        // t = current time; b = start value; c = change in value; d = duration
        const easeInOutQuad = (t: number, b: number, c: number, d: number) => {
            t /= d / 2;
            if (t < 1) {
                return c / 2 * t * t + b;
            }
            t--;
            return -c / 2 * (t * (t - 2) - 1) + b;
        };
        
        const animateScroll = () => {
            const currentDate = new Date().getDate();
            const currentTime = currentDate - startDate;
            element.scrollTop = easeInOutQuad(currentTime, start, change, duration);
            if (currentTime < duration) {
                requestAnimationFrame(animateScroll);
            } else {
                element.scrollTop = to;
            }
        };
        
        animateScroll();
    }
}