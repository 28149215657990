import { Injectable } from '@angular/core';
import {CalculateDiscountExtraChargeValues, CalculateTotalOptions, RateTypes} from "../../models";
import {CalculateTotalValues, ResponseCalculateTotals} from "../../models";
@Injectable({
  providedIn: 'root'
})
export class CalculateTotalsService {
    constructor() { }
    /**
     * @function listTotals
     * @param values CalculateJobTotalValues
     * @param totalOptions CalculateTotalOptions
     * @description calculate an array of values and response totals
     * @return ResponseCalculateTotals
     * */
    listTotals(values: CalculateTotalValues[], totalOptions?: CalculateTotalOptions): ResponseCalculateTotals{
        let totals = new ResponseCalculateTotals();
        values.forEach(value => {
            const valueTotals = this.totals(value, totalOptions);
            totals.netAmount += valueTotals.netAmount;
            totals.totalAmount += valueTotals.totalAmount;
            totals.totalAfterDiscount += valueTotals.totalAfterDiscount;
            totals.surchargeAmount += valueTotals.surchargeAmount;
            totals.discountAmount += valueTotals.discountAmount;
            totals.extraChargeAmount += valueTotals.extraChargeAmount;
            totals.taxAmount += valueTotals.taxAmount;
        });
        return totals;
    }
    /**
     * @function totals
     * @param totalValues CalculateJobTotalValues
     * @param totalOptions CalculateTotalOptions
     * @description calculate totals
     * @return ResponseCalculateTotals
     * */
    totals(totalValues: CalculateTotalValues, totalOptions?: CalculateTotalOptions): ResponseCalculateTotals{
        const values = this.convertValuesToNumber(totalValues);
        const options = this.overwriteOptions(totalOptions);
        let totals = new ResponseCalculateTotals();
        let runningAmount: number = values?.amount || 0;
        if (values?.discount && values?.discount > 0) {
            totals.discountAmount = options?.isDiscountPercentage ? this.percentAmount(runningAmount, values.discount) : values.discount;
            runningAmount = options?.isDiscountPercentage ? this.subtractionPercentAmount(runningAmount, values.discount) : this.subtractionAmount(runningAmount, values.discount);
        }
        if (values?.extraCharge && values?.extraCharge > 0) {
            totals.extraChargeAmount = options?.isExtraChargePercentage ? this.percentAmount(runningAmount, values.extraCharge) : values.extraCharge;
            runningAmount = options?.isExtraChargePercentage ? this.additionPercentAmount(runningAmount, values.extraCharge) : this.additionAmount(runningAmount, values.extraCharge);
        }
        if (values?.vat && !Number.isNaN(values?.vat) && values?.vat > 0) {
            totals.taxAmount = options?.isVatPercentage ? this.percentAmount(runningAmount, values.vat) : values.vat;
            runningAmount = options?.isVatPercentage ? this.additionPercentAmount(runningAmount, values.vat) : this.additionAmount(runningAmount, values.vat);
        }
        if(values.amount) {
            totals.netAmount = values.amount;
            totals.totalAmount = (totals.netAmount - totals.discountAmount + totals.surchargeAmount + totals.extraChargeAmount + totals.taxAmount);
            totals.grossAmount = totals.totalAmount;
            if (totals.discountAmount > 0) {
                totals.totalAfterDiscount = (totals.totalAmount - totals.discountAmount);
            }
            if(values?.credit && values?.credit > 0){
                totals.creditAmount = values.credit;
                totals.totalAmount = this.subtractionAmount(totals.totalAmount, totals.creditAmount);
            }
        }
        return totals;
    }
    /**
     * @function totalAmount
     * @param totalValues CalculateJobTotalValues
     * @param totalOptions CalculateTotalOptions
     * @description calculate job total amount
     * @return number
     * */
    totalAmount(totalValues: CalculateTotalValues, totalOptions?: CalculateTotalOptions): number{
        const values = this.convertValuesToNumber(totalValues);
        const options = this.overwriteOptions(totalOptions);
        let totalAmount: number = 0;
        let netAmount: number = 0;
        if (values?.rate) {
            netAmount = this.netAmount(values);
            totalAmount = netAmount;
            if (values?.discount && !Number.isNaN(values?.discount)) {
                totalAmount = options?.isDiscountPercentage ? this.subtractionPercentAmount(netAmount, values.discount) : this.subtractionAmount(netAmount, values.discount);
            }
            if (values?.extraCharge && !Number.isNaN(values?.extraCharge)) {
                totalAmount = options?.isExtraChargePercentage ? this.additionPercentAmount(totalAmount, values.extraCharge) : this.additionAmount(totalAmount, values.extraCharge);
            }
            if (values?.vat && !Number.isNaN(values?.vat)) {
                totalAmount = options?.isVatPercentage ? this.additionPercentAmount(totalAmount, values.vat) : this.additionAmount(totalAmount, values.vat);
            }
        }
        return totalAmount;
    }
    /**
     * @function netAmount
     * @param totalValues CalculateJobTotalValues
     * @description calculate job net amount
     * @return number
     * */
    netAmount(totalValues: CalculateTotalValues): number{
        const values = this.convertValuesToNumber(totalValues);
        let netAmount: number = 0;
        if (values?.rate) {
            if (values?.rateTypeId == RateTypes.Free) return 0.00;  //Free
            if (values?.rateTypeId == RateTypes.Fixed) { //Fixed Amount
                netAmount = values?.rate;
            }
            if (values?.rateTypeId == RateTypes.Unit || values.rateTypeId == RateTypes.Rush) {  //Rate per Unit or Rush
                if (values?.volume && !Number.isNaN(values?.volume)) {
                    netAmount = values.rate * values.volume;
                }
            }
        }
        return netAmount;
    }
    /**
     * @function discountOrExtraChargeValues
     * @param discountOrSurcharge number
     * @description return discount and extra charge, negative is discount positive is extra charge
     * @return CalculateDiscountExtraChargeValues
     * */
    public discountOrExtraChargeValues(discountOrSurcharge: number): CalculateDiscountExtraChargeValues {
        const discount = (discountOrSurcharge < 0) ? -discountOrSurcharge : null;
        const extraCharge = (discountOrSurcharge > 0) ? discountOrSurcharge : null;
        return {discount, extraCharge};
    }
    /**
     * @function convertValuesToNumber
     * @param values CalculateTotalValues
     * @description convert value to number
     * @return CalculateTotalValues
     * */
    private convertValuesToNumber(values: CalculateTotalValues): CalculateTotalValues{
        if(values?.amount) values.amount = Number(values.amount);
        if(values?.rate) values.rate = Number(values.rate);
        if(values?.volume) values.volume = Number(values.volume);
        if(values?.discount) values.discount = Number(values.discount);
        if(values?.extraCharge) values.extraCharge = Number(values.extraCharge);
        if(values?.vat) values.vat = Number(values.vat);
        return values;
    }
    /**
     * @function overwriteOptions
     * @param options CalculateTotalOptions
     * @description overwrite default options
     * @return CalculateTotalValues
     * */
    private overwriteOptions(options: CalculateTotalOptions): CalculateTotalOptions{
        const defaultOptions = new CalculateTotalOptions();
        return <CalculateTotalOptions>{...defaultOptions, ...options};
    }
    /**
     * @function additionOrSubtraction
     * @param netAmount number
     * @param discountOrSurcharge number
     * @description calculate discount or surcharge
     * @return number
     * */
    public additionOrSubtraction(netAmount: number, discountOrSurcharge: number): number {
        if(this.isDiscount(discountOrSurcharge)) return this.subtractionPercentAmount(netAmount, (-1) * Number(discountOrSurcharge));  //discount
        else return this.additionPercentAmount(netAmount, discountOrSurcharge); //surcharge
    }
    /**
     * @function additionPercentAmount
     * @param runningAmount number
     * @param percentAmount number
     * @description calculate addition percent amount
     * @return number
     * */
    public additionPercentAmount(runningAmount: number, percentAmount: number): number{
        runningAmount += this.percentAmount(runningAmount, percentAmount);
        return runningAmount;
    }
    /**
     * @function subtractionPercentAmount
     * @param runningAmount number
     * @param percentAmount number
     * @description calculate subtraction percent amount
     * @return number
     * */
    public subtractionPercentAmount(runningAmount: number, percentAmount: number): number{
        runningAmount -= this.percentAmount(runningAmount, percentAmount);
        return runningAmount;
    }
    /**
     * @function additionAmount
     * @param runningAmount number
     * @param amount number
     * @description calculate addition amount
     * @return number
     * */
    public additionAmount(runningAmount: number, amount: number): number{
        runningAmount += amount;
        return runningAmount;
    }
    /**
     * @function subtractionAmount
     * @param runningAmount number
     * @param amount number
     * @description calculate subtraction amount
     * @return number
     * */
    public subtractionAmount(runningAmount: number, amount: number): number{
        runningAmount -= amount;
        return runningAmount;
    }
    /**
     * @function percentAmount
     * @param amount number
     * @param percentAmount number
     * @description calculate percent amount
     * @return number
     * */
    public percentAmount(amount: number, percentAmount: number): number{
        return amount * percentAmount / 100;
    }
    /**
     * @function isDiscount
     * @param discountOrSurcharge number
     * @description check if is negative as a discount or positive as a surcharge
     * @return boolean
     * */
    public isDiscount(discountOrSurcharge: number): boolean{
        return (Number(discountOrSurcharge) < 0);
    }

    /**
     * @function isDiscount
     * @param applyAmount number
     * @param availableAmount number
     * @param totalAmount number
     * @description check if is negative as a discount or positive as a surcharge
     * @return boolean
     * */
    public applyAmount(applyAmount: number, availableAmount: number, totalAmount: number): number{
       let appliedAmount: number = 0;
       if(availableAmount) {
           if (applyAmount <= availableAmount) { //less or equal apply amount than available amount
               if (applyAmount <= totalAmount) {
                   appliedAmount = applyAmount
               } else {
                   appliedAmount = totalAmount;
               }
           } else { // bigger applyAmount than available amount
               if (totalAmount >= availableAmount) {
                   appliedAmount = availableAmount
               } else {
                   appliedAmount = totalAmount;
               }
           }
       }
        return appliedAmount;
    }
}