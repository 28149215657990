import { DragDropModule } from '@angular/cdk/drag-drop'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { TpEditTableColumnsComponent } from 'app/components/tp-edit-table-columns/tp-edit-table-columns.component'

@NgModule({
    declarations: [TpEditTableColumnsComponent],
    imports: [CommonModule, DragDropModule, MatCheckboxModule],
    exports: [TpEditTableColumnsComponent],
})
export class TpEditTableColumnsModule {}