import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AppConfigService } from 'app/services/app.settings.service';
import { environment } from 'environments/environment';
import { Observable, of, Subject, throwError } from 'rxjs';
import { SearchCriteriaModel, SearchResult, TpValidationError } from 'app/models';
import { AccountCategorySearchCriteriaModel } from '../models/account-categories-search-criteria.model';
import { AccountCategoryModel } from '../models/account-categories.model';
import { DropDownOptionsModel } from 'app/models/general';
import { catchError, map, startWith, take } from 'rxjs/operators';
import { CORE_SESSION_STORAGE } from 'app/services/storage/tp-storage.service.ts';
import { StorageService } from 'ngx-webstorage-service';

@Injectable()
export class AccountCategoryService {
    
    private readonly _apiUri: string;
   
    private refreshAccountCategoriesObservers$ = new Subject();
    private refreshAccountCategoriessLock: boolean;
    private refAccountCateogryStorageKey : string = "refAccountsCategory";

    constructor(private http: HttpClient, configService: AppConfigService,
        @Inject(CORE_SESSION_STORAGE) private sessionStorage: StorageService) {
        if (configService) {
            this._apiUri = configService.getAPIUrl();
        }
    }
    
    searchAccountCategories(model: SearchCriteriaModel<AccountCategorySearchCriteriaModel>): Observable<SearchResult<AccountCategoryModel>> {
        return this.http.post<SearchResult<AccountCategoryModel>>(`${this._apiUri}${environment.accounting.accountsCategories.getAccountCategory}`, model);
    }

    saveAccountCategory(account: AccountCategoryModel): Observable<AccountCategoryModel> {
        if (account.accountingCategoryId) {
            return this.http.put<AccountCategoryModel>(`${this._apiUri}${environment.accounting.accountsCategories.updateAccountCategory.replace(environment.accounting.accountsCategories.idPlaceholder, account.accountingCategoryId)}`, account);
        } else {
            return this.http.post<AccountCategoryModel>(`${this._apiUri}${environment.accounting.accountsCategories.createAccountCategory}`, account);
        }
    }

    deleteAccountCategory(accountCategoryId: string): Observable<AccountCategoryModel> {
        return this.http.delete<null>(`${this._apiUri}${environment.accounting.accountsCategories.deleteAccountCategory}?ids=${accountCategoryId}`);
    }

    getRefAccountCategories(): Observable<DropDownOptionsModel[]> {
        return this.refreshAccountCategoriesObservers$.pipe(startWith(undefined))
            .pipe(map(() => {
                const persistedData: string = this.sessionStorage.get(this.refAccountCateogryStorageKey) as string;
                if (persistedData) {
                    return JSON.parse(persistedData) as DropDownOptionsModel[];
                } else {
                    this.refreshAccountCategories().pipe(take(1)).subscribe();
                    const persistedDataNew: string = this.sessionStorage.get(this.refAccountCateogryStorageKey) as string;
                    if (persistedDataNew) {
                        return JSON.parse(persistedDataNew) as DropDownOptionsModel[];
                    }
                }
            }));
    }

    refreshAccountCategories(): Observable<void> {
        if (this.refreshAccountCategoriessLock) {
            return of();
        } else {
            this.refreshAccountCategoriessLock = true;
            return this.searchAccountCategories({
                requiresCounts: true,
                showAll: true
            })
            .pipe(map((result) => {
                this.refreshAccountCategoriessLock = false;
                var dropDownResult = result.result?.map(
                    (m) =>
                        <DropDownOptionsModel>{
                            text: m.categoryName,
                            value: m.accountingCategoryId,
                        },
                )

                this.sessionStorage.set(this.refAccountCateogryStorageKey, JSON.stringify(dropDownResult));
                this.refreshAccountCategoriesObservers$.next();
                return;
            }),
            catchError(err => {
                this.refreshAccountCategoriessLock = false;
                return throwError(err);
            }));
            
        }
    }
}