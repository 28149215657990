import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {SendEmailModel} from 'app/models';
import { AppConfigService } from 'app/services/app.settings.service';
import { StorageService } from 'ngx-webstorage-service';
import { Observable, Subject } from 'rxjs';
import { CORE_SESSION_STORAGE } from '../storage/tp-storage.service.ts';
import {ResponseClientInvoiceGridData} from "../../main/pm/client-invoices/models/client-invoice.model";

@Injectable()
/**
 * Service class.
 */
export class EmailManagerService {
    /**
     * Path uri.
     * @type {string}
     * @private
     */
    private _uri = '';
    private subject = new Subject<any>();

    /**
     * Url to endpoint api.
     * @type {string}
     */
    private endpoint = '/Email';
    /**
     * Endpoint request headers.
     * @type {HttpHeaders}
     */
    private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    constructor(
        private http: HttpClient,
        private configService: AppConfigService,
        @Inject(CORE_SESSION_STORAGE)
        private sessionStorage: StorageService<string>,
    ) {
        this.setConfig();
    }

    setConfig(): void {
        if (this.configService) {
            this._uri = this.configService.getAPIUrl();
        }
    }

    sendEmail(model: SendEmailModel): Observable<SendEmailModel> {
        return this.http.post<SendEmailModel>(`${this._uri}${this.endpoint}/sendMail`, model);
    }

    /***
     * @description sends an email under specific email template
     */
     sendEmailWithTemplate(model: SendEmailModel,emailTemplateName:string): Observable<SendEmailModel> {
        return this.http.post<SendEmailModel>(`${this._uri}${this.endpoint}/sendMail/${emailTemplateName}`, model);
    }

    getChaseClientInvoiceEmail(invoice: ResponseClientInvoiceGridData) {
        if (!invoice) {
            throw new Error('invoiceId falsy in EmailManagerService.getChaseClientInvoiceEmail');
        }
        return this.http.post<SendEmailModel>(`${this._uri}/clientInvoice/chase`, invoice);
    }
}