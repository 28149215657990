import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TpAutocompleteDropdownModule } from 'app/components/tp-autocomplete-dropdown/tp-autocomplete-dropdown.module';
import { ClientSearchComponent } from 'app/main/sharedComponents/client-search/client-search.component';


@NgModule({
    declarations: [ClientSearchComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TpAutocompleteDropdownModule,
        TranslateModule,
    ],
    exports: [
        ClientSearchComponent,
    ],
})
export class ClientSearchModule {
}