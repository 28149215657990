import { Pipe, PipeTransform } from '@angular/core';
import { GlobalHolidayModel } from 'app/models/general';
import { GlobalHolidaysService } from 'app/services/general/global-holidays.service';
import { differenceInBusinessDays, isWeekend, isWithinInterval } from 'date-fns';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
    name: 'daysBetweenDatesExcludingHolidays',
})
export class DaysBetweenDatesExcludingGlobalHolidaysPipe implements PipeTransform {
    
    constructor(private readonly globalHolidaysService: GlobalHolidaysService) {
    }
    
    transform(startDate: number | Date, endDate: number | Date, lastDateHalf: boolean | null, firstDateHalf:boolean | null): Observable<number> {
        if (startDate && endDate) {
            return this.globalHolidaysService.getGlobalHolidays().pipe(
                map(globalHolidays => {
                    // diff in days excluding weekends, including both first and last days
                    const startDateAsDate = new Date(startDate);
                    const endDateAsDate = new Date(endDate);
                    
                    let daysBetween: number = differenceInBusinessDays(endDateAsDate, startDateAsDate);
                    if (!isWeekend(endDateAsDate)) {
                        // include the end date if it's not a weekend day
                        daysBetween += 1;
                    }
                    
                    const startEndInterval: Interval = {
                        start: startDateAsDate,
                        end: endDateAsDate,
                    };
                    
                    globalHolidays?.forEach((globalHoliday: GlobalHolidayModel) => {
                        const globalHolidayDate = new Date(globalHoliday.startDate);
                        
                        // TODO update calcualtion to consider that the global holiday can have an end date - meaning that it's longer than one day
                        
                        // weekends already excluded
                        if (!isWeekend(globalHolidayDate) && isWithinInterval(globalHolidayDate, startEndInterval)) {
                            daysBetween -= 1;
                        }
                    });

                    if(lastDateHalf==true && lastDateHalf){
                        daysBetween=daysBetween-0.5;
                    }

                    if(firstDateHalf==true && firstDateHalf){
                        daysBetween=daysBetween-0.5;
                    }
                    
                    return daysBetween;
                }));
        } else {
            return of(0);
        }
    }
}