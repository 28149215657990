import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { TranslateModule } from '@ngx-translate/core'
import { TpDropdownModule } from 'app/components/tp-dropdown/tp-dropdown.module'
import { TpMultiselectDropdownModule } from 'app/components/tp-multiselect-dropdown/tp-multiselect-dropdown.module'
import { TpSalespersonsDropDownComponent } from 'app/components/tp-salesperson-dropdown/tp-salespersons-dropdown.component'

@NgModule({
    declarations: [TpSalespersonsDropDownComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TpDropdownModule,
        TranslateModule,
        TpMultiselectDropdownModule,
    ],
    exports: [TpSalespersonsDropDownComponent],
})
export class TpSalespersonsDropdownModule {}