export enum UserRoleTypes{
    administrator = 1,
    projectManager = 2,
    client = 5,
    freelanceResource = 4,
    siteAdministrator = 5,
    clientContact = 6,
    manager = 7,
    projectAssistant = 20,
    businessDevelopment = 9,
    contractor = 10,
    opsManager = 8,
    accountant = 3,
    resourceManager = 6,
    inHouseResource = 14

}