import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkflowDropdownComponent } from './workflow-dropdown.component';
import {TpDropdownModule} from "../tp-dropdown/tp-dropdown.module";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
    declarations: [
        WorkflowDropdownComponent
    ],
    exports: [
        WorkflowDropdownComponent
    ],
    imports: [
        CommonModule,
        TpDropdownModule,
        TranslateModule
    ]
})
export class WorkflowDropdownModule { }
