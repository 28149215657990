import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexModule } from '@angular/flex-layout'
import { FormsModule } from '@angular/forms'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatMenuModule } from '@angular/material/menu'
import { TranslateModule } from '@ngx-translate/core'
import { TpButtonModule } from 'app/components/tp-button/tp-button.module'
import { TpDynamicColumnsComponent } from 'app/components/tp-dynamic-columns/tp-dynamic-columns.component'
import {MatRippleModule} from "@angular/material/core";
import {MatIconModule} from "@angular/material/icon";

@NgModule({
    declarations: [TpDynamicColumnsComponent],
    imports: [
        CommonModule,
        MatCheckboxModule,
        TranslateModule,
        FormsModule,
        TpButtonModule,
        MatMenuModule,
        FlexModule,
        MatRippleModule,
        MatIconModule,
    ],
    exports: [TpDynamicColumnsComponent],
})
export class TpDynamicColumnsModule {}