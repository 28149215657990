import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import {
    MissingTranslationHandler,
    MissingTranslationHandlerParams,
    TranslateDefaultParser,
    TranslateLoader,
} from '@ngx-translate/core'
import {map} from "rxjs/operators";

export class MyTranslateLoader implements TranslateLoader {
    constructor(
        private http: HttpClient,
        private prefix: string,
        private suffix: string
    ) {}
    getTranslation(lang: string): Observable<any> {
        return this.http.get(`${this.prefix}${lang}${this.suffix}`).pipe(
            map(res => {
                if((lang.toLowerCase() === 'en')) return res;
                else return this.process(res);
            })
        );
    }
    private process(langJson: object): object {
        const responseLangJson: object = {};
        for (const key in langJson) {
            if (langJson.hasOwnProperty(key)) {
                if (typeof langJson[key] === 'object') {
                    responseLangJson[key] = this.process(langJson[key]); //recursive call
                } else if ((typeof langJson[key] === 'string')) {
                    if(langJson[key].trim() === ''){} // do not copy empty strings
                    else responseLangJson[key] = langJson[key];
                } else responseLangJson[key] = langJson[key];
            }
        }
        return responseLangJson;
    }
}

export class MyMissingTranslationHandler implements MissingTranslationHandler {
    constructor() {}

    handle(params: MissingTranslationHandlerParams) {
        let defaultText = ''

        if (params.interpolateParams != null) {
            defaultText = (<any>params.interpolateParams).defaultText || ''
        }

        return params.translateService.parser.interpolate(
            defaultText || params.key,
            params.interpolateParams
        )
    }
}

@Injectable()
export class CustomTranslateParser extends TranslateDefaultParser {
    /**
     * Regex to replace params
     * This is a { key }" ==> "This is a value", with params = { key: "value" }
     */
    public templateMatcher = /{{?\s?([^{}\s]*)\s?}?}/g
}