import { NgModule } from '@angular/core';
import { FormatNumberWithCurrencyPipeModule } from 'app/pipes/format-number-with-currency-pipe/format-number-with-currency-pipe.module';
import { ServiceNamePipe } from 'app/pipes/service-name-pipe/service-name.pipe';
import { ServicesNamesPipe } from 'app/pipes/service-name-pipe/services-names.pipe';
import { UnitNamePipeModule } from '../unit-name-pipe/unit-name-pipe.module';
import { ServicesNamesUsingDescriptorsPipePipe } from './services-names-using-descriptors.pipe';


@NgModule({
    declarations: [ServiceNamePipe, ServicesNamesPipe, ServicesNamesUsingDescriptorsPipePipe],
    imports: [
        FormatNumberWithCurrencyPipeModule,
        UnitNamePipeModule,
    ],
    providers: [ServiceNamePipe, ServicesNamesPipe, ServicesNamesUsingDescriptorsPipePipe],
    exports: [ServiceNamePipe, ServicesNamesPipe, ServicesNamesUsingDescriptorsPipePipe],
})
export class ServiceNamePipeModule {
}