import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { TpProjectStatusButtonComponent } from './tp-project-status-button.component';
import {MatButtonModule} from "@angular/material/button";
import {MatRippleModule} from "@angular/material/core";

@NgModule({
    declarations: [TpProjectStatusButtonComponent],
    imports: [
        CommonModule,
        TranslateModule,
        MatMenuModule,
        MatFormFieldModule,
        MatButtonModule,
        MatRippleModule,
    ],
    exports: [TpProjectStatusButtonComponent],
})
export class TpProjectStatusButtonModule {
}