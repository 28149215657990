import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TpDropdownModule } from 'app/components/tp-dropdown/tp-dropdown.module'
import { TpMultiselectDropdownModule } from 'app/components/tp-multiselect-dropdown/tp-multiselect-dropdown.module'
import { TpServiceLanguageDropdownComponent } from 'app/components/tp-service-language-dropdown/tp-service-language-dropdown.component'

@NgModule({
    declarations: [TpServiceLanguageDropdownComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TpDropdownModule,
        TpMultiselectDropdownModule,
        FormsModule,
        TranslateModule,
    ],
    exports: [TpServiceLanguageDropdownComponent],
})
export class TpServiceLanguageDropdownModule {}