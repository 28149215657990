<div class="cat-count-analyse-container px-2">
    <div class="row align-items-center">
        <div class="col-6">{{'tp-analysis-type' | translate: '{defaultText: \'Analysis Type\'}'}}</div>
        <div class="col-6">
            <tp-dropdown
                label="{{'tp-analysis-type' | translate: '{ defaultText : \'Analysis Type\'}'}}"
                formControlName="analysisType" [options]="catTools" (valueChanged)="getSelectedCatTool($event)">
            </tp-dropdown>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div>
                <tp-drag-and-drop-file [(files)]="files" [autoUpload]="autoUpload" [multiple]="false" (selectedFiles)="getSelectedFiles($event)"></tp-drag-and-drop-file>
            </div>
            <div class="py-2">
                <tp-file-list [(files)]="files"></tp-file-list>
            </div>
        </div>
    </div>
</div>