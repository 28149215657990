import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanguagesComponent } from './languages.component';
import {TpRadioButtonModule} from "../tp-radio-button/tp-radio-button.module";
import {FormsModule} from "@angular/forms";
import {TpDropdownModule} from "../tp-dropdown/tp-dropdown.module";
import {TranslateModule} from "@ngx-translate/core";
import {TpModalModule} from "../tp-modal/tp-modal.module";

@NgModule({
    declarations: [
        LanguagesComponent
    ],
    exports: [
        LanguagesComponent
    ],
    imports: [
        CommonModule,
        TpRadioButtonModule,
        FormsModule,
        TpDropdownModule,
        TranslateModule,
        TpModalModule,
    ]
})
export class LanguagesModule { }