import { Pipe, PipeTransform } from '@angular/core';
import { UserService } from 'app/services/general/user.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
    name: 'projectManagerName',
})
export class ProjectManagerNamePipe implements PipeTransform {
    constructor(private userService: UserService) {
    }
    
    transform(value: number): Observable<string> {
        return this.userService.getProjectManagers(1).pipe(
            map((pms) => {
                const pm = pms?.response.find((f) => f.id == value);
                return pm
                    ? `${pm.projectManagerName}`
                    : '---';
            }),
        );
    }
}