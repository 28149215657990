import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { MatRadioModule } from '@angular/material/radio'
import { SelectClientPaymentTermsComponent } from 'app/components/select-client-payment-terms/select-client-payment-terms.component'

@NgModule({
    declarations: [SelectClientPaymentTermsComponent],
    imports: [CommonModule, MatRadioModule, ReactiveFormsModule],
    exports: [SelectClientPaymentTermsComponent],
})
export class SelectClientPaymentTermsModule {}