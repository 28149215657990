import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TpCategoryDropdownModule } from 'app/components/tp-category-dropdown/tp-category-dropdown.module';
import { TpDropdownModule } from 'app/components/tp-dropdown/tp-dropdown.module';
import { LeadPriorityDropdownComponent } from 'app/main/pm/crm/shared/lead-priority-dropdown/lead-priority-dropdown.component';


@NgModule({
    declarations: [LeadPriorityDropdownComponent],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		TpDropdownModule,
		TranslateModule,
		TpCategoryDropdownModule,
		FormsModule,
	],
    exports: [
        LeadPriorityDropdownComponent,
    ],
})
export class LeadPriorityDropdownModule {
}