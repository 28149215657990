import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { TpButtonModule } from "app/components/tp-button/tp-button.module";
import { TpCheckboxModule } from "app/components/tp-checkbox/tp-checkbox.module";
import { TpDropdownModule } from "app/components/tp-dropdown/tp-dropdown.module";
import { TpGridModule } from "app/components/tp-grid/tp-grid.module";
import { TpRadioButtonModule } from "app/components/tp-radio-button/tp-radio-button.module";
import { TpRichTextBoxModule } from "app/components/tp-rich-textbox/tp-rich-text-box.module";
import { TpTextBoxModule } from "app/components/tp-text-box/tp-text-box.module";
import { PersonBankingDetailsComponent } from "./person-banking-details.component";
import {MatIconModule} from "@angular/material/icon";
import {MatTooltipModule} from "@angular/material/tooltip";
import {TpModalModule} from "../../../components/tp-modal/tp-modal.module";

@NgModule({
    declarations: [PersonBankingDetailsComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        TpDropdownModule,
        TpTextBoxModule,
        TranslateModule,
        TpRadioButtonModule,
        TpCheckboxModule,
        TpGridModule,
        TpRichTextBoxModule,
        TpButtonModule,
        MatIconModule,
        MatTooltipModule,
        TpModalModule
    ],
    exports: [
        PersonBankingDetailsComponent,
    ],
})
export class PersonBankingDetailsModule {
}