import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { TranslateModule } from '@ngx-translate/core'
import { TpDropdownModule } from 'app/components/tp-dropdown/tp-dropdown.module'
import { TpFontSelectionDropDownComponent } from 'app/components/tp-font-selection-dropdown/tp-font-selection-dropdown.component'

@NgModule({
    declarations: [TpFontSelectionDropDownComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TpDropdownModule,
        TranslateModule,
    ],
    exports: [TpFontSelectionDropDownComponent],
})
export class TpFontSelectionDropdownModule {}