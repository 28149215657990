/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import {BaseResultModel, SearchCriteriaModel, SearchResult} from 'app/models'
import { AppConfigService } from 'app/services/app.settings.service'
import { ApirequestService, HttpVerb } from 'app/services/general/api-service.service'
import { environment } from 'environments/environment'
import { Observable, Subject } from 'rxjs'
import { tap } from 'rxjs/operators'
import { RefDataService } from '../../../../services/general/refData.service'
import { ManageCategoryModel } from '../../manage-categories/models'
import {
    ResponseTagCategoryGridData
} from "../../manage-categories/models/response-models/response-tag-category-grid-data";

@Injectable({
    providedIn: 'root',
})
export class ManageCategoriesService {
    /**
     * Path uri.
     * @type {string}
     * @private
     */
    private _uri = ''
    private subject = new Subject<any>()

    /**
     * Url to endpoint api.
     * @type {string}
     */
    private endpoint = '/System/tagCategory'

    /**
     * Component constructor and DI injection point.
     * @param {HttpClient} http
     * @param {AppConfigService} config
     */
    private headers = new HttpHeaders({ 'Content-Type': 'application/json' })

    constructor(
        private http: HttpClient,
        configService: AppConfigService,
        private readonly refDataService: RefDataService,
        private api:ApirequestService
    ) {
        if (configService) {
            this._uri = configService.getAPIUrl()
        }
    }

    searchCategory(model: SearchCriteriaModel<any>): Observable<BaseResultModel<SearchResult<ResponseTagCategoryGridData>>> {
        return this.api.ApiRequest<BaseResultModel<SearchResult<ResponseTagCategoryGridData>>>(environment.apiUrl + this.endpoint + '/search', model, new HttpHeaders(), HttpVerb.post, 'json');
    }
    /**
     * @function getCategoriesGrid
     * @description get all categories grid
     * @return Observable<BaseResultModel<ManageCategoryModel>>
     * */
    getCategoriesGrid(): Observable<BaseResultModel<ResponseTagCategoryGridData[]>> {
        return this.api.ApiRequest<BaseResultModel<ResponseTagCategoryGridData[]>>(environment.apiUrl + this.endpoint + '/status/1/grid', '', new HttpHeaders(), HttpVerb.get, 'json');
    }

    getTagCategoryTypes(): Observable<SearchResult<ManageCategoryModel>> {
        return this.api.ApiRequest<any>(
            environment.apiUrl + this.endpoint + 'Type/1/dropdown',
            '',
            new HttpHeaders(),
            HttpVerb.get,
            'json'
        )
    }

    deleteCatById(id: string) {
        return this.api.ApiRequest<any>(
            environment.apiUrl + this.endpoint + '/' + id,
            '',
            new HttpHeaders(),
            HttpVerb.delete,
            'json'
        )
    }

    getCatById(id: string): Observable<ManageCategoryModel> {
        return this.api.ApiRequest<any>(
            environment.apiUrl + this.endpoint + '/' + id,
            '',
            new HttpHeaders(),
            HttpVerb.get,
            'json'
        )
    }

    updateCat(
        id: string,
        model: ManageCategoryModel
    ): Observable<BaseResultModel<ManageCategoryModel>> {
        if (id != null && id != '') {
            model.id = id;
            return this.api.ApiRequest<any>(
                environment.apiUrl + this.endpoint,
                model,
                new HttpHeaders(),
                HttpVerb.put,
                'json'
            )
        } else {
            return this.api.ApiRequest<any>(
                environment.apiUrl + this.endpoint,
                model,
                new HttpHeaders(),
                HttpVerb.post,
                'json'
            )
        }
    }
}