import {JobPriceDirection} from "../../../../main/pm/quotes/shared/quote-job/quote-job.component";
import { PostFile } from "../../system-models/post-file";
import { PostQuoteJobCatCount } from "./post-quote-job-cat-count";

export class PostQuoteJob
    {
        id?: number;
        description?: string;
        code?: string;
        insertDate?: Date;
        insertUserId?: number;
        assignmentDate?: Date;
        dueDate?: Date;
        quoteJobStatusId?: number;
        quoteJobSecondaryStatusId?: number;
        serviceId?: number;
        quoteId?: number;
        startDate?: Date | string;
        endDate?: Date | string;
        sourceLanguageId?: number;
        targetLanguageId?: number;
        comments?: string;
        volume?: number;
        unitId?: number;
        rateTypeId?: number;
        vat?: number;
        discount?: number;
        extraCharge?: number;
        discountExtraChargeReason?: string;
        priority?: number;
        executionOrder?: number;
        rate?: number;
        totalAmount?: number | string;
        isTimelineEstimated?:boolean;
        timeLineUnitId?:number;
        timeLineUnitValue?:number;
        workflowSteps?: number[];
        workflowStepLists?: number[];
        quoteStatusId?: number;
        quoteJobFiles?:PostQuoteJobFile[];
        catCountDiscounts?:PostQuoteJobCatCount;
        // frontend properties
        priceData?: JobPriceData = new JobPriceData();
}

export class PostQuoteJobFile extends PostFile {
    id?: number;
    insertDate?: string;
    insertUserId?: number;
    quoteJobId?: number;
    isActive?: boolean;
}

export class JobPriceData {
    direction?: keyof typeof JobPriceDirection = null;
    isCurrencyMatching?: boolean;
    isCurrencyMatchingWithClientCurrency?: boolean;
    priceListId?: number = null;
    isConverted?: boolean = null;
    rate?: number = null;
    conversion?: JobPriceConversionData = null;
}

export class JobPriceConversionData {
    base?: string;
    baseRate?: number;
    target?: string;
    targetRate?: number;
}


export class PostQuoteStatus {
    quoteIds?: number[];
    statusId?: number;
    secondaryStatusId?: number;
    changeStatusReasonId?: string;
    updateSecondaryStatus?: boolean;
    notes?: string;
}

export class PostQuoteJobRejection{
    id?: number;
    quoteJobId: number;
    changeStatusReasonId: string;
    remarks?: string;
}

export class PostQuoteTag {
    id?: number;
    insertDate?: Date;
    insertUserId?: number;
    description?: string;
    isActive?: boolean;
    tagCategoryId?: number;
    quoteId?: number;
}