import { Pipe, PipeTransform } from '@angular/core';
import { RefDataService } from 'app/services/general/refData.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
    name: 'categoryName',
})
export class CategoryNamePipe implements PipeTransform {
    constructor(private refDataService: RefDataService) {
    }
    
    transform(value: string): Observable<string> {
        return this.refDataService.getCategories().pipe(
            map((categories) => {
                const category = categories?.find((f) => f.id == value);
                return category?.description || '---';
            }),
        );
    }
}