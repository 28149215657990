import {Component, EventEmitter, forwardRef, Input, Optional, Output} from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DropDownOptionsModel } from 'app/models/general';
import { BaseControlComponentDirective } from '../base-control-component.directive';

@Component({
    selector: 'tp-radio-button',
    templateUrl: './tp-radio-button.component.html',
    styleUrls: ['./tp-radio-button.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TpRadioButtonComponent),
            multi: true,
        },
    ],
})
export class TpRadioButtonComponent extends BaseControlComponentDirective {
    @Output() valueChanged: EventEmitter<any> = new EventEmitter();
    private _options: DropDownOptionsModel[];
    @Input()
    set options(value: DropDownOptionsModel[]) {
        this._options = value;
    }

    get options(): DropDownOptionsModel[] {
        return this._options;
    }

    constructor(
        @Optional() protected readonly controlContainer: ControlContainer,
        protected readonly translateService: TranslateService,
    ) {
        super(controlContainer, translateService);
    }
    onSelect(e): void {
        this.valueChanged.emit(e.value);
    }
}