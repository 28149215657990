<div class="drag-and-drop-container w-100 h-100 d-flex justify-content-center align-items-center flex-column p-2 position-relative"
     tpDragAndDropFileArea [systemBorderRadius]="systemBorderRadius" (droppedFiles)="onSelectedFiles($event)" [ngStyle]="{'min-height': minHeight}">
    <input #fileInputRef class="position-absolute w-100 h-100 cursor-pointer" type="file" [accept]="acceptExtensions" id="fileDropRef" (change)="onSelectedFiles($event.target.files)" [multiple]="multiple">
    <div>
        <mat-icon class="upload-icon">cloud_upload</mat-icon>
    </div>
    <div class="font-weight-bold">
        <span>{{'tp-drag-and-drop-or' | translate : '{defaultText:\'Drag and drop, or \'}'}} </span>
        <a href="javascript:" (click)="fileInputRef.click()">{{'tp-browse' | translate : '{defaultText:\'browse\'}'}} </a>
        <span *ngIf="!label">{{'tp-your-files' | translate : '{defaultText:\'your files\'}'}}</span>
        <span *ngIf="label">{{label}}</span>
    </div>
    <div class="pt-3" *ngIf="allowSelectButton">
        <tp-button buttonStyle="outline" (buttonClicked)="selectButtonClicked.emit(true)">{{selectButtonLabel}}</tp-button>
    </div>
</div>