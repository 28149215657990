import { Pipe, PipeTransform } from '@angular/core';
import { CategoryNamePipe } from 'app/pipes/category-name/category-name.pipe';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
    name: 'categoriesNames',
})
export class CategoriesNamesPipe implements PipeTransform {
    constructor(private categoryNamePipe: CategoryNamePipe) {
    }

    transform(categoryIds: string[]): Observable<string> {
        if (categoryIds?.length > 0) {
            return combineLatest(categoryIds?.map(s => this.categoryNamePipe.transform(s)))
                .pipe(map(categoryNames => categoryNames.join(', ')));
        } else {
            return of('---');
        }
    }
}