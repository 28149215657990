/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatCardModule } from '@angular/material/card';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';

import { TpDatePickerModule } from 'app/components/tp-date-picker/tp-date-picker.module';
import { DaysBetweenDatesExcludingGlobalHolidaysPipeModule } from 'app/pipes/days-between-dates-excluding-global-holidays/days-between-dates-excluding-global-holidays-pipe.module';
import { FormatDatePipeModule } from 'app/pipes/format-date/format-date-pipe.module';
import { FormatDatePipe } from 'app/pipes/format-date/format-date.pipe';
import { TpTextBoxModule } from 'app/components/tp-text-box/tp-text-box.module';

import { MyProfileComponent } from './my-profile.component';
import { MyProfileLeftBarComponent } from './my-profile-left-bar/my-profile-left-bar.component';
import { DateToDiffFromNowPipeModule } from 'app/pipes/date-to-diff-from-now/date-to-diff-from-now-pipe.module';
import { TpSendEmailModule } from 'app/main/sharedComponents/tp-send-email/tp-send-email.module';
import { TpModalModule } from 'app/components/tp-modal/tp-modal.module';
import { TpButtonModule } from 'app/components/tp-button/tp-button.module';
import { EditLoginDetailsComponent } from './my-profile-tabs/edit-login-details/edit-login-details.component';
import { TpGridModule } from 'app/components/tp-grid/tp-grid.module';
import { MyDocumentsTableModule } from 'app/main/sharedComponents';
import { TpCountryDropdownModule } from 'app/components/tp-country-dropdown/tp-country-dropdown.module';
import { TpServiceLanguageDropdownModule } from 'app/components/tp-service-language-dropdown/tp-service-language-dropdown.module';
import { TpDropdownModule } from 'app/components/tp-dropdown/tp-dropdown.module';
import { TpToggleModule } from 'app/components/tp-toggle/tp-toggle.module';
import { TpPhotoUploadModule } from 'app/components/tp-photo-upload/tp-photo-upload.module';
import { PersonBankingDetailsModule } from 'app/main/sharedComponents/person-banking-details/person-banking-details.module';
import { TpUserPermissionListModule } from 'app/components/tp-user-permission-list/tp-user-permission-list.module';
import {DragAndDropFileModule} from "../../../components/drag-and-drop-file/drag-and-drop-file.module";
import {FileListModule} from "../../sharedComponents/file-list/file-list.module";
import {MatBadgeModule} from "@angular/material/badge";



@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        ReactiveFormsModule,
        MatTabsModule,
        MatCardModule,
        MatButtonModule,
        NgxSpinnerModule,
        MatExpansionModule,
        TpGridModule,
        TpModalModule,
        TpButtonModule,
        TpTextBoxModule,
        TpDatePickerModule,
        TpCountryDropdownModule,
        TpServiceLanguageDropdownModule,
        TpPhotoUploadModule,
        DaysBetweenDatesExcludingGlobalHolidaysPipeModule,
        DateToDiffFromNowPipeModule,
        FormatDatePipeModule,
        TpSendEmailModule,
        MyDocumentsTableModule,
        TpDropdownModule,
        TpToggleModule,
        PersonBankingDetailsModule,
        TpUserPermissionListModule,
        DragAndDropFileModule,
        FileListModule,
        MatBadgeModule
    ],
    declarations: [
        MyProfileLeftBarComponent,
        MyProfileComponent,
        EditLoginDetailsComponent
    ],
    exports: [
        MyProfileLeftBarComponent,
        MyProfileComponent,
        TpToggleModule
    ],
    providers: [
        FormatDatePipe,
        
    ],
})

export class MyProfileModule {
}