import {CommonModule } from '@angular/common'
import {NgModule} from '@angular/core'
import {FormsModule, ReactiveFormsModule } from '@angular/forms'
import {MatFormFieldModule } from '@angular/material/form-field'
import {TranslateModule } from '@ngx-translate/core'
import {DatePickerModule, DateTimePickerModule,} from '@syncfusion/ej2-angular-calendars'
import {TpCalendarComponent } from 'app/components/tp-calendar/tp-calendar.component'
import {MatInputModule} from "@angular/material/input";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {NgxMatDatetimePickerModule, NgxMatTimepickerModule} from "@angular-material-components/datetime-picker";
import {NgxMatMomentModule} from "@angular-material-components/moment-adapter";

@NgModule({
    declarations: [TpCalendarComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        DateTimePickerModule,
        DatePickerModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        NgxMatDatetimePickerModule,
        NgxMatMomentModule,
        NgxMatTimepickerModule,
    ],
    exports: [TpCalendarComponent],
})
export class TpCalendarModule {}