import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { TpIframeComponent } from 'app/components/tp-iframe/tp-iframe.component'
import { SafePipeModule } from 'app/pipes/safe-pipe/safe-pipe.module'

@NgModule({
    declarations: [TpIframeComponent],
    imports: [CommonModule, SafePipeModule],
    exports: [TpIframeComponent],
})
export class TpIframeModule {}