import {combineReducers, createFeatureSelector, createReducer, createSelector, on} from '@ngrx/store';
import {createEntity, EntityAdditional, EntitySearchAdditional, entityInitial, entitySearchInitial} from "../../../../store/models";
import {ClientSearchCriteria, ResponseClientData} from "../../clients/models";
import {ClientStateReport} from "../../../../store/models/client/client-state-report";
import {ResponseClientGridData, ResponseClientPOCData} from "../../clients/models/client.model";
import {ResponseClientNoteData} from "../../clients/models/response-models/response-client-note-data";
import {ResponseClientFile} from "../../clients/models/response-models/response-client-file";
import {ResponseClientPricingData} from "../../clients/models/client-pricing";
import {sortedGrid} from "../../../../utills";
import {pmPortalStateFeatureSelector} from "../features";

export const clientsFeatureKey = 'clients';
export const clientGridFeatureKey = 'clientGrid';
export const clientNotesFeatureKey = 'clientNotes';
export const clientContactsFeatureKey = 'clientContacts';
export const clientFilesFeatureKey = 'clientFiles';
export const clientRatesFeatureKey = 'clientRates';

export const clientsEntity = createEntity<ResponseClientData & ClientStateReport, EntityAdditional>(
    pmPortalStateFeatureSelector,
    clientsFeatureKey,
    entityInitial({}),
);
export const clientGridEntity = createEntity<ResponseClientGridData, EntitySearchAdditional<ClientSearchCriteria>>(
    pmPortalStateFeatureSelector,
    clientGridFeatureKey,
    entitySearchInitial( { searchCriteria: { clientStatuses: [1] }, searchFormValue: { clientStatuses: [ { id: 1,name: '' } ] }, sorted: sortedGrid })
);
export const clientNotesEntity = createEntity<ResponseClientNoteData, EntityAdditional>(
    pmPortalStateFeatureSelector,
    clientNotesFeatureKey,
    entityInitial({})
);
export const clientContactsEntity = createEntity<ResponseClientPOCData, EntityAdditional>(
    pmPortalStateFeatureSelector,
    clientContactsFeatureKey,
    entityInitial({})
);
export const clientFilesEntity = createEntity<ResponseClientFile, EntityAdditional>(
    pmPortalStateFeatureSelector,
    clientFilesFeatureKey,
    entityInitial({})
);
export const clientRatesEntity = createEntity<ResponseClientPricingData, EntityAdditional>(
    pmPortalStateFeatureSelector,
    clientRatesFeatureKey,
    entityInitial({})
);