import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import {ScreenBreakpointTypes} from "../../utills";

export enum ScreenSizeTypes {
    ExtraSmall = 0,
    Small = 1,
    Medium = 2,
    Large = 3,
    ExtraLarge = 4
}

@Injectable({
    providedIn: 'root',
})
export class ScreenBreakpointsService {
    currentScreenSize$: Observable<ScreenSizeTypes>;
    constructor(
        private readonly breakpointObserver: BreakpointObserver
    ) {
        this.currentScreenSize$ = combineLatest([
            breakpointObserver.observe(`(max-width:${ScreenBreakpointTypes.ExtraSmall})`).pipe(shareReplay(1), map(state => state.matches)),
            breakpointObserver.observe(`(max-width:${ScreenBreakpointTypes.Small})`).pipe(shareReplay(1), map(state => state.matches)),
            breakpointObserver.observe(`(max-width:${ScreenBreakpointTypes.Medium})`).pipe(shareReplay(1), map(state => state.matches)),
            breakpointObserver.observe(`(max-width:${ScreenBreakpointTypes.Large})`).pipe(shareReplay(1), map(state => state.matches)),
            breakpointObserver.observe(`(max-width:${ScreenBreakpointTypes.ExtraLarge})`).pipe(shareReplay(1), map(state => state.matches)),
        ]).pipe(map(([extraSmall, small, medium, large, extraLarge]) => {
            if (extraSmall) {
                return ScreenSizeTypes.ExtraSmall;
            } else if (small) {
                return ScreenSizeTypes.Small;
            } else if (medium) {
                return ScreenSizeTypes.Medium;
            } else if (large){
                return  ScreenSizeTypes.Large;
            } else {
                return  ScreenSizeTypes.ExtraLarge;
            }
        }));
    }
}