import {ResponseClientData} from "../../main/pm/clients/models";
import {DropDownItem} from "../../models/general/dropdownItem.model";
import {
    ResponseClientGridData,
    ResponseClientInfo,
    ResponseClientPOCData
} from "../../main/pm/clients/models/client.model";
import {ResponseClientPricingData} from "../../main/pm/clients/models/client-pricing";
import {ResponseClientNoteData} from "../../main/pm/clients/models/response-models/response-client-note-data";
import {ResponseClientFile} from "../../main/pm/clients/models/response-models/response-client-file";

export const mapClientDataToDropdown = (clientData: ResponseClientData): DropDownItem => {
    return <DropDownItem>{id: clientData.id, name: clientData.clientPerson.personInfo.name?.trimStart()?.trimEnd(), icon: ''};
}
export const mapClientDataToClientGridData = (clientData: ResponseClientData): ResponseClientGridData => {
    return {
        id: clientData.id,
        code: clientData.code,
        clientName: clientData.clientPerson.personInfo.name,
        comments: clientData.comments,
        countryDescription: clientData.countryDescription,
        countryId: clientData.countryId,
        currencyName: clientData.currencyName,
        currencyId: clientData.currencyId,
        clientTypeId: clientData.clientTypeId,
        clientTypeDescription: clientData.clientTypeDescription,
        clientStatusId: clientData.clientStatusId,
        clientStatusDescription: clientData.clientStatusDescription,
        clientStatusColor: clientData.clientStatusColor,
        companyBranchId: clientData.companyBranchId,
        companyBranchName: clientData.companyBranchName,
        personId: clientData.personId,
        defaultInvoiceTemplateId: clientData.defaultInvoiceTemplateId,
        defaultQuoteTemplateId: clientData.defaultQuoteTemplateId,
        lastJobDate: clientData.lastJobDate,
        avatar: clientData.avatar,
        services: clientData.clientRates,
        insertDate: clientData.insertDate,
    };
}
export const mapClientDataToClientInfo = (clientData: ResponseClientData): ResponseClientInfo => {
    if(!clientData) return null;
    return {
        id: clientData.id,
        code: clientData.code,
        countryId: clientData.countryId,
        currencyName: clientData.currencyName,
        currencyId: clientData.currencyId,
        clientTypeId: clientData.clientTypeId,
        clientStatusId: clientData.clientStatusId,
        clientStatusDescription: clientData.clientStatusDescription,
        companyBranchId: clientData.companyBranchId,
        personId: clientData.personId,
        defaultInvoiceTemplateId: clientData.defaultInvoiceTemplateId,
        defaultQuoteTemplateId: clientData.defaultQuoteTemplateId,
    };
}
export const normalizeClientGridData = (gridData: ResponseClientGridData[]): {gridData: ResponseClientGridData[], rates: ResponseClientPricingData[]} => {
    let rates = [];
    gridData.map(client => {
        rates.push(...(client.services ?? []));
        client.services = [];
    });
    return {gridData, rates};
}
export interface NormalizedClientData {
    data: ResponseClientData,
    notes: ResponseClientNoteData[],
    contacts: ResponseClientPOCData[],
    files: ResponseClientFile[],
    rates: ResponseClientPricingData[],
}
export const normalizeClientData = (clientData: ResponseClientData): NormalizedClientData => {
    let rates = clientData.clientRates ?? [];
    let contacts = clientData.clientContacts ?? [];
    let notes = clientData.clientNotes ?? [];
    let files = clientData.clientFiles ?? [];
    clientData.clientRates = [];
    clientData.clientContacts = [];
    clientData.clientNotes = [];
    clientData.clientFiles = [];
    let data = clientData;
    return {data, rates, contacts, notes, files};
}