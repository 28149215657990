import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';

export enum HttpVerb {
  post = 1,
  get = 2,
  put = 3,
  delete = 4
}

@Injectable({
  providedIn: 'root'
})
export class ApirequestService {

  constructor(
    private httpclient:HttpClient
  ) { }

  
  /***
   @description generic api request based on given parameters
   @returns Observable<T>
   @memberof ApirequestService
   */
  public ApiRequest<T>(url: string, obj: any, headersarray: HttpHeaders, verb: HttpVerb, restype: string) {
    
    try {

      if(restype==null || undefined){
        restype= 'json';
      }

      if (headersarray.keys != null) {
        var requestoptions: Object= {
          headers: headersarray,
          responseType: restype
        }
      }      

      //post
      if (verb == HttpVerb.post) {

        return this.httpclient.post<T>(
          url,
          JSON.stringify(obj),
          requestoptions
        );
      }
      //get
      else if (verb == HttpVerb.get) {

          return this.httpclient.get<T>(
            url,
            requestoptions
          );
      }
      //put
      else if (verb == HttpVerb.put) {

        return this.httpclient.put<T>(
          url,
          JSON.stringify(obj),
          requestoptions
        );
      }
      //delete
      else if (verb == HttpVerb.delete) {

        return this.httpclient.delete<T>(
          url,
          requestoptions
        );
      }

    }
    catch (err) {
          
    }

  } 

}