import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ArrayHelpersService {

    arraysEqual(array1: unknown[], array2: unknown[], ignoreOrder = true): boolean {
        if (!(array1?.length > 0) && !(array2?.length > 0)) {
            return true;
        } else if (array1 && array2) {
            if (array1.length === array2.length) {
                if (ignoreOrder) {
                    const array1CopiedAndSorted = array1.slice().sort();
                    const array2CopiedAndSorted = array2.slice().sort();
                    return array1CopiedAndSorted.every((value, index) => value == array2CopiedAndSorted[index]);
                } else {
                    return array1.every((value, index) => value == array2[index]);
                }
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    arrayMoveMutate(array: unknown[], from: number, to: number): void {
        const startIndex = from < 0 ? array.length + from : from;

        if (startIndex >= 0 && startIndex < array.length) {
            const endIndex = to < 0 ? array.length + to : to;

            const [item] = array.splice(from, 1);
            array.splice(endIndex, 0, item);
        }
    }

    arrayMoveImmutable(array: unknown[], from: number, to: number): unknown[] {
        const clonedArray = [...array];
        this.arrayMoveMutate(clonedArray, from, to);
        return clonedArray;
    }
}