import { createAction, props } from '@ngrx/store';
import {SearchCriteriaModel} from "../../../../../models";
import {PostAvatar} from "../../../../../models/post-models/post-avatar";
import {ResourceSearch} from "../../../../../models/general/system-models/search-models/resource/resource-search";
import {PostAllResourceData} from "../../../resources/models/post-models/post-all-resource-data";
import {PostResourceFile} from "../../../resources/models/post-models/post-resource-file";
import {PostResourceCatCountDiscounts} from "../../../resources/models/resourceCatCount.model";
import {PostResourceNote} from "../../../resources/models/post-models/post-resource-note";
import {ResponseResourceNoteData} from "../../../resources/models/response-models/response-resource-note-data";

export enum ResourceActionTypes {
    searchResourceGrid = '[Resource / Api] Search Resource Grid',
    getResource = '[Resource / Api] Get Resource Data',
    addResource = '[Resource / Api] Add Resource',
    updateResource = '[Resource / Api] Update Resource',
    deleteResource = '[Resource / Api] Delete Resource',
    updateResourceAvatar = '[Resource / Api] Update Resource Avatar',
    saveResourceCatCount = '[Resource / Api] Save Resource Cat Count',
    getResourceNotes = '[Resource / Api] Get Resource Notes',
    addResourceNote = '[Resource / Api] Add Resource Note',
    updateResourceNote = '[Resource / Api] Update Resource Note',
    deleteResourceNote = '[Resource / Api] Delete Resource Note',
}

export const searchResourceGrid = createAction(
    ResourceActionTypes.searchResourceGrid,
    props<SearchCriteriaModel<ResourceSearch>>()
);
export const getResource = createAction(
    ResourceActionTypes.getResource,
    props<{id: number}>()
);
export const addResource = createAction(
    ResourceActionTypes.addResource,
    props<{resource: PostAllResourceData, files: PostResourceFile[], sendCredentials?: boolean}>()
);
export const updateResource = createAction(
    ResourceActionTypes.updateResource,
    props<{resource: PostAllResourceData, sendCredentials?: boolean}>()
);
export const deleteResource = createAction(
    ResourceActionTypes.deleteResource,
    props<{ids: number[]}>()
);
export const updateResourceAvatar = createAction(
    ResourceActionTypes.updateResourceAvatar,
    props<PostAvatar>()
);
export const saveResourceCatCount = createAction(
    ResourceActionTypes.saveResourceCatCount,
    props<PostResourceCatCountDiscounts>()
);
export const getResourceNotes = createAction(
    ResourceActionTypes.getResourceNotes,
    props<{resourceId: number}>()
);
export const addResourceNote = createAction(
    ResourceActionTypes.addResourceNote,
    props<PostResourceNote>()
);
export const updateResourceNote = createAction(
    ResourceActionTypes.updateResourceNote,
    props<PostResourceNote>()
);
export const deleteResourceNote = createAction(
    ResourceActionTypes.deleteResourceNote,
    props<ResponseResourceNoteData>()
);