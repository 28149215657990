import { NgModule } from '@angular/core';
import { UcFirstPipe } from 'app/pipes/uc-first/uc-first.pipe';

@NgModule({
    declarations: [UcFirstPipe],
    providers: [
        UcFirstPipe,
    ],
    exports: [
        UcFirstPipe,
    ],
})
export class UcFirstPipeModule {
}