import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FlexModule } from '@angular/flex-layout'
import { ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatMenuModule } from '@angular/material/menu'
import { TranslateModule } from '@ngx-translate/core'
import { TpButtonModule } from 'app/components/tp-button/tp-button.module'
import { TpGridSearchWrapperComponent } from 'app/components/tp-grid-search-wrapper/tp-grid-search-wrapper.component'
import { ClickOutsideModule } from 'ng-click-outside'
import { BulkActionsMenuComponent } from './bulk-actions-menu/bulk-actions-menu.component'
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatRippleModule} from "@angular/material/core";

@NgModule({
    declarations: [TpGridSearchWrapperComponent, BulkActionsMenuComponent],
    imports: [
        CommonModule,
        FlexModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        ReactiveFormsModule,
        MatButtonModule,
        ClickOutsideModule,
        TranslateModule,
        TpButtonModule,
        MatMenuModule,
        MatTooltipModule,
        MatRippleModule,
    ],
    exports: [TpGridSearchWrapperComponent, BulkActionsMenuComponent],
})
export class TpGridSearchWrapperModule {}