import { Injectable } from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as ResourceRatesActions from "../../actions/resource-actions/resource-rate.actions";
import {catchError, concatMap, filter, map, mergeMap, share, switchMap, withLatestFrom} from "rxjs/operators";
import {of} from "rxjs";
import {NotificationService} from "../../../../../services/notification.service";
import {ResourceService} from "../../../services/resources/resource.service";
import {resourceRatesEntity} from "../../reducers";
import {Store} from "@ngrx/store";
import {State} from "../../../../../store";

@Injectable()
export class ResourceRateEffects {
    getResourceRates$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ResourceRatesActions.getResourceRates),
            mergeMap((action) => of(action).pipe(withLatestFrom(this.store.select(resourceRatesEntity.selectors.selectByPredicate((entity) => entity.resourceId == action.resourceId))))),
            filter(([action, rates]) => !(rates?.length > 0)),
            switchMap(([action, rates]) =>
                this.resourceService.getResourceServicesById(action.resourceId).pipe(
                    map(response => {
                        return resourceRatesEntity.actions.upsertMany({entities: response.response});
                    }),
                    catchError(error => {
                        return of(resourceRatesEntity.actions.updateAdditional({updates:{error}}));
                    })
                )
            ),
            share()
        );
    });
    addResourceRate$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ResourceRatesActions.addResourceRate),
            concatMap((action) =>
                this.resourceService.addResourceRates([action]).pipe(
                    map(response => {
                        const entities = Array.isArray(response.response) ? response.response: [];
                        this.notificationService.showSuccess('Saved', 'Resource Rates Saved Successfully');
                        return resourceRatesEntity.actions.addMany({entities});
                    }),
                    catchError(error => {
                        return of(resourceRatesEntity.actions.updateAdditional({updates:{error}}));
                    })
                )
            ),
            share()
        );
    });
    updateResourceRate$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ResourceRatesActions.updateResourceRate),
            concatMap((action) => {
                return this.resourceService.updateResourceRate(action).pipe(
                    map(result => {
                        this.notificationService.showSuccess('Saved', 'Resource Rates Saved Successfully');
                        this.store.dispatch(resourceRatesEntity.actions.removePredicate({predicate: (rate) => rate?.id == action?.id}));
                        return resourceRatesEntity.actions.upsertMany({entities: result.response});
                    }),
                    catchError(error => {
                        return of(resourceRatesEntity.actions.updateAdditional({updates:{error}}));
                    })
                )}
            ),
            share()
        );
    });
    updateResourceRates$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ResourceRatesActions.updateResourceRates),
            concatMap((action) => {
                return this.resourceService.updateResourceRates(action.rates).pipe(
                    map(result => {
                        this.notificationService.showSuccess('Saved', 'Resource Rates Saved Successfully');
                        this.store.dispatch(resourceRatesEntity.actions.removePredicate({predicate: (rate) => rate?.resourceId == action?.rates[0]?.resourceId}));
                        return resourceRatesEntity.actions.upsertMany({entities: result.response});
                    }),
                    catchError(error => {
                        return of(resourceRatesEntity.actions.updateAdditional({updates:{error}}));
                    })
                )}
            ),
            share()
        );
    });
    deleteResourceRate$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ResourceRatesActions.deleteResourceRates),
            concatMap((action) =>
                this.resourceService.deleteResourceRatesByIds(action.ids).pipe(
                    map(response => {
                        this.notificationService.showSuccess('Deleted', 'Resource Rate Deleted Successfully');
                        return resourceRatesEntity.actions.removeMany({ids: action.ids});
                    }),
                    catchError(error => {
                        return of(resourceRatesEntity.actions.updateAdditional({updates:{error}}));
                    })
                )
            ),
            share()
        );
    });

  constructor(
      private actions$: Actions,
      private notificationService: NotificationService,
      private resourceService: ResourceService,
      private store: Store<State>
  ) {}

}