import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { TpHTMLViewerComponent } from 'app/components/tp-html-viewer/tp-html-viewer.component'
import { TpIframeModule } from 'app/components/tp-iframe/tp-iframe.module'

@NgModule({
    declarations: [TpHTMLViewerComponent],
    imports: [CommonModule, TpIframeModule],
    exports: [TpHTMLViewerComponent],
})
export class TpHtmlViewerModule {}