import { createAction, props } from '@ngrx/store';
import {PostWorkflow} from "../../../admin/workflows/models/post-workflow";
import {PutWorkflow} from "../../../admin/workflows/models/put-workflow";

export enum WorkflowActionTypes {
    getWorkflows = '[Workflows / Api] Get Workflows Data',
    getWorkflow = '[Workflow / Api] Get Workflow Data',
    addWorkflow = '[Workflow / Api] Add Workflow',
    updateWorkflow = '[Workflow / Api] Update Workflow',
    deleteWorkflows = '[Workflow / Api] Delete Workflows',
}

export const getWorkflows = createAction(
    WorkflowActionTypes.getWorkflows
);

export const getWorkflow = createAction(
    WorkflowActionTypes.getWorkflow,
    props<{ id: number }>()
);

export const addWorkflow = createAction(
    WorkflowActionTypes.addWorkflow,
    props<PostWorkflow>()
);

export const updateWorkflow = createAction(
    WorkflowActionTypes.updateWorkflow,
    props<PutWorkflow>()
);

export const deleteWorkflows = createAction(
    WorkflowActionTypes.deleteWorkflows,
    props<{ids: number[]}>()
);
