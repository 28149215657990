<div class="cat-count-analysis-container custom-scrollbar px-2" [formGroup]="formGroup">
    <div class="row align-items-center">
        <div class="col-3"></div>
        <div class="col-3">Words</div>
        <div class="col-3">% of full rate <small class="text-danger">{{catCountOrigin}}</small></div>
        <div class="col-3">Weighted wordcount</div>
    </div>

    <div class="row align-items-center" formGroupName="contextMatch">
        <div class="col-3">Context Match</div>
        <div class="col-3">
            <tp-text-box inputType="number" formControlName="words"></tp-text-box>
        </div>
        <div class="col-3">
            <tp-text-box inputType="number" formControlName="discount"></tp-text-box>
        </div>
        <div class="col-3">{{getGroup('contextMatch').value?.discounted}}</div>
    </div>

    <div class="row align-items-center" formGroupName="xtranslated">
        <div class="col-3">XTranslated</div>
        <div class="col-3">
            <tp-text-box inputType="number" formControlName="words"></tp-text-box>
        </div>
        <div class="col-3">
            <tp-text-box inputType="number" formControlName="discount"></tp-text-box>
        </div>
        <div class="col-3">{{getGroup('xtranslated').value?.discounted}}</div>
    </div>

    <div class="row align-items-center" formGroupName="repetitions">
        <div class="col-3">Repetitions</div>
        <div class="col-3">
            <tp-text-box inputType="number" formControlName="words"></tp-text-box>
        </div>
        <div class="col-3">
            <tp-text-box inputType="number" formControlName="discount"></tp-text-box>
        </div>
        <div class="col-3">{{getGroup('repetitions').value?.discounted}}</div>
    </div>

    <div class="row align-items-center" formGroupName="crossFileRepetitions">
        <div class="col-3">Cross-File Repetitions</div>
        <div class="col-3">
            <tp-text-box inputType="number" formControlName="words"></tp-text-box>
        </div>
        <div class="col-3">
            <tp-text-box inputType="number" formControlName="discount"></tp-text-box>
        </div>
        <div class="col-3">{{getGroup('crossFileRepetitions').value?.discounted}}</div>
    </div>

    <div class="row align-items-center" formGroupName="match101">
        <div class="col-3">101% Matches</div>
        <div class="col-3">
            <tp-text-box inputType="number" formControlName="words"></tp-text-box>
        </div>
        <div class="col-3">
            <tp-text-box inputType="number" formControlName="discount"></tp-text-box>
        </div>
        <div class="col-3">{{getGroup('match101').value?.discounted}}</div>
    </div>

    <div class="row align-items-center" formGroupName="match100">
        <div class="col-3">100% Matches</div>
        <div class="col-3">
            <tp-text-box inputType="number" formControlName="words"></tp-text-box>
        </div>
        <div class="col-3">
            <tp-text-box inputType="number" formControlName="discount"></tp-text-box>
        </div>
        <div class="col-3">{{getGroup('match100').value?.discounted}}</div>
    </div>

    <div class="row align-items-center" formGroupName="match9599">
        <div class="col-3">95%-99%</div>
        <div class="col-3">
            <tp-text-box inputType="number" formControlName="words"></tp-text-box>
        </div>
        <div class="col-3">
            <tp-text-box inputType="number" formControlName="discount"></tp-text-box>
        </div>
        <div class="col-3">{{getGroup('match9599').value?.discounted}}</div>
    </div>

    <div class="row align-items-center" formGroupName="match8594">
        <div class="col-3">85%-94%</div>
        <div class="col-3">
            <tp-text-box inputType="number" formControlName="words"></tp-text-box>
        </div>
        <div class="col-3">
            <tp-text-box inputType="number" formControlName="discount"></tp-text-box>
        </div>
        <div class="col-3">{{getGroup('match8594').value?.discounted}}</div>
    </div>

    <div class="row align-items-center" formGroupName="match7584">
        <div class="col-3">75%-84%</div>
        <div class="col-3">
            <tp-text-box inputType="number" formControlName="words"></tp-text-box>
        </div>
        <div class="col-3">
            <tp-text-box inputType="number" formControlName="discount"></tp-text-box>
        </div>
        <div class="col-3">{{getGroup('match7584').value?.discounted}}</div>
    </div>

    <div class="row align-items-center" formGroupName="match5074">
        <div class="col-3">50%-74%</div>
        <div class="col-3">
            <tp-text-box inputType="number" formControlName="words"></tp-text-box>
        </div>
        <div class="col-3">
            <tp-text-box inputType="number" formControlName="discount"></tp-text-box>
        </div>
        <div class="col-3">{{getGroup('match5074').value?.discounted}}</div>
    </div>

    <div class="row align-items-center" formGroupName="noMatch">
        <div class="col-3">No Match</div>
        <div class="col-3">
            <tp-text-box inputType="number" formControlName="words"></tp-text-box>
        </div>
        <div class="col-3">
            <tp-text-box inputType="number" formControlName="discount"></tp-text-box>
        </div>
        <div class="col-3">{{getGroup('noMatch').value?.discounted}}</div>
    </div>
    <div class="row align-items-center">
        <div class="col-3">Total</div>
        <div class="col-3">{{totalWordCount?.toFixed(2)}}</div>
        <div class="col-3"></div>
        <div class="col-3">{{totalDiscounted?.toFixed(2)}}</div>
    </div>
</div>