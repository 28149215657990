import {DropDownItem} from "../../models/general/dropdownItem.model";
import {ResponseResourceData} from "../../main/pm/resources/models/response-models/response-resource-data";
import {ResponseResourceGridData} from "../../main/pm/resources/models/response-models/response-resource-grid-data";
import {ResponseResourceFile} from "../../main/pm/resources/models/response-models/response-resource-file";
import {
    ResponseResourceServiceRateData
} from "../../main/pm/resources/models/response-models/response-resource-service-rate-data";
import {ResponseClientGridData} from "../../main/pm/clients/models/client.model";
import {ResponseClientPricingData} from "../../main/pm/clients/models/client-pricing";
import {ResourceDropDownItem} from "../models/dropdowns/resource-dropdown-item";

export const mapResourceDataToDropdown = (resourceData: ResponseResourceData): ResourceDropDownItem => {
    return {id: resourceData.id, name: resourceData.resourceName, icon: '', resourceTypeId: resourceData.resourceTypeId, currencyId: resourceData.currencyId};
}
export const mapResourceDataToResourceGridData = (resourceData: ResponseResourceData): ResponseResourceGridData => {
    return {
        id: resourceData.id,
        code: resourceData.code,
        insertDate: resourceData.insertDate,
        resourceFName: resourceData.person.personInfo.fname,
        resourceLName: resourceData.person.personInfo.lname,
        avatarPath: resourceData.avatarPath,
        insertUserId: resourceData.insertUserId,
        remarks: resourceData.remarks,
        isActive: resourceData.isActive,
        personId: resourceData.personId,
        resourceTypeId: resourceData.resourceTypeId,
        spokenLanguadeId: resourceData.spokenLanguageId,
        services: resourceData.serviceRates,
        currencyId: resourceData.currencyId,
        currencyCode: resourceData.currencyCode,
        currencyName: resourceData.currencyName
    };
}
export const normalizeResourceGridData = (gridData: ResponseResourceGridData[]): {gridData: ResponseResourceGridData[], rates: ResponseResourceServiceRateData[]} => {
    let rates = [];
    gridData.map(resource => {
        rates.push(...(resource.services ?? []));
        resource.services = [];
    });
    return {gridData, rates};
}

export interface NormalizedResourceData {
    data: ResponseResourceData,
    files: ResponseResourceFile[],
    rates: ResponseResourceServiceRateData[],
}
export const normalizeResourceData = (resourceData: ResponseResourceData): NormalizedResourceData => {
    let files = resourceData.responseResourceFile ?? [];
    let rates = resourceData.serviceRates.map(rate => {rate.resourceId = resourceData.id; return rate}) ?? [];
    resourceData.responseResourceFile = [];
    resourceData.serviceRates = [];
    let data = resourceData;
    return {data, files, rates};
}