import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TpAutocompleteDropdownModule } from 'app/components/tp-autocomplete-dropdown/tp-autocomplete-dropdown.module';
import { ClientAndLeadSearchComponent } from 'app/main/sharedComponents/client-and-lead-search/client-and-lead-search.component';


@NgModule({
    declarations: [ClientAndLeadSearchComponent],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		TpAutocompleteDropdownModule,
		TranslateModule,
		FormsModule,
	],
    exports: [
        ClientAndLeadSearchComponent,
    ],
})
export class ClientAndLeadSearchModule {
}