import { Pipe, PipeTransform } from '@angular/core';
import { ServiceNamePipe } from 'app/pipes/service-name-pipe/service-name.pipe';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
    name: 'servicesNamesUsingDescriptors',
})
export class ServicesNamesUsingDescriptorsPipePipe implements PipeTransform {

    constructor(private serviceNamePipe: ServiceNamePipe) {
    }

    transform(
        services: {
            serviceId: number,
            sourceLanguage: number | number[],
            targetLanguage: number | number[]
        }[],
    ): Observable<string> {
        if (services?.length > 0) {
            return combineLatest(services?.map(s => this.serviceNamePipe.transform(s.serviceId, s.sourceLanguage, s.targetLanguage)))
                .pipe(map(services => services.join(', ')));
        } else {
            return of('---');
        }
    }
}