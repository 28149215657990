import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpHeaders,
    HttpEventType,
    HttpContextToken,
} from '@angular/common/http'
import {Observable, throwError, of} from 'rxjs'
import { Injectable } from '@angular/core'
import { TpAuthService } from 'app/services/auth/tp-auth.service'
import {catchError, finalize, switchMap} from "rxjs/operators";
import {NotificationService} from "../notification.service";
import {BaseResultModel} from "../../models";
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { State } from 'app/store';
import { mimeTypeDropdownEntity } from 'app/store/reducers';
import { DropDownItem } from 'app/models/general/dropdownItem.model';
import { getFileExtension } from 'app/utills/functions';

export const HttpContextSkippingSpinner = new HttpContextToken(() => false);
export const HttpContextSkippingHeaders = new HttpContextToken(() => false);

@Injectable()
export class CustomHttpInterceptorService implements HttpInterceptor {
    private totalRequests: number = 0;
    private mimeTypes:DropDownItem[]=[];

    constructor(private authService: TpAuthService, 
                private notification: NotificationService,
                private router:Router,
            private store:Store<State>) {
                this.store.select(mimeTypeDropdownEntity.selectors.selectAll)
                .subscribe(res=>{
                    this.mimeTypes=res
                });
            }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if(!req.context.get(HttpContextSkippingSpinner)){
            this.totalRequests++;
            this.notification.startSpinner();
        }
        let tpHeaders = new HttpHeaders();
        if (req.url.endsWith('/auth')) {
            tpHeaders = tpHeaders.append("Content-Type", "application/json");
        } 
        else if(req.url.endsWith('/user/update')){
            tpHeaders=tpHeaders.append("Accept", "*/*");

            const token = this.authService.getAccessToken();
            if (token != '') {
                tpHeaders = tpHeaders.append("Authorization", "Bearer " + token);
            }
        }
        else if(req.url.endsWith('/htmlTemplate')){
            tpHeaders=tpHeaders.append("Accept", "*/*");

            const token = this.authService.getAccessToken();
            if (token != '') {
                tpHeaders = tpHeaders.append("Authorization", "Bearer " + token);
            }
        }
        else if(req.url.endsWith('/preview/htmlTemplate/html')){
            tpHeaders=tpHeaders.append("Accept", "*/*");

            const token = this.authService.getAccessToken();
            if (token != '') {
                tpHeaders = tpHeaders.append("Authorization", "Bearer " + token);
            }
        }
        else if(req.url.endsWith('/preview/htmlTemplate/pdf')){
            tpHeaders=tpHeaders.append("Accept", "*/*");

            const token = this.authService.getAccessToken();
            if (token != '') {
                tpHeaders = tpHeaders.append("Authorization", "Bearer " + token);
            }
        }
        else if(req.url.endsWith('/system/company/update')){
            tpHeaders=tpHeaders.append("Accept", "*/*");

            const token = this.authService.getAccessToken();
            if (token != '') {
                tpHeaders = tpHeaders.append("Authorization", "Bearer " + token);
            }
        }
        else if(req.url.endsWith('/quote/add/with-files')){
            tpHeaders=tpHeaders.append("Accept", "*/*");

            const token = this.authService.getAccessToken();
            if (token != '') {
                tpHeaders = tpHeaders.append("Authorization", "Bearer " + token);
            }
        }
        else if(req.url.endsWith('/quote/cat-tool-analysis/csv')){
            tpHeaders=tpHeaders.append("Accept", "*/*");

            const token = this.authService.getAccessToken();
            if (token != '') {
                tpHeaders = tpHeaders.append("Authorization", "Bearer " + token);
            }
        }
        else if(req.url.includes('/resource/save-send/job/')){
            tpHeaders=tpHeaders.append("Accept", "*/*");

            const token = this.authService.getAccessToken();
            if (token != '') {
                tpHeaders = tpHeaders.append("Authorization", "Bearer " + token);
            }
        }
        else if(req.url.endsWith('/migration/client/upload')){
            tpHeaders=tpHeaders.append("Accept", "*/*");

            const token = this.authService.getAccessToken();
            if (token != '') {
                tpHeaders = tpHeaders.append("Authorization", "Bearer " + token);
            }
        }
        else if(!req.context.get(HttpContextSkippingHeaders)) {
            const token = this.authService.getAccessToken();
            if (token != '') {
                tpHeaders = tpHeaders.append("Authorization", "Bearer " + token);
            }
            if (!(req.body instanceof FormData)) {
                tpHeaders = tpHeaders.append("Content-Type", "application/json");
            }
        }
        else{
            //in case we are dealing with a file (S3 upload) we must define the content type header if file's type
            // is not defined
            var file=req.body;
            if(file instanceof File){
                if(!file.type){
                    var mtMatches=this.mimeTypes?.filter(x=>x.name?.toLowerCase()==getFileExtension(file));

                    if(mtMatches?.length>0){
                        var fileMimeType=mtMatches[0].prefix.toString();
                        tpHeaders = tpHeaders.append("Content-Type", fileMimeType);
                    }                    
                }
            }
        }
        
        const nextReq = req.clone({headers: tpHeaders});
        return next.handle(nextReq).pipe(
            switchMap(response => {
                switch (response.type) {
                    case HttpEventType.Response:
                        const body: BaseResultModel<any> = response.body;
                        if(body) {
                            if(body?.hasOwnProperty('status')) {
                                if(body.status) return of(response);
                                else {
                                    this.notification.showError('Error', body.message);
                                    return throwError(response);
                                }
                            }
                        }
                        return of(response);
                    default:
                        return of(response);
                }
            }),
            catchError( (err) => {
                if (err.status == 403) {   //forbidden
                    // this.notification.showError('Error', err.error);
                }
                else if (err.status == 401) {  //unauthorized
                    // this.router.navigateByUrl('/login');
                }
                return throwError(err);
            }),
            finalize(() => {
                if(this.totalRequests > 0) this.totalRequests--;
                if(this.totalRequests === 0) this.notification.stopSpinner();
            })
        )
    }
}