import { NgModule } from '@angular/core';
import { ClientNamePipe } from 'app/pipes/client-name/client-name-pipe';

@NgModule({
    declarations: [ClientNamePipe],
    providers: [ClientNamePipe],
    exports: [
        ClientNamePipe,
    ],
})
export class ClientNamePipeModule {
}