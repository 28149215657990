import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexModule } from '@angular/flex-layout'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { TranslateModule } from '@ngx-translate/core'
import { SelectClientPaymentTermsModule } from 'app/components/select-client-payment-terms/select-client-payment-terms.module'
import { TpAutocompleteDropdownModule } from 'app/components/tp-autocomplete-dropdown/tp-autocomplete-dropdown.module'
import { TpButtonModule } from 'app/components/tp-button/tp-button.module'
import { TpCalendarModule } from 'app/components/tp-calendar/tp-calendar.module'
import { TpCategoryDropdownModule } from 'app/components/tp-category-dropdown/tp-category-dropdown.module'
import { TpColorPickerModule } from 'app/components/tp-color-picker/tp-color-picker.module'
import { TpCountryDropdownModule } from 'app/components/tp-country-dropdown/tp-country-dropdown.module'
import { TpCurrencyDropdownModule } from 'app/components/tp-currency-dropdown/tp-currency-dropdown.module'
import { TpDatePickerModule } from 'app/components/tp-date-picker/tp-date-picker.module'
import { TpDropdownModule } from 'app/components/tp-dropdown/tp-dropdown.module'
import { TpDynamicColumnsComponent } from 'app/components/tp-dynamic-columns/tp-dynamic-columns.component'
import { TpDynamicColumnsModule } from 'app/components/tp-dynamic-columns/tp-dynamic-columns.module'
import { TpEditTableColumnsModule } from 'app/components/tp-edit-table-columns/tp-edit-table-columns.module'
import { TpFontSelectionDropdownModule } from 'app/components/tp-font-selection-dropdown/tp-font-selection-dropdown.module'
import { TpGanttModule } from 'app/components/tp-gantt/tp-gantt.module'
import { TpGridSearchWrapperModule } from 'app/components/tp-grid-search-wrapper/tp-grid-search-wrapper.module'
import { TpGridModule } from 'app/components/tp-grid/tp-grid.module'
import { TpHtmlViewerModule } from 'app/components/tp-html-viewer/tp-html-viewer.module'
import { TpLanguagesSupportedDropdownModule } from 'app/components/tp-languages-supported-dropdown/tp-languages-supported-dropdown.module'
import { TpModalModule } from 'app/components/tp-modal/tp-modal.module'
import { TpMultiselectDropdownModule } from 'app/components/tp-multiselect-dropdown/tp-multiselect-dropdown.module'
import { TpPaginationModule } from 'app/components/tp-pagination/tp-pagination.module'
import { TpPdfViewerModule } from 'app/components/tp-pdf-viewer/tp-pdf-viewer.module'
import { TpProgressBarCardModule } from 'app/components/tp-progress-bar-card/tp-progress-bar-card.module'
import { TpProjectStatusDropDownComponent } from 'app/components/tp-project-status-dropdown/tp-project-status-dropdown.component'
import { TpProjectStatusDropdownModule } from 'app/components/tp-project-status-dropdown/tp-project-status-dropdown.module'
import { TpProjectManagersDropdownModule } from 'app/components/tp-projectManagers-dropdown/tp-projectManagers-dropdown.module'
import { TpSalespersonsDropdownModule } from 'app/components/tp-salesperson-dropdown/tp-salespersons-dropdown.module'
import { TpServiceLanguageDropdownComponent } from 'app/components/tp-service-language-dropdown/tp-service-language-dropdown.component'
import { TpServiceLanguageDropdownModule } from 'app/components/tp-service-language-dropdown/tp-service-language-dropdown.module'
import { TpServicesDropdownModule } from 'app/components/tp-services-dropdown/tp-services-dropdown.module'
import { TpSliderRangeModule } from 'app/components/tp-slider-range/tp-slider-range.module'
import { TpTextAreaModule } from 'app/components/tp-text-area/tp-text-area.module'
import { TpTextBoxModule } from 'app/components/tp-text-box/tp-text-box.module'
import { TpTimeModule } from 'app/components/tp-time/tp-time.module'
import { TpStartTimeModule } from 'app/components/tp-start-time/tp-start-time.module'
import { TpToggleModule } from 'app/components/tp-toggle/tp-toggle.module'
import { TpUnitsDropdownModule } from 'app/components/tp-units-dropdown/tp-units-dropdown.module'
import { MaterialModule } from 'app/material.module'
import { CategoryNamePipeModule } from 'app/pipes/category-name/category-name-pipe.module'
import { CategoryNamePipe } from 'app/pipes/category-name/category-name.pipe'
import { CharacterLimitPipeModule } from 'app/pipes/character-limit-pipe/character-limit-pipe.module'
import { CharacterLimitPipe } from 'app/pipes/character-limit-pipe/character-limit.pipe'
import { FormatDatePipeModule } from 'app/pipes/format-date/format-date-pipe.module'
import { FormatDatePipe } from 'app/pipes/format-date/format-date.pipe'
import { FormatNumberWithCurrencyPipeModule } from 'app/pipes/format-number-with-currency-pipe/format-number-with-currency-pipe.module'
import { FormatNumberWithCurrencyPipe } from 'app/pipes/format-number-with-currency-pipe/format-number-with-currency.pipe'
import { ProjectManagerNamePipeModule } from 'app/pipes/project-manager-name/project-manager-name-pipe.module'
import { ProjectManagerNamePipe } from 'app/pipes/project-manager-name/project-manager-name.pipe'
import { ResourceNamePipe } from 'app/pipes/resource-name/resource-name-pipe'
import { ResourceNamePipeModule } from 'app/pipes/resource-name/resource-name-pipe.module'
import { RoleNamePipeModule } from 'app/pipes/role-name-pipe/role-name-pipe.module'
import { SafePipe } from 'app/pipes/safe-pipe/safe-pipe'
import { SafePipeModule } from 'app/pipes/safe-pipe/safe-pipe.module'
import { SalesPersonNamePipeModule } from 'app/pipes/sales-person-name-pipe/sales-person-name-pipe.module'
import { SalesPersonNamePipe } from 'app/pipes/sales-person-name-pipe/sales-person-name.pipe'
import { ServiceLanguageNamePipeModule } from 'app/pipes/service-language-name-pipe/service-language-name-pipe.module'
import { ServiceLanguageNamePipe } from 'app/pipes/service-language-name-pipe/service-language-name.pipe'
import { ServiceNamePipeModule } from 'app/pipes/service-name-pipe/service-name-pipe.module'
import { ServiceNamePipe } from 'app/pipes/service-name-pipe/service-name.pipe'
import { UcFirstPipeModule } from 'app/pipes/uc-first/uc-first-pipe.module'
import { UcFirstPipe } from 'app/pipes/uc-first/uc-first.pipe'
import { UnitNamePipeModule } from 'app/pipes/unit-name-pipe/unit-name-pipe.module'
import { UnitNamePipe } from 'app/pipes/unit-name-pipe/unit-name.pipe'
import { RefDataService } from 'app/services/general/refData.service'
import { ClickOutsideModule } from 'ng-click-outside'
import { Ng5SliderModule } from 'ng5-slider'
import { ColorPickerModule } from 'ngx-color-picker'
import { NgxSpinnerModule } from 'ngx-spinner'
import { TpAutoCompleteDropDownComponent } from './tp-autocomplete-dropdown/tp-autocomplete-dropdown.component'
import { TpButtonComponent } from './tp-button/tp-button.component'
import { TpCalendarComponent } from './tp-calendar/tp-calendar.component'
import { TpCategoryDropDownComponent } from './tp-category-dropdown/tp-category-dropdown.component'
import { TpColorPickerComponent } from './tp-color-picker/tp-color-picker.component'
import { TpCountryDropDownComponent } from './tp-country-dropdown/tp-country-dropdown.component'
import { TpCurrencyDropDownComponent } from './tp-currency-dropdown/tp-currency-dropdown.component'
import { TpDatePickerComponent } from './tp-date-picker/tp-date-picker.component'
import { TpDropDownComponent } from './tp-dropdown/tp-dropdown.component'
import { TpEditTableColumnsComponent } from './tp-edit-table-columns/tp-edit-table-columns.component'
import { TpFontSelectionDropDownComponent } from './tp-font-selection-dropdown/tp-font-selection-dropdown.component'
import { TpGanttComponent } from './tp-gantt/tp-gantt.component'
import { TpGridSearchWrapperComponent } from './tp-grid-search-wrapper/tp-grid-search-wrapper.component'
import { TpGridComponent } from './tp-grid/tp-grid.component'
import { TpHTMLViewerComponent } from './tp-html-viewer/tp-html-viewer.component'
import { TpInvoicingRadioComponent } from './tp-invoicing-radio/tp-invoicing-radio.component'
import { TpInvoicingRadioModule } from './tp-invoicing-radio/tp-invoicing-radio.module'
import { TpLanguagesSupportedDropDownComponent } from './tp-languages-supported-dropdown/tp-languages-supported-dropdown.component'
import { TpModalComponent } from './tp-modal/tp-modal.component'
import { TpMultiSelectDropDownComponent } from './tp-multiselect-dropdown/tp-multiselect-dropdown.component'
import { TpPaginationComponent } from './tp-pagination/tp-pagination.component'
import { TpPaymentTermsComponent } from './tp-payment-terms/tp-payment-terms.component'
import { TpPaymentTermsModule } from './tp-payment-terms/tp-payment-terms.module'
import { TpPDFViewerComponent } from './tp-pdf-viewer/tp-pdf-viewer.component'
import { TpProgressBarCardComponent } from './tp-progress-bar-card/tp-progress-bar-card.component'
import { TpProjectManagersDropDownComponent } from './tp-projectManagers-dropdown/tp-projectManagers-dropdown.component'
import { TpRadioButtonComponent } from './tp-radio-button/tp-radio-button.component'
import { TpRadioButtonModule } from './tp-radio-button/tp-radio-button.module'
import { TpSalespersonsDropDownComponent } from './tp-salesperson-dropdown/tp-salespersons-dropdown.component'
import { TpServicesDropDownComponent } from './tp-services-dropdown/tp-services-dropdown.component'
import { TpSliderRangeComponent } from './tp-slider-range/tp-slider-range.component'
import { TpTextAreaComponent } from './tp-text-area/tp-text-area.component'
import { TpTextBoxComponent } from './tp-text-box/tp-text-box.component'
import { TpTimeComponent } from './tp-time/tp-time.component'
import { TpStartTimeComponent } from './tp-start-time/tp-start-time.component'
import { TpToggleComponent } from './tp-toggle/tp-toggle.component'
import { TpUnitsDropDownComponent } from './tp-units-dropdown/tp-units-dropdown.component';
import { TpButtonDropdownComponent } from './tp-button-dropdown/tp-button-dropdown.component';
import { TpButtonDropdownItemComponent } from './tp-button-dropdown/tp-button-dropdown-item/tp-button-dropdown-item.component';
import { TpExportComponent } from './tp-export/tp-export.component';
import { TpBreadcrumbModule } from "./tp-breadcrumb/tp-breadcrumb.module";
import {TpUnderlineDropdownModule} from "./tp-underline-dropdown/tp-underline-dropdown.module";
import { CustomFileUploadModule } from './custom-file-upload/custom-file-upload.module'
import {LanguagesModule} from "./languages/languages.module";

@NgModule({
    imports: [
        CommonModule,
        FlexModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        RouterModule,
        ClickOutsideModule,
        NgxSpinnerModule,
        ColorPickerModule,
        TpEditTableColumnsModule,
        TpTimeModule,
        TpStartTimeModule,
        TpFontSelectionDropdownModule,
        TpColorPickerModule,
        CustomFileUploadModule,
        UcFirstPipeModule,
        FormatDatePipeModule,
        ResourceNamePipeModule,
        TpDropdownModule,
        TpMultiselectDropdownModule,
        TpTextBoxModule,
        TpTextAreaModule,
        TpCalendarModule,
        TpProjectManagersDropdownModule,
        TpProjectStatusDropdownModule,
        TpSalespersonsDropdownModule,
        TpCountryDropdownModule,
        TpCurrencyDropdownModule,
        TpServicesDropdownModule,
        TpServiceLanguageDropdownModule,
        TpCategoryDropdownModule,
        TpAutocompleteDropdownModule,
        TpLanguagesSupportedDropdownModule,
        TpDatePickerModule,
        TpToggleModule,
        TpDynamicColumnsModule,
        TpSliderRangeModule,
        TpUnitsDropdownModule,
        SelectClientPaymentTermsModule,
        TpModalModule,
        TpButtonModule,
        TpPaginationModule,
        TpGridModule,
        TpGridSearchWrapperModule,
        TpHtmlViewerModule,
        TpPdfViewerModule,
        TpGanttModule,
        ProjectManagerNamePipeModule,
        CategoryNamePipeModule,
        CharacterLimitPipeModule,
        FormatNumberWithCurrencyPipeModule,
        ServiceLanguageNamePipeModule,
        RoleNamePipeModule,
        SafePipeModule,
        SalesPersonNamePipeModule,
        ServiceNamePipeModule,
        UnitNamePipeModule,
        Ng5SliderModule,
        TpProgressBarCardModule,
        TpPaymentTermsModule,
        TpInvoicingRadioModule,
        TpRadioButtonModule,
        TpBreadcrumbModule,
        TpUnderlineDropdownModule,
        LanguagesModule,
    ],
    providers: [FormatNumberWithCurrencyPipe, RefDataService],
    exports: [
        TpModalComponent,
        TpTextBoxComponent,
        TpDropDownComponent,
        TpCurrencyDropDownComponent,
        TpUnitsDropDownComponent,
        TpCalendarComponent,
        TpToggleComponent,
        TpTextAreaComponent,
        TpServicesDropDownComponent,
        TpServiceLanguageDropdownComponent,
        TpCountryDropDownComponent,
        TpLanguagesSupportedDropDownComponent,
        FormatNumberWithCurrencyPipe,
        FormatDatePipe,
        SafePipe,
        ResourceNamePipe,
        UnitNamePipe,
        CharacterLimitPipe,
        UcFirstPipe,
        ServiceNamePipe,
        CategoryNamePipe,
        ServiceLanguageNamePipe,
        SalesPersonNamePipe,
        ProjectManagerNamePipe,
        TpAutoCompleteDropDownComponent,
        TpRadioButtonComponent,
        TpCategoryDropDownComponent,
        TpMultiSelectDropDownComponent,
        TpTimeComponent,
        TpStartTimeComponent,
        TpEditTableColumnsComponent,
        TpDatePickerComponent,
        TpDynamicColumnsComponent,
        TpPaymentTermsComponent,
        TpInvoicingRadioComponent,
        TpPaginationComponent,
        TpSliderRangeComponent,
        TpSalespersonsDropDownComponent,
        TpProjectManagersDropDownComponent,
        TpGridSearchWrapperComponent,
        TpProgressBarCardComponent,
        NgxSpinnerModule,
        TpButtonComponent,
        TpGridComponent,
        TpGanttComponent,
        TpFontSelectionDropDownComponent,
        TpColorPickerComponent,
        TpPDFViewerComponent,
        TpHTMLViewerComponent,
        TpProjectStatusDropDownComponent,
        TpButtonDropdownComponent,
        TpButtonDropdownItemComponent,
        TpExportComponent,
    ],
    entryComponents: [TpServicesDropDownComponent],
    declarations: [TpButtonDropdownComponent, TpButtonDropdownItemComponent, TpExportComponent],
})
export class ComponentsModule {}