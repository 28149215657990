import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TpDropdownModule } from 'app/components/tp-dropdown/tp-dropdown.module';
import { QuoteStatusDropDownComponent } from 'app/main/pm/quotes/shared/quote-status-dropdown/quote-status-dropdown.component';
import { TpMultiselectDropdownModule } from '../../../../../components/tp-multiselect-dropdown/tp-multiselect-dropdown.module';

@NgModule({
    declarations: [QuoteStatusDropDownComponent],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		TpDropdownModule,
		TranslateModule,
		TpMultiselectDropdownModule,
	],
    exports: [
        QuoteStatusDropDownComponent,
    ],
})
export class QuoteStatusDropdownModule {
}