import { NgModule } from '@angular/core';
import { SafePipe } from 'app/pipes/safe-pipe/safe-pipe';

@NgModule({
    declarations: [SafePipe],
    providers: [SafePipe],
    exports: [
        SafePipe,
    ],
})
export class SafePipeModule {
}