import { createAction, props } from '@ngrx/store';
import {PostServiceWithPriceList} from "../../../admin/manage-services/models/post-models/post-service-with-price-list";
import {SearchCriteriaModel} from "../../../../models";
export enum ServiceActionTypes {
    searchServiceGrid = '[Service / Api] Search Service Grid',
    getService = '[Service / Api] Get Service Data',
    addService = '[Service / Api] Add Service',
    updateService = '[Service / Api] Update Service',
    deleteService = '[Service / Api] Delete Service',
}
export const searchServiceGrid = createAction(
    ServiceActionTypes.searchServiceGrid,
    props<SearchCriteriaModel<any>>()
);
export const getService = createAction(
    ServiceActionTypes.getService,
    props<{id: number}>()
);
export const addService = createAction(
    ServiceActionTypes.addService,
    props<PostServiceWithPriceList>()
);
export const updateService = createAction(
    ServiceActionTypes.updateService,
    props<PostServiceWithPriceList>()
);
export const deleteService = createAction(
    ServiceActionTypes.deleteService,
    props<{id: number}>()
);