import { createAction, props } from '@ngrx/store';
import {EntityAdditional} from "../../../../../store/models";
import {
    ResponseResourceServiceRateData
} from "../../../resources/models/response-models/response-resource-service-rate-data";
import {PostResourceServiceRate} from "../../../resources/models/post-models/post-resource-service-rate";

export enum ResourceRateActionTypes {
    getResourceRates = '[ResourceRates / Api] Get Resource Rates',
    addResourceRate = '[ResourceRate / Api] Add Resource Rate',
    updateResourceRate = '[ResourceRate / Api] Update Resource Rate',
    updateResourceRates = '[ResourceRate / Api] Update Resource Rates',
    deleteResourceRates = '[ResourceRate / Api] Delete Resource Rates',
}

export const getResourceRates = createAction(
    ResourceRateActionTypes.getResourceRates,
    props<{resourceId: number}>()
);
export const addResourceRate = createAction(
    ResourceRateActionTypes.addResourceRate,
    props<PostResourceServiceRate>()
);
export const updateResourceRate = createAction(
    ResourceRateActionTypes.updateResourceRate,
    props<PostResourceServiceRate>()
);
export const updateResourceRates = createAction(
    ResourceRateActionTypes.updateResourceRates,
    props<{ rates : PostResourceServiceRate[] }>()
);

export const deleteResourceRates = createAction(
    ResourceRateActionTypes.deleteResourceRates,
    props<{ids: number[]}>()
);