<div class="row" *ngIf="!select; else selectFiles">
    <div class="col-lg-3 col-md-3 col-sm-3 border-right">
        <tp-file-tabs #fileTabs [displayTabs]="['Source','Reference','Target']" [files]="files"></tp-file-tabs>
    </div>
    <div class="col-lg-9 col-md-9 col-sm-9">
        <div class="quote-job-upload-files-content">
            <tp-drag-and-drop-file [fileType]="fileTabs.activeTab"
                                   [autoUpload]="autoUpload"
                                   [files]="files"
                                   (filesChange)="onFilesChange($event)">
            </tp-drag-and-drop-file>
        </div>
        <div class="py-2">
            <tp-file-list [fileType]="fileTabs.activeTab" [endpoint]="autoUpload.endpoint" [files]="files" (filesChange)="onFilesChange($event)"></tp-file-list>
        </div>
    </div>
</div>
<ng-template #selectFiles>
    <div class="row">
        <div class="col-lg-3 col-md-3 col-sm-3 border-right">
            <tp-file-tabs #selectFileTabs [displayTabs]="['Source','Reference','Target']" [files]="files"></tp-file-tabs>
        </div>
        <div class="col-lg-9 col-md-9 col-sm-9">
            <tp-file-list
                    [fileType]="selectFileTabs.activeTab"
                    [allowSelecting]="select"
                    [allowDeleting]="false"
                    [allowDownloading]="false"
                    [files]="files"
                    (selectedFilesChange)="onSelectedFilesChange($event)">
            </tp-file-list>
        </div>
    </div>
</ng-template>
