import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'

export type StarType = 'empty' | 'half' | 'full' | 'full-red' | 'full-yellow' | 'full-green' | 'half-red' | 'half-yellow' | 'half-green'

export interface EditableStar {
    position: number
    classname?: string
}

@Component({
    selector: 'tp-star-rating',
    templateUrl: './tp-star-rating.component.html',
    styleUrls: ['./tp-star-rating.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TpStarRatingComponent),
            multi: true,
        },
    ],
})
export class TpStarRatingComponent
    implements OnInit, ControlValueAccessor, OnDestroy {
    @Input() maxStars = 5
    @Input() set initialStars(stars: number) {
        if(stars) {
            this._initialStars = stars;
            this.setRating(this.initialStars)
        }
    }
    get initialStars(): number{ return this._initialStars}
    @Input() readonly: boolean=true;
    @Input() size: number
    @Input() color: string
    @Input() animation: boolean
    @Input() animationSpeed = 100
    @Input() customPadding: string
    @Input() wholeStars = false
    @Input() customStarIcons: { empty: string; half: string; full: string }
    _initialStars: number = 0;
    rating: number
    editableStars: EditableStar[]
    animationInterval: any
    animationRunning: boolean

    private customCssClasses: HTMLStyleElement[]
    private customClassIdentifier = Math.random().toString(36).substring(2)

    private static clickedInFirstHalf(event: MouseEvent): boolean {
        const starIcon = event.target as HTMLElement
        return (
            event.pageX <
            starIcon.getBoundingClientRect().left + starIcon.offsetWidth / 2
        )
    }

    propagateChange: (rating: number) => void = (): void => {}

    ngOnInit(): void {
        this.setupStarImages()
        this.editableStars = Array.from(new Array(this.maxStars)).map(
            (elem, index) =>
                ({
                    position: index,
                } as EditableStar)
        )
        this.setRating(this.initialStars)

        if (this.animation) {
            this.animationInterval = setInterval(
                () => this.starAnimation.bind(this),
                this.animationSpeed
            )
        }
    }

    ngOnDestroy(): void {
        // remove the three custom classes we created if custom image urls were provided
        if (this.customCssClasses) {
            this.customCssClasses.forEach((style) => {
                if (style && style.parentNode) {
                    style.parentNode.removeChild(style)
                }
            })
        }
    }

    public registerOnChange(fn: any): void {
        this.propagateChange = fn
    }

    public registerOnTouched(fn: any): void {}

    public setDisabledState(isDisabled: boolean): void {
        this.readonly = isDisabled
    }

    public writeValue(rating: number): void {
        this.rating = Math.round(rating * 2) / 2
        this.onStarsUnhover()
    }

    setRating(rating: number): void {
        this.rating = Math.round(rating * 2) / 2
        this.onStarsUnhover()
        this.propagateChange(this.rating)
    }

    starPadding(): { [p: string]: string } {
        return {
            'margin-right': this.customPadding || `0.${this.safeSize()}rem`,
        }
    }

    starSize(): { [p: string]: string } {
        return {
            height: `${15 * this.safeSize()}px`,
            width: `${16 * this.safeSize()}px`,
        }
    }

    starAnimation(): void {
        this.animationRunning = true
        if (this.rating < this.maxStars) {
            this.setRating((this.rating += 0.5))
        } else {
            this.setRating(0)
        }
    }

    cancelStarAnimation(): void {
        if (this.animationRunning) {
            clearInterval(this.animationInterval)
            this.setRating(0)
            this.animationRunning = false
        }
    }

    onStarHover(event: MouseEvent, clickedStar: EditableStar): void {
        this.cancelStarAnimation()

        const clickedInFirstHalf = TpStarRatingComponent.clickedInFirstHalf(
            event
        )

        this.setRating(
            clickedStar.position +
                (!this.wholeStars && clickedInFirstHalf ? 0.5 : 1)
        );
    }

    onStarClick(event: MouseEvent, clickedStar: EditableStar): void {
        this.cancelStarAnimation()

        // lock in current rating
        const clickedInFirstHalf = TpStarRatingComponent.clickedInFirstHalf(
            event
        )
        this.setRating(
            clickedStar.position +
                (!this.wholeStars && clickedInFirstHalf ? 0.5 : 1)
        )
    }

    // hidden star to left of first star lets user click there to set to 0
    onZeroStarClick(): void {
        this.setRating(0)
    }

    onZeroStarHover(): void {
        // clear all stars
        this.editableStars.forEach(
            (star) => (star.classname = this.getStarClass('empty'))
        )
    }

    onStarsUnhover(): void {
        // when user stops hovering we want to make stars reflect the last rating applied by clicking
        this.editableStars?.forEach((star) => {
            const starNumber = star.position + 1;
            
            if (this.rating >= starNumber) {
                if(this.rating<=2.5){
                    star.classname = this.getStarClass('full-red');
                }
                else if(this.rating>2.5 && this.rating<4){
                    star.classname = this.getStarClass('full-yellow');
                }
                else{
                    star.classname = this.getStarClass('full-green');
                }
            } else if (
                this.rating > starNumber - 1 &&
                this.rating < starNumber
            ) {
                if(this.rating<=2.5){
                    star.classname = this.getStarClass('half-red');
                }
                else if(this.rating>2.5 && this.rating<4){
                    star.classname = this.getStarClass('half-yellow');
                }
                else{
                    star.classname = this.getStarClass('half-green');
                }
            } else {
                star.classname = this.getStarClass('empty');
            }
        })
    }

    noop(): void {}

    private setupStarImages() {
        if (this.customStarIcons) {
            this.customCssClasses = []
            Object.keys(this.customStarIcons)
                .map((key) => key as StarType)
                .forEach((starType) => {
                    const classname = this.getStarClass(starType)
                    this.createCssClass(classname, starType)
                })
        }
    }

    private createCssClass(classname: string, starType: StarType) {
        const clazz = document.createElement('style')
        clazz.type = 'text/css'
        clazz.innerHTML = `.${classname} {
      -webkit-mask-image: url(${this.customStarIcons[starType]});
      mask-image: url(${this.customStarIcons[starType]});
    }`
        document.getElementsByTagName('head')[0].appendChild(clazz)
        this.customCssClasses.push(clazz)
    }

    private getStarClass(starType: StarType) {
        if (this.customCssClasses) {
            return `ngx-stars-star-${starType}-${this.customClassIdentifier}`
        }
        return `star-${starType}`
    }

    private safeSize = () =>
        typeof this.size == 'number' && this.size > 0 && this.size < 6
            ? this.size
            : 1    
}