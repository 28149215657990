import { Injectable } from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {filter, map} from "rxjs/operators";
import {PageTypes} from "../../utills";
import {
    NavigationBuilderService, SidenavMenuItem
} from "./navigation-builder.service";
import {BehaviorSubject, combineLatest} from "rxjs";
import {TpAuthService} from "../auth/tp-auth.service";
import _ from "lodash";
import {TPStorageService} from "../storage/tp-storage.service.ts";
import {StorageKeys} from "../../utills/enums/storage-keys";
@Injectable({
  providedIn: 'root'
})
export class NavigationService {
    public title: string;
    public pageType$: BehaviorSubject<PageTypes> = new BehaviorSubject<PageTypes>(null);
    public activatedItem$: BehaviorSubject<SidenavMenuItem> = new BehaviorSubject<SidenavMenuItem>(null);
    public activatedRootItem$: BehaviorSubject<SidenavMenuItem> = new BehaviorSubject<SidenavMenuItem>(null);
    private exists: boolean = false;
    globalHoverStatus: boolean = false;
    setTimeout: any;
    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private navigationBuilderService: NavigationBuilderService,
        private authService: TpAuthService,
        private storageService: TPStorageService
    ) {

    }
    /**
     * @function subscribeRouterEvents
     * @description subscribe router events
     * @return void
     * */
    public subscribeRouterEvents(): void {
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe((event: NavigationEnd) => {
            this.activeNavigation(event.url);
        });
    }
    /**
     * @function activeNavigation
     * @param url: string = null
     * @param matchManualUrl: boolean = false
     * @description handle active router data
     * @return ActivatedRoute
     * */
    public activeNavigation(url: string = null, matchManualUrl: boolean = false): void {
        let items: SidenavMenuItem[] = [];
        let observableItems = combineLatest(this.navigationBuilderService.topMenuItems$, this.navigationBuilderService.bottomMenuItems$).subscribe(
            ([top, bottom]) => {
                items = [...top, ...bottom];
            }
        );
        observableItems.unsubscribe();
        this.exists = false;
        if(url !== '/unauthorized') {
            this.getActivatedRouteData(items, matchManualUrl ? url : null);
            this.getActivatedRootItem(items, matchManualUrl ? url : null);
        }
    }
    /**
     * @function getLastActivatedRoute
     * @param activatedRoute: ActivatedRoute
     * @description recursive finding last activated route
     * @return ActivatedRoute
     * */
    private getLastActivatedRoute(activatedRoute: ActivatedRoute): ActivatedRoute{
        return (activatedRoute.firstChild) ? this.getLastActivatedRoute(activatedRoute.firstChild) : activatedRoute;
    }
    /**
     * @function getActivatedRouteData
     * @param items: SidenavMenuItem[]
     * @param url: string = null
     * @description recursive finding last activated route data
     * @return void
     * */
    private getActivatedRouteData(items: SidenavMenuItem[], url: string = null): void {
        items.some(item => {
            if (this.isItemActive(item, url)) {
                this.title = item?.data?.title;
                this.pageType$.next(item?.data?.pageType);
                this.activatedItem$.next(item);
                this.exists = true;
            }
            if(item?.items) this.getActivatedRouteData(item.items, url);
        });
        if (!this.exists) {
            this.title = '';
            this.pageType$.next(null);
            this.activatedItem$.next(null);
        }
    }
    /**
     * @function getActivatedRootItem
     * @param items: SidenavMenuItem[]
     * @param url: string = null
     * @description get activated root item
     * @return SidenavMenuItem
     * */
    private getActivatedRootItem(items: SidenavMenuItem[], url: string = null): SidenavMenuItem {
        const activatedRootItem = items.find(item => this.isAnyItemActive([item], url));
        this.activatedRootItem$.next(activatedRootItem);
        return activatedRootItem;
    }
    /**
     * @function replaceUrlParametricParams
     * @param url: string
     * @description replace parametric data defined as in routes :id
     * @return string
     * */
    public replaceUrlParametricParams(url: string): string{
        if(url && url?.includes(':') || this.router.url && this.router.url?.includes('?')) {
            let params: object;
            let queryParams: object;
            let activatedRote = this.getLastActivatedRoute(this.activatedRoute);
            let observableParams = activatedRote.params.subscribe(result => {
                params = result;
            });
            observableParams.unsubscribe();
            let observableQueryParams = activatedRote.queryParams.subscribe(result => {
                queryParams = result;
                if(!_.isEmpty(result)) {
                    if(this.router.url && this.router.url?.includes('?')) {
                        let urlQueryParams = this.router.url.split('?').filter(segment => segment != '')[1];
                        url += '?' + urlQueryParams;
                    }
                }
            });
            observableQueryParams.unsubscribe();
            let segments = url.split('/').filter(segment => segment != '');
            segments = segments.map(segment => {
                if (segment.startsWith(':')) {
                    let paramKey = segment.slice(1);
                    if (params[paramKey]) segment = params[paramKey];
                }
                return segment;
            });
            return '/' + segments.join('/');
        }
        return url;
    }
    /**
     * @function isAnyItemActive
     * @param {SidenavMenuItem[]} items
     * @param url: string = null
     * @description recursive function to check if any item is active
     * @return boolean
     * */
    public isAnyItemActive(items: SidenavMenuItem[], url: string = null): boolean {
        let result: boolean = false;
        for (let item of items) {
            if (item?.items?.length > 0) result = this.isAnyItemActive(item.items, url); // recursive deep dive into n nested items
            else result = this.isItemActive(item, url);
            if (result) break;
        }
        return result;
    }
    /**
     * @function isItemActive
     * @param navItem: SidenavMenuItem
     * @param url: string = null
     * @description check if item is active
     * @return boolean
     * */
    public isItemActive(navItem: SidenavMenuItem, url: string = null): boolean {
        const isActive = url ? url === this.replaceUrlParametricParams(navItem.url) : this.router.isActive(this.replaceUrlParametricParams(navItem.url), true);
        return navItem && isActive;
    }
    /**
     * @function mapBadge
     * @param pageType PageTypes
     * @param badge number
     * @param assignment boolean
     * @description update nav item badge
     * @return void
     * */
    public mapBadge(pageType: PageTypes, badge: number, assignment: boolean = false) {
        this.navigationBuilderService.topMenuItems$ = this.navigationBuilderService.topMenuItems$.pipe(map(items => {
            return items.map(item => {
                if(item.data.pageType === pageType){
                   if(assignment) item.badge += badge;
                   else item.badge = badge;
                   if(item.badge < 0) item.badge = 0;
                }
                return item;
            });
        }));
        this.navigationBuilderService.bottomMenuItems$ = this.navigationBuilderService.bottomMenuItems$.pipe(map(items => {
            return items.map(item => {
                if(item.data.pageType === pageType){
                    if(assignment) item.badge += badge;
                    else item.badge = badge;
                    if(item.badge < 0) item.badge = 0;
                }
                return item;
            });
        }));
        return {topMenuItems$: this.navigationBuilderService.topMenuItems$, bottomMenuItems$: this.navigationBuilderService.bottomMenuItems$};
    }
    /**
     * @function redirectToLogin
     * @description redirect user to login page
     * @return void
     * */
    public redirectToLogin(): void {
        this.storageService.removeItem(StorageKeys.Redirect);
        this.router.navigateByUrl('/login').then();
    }
    /**
     * @function redirectToAdmin
     * @description redirect user to admin page
     * @return void
     * */
    public redirectToAdmin(): void {
      const adminItem = this.navigationBuilderService._buildAdminItem();
      this.router.navigateByUrl(adminItem.url).then();
    }
    /**
     * @function redirectToHome
     * @description redirect user to home page
     * @return void
     * */
    public redirectToHome(): void {
        this.router.navigateByUrl(this.authService.redirectUser()).then();
    }
    /**
     * @function redirectToQuotes
     * @description redirect user to quotes
     * @return void
     * */
    public redirectToQuotes(): void {
        this.router.navigateByUrl(this.navigationBuilderService.buildManagerQuotesNav().url).then();
    }
    /**
     * @function redirectToProjects
     * @description redirect user to projects
     * @return void
     * */
    public redirectToProjects(): void {
        this.router.navigateByUrl(this.navigationBuilderService.buildManagerProjectsNav().url).then();
    }
    /**
     * @function redirectToClients
     * @description redirect user to clients
     * @return void
     * */
    public redirectToClients(): void {
        this.router.navigateByUrl(this.navigationBuilderService.buildManagerClientsNav().url).then();
    }
    /**
     * @function redirectToResources
     * @description redirect user to resources
     * @return void
     * */
    public redirectToResources(): void {
        this.router.navigateByUrl(this.navigationBuilderService.buildManagerResourcesNav().url).then();
    }
    /**
     * @function redirectToRequestAbsences
     * @description redirect user to request absences
     * @return void
     * */
    public redirectToRequestAbsences(): void {
        const requestAbsencesItem = this.navigationBuilderService.buildRequestAbsencesNav();
        this.router.navigateByUrl(requestAbsencesItem.url).then();
    }
    /**
     * @function redirectToProfitVsLossReports
     * @description redirect user to profit vs loss reports
     * @return void
     * */
    public redirectToProfitVsLossReports(): void {
        this.router.navigateByUrl(this.navigationBuilderService.buildProfitVsLossReportNav().url).then();
    }
    /**
     * @function redirectToProfitReports
     * @description redirect user to profit reports
     * @return void
     * */
    public redirectToProfitReports(): void {
        this.router.navigateByUrl(this.navigationBuilderService.buildProfitReportNav().url).then();
    }
    /**
     * @function redirectToQuotesReports
     * @description redirect user to quotes reports
     * @return void
     * */
    public redirectToQuotesReports(): void {
        this.router.navigateByUrl(this.navigationBuilderService.buildQuotesReportNav().url).then();
    }
    /**
     * @function redirectToServicesReports
     * @description redirect user to services reports
     * @return void
     * */
    public redirectToServicesReports(): void {
        this.router.navigateByUrl(this.navigationBuilderService.buildServicesReportNav().url).then();
    }
    /**
     * @function redirectToServicesReports
     * @description redirect user to services reports
     * @return void
     * */
    public redirectToUtilizationReports(): void {
        this.router.navigateByUrl(this.navigationBuilderService.buildUtilizationReportNav().url).then();
    }
    /**
     * @function redirectToServicesReports
     * @description redirect user to services reports
     * @return void
     * */
    public redirectToProductivityReports(): void {
        this.router.navigateByUrl(this.navigationBuilderService.buildProductivityReportNav().url).then();
    }
    /**
     * @function redirectToSalesReports
     * @description redirect user to sales reports
     * @return void
     * */
    public redirectToSalesReports(): void {
        this.router.navigateByUrl(this.navigationBuilderService.buildSalesReportNav().url).then();
    }
    /**
     * @function redirectToClientPaymentsReports
     * @description redirect user to client payments reports
     * @return void
     * */
    public redirectToClientPaymentsReports(): void {
        this.router.navigateByUrl(this.navigationBuilderService.buildClientPaymentReportNav().url).then();
    }
    /**
     * @function redirectToClientPaymentsReports
     * @description redirect user to client payments reports
     * @return void
     * */
    public redirectToResourcePaymentsReports(): void {
        this.router.navigateByUrl(this.navigationBuilderService.buildResourcePaymentReportNav().url).then();
    }
    /**
     * @function redirectToProject
     * @param id: number
     * @description redirect user to project
     * @return void
     * */
    public redirectToProject(id: number): void {
        this.router.navigateByUrl(`/pm/projects/${id}/edit`).then();
    }
    /**
     * @function redirectToRole
     * @param id: number
     * @description redirect user to role
     * @return void
     * */
    public redirectToRole(id: number): void {
        this.router.navigateByUrl(`/pm/admin/roles/${id}/edit`).then();
    }
    /**
     * @function redirectToProject
     * @param id: number | string
     * @description redirect user to project
     * @return void
     * */
    public redirectToEmailTemplateType(id: number | string): void {
        this.router.navigateByUrl(`/pm/admin/email-templates/${id}/edit`).then();
    }
}