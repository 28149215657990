import { Inject, Injectable, Optional } from '@angular/core'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { environment } from 'environments/environment'

/**
 * Config class to be wired into an injector.
 * @see CoreModule#forRoot
 * @see https://angular.io/guide/dependency-injection#optional-dependencies
 */
export class AppConfigServiceConfig {
    uri = ''
}

/**
 * Declaration of config class
 */
export class AppSettings {
    //Your properties here
    readonly production: boolean
    readonly configUrls: AppSettingsUrls
}

export class AppSettingsUrls {
    //Your properties here
    readonly authURL: string
    readonly apiURL: string
    readonly commonApiURL: string
}

/**
 * Global variable containing actual config to use. Initialised via ajax call
 */
export let APP_CONFIG: AppSettings

/**
 * Service class.
 */

@Injectable({
    providedIn: 'root',
})
export class AppConfigService {
    /**
     * Path uri.
     * @type {string}
     * @private
     */
    //private _uri = environment.configUrl;

    /**
     * Endpoint request headers.
     * @type {HttpHeaders}
     */
    private headers = new HttpHeaders({ 'Content-Type': 'application/json' })

    /**
     * Component constructor and DI injection point.
     * @param {HttpClient} http
     * @param {AppConfigsServiceConfig} config
     */
    constructor(
        private http: HttpClient,
        @Optional() config: AppConfigServiceConfig
    ) {
        //if (config) {
        //  this._uri = config.uri;
        //}
    }

    //load(callback: (config: AppConfig) => any) {
    //  this.http.get<AppConfig>(this._uri).subscribe(config => {
    //    this.appConfig = config;
    //    callback(config);
    //  });
    //}

    getOrigin(): string {
        if (!window.location.origin) {
            return (
                window.location.protocol +
                '//' +
                window.location.hostname +
                (window.location.port ? ':' + window.location.port : '')
            )
        } else {
            return window.location.origin
        }
    }

    // public loadSettings() {
    //     return new Promise((resolve, reject) => {
    //         this.http.get('/assets/appsettings.json').subscribe(
    //             (envResponse: any) => {
    //                 const t = new AppSettings()

    //                 //Modify envResponse here if needed (e.g. to ajust parameters for https,...)
    //                 APP_CONFIG = Object.assign(t, envResponse)
    //                 resolve(APP_CONFIG)
    //             },
    //             (error) => {
    //                 reject(true)
    //                 return Observable.throw('Server error')
    //             }
    //         )
    //     })
    // }

    public loadSettings() {
        return  this.http.get('/assets/appsettings.json').pipe(map((envResponse) => {
            const t = new AppSettings()
            APP_CONFIG = Object.assign(t, envResponse);
            return APP_CONFIG
        }));
    }

    /**
     * Pulls a single AppConfig object.
     * @param {number | string} id to retrieve.
     * @returns {Observable<AppConfig>}
     */
    getAppSettings(): AppSettings {
        return APP_CONFIG
    }

    getAuthUrl(): string {
        return environment.authUrl;
    }

    getAPIUrl(): string {
       return environment.apiUrl;
    }

    getCommonAPIUrl(): string {
        //return APP_CONFIG.configUrls.commonApiURL
        return environment.commonApiUrl;
    }
}