<div>
    <label *ngIf="label" id="example-radio-group-label">{{ label }}</label>
    
    <ng-container
        *ngIf="
            filteredErrors?.length > 0;
            then backendErrors;
            else frontendErrors
        "
    ></ng-container>
    
    <ng-template #backendErrors>
        <mat-error *ngIf="filteredErrors[0] as err">
            <span>
                <ng-container *ngIf="useTranslation">
                    {{ err.errorCode | translate: err.errorParameters }}
                </ng-container>
                <ng-container *ngIf="!useTranslation">
                    {{ err.errorDescription }}
                </ng-container>
            </span>
        </mat-error>
    </ng-template>
    
    <ng-template #frontendErrors>
        <mat-error *ngIf="showFormError">
            <span>{{ formErrors[0] }}</span>
        </mat-error>
    </ng-template>
    
    
    <ng-container *ngIf="control; then reactive; else nonReactive"></ng-container>
    
    <ng-template #reactive>
        <mat-radio-group
            [formControl]="control"
            color="primary"
            class="radio-group"
            fxLayout="row wrap"
            (change)="onSelect($event)"
        >
            <mat-radio-button
                class="radio-button"
                *ngFor="let option of options"
                [value]="option.value"
            >
                <ng-container *ngIf="option.textAsync">
                    {{ option.textAsync | async }}
                </ng-container>
                
                <ng-container *ngIf="!option.textAsync">
                    {{ option.text }}
                </ng-container>
            </mat-radio-button>
        </mat-radio-group>
    </ng-template>
    
    <ng-template #nonReactive>
        <mat-radio-group
            [(ngModel)]="latestWrittenValue" (ngModelChange)="writeValue($event)" (change)="onSelect($event)"
            [ngModelOptions]="{ standalone: true }"
            color="primary"
            class="radio-group"
            fxLayout="row wrap"
            (selectionchange)="onSelect($event)"
        >
            <mat-radio-button
                class="radio-button"
                *ngFor="let option of options"
                [value]="option.value"
            >
                <ng-container *ngIf="option.textAsync">
                    {{ option.textAsync | async }}
                </ng-container>
                
                <ng-container *ngIf="!option.textAsync">
                    {{ option.text }}
                </ng-container>
            </mat-radio-button>
        </mat-radio-group>
    </ng-template>


</div>