import { Injectable, ViewContainerRef } from '@angular/core'

export type CreateOrDestroyListenerCallback = (view: ViewContainerRef) => void

@Injectable({
    providedIn: 'root',
})
export class TpComponentService {
    createListeners: CreateOrDestroyListenerCallback[] = []
    destroyListeners: CreateOrDestroyListenerCallback[] = []

    registerCreateContainerListener(fn: CreateOrDestroyListenerCallback): void {
        this.createListeners.push(fn)
    }

    registerDestroyContainerListener(
        fn: CreateOrDestroyListenerCallback
    ): void {
        this.destroyListeners.push(fn)
    }

    createContainer(container: ViewContainerRef): void {
        this.createListeners.forEach((fn) => {
            fn(container)
        })
    }

    destroyContainer(container: ViewContainerRef): void {
        this.destroyListeners.forEach((fn) => {
            fn(container)
        })
    }

    clearContainerListeners(): void {
        this.createListeners = []
        this.destroyListeners = []
    }
}