import { Injectable } from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as ClientRatesActions from "../../actions/client-actions/client-rate.actions";
import {catchError, concatMap, filter, map, mergeMap, share, switchMap, withLatestFrom} from "rxjs/operators";
import {of} from "rxjs";
import {ClientService} from "../../../services/clients/client.service";
import {NotificationService} from "../../../../../services/notification.service";
import {Store} from "@ngrx/store";
import {State} from "../../../../../store";
import {clientRatesEntity} from "../../reducers";
@Injectable()
export class ClientRateEffects {

    getClientRates$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ClientRatesActions.getClientRates),
            mergeMap((action) => of(action).pipe(withLatestFrom(this.store.select(clientRatesEntity.selectors.selectByPredicate((entity) => entity.clientId == action.clientId))))),
            filter(([action, rates]) => !(rates?.length > 0)),
            switchMap(([action, rates]) =>
                this.clientService.getClientPriceListByClientId(action.clientId).pipe(
                    map(response => {
                        return clientRatesEntity.actions.upsertMany({entities: response.response});
                    }),
                    catchError(error => {
                        return of(clientRatesEntity.actions.updateAdditional({updates:{error}}));
                    })
                )
            ),
            share()
        );
    });
    addClientRate$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ClientRatesActions.addClientRate),
            concatMap((action) =>
                this.clientService.addClientPriceList(action).pipe(
                    map(response => {
                        this.notificationService.showSuccess('Saved', 'Client Rates Saved Successfully');
                        return clientRatesEntity.actions.addMany({entities: response.response});
                    }),
                    catchError(error => {
                        return of(clientRatesEntity.actions.updateAdditional({updates:{error}}));
                    })
                )
            ),
            share()
        );
    });
    updateClientRate$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ClientRatesActions.updateClientRate),
            concatMap((action) =>
                this.clientService.updateClientPriceList(action).pipe(
                    map(response => {
                        this.notificationService.showSuccess('Saved', 'Client Rate Saved Successfully');
                        this.store.dispatch(clientRatesEntity.actions.removePredicate({predicate: (rate) => rate?.id == action?.id}));
                        return clientRatesEntity.actions.upsertMany({entities: response.response});
                    }),
                    catchError(error => {
                        return of(clientRatesEntity.actions.updateAdditional({updates:{error}}));
                    })
                )
            ),
            share()
        );
    });
    updateClientRates$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ClientRatesActions.updateClientRates),
            concatMap((action) =>
                this.clientService.bulkUpdateClientPriceList(action.rates).pipe(
                    map(response => {
                        this.notificationService.showSuccess('Saved', 'Client Rates Saved Successfully');
                        this.store.dispatch(clientRatesEntity.actions.removePredicate({predicate: (rate) => rate?.clientId == action?.rates[0]?.clientId}));
                        return clientRatesEntity.actions.upsertMany({entities: response.response});
                    }),
                    catchError(error => {
                        return of(clientRatesEntity.actions.updateAdditional({updates:{error}}));
                    })
                )
            ),
            share()
        );
    });
    deleteClientRate$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ClientRatesActions.deleteClientRate),
            concatMap((action) =>
                this.clientService.deleteClientPriceById(action.id).pipe(
                    map(response => {
                        this.notificationService.showSuccess('Deleted', 'Client Rate Deleted Successfully');
                        return clientRatesEntity.actions.removeOne({id: action.id});
                    }),
                    catchError(error => {
                        return of(clientRatesEntity.actions.updateAdditional({updates:{error}}));
                    })
                )
            ),
            share()
        );
    });
    constructor(
        private actions$: Actions,
        private clientService: ClientService,
        private notificationService: NotificationService,
        private store: Store<State>
    ) {}

}