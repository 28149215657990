import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ResponseQuoteJobData} from "../../../../models/general/quote/response-models/response-quote-job-data";
import {TpValidationError} from "../../../../models";
import {TpGridTableMetaData} from "../../../../components/tp-grid/tp-grid.component";
import {TranslateService} from "@ngx-translate/core";
import {ResponseClientJobData} from "../../projects/models/response-models/response-client-job-data";
import {ResponseClientData} from "../../clients/models";
import {DropDownItem} from "../../../../models/general/dropdownItem.model";
import {RefDataService} from "../../../../services/general/refData.service";
import {JobPriceDirection} from "../../quotes/shared/quote-job/quote-job.component";
import {CalculateTotalsService} from "../../../../services/calculate-totals/calculate-totals.service";
import {Store} from "@ngrx/store";
import {State} from "../../../../store";
import {unitDropdownEntity} from "../../../../store/reducers";

export interface ChangedUnitJobCombinationEvent{
    job: ResponseQuoteJobData,
    unitId: number
}

export interface ChangedRateJobCombinationEvent{
    job: ResponseQuoteJobData,
    rate: number
}

export enum CombinationsDirection {
    clientJobs = 'clientJobs',
    quoteJobs = 'quoteJobs'
}

export interface ChangedRateClientJobCombinationEvent{
    job: ResponseClientJobData,
    rate: number
}

export interface ChangedUnitClientJobCombinationEvent{
    job: ResponseClientJobData,
    unitId: number
}


@Component({
  selector: 'tp-job-combinations-grid',
  templateUrl: './job-combinations-grid.component.html',
  styleUrls: ['./job-combinations-grid.component.scss']
})
export class JobCombinationsGridComponent implements OnInit {

    @Input() set quoteJobs(value: ResponseQuoteJobData[]) {
        if (value) {
            this._quoteJobs = value.map((val, i) => {
                (val as any).index = i;
                (val as any).hasCustomValue = false;
                return val;
            });
        }
    };
    @Input() clientJobs: ResponseClientJobData[] = [];
    @Input() direction: keyof typeof CombinationsDirection = CombinationsDirection.quoteJobs;
    @Input() client: ResponseClientData;

    @Output() changedUnit: EventEmitter<ChangedUnitJobCombinationEvent> = new EventEmitter<ChangedUnitJobCombinationEvent>();
    @Output() changedRate: EventEmitter<ChangedRateJobCombinationEvent> = new EventEmitter<ChangedRateJobCombinationEvent>();
    @Output() changedClientJobUnit: EventEmitter<ChangedUnitClientJobCombinationEvent> = new EventEmitter<ChangedUnitClientJobCombinationEvent>();
    @Output() changedClientJobRate: EventEmitter<ChangedRateClientJobCombinationEvent> = new EventEmitter<ChangedRateClientJobCombinationEvent>();
    @Output() addPrice = new EventEmitter();

    validationErrors: TpValidationError[] = [];
    _quoteJobs: ResponseQuoteJobData[] = [];

    combinationJobsConfig: TpGridTableMetaData = {
        gridId: 'quote-jobs-creation',
        containsLevel2: false,
        containsActionColumn: false,
        displayedColumns: [
            'serviceDescription',
            'volume',
            'unit',
            'rate',
            'vat',
            'discount',
        ],
        columns: [
            {
                sortable: false,
                isTemplate: true,
                dataField: 'serviceDescription',
                title: this.translateService.stream('tp-description', { defaultText: 'Description' }),
            },
            {
                sortable: false,
                isTemplate: true,
                dataField: 'volume',
                title: this.translateService.stream('tp-volume', { defaultText: 'Volume' }),
            },
            {
                sortable: false,
                isTemplate: true,
                dataField: 'unit',
                title: this.translateService.stream('tp-unit', { defaultText: 'Unit' }),
            },
            {
                sortable: false,
                isTemplate: true,
                dataField: 'rate',
                title: this.translateService.stream('tp-rate', { defaultText: 'Rate' }),
            },
            {
                sortable: false,
                isTemplate: true,
                dataField: 'vat',
                title: this.translateService.stream('tp-vat', { defaultText: 'Vat' }),
            },
            {
                sortable: false,
                isTemplate: true,
                dataField: 'discount',
                title: this.translateService.stream('tp-discount', { defaultText: 'Discount' }),
            },
        ],
    };
    _units: DropDownItem[] = [];
    constructor(
        private translateService: TranslateService,
        private refData: RefDataService,
        private totalsService: CalculateTotalsService,
        private store: Store<State>
    ) {
        this.store.select(unitDropdownEntity.selectors.selectAll).subscribe(units => this._units = units);
    }

    ngOnInit(): void {}

    onChangedUnit(job: ResponseQuoteJobData, unitId: number): void{
        //update loaded array
        var index=this._quoteJobs.indexOf(job);

        if(index>-1){
            this._quoteJobs[index].unitId=unitId;
            this._quoteJobs[index].unitDescription=this._units.filter(x=>x.id==unitId)[0]?.name;
        }

        this.changedUnit.emit({job, unitId});
    }

    onChangedClientJobUnit(job: ResponseClientJobData, unitId: number): void{
        this.changedClientJobUnit.emit({job, unitId});
    }

    onChangeRate(job: any, event): void {
        this.changedRate.emit({job, rate: event.target.value});
        this.calculateTotal(job);
    }
    onChangeClientJobRate(job: ResponseClientJobData, event): void {
        this.changedClientJobRate.emit({job, rate: event.target.value});
        this.calculateTotal(job);
    }
    onChangeDiscountOrSurcharge(job): void {
        const discountOrSurchargeValues = this.totalsService.discountOrExtraChargeValues(job?.discountOrSurcharge);
        job.discount = discountOrSurchargeValues.discount;
        job.extraCharge = discountOrSurchargeValues.extraCharge;
        this.calculateTotal(job);
    }
    /**
     * @function calculateTotal
     * @param job
     * @description calculate total amount of job
     * @return void
     * */
    calculateTotal(job): void {
        job.totalAmount = this.totalsService.totalAmount({...job});
    }

    addToServicePrice(rowData): void{ 
        //check to send correct rate
        var calcRate:number=0;

        if(rowData?.priceData?.direction==JobPriceDirection.custom){
            calcRate=rowData?.rate;
        }
        else {
            if(rowData?.priceData?.conversion?.targetRate!=null && rowData?.priceData?.conversion?.targetRate!=undefined){
                calcRate=rowData?.rate;
            }
            else{
                calcRate=rowData?.priceData?.rate;
            }
        }

        this.addPrice.emit({
            rate: calcRate,
            targetLanguageId: rowData.targetLanguageId,
            unitId:rowData.unitId
        });
    }

    isPositiveRate(rate: number): boolean{
        return (Number(rate) > 0);
    }

    isDirectionQuoteJobs(): boolean{
        return (this.direction === CombinationsDirection.quoteJobs);
    }
    isClientPrice(direction: string): boolean {
        return (direction === JobPriceDirection.clientPriceList);
    }
    isCustomPrice(direction: string): boolean {
        return (direction === JobPriceDirection.custom);
    }

}