import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileListComponent } from './file-list.component';
import {MatIconModule} from "@angular/material/icon";
import {FilterPipeModule} from "../../../pipes/filter/filter-pipe.module";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {TpProgressBarModule} from "../../../components/tp-progress-bar/tp-progress-bar.module";
import {TpCheckboxModule} from "../../../components/tp-checkbox/tp-checkbox.module";
import {FormsModule} from "@angular/forms";
import {MatTooltipModule} from "@angular/material/tooltip";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
    declarations: [
        FileListComponent
    ],
    exports: [
        FileListComponent
    ],
    imports: [
        CommonModule,
        MatIconModule,
        FilterPipeModule,
        MatProgressBarModule,
        TpProgressBarModule,
        TpCheckboxModule,
        FormsModule,
        MatTooltipModule,
        TranslateModule
    ]
})
export class FileListModule { }