import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { TpPDFViewerComponent } from 'app/components/tp-pdf-viewer/tp-pdf-viewer.component'
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer'

@NgModule({
    declarations: [TpPDFViewerComponent],
    imports: [CommonModule, NgxExtendedPdfViewerModule],
    exports: [TpPDFViewerComponent],
})
export class TpPdfViewerModule {}