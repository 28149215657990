import { NgModule } from '@angular/core';
import { FormatNumberWithCurrencyPipe } from 'app/pipes/format-number-with-currency-pipe/format-number-with-currency.pipe';


@NgModule({
    declarations: [FormatNumberWithCurrencyPipe],
    providers: [FormatNumberWithCurrencyPipe],
    exports: [FormatNumberWithCurrencyPipe],
})
export class FormatNumberWithCurrencyPipeModule {
}