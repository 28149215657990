import {Pipe, PipeTransform} from '@angular/core';
import {GlobalConfiguration} from 'app/models/general/global-configuration';
import {Observable, of} from 'rxjs';
import {NumberOfDecimalTypes} from "../../utills";
import { UtilsService } from 'app/services/utils';

@Pipe({
    name: 'formatNumber',
})
export class FormatNumberPipe implements PipeTransform {
    public defaultNoOfDecimals = 2;
    public defaultThousandSeparator = ',';
    public defaultDecimalSeparator = '.';
    constructor(
        private globalConfiguration:GlobalConfiguration,
        private utilService:UtilsService
    ) {}
    /**
     * @function transform
     * @description transform number pipe
     * @param value number
     * @param noOfDecimals number | 'default'
     * @return Observable<string>
     * */
    transform(value: number | string, noOfDecimals: NumberOfDecimalTypes | number = NumberOfDecimalTypes.Default): Observable<string> {
        const number = Number(value);
        if (number || number == 0) return of(this.formatNumber(number, this.getDecimalPlaces(noOfDecimals)));
        else return of('---');
    }
    /**
     * @function transformSync
     * @description transform number pipe
     * @param value number
     * @param noOfDecimals number | 'default'
     * @return Observable<string>
     * */
    transformSync(value: number | string, noOfDecimals: NumberOfDecimalTypes | number = NumberOfDecimalTypes.Default): string {
        const number = Number(value);
        if (number || number == 0) return this.formatNumber(number, this.getDecimalPlaces(noOfDecimals));
        else return '---';
    }
    transformDecimalPlaces(value: number | string, noOfDecimals: NumberOfDecimalTypes | number = NumberOfDecimalTypes.Default): number {
        const number = Number(value);
        if (number || number == 0) {
            return Number(this.utilService.formatNumberBasedOnCompanySettings(number,null, this.getDecimalPlaces(noOfDecimals), true)) ?? 0;
        }
        else return 0;
    }
    /**
     * @function formatNumber
     * @description format number based on branch settings
     * @param number number
     * @param noOfDecimals number
     * @return string
     * */
    private formatNumber(number: number, noOfDecimals: number): string {
        // const branchSettingsFinance = this.globalConfiguration?.companyBranchSetting?.companyBranchFinanceData;
        // let numberParts = new Intl.NumberFormat().formatToParts(number).map((p) => p.value);
        // if(numberParts.includes('.') && numberParts[numberParts.length - 1].length > noOfDecimals) {
        //     numberParts.pop();
        //     numberParts.push(number.toFixed(noOfDecimals).split('.')[1]);
        // }
        // return  numberParts.join('')
        //     .replace(',', branchSettingsFinance?.thousandSeparator ?? this.defaultThousandSeparator)
        //     .replace('.', branchSettingsFinance?.decimalSeparator ?? this.defaultDecimalSeparator);
        
        return this.utilService.formatNumberBasedOnCompanySettings(number,this.globalConfiguration.companyBranchSetting.companyBranchFinanceData,noOfDecimals);
    }
    /**
     * @function getDecimalPlaces
     * @description get how many decimal places show be shown
     * @param noOfDecimals number | 'default'
     * @return number
     * */
    private getDecimalPlaces(noOfDecimals?: NumberOfDecimalTypes | number): number{
        const branchSettings = this.globalConfiguration?.companyBranchSetting;
        let decimalPlaces = this.defaultNoOfDecimals;
        if (noOfDecimals || noOfDecimals === 0){
            if(noOfDecimals === NumberOfDecimalTypes.Default) decimalPlaces = this.defaultNoOfDecimals;
            else if(noOfDecimals === NumberOfDecimalTypes.System) decimalPlaces = branchSettings?.noOfDecimals;
            else decimalPlaces = noOfDecimals;
        }
        else if (branchSettings?.noOfDecimals) decimalPlaces = branchSettings?.noOfDecimals;
        return decimalPlaces;
    }
}