import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatFormFieldModule } from '@angular/material/form-field'
import { TranslateModule } from '@ngx-translate/core'
import { TpCheckboxComponent } from 'app/components/tp-checkbox/tp-checkbox.component'

@NgModule({
    declarations: [TpCheckboxComponent],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		MatFormFieldModule,
		TranslateModule,
		MatCheckboxModule,
		FormsModule,
	],
    exports: [TpCheckboxComponent],
})
export class TpCheckboxModule {}