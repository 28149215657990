import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core'
import { TpPhotoUploadComponent } from './tp-photo-upload.component';
import {CropImageModalModule} from "../crop-image/crop-image-modal/crop-image-modal.module";

@NgModule({
    declarations: [TpPhotoUploadComponent],
    imports: [
        CommonModule,
        MatFormFieldModule,
        TranslateModule,
        ReactiveFormsModule,
        FlexModule,
        MatIconModule,
        CropImageModalModule
    ],
    exports: [TpPhotoUploadComponent],
})
export class TpPhotoUploadModule {}