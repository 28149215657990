import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TpAutocompleteDropdownModule } from 'app/components/tp-autocomplete-dropdown/tp-autocomplete-dropdown.module';
import { ResourceSearchComponent } from 'app/main/sharedComponents/resource-search/resource-search.component';

@NgModule({
    declarations: [ResourceSearchComponent],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		TpAutocompleteDropdownModule,
		TranslateModule,
		FormsModule,
	],
    exports: [
        ResourceSearchComponent,
    ],
})
export class ResourceSearchModule {
}