import { Injectable } from '@angular/core';
import { ResponseCompanyBranchFinanceData } from 'app/main/admin/models/response-models/response-company-branch-finance-data';
import { addSeconds } from 'date-fns';

@Injectable({
    providedIn: 'root',
})
export class UtilsService {
    
    scrollToTop(id) {
        setTimeout(() => {
          const objDiv = document.getElementById(id);
          if (objDiv) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }
        }, 500);
    }

    scrollToElement(id) {
        setTimeout(() => {
          const objDiv = document.getElementById(id);
          if (objDiv) {
            objDiv.scrollIntoView({ block: 'end',  behavior: 'smooth' });
          }
        }, 500);
    }

    /***
     * @description function that strips all html tags from a string
     * @param str string
     * @returns string
     */
    removeTags(str) {
      if ((str===null) || (str===''))
          return false;
      else
          str = str.toString();
            
      // Regular expression to identify HTML tags in 
      // the input string. Replacing the identified 
      // HTML tag with a null string.
      return str.replace( /(<([^>]+)>)/ig, '');
    }

    /***
     * @description function that calculates a future date by adding given seconds top a starting date
     * @param seconds number
     * @param startDate Date
     * @returns Date
     */
     addSecondsToDate(seconds:number,startDate:Date):Date {
      return addSeconds(new Date(startDate), seconds);
    }

    /**
     * 
     * @param f function that takes a file as parameters and returns it's extension
     * @returns 
     */
    getFileExtension(f:File){
        return this.getFileExtensionByName(f.name);
    }
    /**
     *
     * @param f function that takes a file as parameters and returns it's extension
     * @returns
     */
    getFileExtensionByName(name: string){
        if(name){
            var a = name.split(".");
            if( a.length === 1 || ( a[0] === "" && a.length === 2 ) ) {
                return "";
            }
            return a.pop();
        }
        return '';
    }
    /**
     *
     * @param f function that takes a file as parameters and returns it's extension
     * @returns
     */
    getFileName(name: string){
        if(name){
            var a = name.split(".");
            if( a.length === 0 ) return "";
            return a.shift();
        }
        return '';
    }

     /**
     * @description converts a number with comma to number with dot
     * @param num:string
     * @param allowNegativeValue:boolean (default false)
     * @returns number
     */
     convertCommaToDot(num:string,allowNegativeValue:boolean=false):number{
      var res:number=0;
      var re = /,/gi; 
      var nnre = /-/gi;       

      if(num && this.isNumber(num)){  
        
        if(allowNegativeValue==true){
          num=num.replace(re,'.').replace('+','');
        }
        else{
          num=num.replace(re,'.').replace(nnre,'').replace('+','');
        }

        res=Number(num);
      }
      return res; 
    }

    /**
     * @description checks if a string is numeric or not
     * @param num:string
     * @returns boolean
     */
    isNumber(input:string):boolean{
      var pattern = /^[-+]?\d+\,?\.?\d*$/;
      return pattern.test(input);
    }

    /**
     * @description formats a number based on companby settings
     * @param num:number
     * @param companyBranchFinanceData:ResponseCompanyBranchFinanceData
     * @param overideNoOfDecimals:number
     * @returns string
     */
    formatNumberBasedOnCompanySettings(
        num:number,
        companyBranchFinanceData:ResponseCompanyBranchFinanceData | null,
        overideNoOfDecimals:number,
        ignoreThousandSeparator: boolean = false
    ):string {
        var res:string='';
        var tempRes:string='';
        var noOfDecimals:number=overideNoOfDecimals ?? 2;
        var thousandSeparator:string = ignoreThousandSeparator ? '' : ',';
        var decimalSeparator:string='.';
        var isNegative:boolean=false;

        if(num<0){
          isNegative=true;
        }

        if(companyBranchFinanceData){
            if(overideNoOfDecimals && overideNoOfDecimals>0){
              noOfDecimals=overideNoOfDecimals;
            }
            else{
              noOfDecimals=companyBranchFinanceData?.noOfDecimals;
            }
            if(!ignoreThousandSeparator) thousandSeparator=companyBranchFinanceData?.thousandSeparator;
            decimalSeparator=companyBranchFinanceData?.decimalSeparator;
        }

        //make conversions + calculations on the absolute value of the number and 
        //we will assign the neagtive sign in the end
        tempRes=Math.abs(num).toFixed(noOfDecimals);

        var decimalSeparatorIndex=tempRes.indexOf('.');

        if(decimalSeparatorIndex>-1){
          var intPart=tempRes.substring(0,decimalSeparatorIndex);
          var decimalPlaces=tempRes.substring(decimalSeparatorIndex+1);

          //determine how many digits this number has in it's integer part
          var digits=intPart?.length;
          
          //if digits more than 3 then thousands separator will be needed
          if(digits>3){
              var digitCounter:number=1;

              for(var i:number=intPart.length-1;i>=0;i--){
                  if(digitCounter>3){
                      res=intPart[i]+thousandSeparator+res;
                      digitCounter=1;
                  }
                  else{
                      res=intPart[i]+res;
                  }
                  
                  digitCounter++;
              }
          }
          else{
              res=intPart;
          }            
          
          if(isNegative==true){
            res='-' + res;
          }

          //add decimals
          res=res+((Number(decimalPlaces)==0)
                                   ?''
                                   :decimalSeparator+decimalPlaces);          
      }

      return res;
    }
}