import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core'
import { TpCountryDropDownComponent } from 'app/components/tp-country-dropdown/tp-country-dropdown.component'
import { TpDropdownModule } from 'app/components/tp-dropdown/tp-dropdown.module'
import { TpMultiselectDropdownModule } from 'app/components/tp-multiselect-dropdown/tp-multiselect-dropdown.module'

@NgModule({
    declarations: [TpCountryDropDownComponent],
	imports: [
		CommonModule,
		TranslateModule,
		ReactiveFormsModule,
		TpDropdownModule,
		TpMultiselectDropdownModule,
		FormsModule,
	],
    exports: [TpCountryDropDownComponent],
})
export class TpCountryDropdownModule {}