import {SearchCriteriaModel} from "../../models";
import {EntityAdditional, entityInitial} from "./entity-additional";
export interface EntitySearchAdditional<C> extends EntityAdditional{
    criteria?: SearchCriteriaModel<C>
    count?: number;
    currentPage?: number;
}
export const entitySearchInitial = <C>(overwriteCriteria: SearchCriteriaModel<C> = {}, overwrite: EntityAdditional = {}): EntitySearchAdditional<C> => {
    let defaultCriteria = {take: 20, skip: 0, searchCriteria: {}, searchFormValue: {}};
    return {
        ...entityInitial(overwrite),
        ...{criteria: {...defaultCriteria, ...overwriteCriteria}, count: null, currentPage: 0}
    }
}