import { NgModule } from '@angular/core';
import { UnitNamePipe } from 'app/pipes/unit-name-pipe/unit-name.pipe';


@NgModule({
    declarations: [UnitNamePipe],
    providers: [UnitNamePipe],
    exports: [UnitNamePipe],
})
export class UnitNamePipeModule {
}