import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { TranslateModule } from '@ngx-translate/core'
import { TpDropdownModule } from 'app/components/tp-dropdown/tp-dropdown.module'
import { TpLanguagesSupportedDropDownComponent } from 'app/components/tp-languages-supported-dropdown/tp-languages-supported-dropdown.component'
import { TpMultiselectDropdownModule } from '../tp-multiselect-dropdown/tp-multiselect-dropdown.module'

@NgModule({
    declarations: [TpLanguagesSupportedDropDownComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TpDropdownModule,
        TranslateModule,
        TpMultiselectDropdownModule,
    ],
    exports: [TpLanguagesSupportedDropDownComponent],
})
export class TpLanguagesSupportedDropdownModule {}