import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { TranslateModule } from '@ngx-translate/core'
import { TpCurrencyDropDownComponent } from 'app/components/tp-currency-dropdown/tp-currency-dropdown.component'
import { TpDropdownModule } from 'app/components/tp-dropdown/tp-dropdown.module'
import { TpMultiselectDropdownModule } from 'app/components/tp-multiselect-dropdown/tp-multiselect-dropdown.module'
import { RefDataService } from 'app/services/general/refData.service'

@NgModule({
    declarations: [TpCurrencyDropDownComponent],
    imports: [
        CommonModule,
        TpDropdownModule,
        ReactiveFormsModule,
        TranslateModule,
        TpMultiselectDropdownModule,
    ],
    providers: [RefDataService],
    exports: [TpCurrencyDropDownComponent],
})
export class TpCurrencyDropdownModule {}