<div class="inline-editing-content w-100 cursor-pointer" *ngIf="!isInlineEditing" (click)="onEditing($event)">
    <div class="label-text">
       <span class="small-text">{{label}}</span>
    </div>
    <div class="editing-text">
        <span *ngIf="control">
            {{control.value}}
        </span>
        <span *ngIf="!control">
            {{latestWrittenValue}}
        </span>
    </div>
</div>

<mat-form-field tpAutoClick [allowAutoClick]="enableInlineEditing" [appearance]="appearance" [class.bg-theme]="addBgTheme" *ngIf="isInlineEditing" (clickOutside)="onClickOutside($event)" [attachOutsideOnClick]="true">
    <mat-label [class.font-weight-semibold]="required && !readOnly">{{ label }}</mat-label>
    <input tpAutoFocus [allowAutofocus]="enableInlineEditing" *ngIf="control" [formControl]="control" matInput
           [errorStateMatcher]="errorStateMatcher" [type]="inputType" [name]="control" [placeholder]="label"
           (keydown.enter)="emitEvent()" [required]="required" [autocomplete]="autoComplete"
    />
    <input tpAutoFocus [allowAutofocus]="enableInlineEditing" matInput *ngIf="!control" [(ngModel)]="latestWrittenValue" (ngModelChange)="writeValue($event)"
           [ngModelOptions]="{ standalone: true }" [errorStateMatcher]="errorStateMatcher" [type]="inputType" [name]="latestWrittenValue"
           [autocomplete]="autoComplete" [placeholder]="label" [required]="required" [disabled]="disabled"
    />    
    <span *ngIf="prefix && !prefix.isIcon" matPrefix class="pr-2">{{ prefix.content }}</span>
    <mat-icon *ngIf="prefix && prefix.isIcon" matPrefix class="prefix-or-suffix-icon">
        {{ prefix.content }}
    </mat-icon>
    <span *ngIf="suffix && !suffix.isIcon" matSuffix>{{ suffix.content }}</span>
    <mat-icon *ngIf="suffix && suffix.isIcon" matSuffix class="prefix-or-suffix-icon">
        {{ suffix.content }}
    </mat-icon>
    
    <ng-container *ngIf="filteredErrors?.length > 0; then backendErrors;else frontendErrors"></ng-container>
    <ng-template #backendErrors>
        <mat-error *ngIf="filteredErrors[0] as err">
            <span>
                <ng-container *ngIf="useTranslation">
                    {{ err.errorCode | translate: err.errorParameters }}
                </ng-container>
                <ng-container *ngIf="!useTranslation">
                    {{ err.errorDescription }}
                </ng-container>
            </span>
        </mat-error>
    </ng-template>
    <ng-template #frontendErrors>
        <mat-error *ngIf="showFormError">
            <span>{{ formErrors[0] }}</span>
        </mat-error>
    </ng-template>
    <div class="inline-editing-actions position-absolute" *ngIf="enableInlineEditing">
        <tp-button buttonStyle="icon" iconClass="ion-ios-checkmark" (buttonClicked)="onMarkEditing($event)" [matTooltip]="'Save'"></tp-button>
        <tp-button class="pl-1" buttonStyle="icon" iconClass="ion-ios-close" (buttonClicked)="onCloseEditing($event)" [matTooltip]="'Close'"></tp-button>
        <ng-content select="[inlineEditingActions]"></ng-content>
    </div>
    <mat-hint *ngIf="hint">{{hintText}}</mat-hint>
</mat-form-field>