import { Pipe, PipeTransform } from '@angular/core';
import { GlobalConfiguration } from 'app/models/general/global-configuration';
import { FormatNumberWithCurrencyPipe } from 'app/pipes/format-number-with-currency-pipe/format-number-with-currency.pipe';
import { RefDataService } from 'app/services/general/refData.service';
import { combineLatest, Observable, of } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { UnitNamePipe } from '../unit-name-pipe/unit-name.pipe';

@Pipe({
    name: 'serviceName',
})
export class ServiceNamePipe implements PipeTransform {

    noOfDecimalsForRate = 0;

    constructor(private refDataService: RefDataService,
                private formatNumberWithCurrencyPipe: FormatNumberWithCurrencyPipe,
                private readonly unitNamePipe: UnitNamePipe,
                private globalConfiguration:GlobalConfiguration
                ) {
                    this.noOfDecimalsForRate=this.globalConfiguration?.companyBranchSetting?.noOfDecimals;
                    // refDataService.getCurrentUserCompanyBranchSetting().pipe(first()).subscribe(data => {
                    //     this.noOfDecimalsForRate = data.response.noOfDecimals;
                    // });
    }

    transform(
        serviceId: number,
        sourceLanguage?: number | number[],
        targetLanguage?: number | number[],
        price?: number,
        currency?: string,
        unit?: number,
    ): Observable<string> {
        return this.refDataService.getServices().pipe(
            switchMap((services) => {
                const service = services.response.find((f) => f.id == serviceId);
                if (service) {
                    return this.getFormattedName(
                        service.name,
                        sourceLanguage,
                        targetLanguage,
                        price,
                        currency,
                        unit,
                    );
                } else {
                    return of('---');
                }
            }),
        );
    }

    getFormattedName(
        name: string,
        sourceLanguage?: number | number[],
        targetLanguage?: number | number[],
        price?: number,
        currency?: string,
        unit?: number,
    ): Observable<string> {
        let formattedServiceName = `${name}`;

        if (sourceLanguage) {
            formattedServiceName = formattedServiceName + ` ${Array.isArray(sourceLanguage) ? sourceLanguage.join(', ') : sourceLanguage}`;
        }

        if (targetLanguage) {
            formattedServiceName = formattedServiceName + ` -> ${Array.isArray(targetLanguage) ? targetLanguage.join(', ') : targetLanguage}`;
        }

        if (price) {
            return combineLatest([
                this.formatNumberWithCurrencyPipe.transform(price, currency, this.noOfDecimalsForRate),
                unit ? this.unitNamePipe.transform(unit) : of(''),
            ])
                .pipe(
                    map(([priceWithCurrency, unitName]) => {
                        formattedServiceName = formattedServiceName + ` (${priceWithCurrency} + ${unitName ? ` / ${unitName}` : ''})`;
                        return formattedServiceName;
                    }),
                );
        }

        return of(formattedServiceName);
    }
}