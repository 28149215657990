import { Injectable, Inject } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { AppConfigService } from 'app/services/app.settings.service'
import { of, BehaviorSubject } from 'rxjs'
import { flatMap, map } from 'rxjs/operators'
import { TranslateService } from '@ngx-translate/core'
import { Observable, Subject } from 'rxjs'
import { CurrencyData } from '../../models/'
import {BaseResultModel, DropDownOptionsModel} from 'app/models/general'
import {ResponseCurrencyGridData} from "../../models/response-models/response-currency-grid-data";
import {DropDownItem} from "../../../../models/general/dropdownItem.model";
import {ResponseCurrencyData} from "../../models/currency.model";
import {PostCurrency} from "../../models/post-models/post-currency";
import {ResponseCurrencyConversionData} from "../../models/response-models/response-currency-converstion-data";
import {PostCurrencyExchangeRate} from "../../../../models/post-models/post-currency-exchange-rate";
import {ResponseCurrencyExchangeRate} from "../../../../models/response-models/response-currency-exchange-rate";
import {environment} from "../../../../../environments/environment";
import {ApirequestService, HttpVerb} from "../../../../services/general/api-service.service";
import { PostSnapshotDate } from '../../models/post-models/post-currency-snapshot-date'
import { ResponseCurrencyConversionGridData } from '../../models/response-models/response-currency-conversion-grid-data'

@Injectable({
    providedIn: 'root',
})
export class CurrencyserviceService {
    /**
     * Path uri.
     * @type {string}
     * @private
     */
    private _uri = ''
    private subject = new Subject<any>()

    /**
     * Url to endpoint api.
     * @type {string}
     */
    private endpoint = '/api/Admin'
    /**
     * Endpoint request headers.
     * @type {HttpHeaders}
     */
    private headers = new HttpHeaders({ 'Content-Type': 'application/json' })

    /**
     * Component constructor and DI injection point.
     * @param {HttpClient} http
     * @param {AppConfigService} config
     */
    constructor(
        private http: HttpClient,
        configService: AppConfigService,
        private translate: TranslateService,
        private api: ApirequestService
    ) {
        if (configService) {
            this._uri = configService.getAPIUrl()
        }
    }

    saveCurrency(postCurrency: PostCurrency): Observable<BaseResultModel<PostCurrency>> {
        if (postCurrency?.id) {
          return this.updateCurrency(postCurrency);
        } else {
            return this.http.post<BaseResultModel<PostCurrency>>(this._uri+ '/system/currencies', postCurrency);
        }

    }

    updateCurrency(postCurrency: PostCurrency): Observable<BaseResultModel<PostCurrency>> {
        return this.http.put<BaseResultModel<PostCurrency>>(this._uri+ '/system/currency', postCurrency);
    }

    deleteCurrencyById(id: number): Observable<BaseResultModel<boolean>> {
        return this.http.delete<BaseResultModel<boolean>>(this._uri + '/system/currency/' + id );
    }

    configurableCurrencies(): Observable<DropDownOptionsModel[]> {
        return this.http.get<DropDownOptionsModel[]>(`${this._uri}${this.endpoint}/configurableCurrencies`)

    }

    getCurrencyById(id: number): Observable<ResponseCurrencyData> {
        return this.http.get<ResponseCurrencyData>(this._uri + `/system/currencies/one/${id}`);
    }

    getCurrencies(): Observable<BaseResultModel<ResponseCurrencyGridData[]>> {
        return this.http.get<BaseResultModel<ResponseCurrencyGridData[]>>(this._uri + '/system/currencies/1');
    }

    exchangeRate(postCurrencyExchangeRate: PostCurrencyExchangeRate): Observable<BaseResultModel<ResponseCurrencyExchangeRate>> {
        return this.api.ApiRequest<BaseResultModel<ResponseCurrencyExchangeRate>>(environment.apiUrl + '/system/currency-rates/api',postCurrencyExchangeRate,new HttpHeaders(),HttpVerb.post,'json');
    }

    getCurrenciesWithConversionRates(): Observable<BaseResultModel<ResponseCurrencyConversionData[]>> {
        return this.http.get<BaseResultModel<ResponseCurrencyConversionData[]>>(this._uri + '/system/currencies/with-rates');
    }

    getGlobalCurrenciesDropdown(): Observable<BaseResultModel<DropDownItem[]>> {
        return this.http.get<BaseResultModel<DropDownItem[]>>(this._uri + '/system/currencies/with-rates/dropdown');

    }

    /**
     * @description returns latest (based on submitted howmany param) saved exchange rate snapshot dates
     * @param howMany number
     * @returns BaseResultModel<DropDownItem[]>
     */
    getLatestConversionSnapshotsDropdown(howMany:number): Observable<BaseResultModel<DropDownItem[]>> {
        return this.http.get<BaseResultModel<DropDownItem[]>>(this._uri + '/system/exchange-rate-snapshots/' + howMany + '/latest-conversions/dropdown');
    }

    /**
     * @description returns stored conversion rates snapshot history
     * @returns BaseResultModel<DropDownItem[]>
     */
    getStoredHistoryExchangeRateSnapshots(): Observable<BaseResultModel<DropDownItem[]>> {
        return this.http.get<BaseResultModel<DropDownItem[]>>(this._uri + '/system/exchange-rate-snapshot-history/dropdown');
    }

    /**
     * @description adds new currency snapshot based on selected date
     * @param currenciesWithRates ResponseCurrencyConversionData[]
     * @returns BaseResultModel<ResponseCurrencyConversionData[]>
     */
    addNewCurrencySnapshot(currenciesWithRates:ResponseCurrencyConversionData[]): Observable<BaseResultModel<ResponseCurrencyConversionData[]>> {
        return this.api.ApiRequest<BaseResultModel<ResponseCurrencyConversionData[]>>(this._uri + '/system/exchange-rate-snapshot',currenciesWithRates,new HttpHeaders(),HttpVerb.post,'json');

    }

    /**
     * @description uses already stored currency snapshot based on selected date
     * @param currenciesWithRates ResponseCurrencyConversionData[]
     * @returns BaseResultModel<boolean>
     */
    useStoredCurrencySnapshot(currenciesWithRates:ResponseCurrencyConversionData[]): Observable<BaseResultModel<boolean>> {
        return this.api.ApiRequest<BaseResultModel<boolean>>(this._uri + '/system/exchange-rate-snapshot/use',currenciesWithRates,new HttpHeaders(),HttpVerb.post,'json');

    }

    /**
     * @description adds new currency snapshot based on selected date
     * @param snapshotId number
     * @returns BaseResultModel<ResponseCurrencyConversionData[]>
     */
    getStoredHistoryExchangeRateSnapshotsById(snapshotId:number): Observable<BaseResultModel<ResponseCurrencyConversionData[]>> {
        return this.api.ApiRequest<BaseResultModel<ResponseCurrencyConversionData[]>>(this._uri + '/system/exchange-rate-snapshot-history/id/' + snapshotId,'',new HttpHeaders(),HttpVerb.get,'json');

    }

    /**
     * @description deletes saved currency snapshot so that the system uses fresh rates
     * @param howMany number
     * @returns BaseResultModel<DropDownItem[]>
     */
    deleteCurrencySnapshot(): Observable<BaseResultModel<ResponseCurrencyConversionData[]>> {
        return this.api.ApiRequest<BaseResultModel<ResponseCurrencyConversionData[]>>(this._uri + '/system/exchange-rate-snapshot','',new HttpHeaders(),HttpVerb.delete,'json');

    }

    /**
     * @description deletes saved historical currency snapshot
     * @param snapshotId number
     * @returns BaseResultModel<DropDownItem[]>
     */
    deleteCurrencySnapshotById(snapshotId:number): Observable<BaseResultModel<boolean>> {
        return this.api.ApiRequest<BaseResultModel<boolean>>(this._uri + '/system/exchange-rate-snapshot-history/id/' + snapshotId,'',new HttpHeaders(),HttpVerb.delete,'json');

    }

    /**
     * @description invokes fixer.io API to get fresh rates
     * @returns BaseResultModel<ResponseCurrencyConversionData[]>
     */
    syncCurrencyRatesFromAPI(): Observable<BaseResultModel<ResponseCurrencyConversionData[]>> {
        return this.api.ApiRequest<BaseResultModel<ResponseCurrencyConversionData[]>>(this._uri + '/system/currency-exchange-rates/stored/sync','',new HttpHeaders(),HttpVerb.get,'json');

    }
}