import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CropImageComponent } from './crop-image.component';
import {AngularCropperjsModule} from "angular-cropperjs";
@NgModule({
    declarations: [
        CropImageComponent
    ],
    imports: [
        CommonModule,
        AngularCropperjsModule,
    ],
    exports: [
        CropImageComponent
    ]
})
export class CropImageModule { }