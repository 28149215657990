import {Component, Input, OnInit} from '@angular/core';
import {ResponseCatCountDiscounts} from "../models/response-models/response-cat-count-discounts";
import {CatCountService} from "../../services/cat-count/cat-count.service";
import {FormBuilder, FormGroup} from "@angular/forms";
import {CatCountAnalysis, CatCountAnalysisDetails} from "../models/cat-count-analysis";
import {CalculateTotalsService} from "../../../../services/calculate-totals/calculate-totals.service";
import {SystemCatCountDiscountsTypes} from "../../../../utills";
import { ResponseCatToolAnalysisResult } from '../models/cat-tool-analysis';
import { PostCatCountDiscounts } from '../models/post-models/post-cat-count-discounts';
import { GlobalConfiguration } from 'app/models/general/global-configuration';
import { ResponseQuoteJobCatCount } from 'app/models/general/quote/response-models/response-quote-job-cat-count';
import { Subject } from 'rxjs';

@Component({
  selector: 'tp-cat-count-analysis',
  templateUrl: './cat-count-analysis.component.html',
  styleUrls: ['./cat-count-analysis.component.scss']
})
export class CatCountAnalysisComponent implements OnInit {
    @Input() set discounts(discounts: ResponseCatCountDiscounts){
        if(discounts) this.initDiscounts(discounts);
    }

    @Input() set words(words: ResponseCatCountDiscounts){
        if(words) this.initCountWords(words);
    }

    @Input() set clientId(id: number){
        if(id && id>0) this.getCatCountDiscountsByClientId(id);
    }

    @Input() set resourceId(id: number){
        if(id && id>0) this.getCatCountDiscountsByResourceId(id);
    }

    @Input() set system(catCountDiscountsFor: keyof typeof SystemCatCountDiscountsTypes) {
        if(this.isSystemClient(catCountDiscountsFor)) this.getSystemClientCatCountDiscounts();
        else if(this.isSystemResource(catCountDiscountsFor)) this.getSystemResourceCatCountDiscounts();
    }

    @Input() set analysisResult(val: ResponseCatToolAnalysisResult){
        if(val){
            this._analysisResult;
            //convert analysis result to our system's discount namings
            var convertedDiscounts:ResponseCatCountDiscounts=this.convertCatAnalysisDiscounts(val);
            this.loadAnalysisData(convertedDiscounts);
        }
    }

    @Input() set catCountFigures(val: ResponseQuoteJobCatCount){        
        if(!val?.isFresh && val){  //load data by already stored cat count figures, otherwise let new analysis be displayed
            this._catCountFigures=val;
            this.loadQuoteJobStoredCatCountData(val);
        }
    }

    get analysisResult(){
        return this._analysisResult;
    }

    get catCountFigures(){
        return this._catCountFigures;
    }

    private obs$:Subject<void>=new Subject<void>();
    formGroup: FormGroup;
    totalWordCount: number = 0;
    totalDiscounted: number = 0;
    catCountOrigin?:string;
    _analysisResult:ResponseCatToolAnalysisResult=new ResponseCatToolAnalysisResult();
    _catCountFigures:ResponseQuoteJobCatCount=new ResponseQuoteJobCatCount();

    constructor(
        private catCountService: CatCountService,
        private formBuilder: FormBuilder,
        private totalsService: CalculateTotalsService,
        private globalConfiguration:GlobalConfiguration
    ) {
        this.formGroup = this.formBuilder.group({
            contextMatch: this.formBuilder.group(new CatCountAnalysisDetails()),
            xtranslated: this.formBuilder.group(new CatCountAnalysisDetails()),
            repetitions: this.formBuilder.group(new CatCountAnalysisDetails()),
            crossFileRepetitions: this.formBuilder.group(new CatCountAnalysisDetails()),
            match101: this.formBuilder.group(new CatCountAnalysisDetails()),
            match100: this.formBuilder.group(new CatCountAnalysisDetails()),
            match9599: this.formBuilder.group(new CatCountAnalysisDetails()),
            match8594: this.formBuilder.group(new CatCountAnalysisDetails()),
            match7584: this.formBuilder.group(new CatCountAnalysisDetails()),
            match5074: this.formBuilder.group(new CatCountAnalysisDetails()),
            noMatch: this.formBuilder.group(new CatCountAnalysisDetails()),
        });
        this.formGroup.valueChanges.subscribe(values => {
            this.refreshForm(values);
        });
    }
    ngOnInit(): void {}

    ngOnDestroy():void{
        this.obs$.next();
        this.obs$.complete();
    }
    
    /**
     * @function getGroup
     * @param controlName: string
     * @description get group control
     * @return FormGroup
     * */
    getGroup(controlName: string): FormGroup { return this.formGroup.controls[controlName] as FormGroup };

    /**
     * @function initDiscounts
     * @description init discounts into analysis
     * @return void
     * */
    initDiscounts(discounts: ResponseCatCountDiscounts): void {
        this.formGroup.patchValue({
            contextMatch: {discount: discounts?.contextExact ?? 0},
            xtranslated: {discount: discounts?.xtranslatedPerc ?? 0},
            repetitions: {discount: discounts?.repetitionsPerc ?? 0},
            crossFileRepetitions: {discount: discounts?.crossFileRepetitionsPerc ?? 0},
            match101: {discount: discounts?.match101Perc ?? 0},
            match100: {discount: discounts?.match100Perc ?? 0},
            match9599: {discount: discounts?.match9599Perc ?? 0},
            match8594: {discount: discounts?.match8594Perc ?? 0},
            match7584: {discount: discounts?.match7584Perc ?? 0},
            match5074: {discount: discounts?.match5074Perc ?? 0},
            noMatch: {discount: discounts?.noMatchPerc ?? 0},
        });

        //display catCountOrigin
        this.catCountOrigin=(discounts.isCompanyGrid ?? false)==true
                            ?' (Company Defaults)'
                            :'';
    }

    /**
     * @function initDiscounts
     * @description init discounts into analysis
     * @return void
     * */
    initCountWords(words: ResponseCatCountDiscounts): void {
        this.formGroup.patchValue({
            contextMatch: {words: words?.contextExact ?? 0},
            xtranslated: {words: words?.xtranslatedPerc ?? 0},
            repetitions: {words: words?.contextExact ?? 0},
            crossFileRepetitions: {words: words?.repetitionsPerc ?? 0},
            match101: {words: words?.match101Perc ?? 0},
            match100: {words: words?.match100Perc ?? 0},
            match9599: {words: words?.match9599Perc ?? 0},
            match8594: {words: words?.match8594Perc ?? 0},
            match7584: {words: words?.match7584Perc ?? 0},
            match5074: {words: words?.match5074Perc ?? 0},
            noMatch: {words: words?.noMatchPerc ?? 0},
        });

       //display catCountOrigin
       this.catCountOrigin=(words.isCompanyGrid ?? false)==true
                            ?' (Company Defaults)'
                            :'';
    }

    /**
     * @function loadAnalysisData
     * @description loads cat tool file analysis data based on client's cat count discount
     * @return void
     * */
    loadAnalysisData(words: ResponseCatCountDiscounts): void {       
        this.formGroup.patchValue({
            contextMatch: {words: words?.contextExact ?? 0},
            xtranslated: {words: words?.xtranslatedPerc ?? 0},
            repetitions: {words: words?.repetitionsPerc ?? 0},
            crossFileRepetitions: {words: words?.crossFileRepetitionsPerc ?? 0},
            match101: {words: words?.match101Perc ?? 0},
            match100: {words: words?.match100Perc ?? 0},
            match9599: {words: words?.match9599Perc ?? 0},
            match8594: {words: words?.match8594Perc ?? 0},
            match7584: {words: words?.match7584Perc ?? 0},
            match5074: {words: words?.match5074Perc ?? 0},
            noMatch: {words: words?.noMatchPerc ?? 0},
        });

        this.totalWordCount=this.analysisResult.totalWordCount;
        this.totalDiscounted=this.analysisResult.totalDiscountedWordCount;

        //display catCountOrigin
        this.catCountOrigin=(words.isCompanyGrid ?? false)==true
                            ?' (Company Defaults)'
                            :'';
    }

    /**
     * @function loadQuoteJobStoredCatCountData
     * @description loads stored quote job cat count data
     * @return void
     * */
    loadQuoteJobStoredCatCountData(val: ResponseQuoteJobCatCount): void {
        var test=this.formGroup
        this.formGroup.patchValue({
            contextMatch: {words: val?.contextExact ?? 0, discount:val?.contextExactDiscount ?? 0},
            xtranslated: {words: val?.xtranslatedPerc ?? 0, discount:val?.xtranslatedDiscount ?? 0},
            repetitions: {words: val?.repetitionsPerc ?? 0, discount:val?.repetitionsDiscount ?? 0},
            crossFileRepetitions: {words: val?.crossFileRepetitionsPerc ?? 0, discount:val?.crossFileRepetitionsDiscount ?? 0},
            match101: {words: val?.match101Perc ?? 0, discount:val?.match101Discount ?? 0},
            match100: {words: val?.match100Perc ?? 0, discount:val?.match100Discount ?? 0},
            match9599: {words: val?.match9599Perc ?? 0, discount:val?.match9599Discount ?? 0},
            match8594: {words: val?.match8594Perc ?? 0, discount:val?.match8594Discount ?? 0},
            match7584: {words: val?.match7584Perc ?? 0, discount:val?.match7584Discount ?? 0},
            match5074: {words: val?.match5074Perc ?? 0, discount:val?.match5074Discount ?? 0},
            noMatch: {words: val?.noMatchPerc ?? 0, discount:val?.noMatchDiscount ?? 0},
        });

        this.totalWordCount=val.totalWordsCount;
        this.totalDiscounted=val.totalDiscountedWords;
    }

    /**
     * @function refreshForm
     * @description refresh the form on change
     * @return void
     * */
    private refreshForm(values: CatCountAnalysis): void {
        this.totalDiscounted = 0;
        this.totalWordCount = 0;
        for(let property in values) {
            const discounted = this.totalsService.percentAmount(values[property].words, values[property].discount);
            this.refreshGroup(property,{discounted});
            this.totalDiscounted += discounted;
            this.totalWordCount += Number(values[property].words) ?? 0;
        }
    }

    /**
     * @function refreshGroup
     * @description refresh the group on change
     * @return void
     * */
    private refreshGroup(group: string, details: Partial<CatCountAnalysisDetails>, emitEvent: boolean = false): void {
        this.formGroup.patchValue({[group]: details}, {emitEvent});
    }
    /**
     * @function getSystemClientCatCountDiscounts
     * @description get system client cat count discounts
     * @return void
     * */
    getSystemClientCatCountDiscounts(): void {
        this.catCountService.getSystemClientCatCountDiscounts().subscribe(result => {
            this.initDiscounts(result.response ?? null);
        });
    }

    /**
     * @function getSystemClientCatCountDiscounts
     * @description get system resource cat count discounts
     * @return void
     * */
    getSystemResourceCatCountDiscounts(): void {
        this.catCountService.getSystemResourceCatCountDiscounts().subscribe(result => {
            this.initDiscounts(result.response ?? null);
        });
    }

    /**
     * @function getCatCountDiscountsByClientId
     * @param clientId: number
     * @description get cat count discounts by client id
     * @return void
     * */
    getCatCountDiscountsByClientId(clientId: number): void {
        this.catCountService.getCatCountDiscountsByClientId(clientId).subscribe(result => {
            this.initDiscounts(result.response ?? null);
        });
    }

    /**
     * @function getCatCountDiscountsByResourceId
     * @param resourceId: number
     * @description get cat count discounts by resource id
     * @return void
     * */
    getCatCountDiscountsByResourceId(resourceId: number): void {
        this.catCountService.getCatCountDiscountsByResourceId(resourceId).subscribe(result => {
            this.initDiscounts(result.response ?? null);
        });
    }

    /**
     * @function isSystemClient
     * @description check if is system client cat count discounts
     * @return void
     * */
    private isSystemClient(catCountDiscountsFor: keyof typeof SystemCatCountDiscountsTypes): boolean{
        return (catCountDiscountsFor === SystemCatCountDiscountsTypes.Client);
    }

    /**
     * @function isSystemResource
     * @description check if is system resource cat count discounts
     * @return void
     * */
    private isSystemResource(catCountDiscountsFor: keyof typeof SystemCatCountDiscountsTypes): boolean{
        return (catCountDiscountsFor === SystemCatCountDiscountsTypes.Resource);
    }

    /**
     * @description converts an analysis object to our own system's cat count discounts
     * @param val ResponseCatToolAnalysisResult
     * @returns ResponseCatCountDiscounts
     */
    private convertCatAnalysisDiscounts(val:ResponseCatToolAnalysisResult):ResponseCatCountDiscounts{
        if(val?.analysis){
            var mapObject:ResponseCatCountDiscounts=new ResponseCatCountDiscounts();

            val.analysis.forEach(x=>{
                if(x.columnName?.toLowerCase().startsWith('repet')){
                    mapObject.repetitionsPerc=x.wordsCount;
                }
                else if(x.columnName?.toLowerCase().indexOf('cross')>-1){
                    mapObject.crossFileRepetitionsPerc=x.wordsCount;
                }
                else if(x.columnName?.toLowerCase().indexOf('100')>-1){
                    mapObject.match100Perc=x.wordsCount;
                }
                else if(x.columnName?.toLowerCase().indexOf('101')>-1){
                    mapObject.match101Perc=x.wordsCount;
                }
                else if(x.columnName?.toLowerCase().indexOf('50')>-1){
                    mapObject.match5074Perc=x.wordsCount;
                }
                else if(x.columnName?.toLowerCase().indexOf('75')>-1){
                    mapObject.match7584Perc=x.wordsCount;
                }
                else if(x.columnName?.toLowerCase().indexOf('94')>-1){
                    mapObject.match8594Perc=x.wordsCount;
                }
                else if(x.columnName?.toLowerCase().indexOf('99')>-1){
                    mapObject.match9599Perc=x.wordsCount;
                }
                else if(x.columnName?.toLowerCase().trim().replace(' ','').indexOf('nomatch')>-1){
                    mapObject.noMatchPerc=x.wordsCount;
                }
                else if(x.columnName?.toLowerCase().indexOf('translat')>-1){
                    mapObject.xtranslatedPerc=x.wordsCount;
                }
                else if(x.columnName?.toLowerCase().indexOf('context')>-1){
                    mapObject.contextExact=x.wordsCount;
                    mapObject.contextMatch=x.wordsCount;
                }
            });
            
            return mapObject;
        }
        else{
            return null;
        }
    }

    /**
     * @description gathers current form values and transmits them to quote job component
     * @returns any
     */
    getCatAnalysisResult():any{
        const form=this.formGroup.getRawValue();

        var res={
            totalDiscounted:this.totalDiscounted,
            totalWordCount:this.totalWordCount,
            discounts:<PostCatCountDiscounts>{
                insertUserId: this.globalConfiguration.currentUser.id,
                catCountFor: '',
                contextMatch: form.contextMatch['words'],
                contextExact: form.contextMatch['words'],
                xtranslatedPerc: form.xtranslated['words'],
                repetitionsPerc: form.repetitions['words'],
                crossFileRepetitionsPerc: form.crossFileRepetitions['words'],
                match101Perc: form.match101['words'],
                match100Perc: form.match100['words'],
                match9599Perc: form.match9599['words'],
                match8594Perc: form.match8594['words'],
                match7584Perc: form.match7584['words'],
                match5074Perc: form.match5074['words'],
                noMatchPerc: form.noMatch['words'],
                contextMatchDiscount: form.contextMatch['discount'],
                contextExactDiscount: form.contextMatch['discount'],
                xtranslatedDiscount: form.xtranslated['discount'],
                repetitionsDiscount: form.repetitions['discount'],
                crossFileRepetitionsDiscount: form.crossFileRepetitions['discount'],
                match101Discount: form.match101['discount'],
                match100Discount: form.match100['discount'],
                match9599Discount: form.match9599['discount'],
                match8594Discount: Number(form.match8594['discount']),
                match7584Discount: form.match7584['discount'],
                match5074Discount: form.match5074['discount'],
                noMatchDiscount: form.noMatch['discount']
            }
        }
        
        return res;
    }

    getCleanCatAnalysisResult():any{
        const form=this.formGroup.getRawValue();

        var res={
            totalDiscounted:0,
            totalWordCount:0,
            discounts:<PostCatCountDiscounts>{
                insertUserId: this.globalConfiguration.currentUser.id,
                catCountFor: '',
                contextMatch: form.contextMatch['words'],
                contextExact: null,
                xtranslatedPerc: form.xtranslated['words'],
                repetitionsPerc: form.repetitions['words'],
                crossFileRepetitionsPerc: form.crossFileRepetitions['words'],
                match101Perc: form.match101['words'],
                match100Perc: form.match100['words'],
                match9599Perc: form.match9599['words'],
                match8594Perc: form.match8594['words'],
                match7584Perc: form.match7584['words'],
                match5074Perc: form.match5074['words'],
                noMatchPerc: form.noMatch['words'],
                contextMatchDiscount: form.contextMatch['discount'],
                contextExactDiscount: null,
                xtranslatedDiscount: form.xtranslated['discount'],
                repetitionsDiscount: form.repetitions['discount'],
                crossFileRepetitionsDiscount: form.crossFileRepetitions['discount'],
                match101Discount: form.match101['discount'],
                match100Discount: form.match100['discount'],
                match9599Discount: form.match9599['discount'],
                match8594Discount: Number(form.match8594['discount']),
                match7584Discount: form.match7584['discount'],
                match5074Discount: form.match5074['discount'],
                noMatchDiscount: form.noMatch['discount']
            }
        }
        
        return res;
    }
}