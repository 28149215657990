import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'ucfirst',
})
export class UcFirstPipe implements PipeTransform {
    transform(value: string): string {
        if (!value) {
            return '';
        } else {
            const first = value[0].toLocaleUpperCase();
            const result = value.slice(1).toLocaleLowerCase();
            return first + result;
        }
    }
}