import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MyProfileModalComponent} from "./my-profile-modal.component";
import {TpModalModule} from "../../../../components/tp-modal/tp-modal.module";
import {TpButtonModule} from "../../../../components/tp-button/tp-button.module";
import {TranslateModule} from "@ngx-translate/core";



@NgModule({
  declarations: [MyProfileModalComponent],
    imports: [
        CommonModule,
        TpModalModule,
        TpButtonModule,
        TranslateModule
    ],
    exports: [
        MyProfileModalComponent
    ]
})
export class MyProfileModalModule { }