import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {QuoteHistoryComponent} from "./quote-history.component";
import {MyProfileModalModule} from "../../../../modals/my-profile-modal/my-profile-modal.module";
import {TpGridModule} from "../../../../../../components/tp-grid/tp-grid.module";
import {
    TpProjectStatusButtonModule
} from "../../../../../../components/tp-project-status-button/tp-project-status-button.module";
import {TpDynamicColumnsModule} from "../../../../../../components/tp-dynamic-columns/tp-dynamic-columns.module";
import {TpSliderRangeModule} from "../../../../../../components/tp-slider-range/tp-slider-range.module";
import {TpToggleModule} from "../../../../../../components/tp-toggle/tp-toggle.module";
import {TpDropdownModule} from "../../../../../../components/tp-dropdown/tp-dropdown.module";
import {TpTextBoxModule} from "../../../../../../components/tp-text-box/tp-text-box.module";
import {TpDatePickerModule} from "../../../../../../components/tp-date-picker/tp-date-picker.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {
    TpGridSearchWrapperModule
} from "../../../../../../components/tp-grid-search-wrapper/tp-grid-search-wrapper.module";
import {
    ClientContactProfileModule
} from "../../../../../sharedComponents/client-contact-profile/client-contact-profile.module";
import {CharacterLimitPipeModule} from "../../../../../../pipes/character-limit-pipe/character-limit-pipe.module";
import {
    TpUnderlineDropdownModule
} from "../../../../../../components/tp-underline-dropdown/tp-underline-dropdown.module";
import {
    TpTimeframeValueChangeBoxModule
} from "../../../../../../components/tp-timeframe-value-change-box/tp-timeframe-value-change-box.module";

@NgModule({
    declarations: [QuoteHistoryComponent],
    imports: [
        CommonModule,
        MyProfileModalModule,
        TpGridModule,
        TpProjectStatusButtonModule,
        TpDynamicColumnsModule,
        TpSliderRangeModule,
        TpToggleModule,
        TpDropdownModule,
        TpTextBoxModule,
        TpDatePickerModule,
        ReactiveFormsModule,
        TranslateModule,
        TpGridSearchWrapperModule,
        FormsModule,
        CharacterLimitPipeModule,
        TpUnderlineDropdownModule,
        TpTimeframeValueChangeBoxModule,
    ],
    exports: [
        QuoteHistoryComponent
    ]
})
export class QuoteHistoryModule { }