import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TpDropdownModule } from 'app/components/tp-dropdown/tp-dropdown.module';
import { QuoteProbabilitySelectorComponent } from 'app/main/sharedComponents/quote-probability-selector/quote-probability-selector.component';

@NgModule({
    declarations: [QuoteProbabilitySelectorComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TpDropdownModule,
    ],
    exports: [
        QuoteProbabilitySelectorComponent,
    ],
})
export class QuoteProbabilitySelectorModule {
}