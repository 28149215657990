import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SupportLinksComponent } from './support-links.component';
import {MatMenuModule} from "@angular/material/menu";
import {TranslateModule} from "@ngx-translate/core";
@NgModule({
    declarations: [
        SupportLinksComponent
    ],
    exports: [
        SupportLinksComponent
    ],
    imports: [
        CommonModule,
        MatMenuModule,
        TranslateModule
    ]
})
export class SupportLinksModule { }