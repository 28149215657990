export class PostFile {
    id?: number;
    fileIndex?: number;
    fileTypeId?: number;
    mimeTypeId?: number;
    fileId?: string;
    description?: string;
    size?: number;
    file?: File;
    link?: string;
    progress?: boolean;  // frontend allow progress bar
    status?: FileStatusTypes; // frontend status
    loaded?: number;  // frontend percentage uploaded
    selected?: boolean;  // frontend
    uid?: string; // frontend unique identifier
    icon?: string; // frontend icon
}

export enum FileStatusTypes {
    Pending = 1,
    Uploading = 2,
    Uploaded = 3,
    Rejected = 4
}