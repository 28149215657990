import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { TpDropdownModule } from 'app/components/tp-dropdown/tp-dropdown.module';
import { TpPaginationComponent } from 'app/components/tp-pagination/tp-pagination.component';
import { NgxPaginationModule } from 'ngx-pagination';

@NgModule({
    declarations: [TpPaginationComponent],
    imports: [
        CommonModule,

        NgxPaginationModule,
        MatFormFieldModule,
        MatSelectModule,
        FormsModule,
        RouterModule,
        FlexModule,
        TranslateModule,
        TpDropdownModule,
        ReactiveFormsModule,
    ],
    exports: [TpPaginationComponent, NgxPaginationModule],
})
export class TpPaginationModule {
}