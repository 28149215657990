import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { TranslateModule } from '@ngx-translate/core'
import { FormsModule } from '@angular/forms'

import { TpGanttComponent } from 'app/components/tp-gantt/tp-gantt.component'
import { TpDropdownModule } from '../tp-dropdown/tp-dropdown.module'

@NgModule({
    declarations: [TpGanttComponent],
    imports: [
        CommonModule,
        TranslateModule,
        FormsModule,

        TpDropdownModule
    ],
    exports: [TpGanttComponent],
})
export class TpGanttModule {}