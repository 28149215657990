import { Pipe, PipeTransform } from '@angular/core';
import { RefDataService } from 'app/services/general/refData.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
    name: 'currencyName',
})
export class CurrencyNamePipe implements PipeTransform {
    constructor(private refDataService: RefDataService) {
    }
    
    transform(value: number): Observable<string> {
        if (value) {
            return this.refDataService.getCurrencyCodes()
                .pipe(
                    map((currencies) => {
                        return currencies.response.find((a) => a.id === value)?.name || '---';
                    }),
                );
        } else {
            return of('---');
        }
    }
}