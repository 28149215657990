import { NgModule } from '@angular/core';
import { CharacterLimitPipe } from 'app/pipes/character-limit-pipe/character-limit.pipe';


@NgModule({
    declarations: [CharacterLimitPipe],
    providers: [CharacterLimitPipe],
    exports: [
        CharacterLimitPipe,
    ],
})
export class CharacterLimitPipeModule {
}