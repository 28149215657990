import {DropDownGroup, DropDownGroupBy, DropDownGroupedBy, DropDownItem} from "../../models/general/dropdownItem.model";

export const groupDropdownOptions = (options: DropDownItem[], groupBy: DropDownGroupBy): DropDownGroup => {
    let result: DropDownItem[] = [];
    const groups: { [key: string]: DropDownItem[] } = {};

    for (const option of options) {
        const group = groupBy.group(option, options);
        if (!groups[group]) {
            groups[group] = [option];
        } else {
            groups[group].push(option);
        }
    }

    const grouped: DropDownGroupedBy[] = Object.entries(groups).map(group => {
        return {group: group[0], options: group[1]}
    }).sort(
        (a, b) => a.group?.localeCompare(b.group)
    );

    if (grouped.length > 0) {
        grouped.forEach(group => {
            const label = groupBy.label(group, grouped.filter(group => groupBy.display(group)));
            const display = groupBy.display(group, grouped);
            if ((label?.display) && display) {
                const groupOption: DropDownItem = {
                    textAsync: label.textAsync,
                    isGroup: true,
                    id: 0
                }
                result.push(...[groupOption, ...group.options]);
            } else if (display) {
                result.push(...group.options);
            }
        });
    } else {
        result.push(...options);
    }

    return {hasGroups: grouped.length > 0, hasMultiGroups: grouped.length > 1, options: result};
}