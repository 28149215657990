import { Component, forwardRef, Input, Optional } from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ResponseCompanyBranch } from 'app/models/general/company-setting.model';
import { GlobalConfiguration } from 'app/models/general/global-configuration';
import {combineLatest, Observable, of, ReplaySubject} from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { BaseControlComponentDirective } from '../base-control-component.directive';
import {ThemePalette} from "@angular/material/core";
import {NGX_MAT_DATE_FORMATS, NgxMatDateFormats} from "@angular-material-components/datetime-picker";

const CUSTOM_DATE_FORMATS: NgxMatDateFormats = {
    parse: {
        dateInput: "LL"
    },
    display: {
        dateInput: "LL",
        monthYearLabel: "MMM YYYY",
        dateA11yLabel: "LL",
        monthYearA11yLabel: "MMMM YYYY"
    }
};
@Component({
    selector: 'tp-calendar',
    templateUrl: './tp-calendar.component.html',
    styleUrls: ['./tp-calendar.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TpCalendarComponent),
            multi: true,
        },
        {
            provide: NGX_MAT_DATE_FORMATS,
            useValue: CUSTOM_DATE_FORMATS
        }
    ],
})
export class TpCalendarComponent extends BaseControlComponentDirective {
    @Input() showSpinners = true;
    @Input() showSeconds = false;
    @Input() touchUi = false;
    @Input() enableMeridian = false;
    @Input() stepHour = 1;
    @Input() stepMinute = 15;
    @Input() stepSecond = 30;
    @Input() color: ThemePalette = 'primary';

    @Input() public set showTime(value: boolean) {
        this._showTime = value
        this._showTimeChange$.next(value)
    }
    public get showTime(): boolean {
        return this._showTime
    }
    public dateFormat$: Observable<string>
    private _showTime = false
    private _showTimeChange$ = new ReplaySubject<boolean>(1)

    constructor(
        @Optional()  protected readonly controlContainer: ControlContainer,
        protected readonly translateService: TranslateService,
        private globalConfig: GlobalConfiguration
    ) {
        super(controlContainer, translateService)
        this.dateFormat$ = combineLatest([
            of(this.globalConfig.companyBranchSetting),
            this._showTimeChange$.asObservable().pipe(startWith(this._showTime)),
        ]).pipe(
            map(([companyBranchData, showTime]: [ResponseCompanyBranch, boolean]) => {
                // let dateFormat = companyBranchData?.dateFormat?.replace('DD', 'dd').replace('YYYY','yy') || 'dd/MM/yy'  // ejs format
                let dateFormat = companyBranchData?.dateFormat?.replace('dd', 'DD').replace('yyyy','YYYY') || 'DD/MM/YYYY'
                if (showTime) {
                    dateFormat += ' HH:mm'
                }
                CUSTOM_DATE_FORMATS.display.dateInput = dateFormat;
                CUSTOM_DATE_FORMATS.parse.dateInput = dateFormat;
                return dateFormat;
            })
        )
    }
}