import { NgModule } from '@angular/core';
import { CategoriesNamesPipe } from 'app/pipes/category-name/categories-names.pipe';
import { CategoryNamePipe } from 'app/pipes/category-name/category-name.pipe';

@NgModule({
    declarations: [CategoryNamePipe, CategoriesNamesPipe],
    providers: [CategoryNamePipe, CategoriesNamesPipe],
    exports: [
        CategoryNamePipe,
        CategoriesNamesPipe,
    ],
})
export class CategoryNamePipeModule {
}