import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CompanyEventModel } from 'app/main/admin/company-events/models/company-event.model';
import { CompanyEventsSearchCriteriaModel } from 'app/main/admin/company-events/models/company-events-search-criteria.model';
import { SearchCriteriaModel, SearchResult } from 'app/models';
import { TpHttpResponseModel } from 'app/models/tp-http-response.model';
import { AppConfigService } from 'app/services/app.settings.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AdminCompanyEventsService {
    
    private readonly _uri: string;
    
    private readonly _companyEventEndpoint = environment.companyEvents.companyEventEndpoint;
    private readonly _companyEventsSearchEndpoint = environment.companyEvents.companyEventsSearchEndpoint;
    
    constructor(private http: HttpClient, configService: AppConfigService) {
        if (configService) {
            this._uri = configService.getAPIUrl() + environment.adminApiSection;
        }
    }
    
    searchCompanyEvents(model: SearchCriteriaModel<CompanyEventsSearchCriteriaModel>): Observable<SearchResult<CompanyEventModel>> {
        return this.http.post<SearchResult<CompanyEventModel>>(`${this._uri}/${this._companyEventsSearchEndpoint}`, model);
    }
    
    addCompanyEvent(model: CompanyEventModel): Observable<TpHttpResponseModel<CompanyEventModel>> {
        return this.http.post<TpHttpResponseModel<CompanyEventModel>>(`${this._uri}/${this._companyEventEndpoint}`, model);
    }
    
    editCompanyEvent(model: CompanyEventModel): Observable<TpHttpResponseModel<CompanyEventModel>> {
        return this.http.put<TpHttpResponseModel<CompanyEventModel>>(`${this._uri}/${model.id}/${this._companyEventEndpoint}`, model);
    }
    
    deleteCompanyEvent(id: string): Observable<null> {
        return this.http.delete<null>(`${this._uri}/${id}/${this._companyEventEndpoint}`);
    }
}