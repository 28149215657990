import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {catchError, map, concatMap, withLatestFrom, filter, switchMap, mergeMap,} from 'rxjs/operators';
import {of} from 'rxjs';
import * as ServiceActions from '../actions/service.actions';
import {ManageServicesService} from "../../../admin/services/manage-services.service";
import {Store} from "@ngrx/store";
import {State} from "../../../../store";
import {NotificationService} from "../../../../services/notification.service";
import {share} from "rxjs/operators";
import {serviceDropdownEntity, serviceGridEntity, servicesEntity} from "../reducers";
import {mapSearchProps} from "../../../../store/utils";
import {mapServiceDataToDropdown, mapServiceDataToServiceGridData} from "../../../../store/utils/service-mapping";

@Injectable()
export class ServiceEffects {
    searchServiceGrid$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ServiceActions.searchServiceGrid),
            withLatestFrom(this.store.select(serviceGridEntity.selectors.selectState)),
            filter(([action, serviceGridSearch]) => !(JSON.stringify(serviceGridSearch.criteria) === JSON.stringify(action)) || !serviceGridSearch.firstLoad),
            switchMap(([action, serviceGridSearch]) => {
                this.store.dispatch(serviceGridEntity.actions.updateAdditional({updates:{isLoading: true}}))
                return this.manageService.search(action).pipe(
                    map(response => {
                        this.store.dispatch(serviceGridEntity.actions.updateAdditional({updates: mapSearchProps(action, response.response.count)}))
                        return serviceGridEntity.actions.setAll({entities: response.response.result})
                    }),
                    catchError(error => of(serviceGridEntity.actions.updateAdditional({updates: {isLoading: false, error: error}}))),
                )}
            )
        );
    });
    getService$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ServiceActions.getService),
            mergeMap((action) => of(action).pipe(withLatestFrom(this.store.select(servicesEntity.selectors.selectById(action.id))))),
            filter(([action, service]) => !service),
            switchMap(([action, service]) =>
                this.manageService.getServiceById(action.id.toString()).pipe(
                    map(response => servicesEntity.actions.upsertOne({entity: response.response})),
                    catchError(error => {
                        return of(servicesEntity.actions.updateAdditional({updates:{error}}));
                    })
                )
            ),
            share()
        );
    });
    addService$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ServiceActions.addService),
            concatMap((action) =>
                this.manageService.addService(action).pipe(
                    map(response => {
                        this.notificationService.showSuccess('Saved', 'Service Saved Successfully');
                        this.store.dispatch(serviceDropdownEntity.actions.upsertOne({entity: mapServiceDataToDropdown(response.response)}));
                        this.store.dispatch(serviceGridEntity.actions.unshift({entities: [mapServiceDataToServiceGridData(response.response)]}));
                        return servicesEntity.actions.addOne({entity: response.response});
                    }),
                    catchError(error => {
                        return of(servicesEntity.actions.updateAdditional({updates:{error}}));
                    })
                )
            ),
            share()
        );
    });
    updateService$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ServiceActions.updateService),
            concatMap((action) =>
                this.manageService.updateService(action.id.toString(), action).pipe(
                    map(response => {
                        this.notificationService.showSuccess('Saved', 'Service Saved Successfully');
                        this.store.dispatch(serviceDropdownEntity.actions.upsertOne({entity: mapServiceDataToDropdown(response.response)}));
                        this.store.dispatch(serviceGridEntity.actions.updateOne( {update: {id: response.response.id, changes: mapServiceDataToServiceGridData(response.response)}}));
                        return servicesEntity.actions.updateOne({update:{id: response.response.id, changes: response.response}});
                    }),
                    catchError(error => {
                        return of(servicesEntity.actions.updateAdditional({updates:{error}}));
                    })
                )
            ),
            share()
        );
    });
    deleteService$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ServiceActions.deleteService),
            concatMap((action) =>
                this.manageService.deleteService(action.id.toString()).pipe(
                    map(data => {
                        this.notificationService.showSuccess('Deleted', 'Service Deleted Successfully');
                        this.store.dispatch(serviceDropdownEntity.actions.removeOne({id: action.id}));
                        this.store.dispatch(serviceGridEntity.actions.removeOne( {id: action.id}));
                        return servicesEntity.actions.removeOne({id: action.id});
                    }),
                    catchError(error => {
                        return of(servicesEntity.actions.updateAdditional({updates:{error}}));
                    })
                )
            ),
            share()
        );
    });
    constructor(
        private actions$: Actions,
        private manageService: ManageServicesService,
        private notificationService: NotificationService,
        private store: Store<State>
    ) {}
}