export class PostCatCountDiscounts{
     insertUserId?: number = null;
     catCountFor: string = null;
     contextMatch: number = null;
     contextExact: number = null;
     xtranslatedPerc: number = null;
     repetitionsPerc: number = null;
     crossFileRepetitionsPerc: number = null;
     match101Perc: number = null;
     match100Perc: number = null;
     match9599Perc: number = null;
     match8594Perc: number = null;
     match7584Perc: number = null;
     match5074Perc: number = null;
     noMatchPerc: number = null;
}