import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PostQuoteFile} from "../../../models/post-quote-file";
import {QuoteService} from "../../../../services/quotes/quotes.service";
import {FileTypes} from "../../../../../../utills";
import _ from "lodash";
import {FileAutoUpload} from "../../../../../../components/drag-and-drop-file/drag-and-drop-file.component";

@Component({
  selector: 'tp-quote-files',
  templateUrl: './quote-files.component.html',
  styleUrls: ['./quote-files.component.scss']
})
export class QuoteFilesComponent implements OnInit {
    @Input() files: PostQuoteFile[] = [];
    @Output() filesChange: EventEmitter<PostQuoteFile[]> = new EventEmitter<PostQuoteFile[]>();
    @Input() selectedFiles: PostQuoteFile[] = [];
    @Output() selectedFilesChange: EventEmitter<PostQuoteFile[]> = new EventEmitter<PostQuoteFile[]>();
    @Input() select: boolean = false;
    @Input() set quoteId(id: number){
        this.getQuoteFiles(id);
        this.autoUpload = {...this.autoUpload, entity: id ? {quoteId: id} : null};
    }
    autoUpload: Partial<FileAutoUpload<PostQuoteFile>> = {endpoint: '/quote/file'};
    readonly FileTypes = FileTypes;
    constructor(
        private quoteService: QuoteService
    ) { }
    ngOnInit(): void {}
    getQuoteFiles(id: number){
        if(id){ // get quote files
            this.quoteService.getQuoteFiles(id).subscribe(result =>{
                if(result.status) this.onFilesChange(result.response ?? []);
            });
        } else this.files = [];
    }

    onFilesChange(files: PostQuoteFile[]): void {
        this.files = files;
        this.filesChange.emit(this.files);

    }
    onSelectedFilesChange(files: PostQuoteFile[]): void {
        this.selectedFiles = _.cloneDeep(files ?? []);
        this.selectedFilesChange.emit(this.selectedFiles);
    }
    clearSelectedFiles(): void {
        this.files.map(file => {
            file.selected = false;
            return file;
        });
        this.onSelectedFilesChange([]);
    }
}
