import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {catchError, map, concatMap, withLatestFrom, filter, mergeMap, switchMap, share} from 'rxjs/operators';
import { of } from 'rxjs';
import * as UnitActions from '../actions/unit.actions';
import {UnitServiceService} from "../../../admin/services/unit/unit-service.service";
import {Store} from "@ngrx/store";
import {State} from "../../../../store";
import {NotificationService} from "../../../../services/notification.service";
import {unitsEntity} from "../reducers";

@Injectable()
export class UnitEffects {
    getUnits$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(UnitActions.getUnits),
            withLatestFrom(this.store.select(unitsEntity.selectors.selectState)),
            filter(([action, unitState]) => !unitState.firstLoad),
            concatMap(() => {
                this.store.dispatch(unitsEntity.actions.updateAdditional({updates:{isLoading: true}}));
                return this.unitService.getUnits().pipe(
                    map(response => unitsEntity.actions.setAll({entities: response.response})),
                    catchError(error => of(unitsEntity.actions.updateAdditional({updates: {error}})))
                )}
            )
        );
    });
    getUnit$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(UnitActions.getUnit),
            mergeMap((action) => of(action).pipe(withLatestFrom(this.store.select(unitsEntity.selectors.selectById(action.id))))),
            filter(([action, unit]) => !unit),
            switchMap(([action, unit]) =>
                this.unitService.getUnitById(action.id).pipe(
                    map(response => unitsEntity.actions.upsertOne({entity: response.response})),
                    catchError(error => {
                        return of(unitsEntity.actions.updateAdditional({updates: {error}}));
                    })
                )
            ),
            share()
        );
    });
    addUnit$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(UnitActions.addUnit),
            concatMap((action) =>
                this.unitService.addUnit(action).pipe(
                    map(response => {
                        this.notificationService.showSuccess('Saved', 'Unit Saved Successfully');
                        return unitsEntity.actions.unshift({entities: [response.response]});
                    }),
                    catchError(error => {
                        return of(unitsEntity.actions.updateAdditional({updates: {error}}));
                    })
                )
            ),
            share()
        );
    });
    updateUnit$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(UnitActions.updateUnit),
            concatMap((action) =>
                this.unitService.saveUnit(action.id, action).pipe(
                    map(response => {
                        this.notificationService.showSuccess('Saved', 'Unit Saved Successfully');
                        return unitsEntity.actions.updateOne({update: {id: response.response.id, changes:response.response}});
                    }),
                    catchError(error => {
                        return of(unitsEntity.actions.updateAdditional({updates: {error}}));
                    })
                )
            ),
            share()
        );
    });
    deleteUnit$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(UnitActions.deleteUnit),
            concatMap((action) =>
                this.unitService.deleteUnit(action.id).pipe(
                    map(data => {
                        this.notificationService.showSuccess('Deleted', 'Unit Deleted Successfully');
                        return unitsEntity.actions.removeOne({id: action.id});
                    }),
                    catchError(error => {
                        return of(unitsEntity.actions.updateAdditional({updates: {error}}));
                    })
                )
            ),
            share()
        );
    });
  constructor(
      private actions$: Actions,
      private unitService: UnitServiceService,
      private notificationService: NotificationService,
      private store: Store<State>
  ) {}
}