import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { TranslateModule } from '@ngx-translate/core'
import { TpInvoicingRadioComponent } from 'app/components/tp-invoicing-radio/tp-invoicing-radio.component'
import { TpRadioButtonModule } from 'app/components/tp-radio-button/tp-radio-button.module'

@NgModule({
    declarations: [TpInvoicingRadioComponent],
    imports: [
        CommonModule,
        TranslateModule,
        ReactiveFormsModule,
        TpRadioButtonModule,
    ],
    exports: [TpInvoicingRadioComponent],
})
export class TpInvoicingRadioModule {}