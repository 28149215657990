import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {TpUnderlineDropdownComponent} from "./tp-underline-dropdown.component";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatSelectModule} from "@angular/material/select";
import {NgxMatSelectSearchModule} from "ngx-mat-select-search";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import { ClickOutsideModule } from 'ng-click-outside'
import {MatMenuModule} from "@angular/material/menu";
import {MatRippleModule} from "@angular/material/core";
import {TpCheckboxModule} from "../tp-checkbox/tp-checkbox.module";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {ScrollingModule} from "@angular/cdk/scrolling";
import {AutoFocusModule} from "../../directives/input-focus/auto-focus.module";
import {MatButtonModule} from "@angular/material/button";
import {TpButtonModule} from "../tp-button/tp-button.module";
import {TpToggleModule} from "../tp-toggle/tp-toggle.module";
@NgModule({
    declarations: [TpUnderlineDropdownComponent],
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatSelectModule,
        NgxMatSelectSearchModule,
        ReactiveFormsModule,
        FormsModule,
        TranslateModule,
        ClickOutsideModule,
        MatMenuModule,
        MatRippleModule,
        TpCheckboxModule,
        MatCheckboxModule,
        ScrollingModule,
        AutoFocusModule,
        MatButtonModule,
        TpButtonModule,
        TpToggleModule,
    ],
    exports: [
        TpUnderlineDropdownComponent
    ]
})
export class TpUnderlineDropdownModule { }