import { Pipe, PipeTransform } from '@angular/core';
import { RefDataService } from 'app/services/general/refData.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
    name: 'unitName',
})
export class UnitNamePipe implements PipeTransform {
    constructor(private refDataService: RefDataService) {
    }
    
    transform(value: number): Observable<string> {
        return this.refDataService.getUnits().pipe(
            map((units) => {
                return units?.response.find((f) => f.id == value)?.name || '---';
            }),
        );
    }
}