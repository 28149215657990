import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { TpButtonModule } from 'app/components/tp-button/tp-button.module';
import { TpCategoryDropdownModule } from 'app/components/tp-category-dropdown/tp-category-dropdown.module';
import { TpDropdownModule } from 'app/components/tp-dropdown/tp-dropdown.module';
import { TpModalModule } from 'app/components/tp-modal/tp-modal.module';
import { TpProjectStatusDropdownModule } from 'app/components/tp-project-status-dropdown/tp-project-status-dropdown.module';
import { TpProjectManagersDropdownModule } from 'app/components/tp-projectManagers-dropdown/tp-projectManagers-dropdown.module';
import { TpRichTextBoxModule } from 'app/components/tp-rich-textbox/tp-rich-text-box.module';
import { TpRobertTemplateDropdownModule } from 'app/components/tp-robert-template-dropdown/tp-robert-template-dropdown.module';
import { TpRobertWorkflowDropdownModule } from 'app/components/tp-robert-workflow-dropdown/tp-robert-workflow-dropdown.module';
import { TpServiceLanguageDropdownModule } from 'app/components/tp-service-language-dropdown/tp-service-language-dropdown.module';
import { TpServicesDropdownModule } from 'app/components/tp-services-dropdown/tp-services-dropdown.module';
import { TpTextBoxModule } from 'app/components/tp-text-box/tp-text-box.module';
import { TpToggleModule } from 'app/components/tp-toggle/tp-toggle.module';
import { BulkEditProjectJobsModule } from 'app/main/pm/projects/shared/bulk-edit-project-jobs/bulk-edit-project-jobs.module';
import { ClientContactSelectorModule } from 'app/main/sharedComponents/client-contact-selector/client-contact-selector.module';
import { ClientSearchModule } from 'app/main/sharedComponents/client-search/client-search.module';
import { TpCheckboxModule } from '../../../../../components/tp-checkbox/tp-checkbox.module';
import { ClientContractSelectorModule } from '../../../../sharedComponents/client-contract-selector/client-contract-selector.module';
import { ClientContractsModule } from '../../../clients/shared/client-contracts/client-contracts.module';
import { CreateProjectComponent } from './create-project.component';
import {
    ClientNotesIconModule
} from "../../../../sharedComponents/client-profile/client-notes-icon/client-notes-icon.module";
import {
    TpAutocompleteDropdownModule
} from "../../../../../components/tp-autocomplete-dropdown/tp-autocomplete-dropdown.module";
import {ContactModalModule} from "../../../../sharedComponents/contact-modal/contact-modal.module";
import { TpCalendarModule } from 'app/components/tp-calendar/tp-calendar.module';


@NgModule({
    declarations: [CreateProjectComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TpTextBoxModule,
        TranslateModule,
        ClientSearchModule,
        TpDropdownModule,
        ClientContactSelectorModule,
        TpProjectManagersDropdownModule,
        TpCategoryDropdownModule,
        TpProjectStatusDropdownModule,
        TpToggleModule,
        TpCalendarModule,
        TpRichTextBoxModule,
        TpModalModule,
        TpButtonModule,
        BulkEditProjectJobsModule,
        TpCheckboxModule,
        ClientContractSelectorModule,
        FlexModule,
        MatIconModule,
        ClientContractsModule,
        TpServiceLanguageDropdownModule,
        TpRobertWorkflowDropdownModule,
        TpRobertTemplateDropdownModule,
        TpServicesDropdownModule,
        ClientNotesIconModule,
        TpAutocompleteDropdownModule,
        ContactModalModule
    ],
    exports: [
        CreateProjectComponent,
    ],
})
export class CreateProjectModule {
}