import {DropDownItem} from "../../models/general/dropdownItem.model";

export class ResponsesSplitQuoteProjectTags {
    sectorTags: number[] = [];
    quoteProjectTags: number[] = [];
}

export const splitQuoteProjectTags = (tags: number[], quoteProjectTags: DropDownItem[], sectorTags: DropDownItem[]): ResponsesSplitQuoteProjectTags => {
    let response = new ResponsesSplitQuoteProjectTags();
    if(!tags) tags = [];
    if(!quoteProjectTags) quoteProjectTags = [];
    if(!sectorTags) sectorTags = [];

    response.sectorTags = sectorTags.filter(
        sectorTag => tags.includes(sectorTag.id)
    ).map(sectorTag => sectorTag.id);
    response.quoteProjectTags = quoteProjectTags.filter(
        quoteProjectTag => tags.includes(quoteProjectTag.id)
    ).map(quoteProjectTag => quoteProjectTag.id);

    return response;
}