import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSortModule } from '@angular/material/sort';
import { TranslateModule } from '@ngx-translate/core';
import { TpGridComponent } from 'app/components/tp-grid/tp-grid.component';
import { TpPaginationModule } from 'app/components/tp-pagination/tp-pagination.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { TpButtonModule } from '../tp-button/tp-button.module';
import { TpCheckboxModule } from '../tp-checkbox/tp-checkbox.module';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatTooltipModule} from "@angular/material/tooltip";
@NgModule({
    declarations: [TpGridComponent],
    imports: [
        CommonModule,
        MatSortModule,
        FormsModule,
        NgxPaginationModule,
        MatButtonModule,
        MatMenuModule,
        MatIconModule,
        TpPaginationModule,
        TranslateModule,
        FlexModule,
        TpButtonModule,
        TpCheckboxModule,
        DragDropModule,
        MatTooltipModule,
    ],
    exports: [TpGridComponent],
})
export class TpGridModule {
}