import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TranslateModule } from '@ngx-translate/core';
import { TpToggleComponent } from 'app/components/tp-toggle/tp-toggle.component';

@NgModule({
    declarations: [TpToggleComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatSlideToggleModule,
        MatFormFieldModule,
        TranslateModule,
        FormsModule,
    ],
    exports: [TpToggleComponent],
})
export class TpToggleModule {
}