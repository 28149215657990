import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TotalsComponent } from './totals.component';
import {TranslateModule} from "@ngx-translate/core";
import {FormatNumberPipeModule} from "../../pipes/format-number-pipe/format-number-pipe.module";
@NgModule({
    declarations: [
        TotalsComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        FormatNumberPipeModule
    ],
    exports: [
        TotalsComponent
    ]
})
export class TotalsModule { }