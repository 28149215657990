import { Pipe, PipeTransform } from '@angular/core';
import {Observable, of} from "rxjs";

@Pipe({
  name: 'serviceDescription'
})
export class ServiceDescriptionPipe implements PipeTransform {
    private fromToLanguagesSymbol = '-> ';
    /**
     * @function transform
     * @param value: string
     * @param source?: string
     * @param target?: string
     * @description build service description contacting languages if passed as parameters
     * @return Observable<string>
     * */
    transform(value: string, source?: string, target?: string): Observable<string> {
        return of(((source && target) ? value + ' ' + source + this.fromToLanguagesSymbol + target : value));
    }
}