import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { TranslateModule } from '@ngx-translate/core'
import { TpTextAreaComponent } from 'app/components/tp-text-area/tp-text-area.component'
import {ClickOutsideModule} from "ng-click-outside";
import {TpButtonModule} from "../tp-button/tp-button.module";
import {AutoFocusModule} from "../../directives/input-focus/auto-focus.module";
import {AutoClickModule} from "../../directives/auto-click/auto-click.module";
import {MatTooltipModule} from "@angular/material/tooltip";

@NgModule({
    declarations: [TpTextAreaComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        TranslateModule,
        FormsModule,
        ClickOutsideModule,
        TpButtonModule,
        AutoFocusModule,
        AutoClickModule,
        MatTooltipModule
    ],
    exports: [TpTextAreaComponent],
})
export class TpTextAreaModule {}