import {PostQuoteJob, PostQuoteJobFile} from 'app/models/general/quote/post-models/post-quote-job';

export class ResponseQuoteJobData extends PostQuoteJob
{
    serviceDescription?: string;
    unitDescription?: string;
    currencyDescription?: string;
    currencyId?: number;
    currencyName?: string;
    currencyCode?: string;
    currencySymbol?: string;
    totalAmount?: number | string;
    timeLineUnitDescription?:string;
    discountOrSurcharge?: number;
    quoteJobFilesResponse?: PostQuoteJobFile[];
    clientContactName?: string;
    clientContactId?: number;
    projectManagerName?: string;
    projectManagerId?: number;
    quoteDateSent?: string;
}