import { NgModule } from '@angular/core';
import { ResourceNamePipe } from 'app/pipes/resource-name/resource-name-pipe';
import { ResourcesNamesPipe } from './resources-names-pipe';

@NgModule({
    declarations: [ResourceNamePipe, ResourcesNamesPipe],
    providers: [ResourceNamePipe, ResourcesNamesPipe],
    exports: [
        ResourceNamePipe,
        ResourcesNamesPipe,
    ],
})
export class ResourceNamePipeModule {
}