import {Action, combineReducers, createFeatureSelector, createReducer, createSelector, on} from '@ngrx/store';
import {entityInitial, entitySearchInitial, createEntity, EntityAdditional, EntitySearchAdditional} from "../../../../store/models";
import {ResponseResourceFile} from "../../resources/models/response-models/response-resource-file";
import {ResponseResourceServiceRateData} from "../../resources/models/response-models/response-resource-service-rate-data";
import {ResponseResourceGridData} from "../../resources/models/response-models/response-resource-grid-data";
import {ResourceSearch} from "../../../../models/general/system-models/search-models/resource/resource-search";
import {ResponseResourceData} from "../../resources/models/response-models/response-resource-data";
import {sortedGrid} from "../../../../utills";
import {pmPortalStateFeatureSelector} from "../features";
import {ResponseResourceNoteData} from "../../resources/models/response-models/response-resource-note-data";

export const resourcesFeatureKey = 'resources';
export const resourceGridFeatureKey = 'resourceGrid';
export const resourceFilesFeatureKey = 'responseResourceFile';
export const resourceRatesFeatureKey = 'resourceRates';
export const resourceNotesFeatureKey = 'resourceNotes';

export const resourcesEntity = createEntity<ResponseResourceData, EntityAdditional>(
    pmPortalStateFeatureSelector,
    resourcesFeatureKey,
    entityInitial({}),
);

export const resourceGridEntity = createEntity<ResponseResourceGridData, EntitySearchAdditional<ResourceSearch>>(
    pmPortalStateFeatureSelector,
    resourceGridFeatureKey,
    entitySearchInitial( {showAll: false, sorted: sortedGrid, searchCriteria: {showActiveOnly: true}}),
);

export const resourceFilesEntity = createEntity<ResponseResourceFile, EntityAdditional>(
    pmPortalStateFeatureSelector,
    resourceFilesFeatureKey,
    entityInitial({}),
);

export const resourceRatesEntity = createEntity<ResponseResourceServiceRateData, EntityAdditional>(
    pmPortalStateFeatureSelector,
    resourceRatesFeatureKey,
    entityInitial()
);

export const resourceNotesEntity = createEntity<ResponseResourceNoteData, EntityAdditional>(
    pmPortalStateFeatureSelector,
    resourceNotesFeatureKey,
    entityInitial({})
);