import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragAndDropFileAreaDirective } from './drag-and-drop-file-area.directive';

@NgModule({
    declarations: [
        DragAndDropFileAreaDirective
    ],
    exports: [
        DragAndDropFileAreaDirective
    ],
    imports: [
        CommonModule
    ]
})
export class DragAndDropFileAreaModule { }