<ng-template #buttonContent>
    <ng-container *ngIf="label">{{ label }}</ng-container>
    <ng-container *ngIf="!label">
        <ng-content></ng-content>
    </ng-container>
</ng-template>

<ng-container [ngSwitch]="_style">
    <ng-container *ngSwitchCase="buttonType.cancel">
        <button
            mat-raised-button
            [color]="color"
            type="button"
            [class.cursor-block]="disabled"
            [class.mr-3]="showMarginRightForCancelButton"
            [class.mr-0]="!showMarginRightForCancelButton"
            (click)="emitEvent($event)"
            [disabled]="disabled"
            [matTooltip]="disabledTooltipContent"
            [matTooltipDisabled]="!disabled"
            class="btn-negative"
        >
            <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
        </button>
    </ng-container>
    
    <ng-container *ngSwitchCase="buttonType.submit">
        <button
            mat-raised-button
            [color]="color"
            [class.cursor-block]="disabled"
            class="btn-affirmative btn-submit"
            [disabled]="disabled"
            type="button"
            (click)="emitEvent($event)"
            [matTooltip]="disabledTooltipContent"
            [matTooltipDisabled]="!disabled"
        >
            <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
        </button>
    </ng-container>
    
    <ng-container *ngSwitchCase="buttonType.smallPrimary">
        <button
            mat-raised-button
            [color]="color"
            [disabled]="disabled"
            [class.cursor-block]="disabled"
            class="btn-sm btn-primary"
            type="button"
            (click)="emitEvent($event)"
            [matTooltip]="disabledTooltipContent"
            [matTooltipDisabled]="!disabled"
        >
            <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
        </button>
    </ng-container>

    <ng-container *ngSwitchCase="buttonType.smallSuccess">
        <button
            mat-raised-button
            [color]="color"
            [disabled]="disabled"
            [class.cursor-block]="disabled"
            class="btn-sm btn-success"
            type="button"
            (click)="emitEvent($event)"
            [matTooltip]="disabledTooltipContent"
            [matTooltipDisabled]="!disabled"
        >
            <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
        </button>
    </ng-container>
    
    <ng-container *ngSwitchCase="buttonType.outline">
        <button
            mat-stroked-button
            [color]="color"
            type="button"
            [disabled]="disabled"
            [class.cursor-block]="disabled"
            (click)="emitEvent($event)"
            [matTooltip]="disabledTooltipContent"
            [matTooltipDisabled]="!disabled"
        >
            <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
        </button>
    </ng-container>

    <ng-container *ngSwitchCase="buttonType.secondary">
        <button
            mat-stroked-button
            [color]="color"
            class="dropdown-toggle"
            type="button"
            [disabled]="disabled"
            [class.cursor-block]="disabled"
            (click)="emitEvent($event)"
            [matTooltip]="disabledTooltipContent"
            [matTooltipDisabled]="!disabled"
        >
            <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
        </button>
    </ng-container>
    
    <ng-container *ngSwitchCase="buttonType.dropdown">
        <button
            mat-stroked-button
            [color]="color"
            class="dropdown-toggle"
            type="button"
            [disabled]="disabled"
            [class.cursor-block]="disabled"
            (click)="emitEvent($event)"
            [matTooltip]="disabledTooltipContent"
            [matTooltipDisabled]="!disabled"
        >
            <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
        </button>
    </ng-container>

    <ng-container *ngSwitchCase="buttonType.add">
        <button
            mat-raised-button
            [color]="color"
            type="button"
            [class.cursor-block]="disabled"
            [class.mr-3]="showMarginRightForCancelButton"
            [class.mr-0]="!showMarginRightForCancelButton"
            (click)="emitEvent($event)"
            [disabled]="disabled"
            [matTooltip]="disabledTooltipContent"
            [matTooltipDisabled]="!disabled"
            class="add-button"
        >
            <mat-icon>add</mat-icon>
            <ng-container *ngIf="!iconClass">
                <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
            </ng-container>
        </button> 
    </ng-container>

    <ng-container *ngSwitchCase="buttonType.icon">
        <button
            mat-icon-button
            [color]="color"
            type="button"
            [class.cursor-block]="disabled"
            (click)="emitEvent($event)"
            [disabled]="disabled"
            [matTooltip]="disabledTooltipContent"
            [matTooltipDisabled]="!disabled"
            class="">
            <i class="icon-font-size ion" [ngClass]="getIconClass()"></i>
            <ng-container *ngIf="!iconClass">
                <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
            </ng-container>
        </button>
    </ng-container>

    <ng-container *ngSwitchCase="buttonType.reset_pass">
        <button
            mat-raised-button
            [color]="color"
            [disabled]="disabled"
            [style.width.px]="buttonWidth"
            [class.cursor-block]="disabled"
            class="btn-sm btn-success"
            type="button"
            (click)="emitEvent($event)"
            [matTooltip]="disabledTooltipContent"
            [matTooltipDisabled]="!disabled"
        >
            <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
        </button>
    </ng-container>
</ng-container>