import { Injectable } from '@angular/core';
import {TPStorageService} from "../storage/tp-storage.service.ts";
import {StorageKeys} from "../../utills/enums/storage-keys";

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
    isDarkTheme = false;
    constructor(
        private tpStorageService: TPStorageService
    ) { }

    setDarkTheme(): void {
        this.setTheme('dark');
    }
    setLightTheme(): void {
        this.setTheme('light');
    }
    setTheme(theme: 'dark' | 'light'): void {
        this.isDarkTheme = (theme === 'dark');
        document.getElementsByTagName('body')[0].setAttribute('color-theme', theme);
        this.tpStorageService.writeLocalStorage(StorageKeys.Theme, theme);
    }
    init(): void{
        const theme = this.tpStorageService.readLocalStorage(StorageKeys.Theme) as 'dark' | 'light';
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) this.setTheme('dark');
        if(theme) this.setTheme(theme);
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => {
            const theme = e.matches ? "dark" : "light";
            this.setTheme(theme);
        });
    }
}
