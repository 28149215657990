import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { DropDownItem } from 'app/models/general/dropdownItem.model';
import { PaginationControlsDirective } from 'ngx-pagination';
import { Subject } from 'rxjs';

export interface TpPaginationPageChangedEvent {
    page: number
    count: number
}

@Component({
    selector: 'tp-pagination',
    templateUrl: './tp-pagination.component.html',
    styleUrls: ['./tp-pagination.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TpPaginationComponent implements AfterViewInit, OnInit, OnDestroy {

    @ViewChild('paginator', { static: true }) paginator: PaginationControlsDirective;

    @Output() changePage = new EventEmitter<TpPaginationPageChangedEvent>();

    @Input() name = 'paginationApi';

    @Input() selectedCountOfItems = 20;

    @Input() set options(value: number[]) {
        if (value?.length > 0) {
            this._options = value;
            this.dropdownOptions = this.mapOptionsToDropdownOptions();
            this.selectedCountOfItems = value[0];
            this.paginator.setCurrent(1);
        }

        this.ref.detectChanges();
    }

    get options(): number[] {
        return this._options;
    }

    dropdownOptions: DropDownItem[] = [
        {
            name: '10',
            id: 10,
        },
        {
            name: '20',
            id: 20,
        },
        {
            name: '50',
            id: 50,
        },
        {
            name: '100',
            id: 100,
        },
    ];

    selectedPage = 1;

    private _options: number[] = [10, 20, 50, 100];
    private onDestroy$ = new Subject();

    constructor(private readonly ref: ChangeDetectorRef) {
    }

    ngOnInit() {
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    ngAfterViewInit(): void {
        this.paginator.setCurrent(1);
        this.ref.markForCheck();
    }

    pageChanged(newPage: number, newCount: number): void {
        this.selectedPage = newPage;
        this.selectedCountOfItems = newCount;

        this.changePage.emit({
            page: this.selectedPage,
            count: this.selectedCountOfItems,
        });

        this.ref.detectChanges();
    }

    private mapOptionsToDropdownOptions(): DropDownItem[] {
        return this._options?.map((o) => ({
            name: o.toString(),
            id: o,
        }));
    }
}