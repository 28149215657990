import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TpDropdownModule } from 'app/components/tp-dropdown/tp-dropdown.module';
import { ClientContractSelectorComponent } from './client-contract-selector.component';


@NgModule({
    declarations: [ClientContractSelectorComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TranslateModule,
        TpDropdownModule,
    ],
    exports: [
        ClientContractSelectorComponent,
    ],
})
export class ClientContractSelectorModule {
}