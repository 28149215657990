import { Pipe, PipeTransform } from '@angular/core';
import { UserService } from 'app/services/general/user.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
    name: 'salesPersonName',
})
export class SalesPersonNamePipe implements PipeTransform {
    constructor(private userService: UserService) {
    }
    
    transform(value: number): Observable<string> {
        return this.userService.getSalesPersons().pipe(
            map((salesPersons) => {
                const salesPerson = salesPersons?.find((f) => f.userId == value);
                return salesPerson
                    ? `${salesPerson.firstName} ${salesPerson.lastName}`
                    : '---';
            }),
        );
    }
}