import { NgModule } from '@angular/core'
import { ProgressBarModule } from '@syncfusion/ej2-angular-progressbar';

import { TpProgressBarComponent } from './tp-progress-bar.component';

@NgModule({
    declarations: [
        TpProgressBarComponent
    ],
    imports: [
        ProgressBarModule
    ],
    exports: [
        TpProgressBarComponent
    ],
})
export class TpProgressBarModule {}