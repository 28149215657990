import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragAndDropFileComponent } from './drag-and-drop-file.component';
import {MatIconModule} from "@angular/material/icon";
import {DragAndDropFileAreaModule} from "../../directives/drag-drop-file-area-directive/drag-and-drop-file-area.module";
import {TranslateModule} from "@ngx-translate/core";
import {TpButtonModule} from "../tp-button/tp-button.module";

@NgModule({
    declarations: [
        DragAndDropFileComponent
    ],
    exports: [
        DragAndDropFileComponent
    ],
    imports: [
        CommonModule,
        MatIconModule,
        DragAndDropFileAreaModule,
        TranslateModule,
        TpButtonModule
    ]
})
export class DragAndDropFileModule { }