import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';

import Swal from 'sweetalert2/dist/sweetalert2.js';

export interface NotificationAlertModel {
    title?: string,
    text?: string,
    confirmButtonText?: string,
    cancelButtonText?: string,
    confirmClicked?: () => void,
    cancelClicked?: () => void,
}

export interface NotificationAlertModelUsingObservables {
    titleTranslationKey: string,
    titleDefaultText: string,
    
    textTranslationKey: string,
    textDefaultText: string,
    
    confirmButtonTextTranslationKey?: string,
    confirmButtonDefaultText?: string,
    
    cancelButtonTextTranslationKey?: string,
    cancelButtonDefaultText?: string,
    
    confirmClicked: () => void,
    cancelClicked?: () => void,
}

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    
    private showSpinnerCallCounts = 0;
    private spinnerName: string;
    public spinnerStatus: boolean;
    
    constructor(
        private translateService: TranslateService,
        private toastr: ToastrService,
        private spinner: NgxSpinnerService
    ) {}
    
    setupSpinner(appSpinnerName: string): void {
        this.showSpinnerCallCounts = 0;
        this.spinnerName = appSpinnerName;
    }

    startSpinner(): void {
        if(!this.spinnerName) this.spinnerName = 'tpSpinner';
        this.spinner.show(this.spinnerName, {
            type: 'ball-circus',
            size: 'large',
            bdColor: '',
            color: '#91b7e1',
            fullScreen: true,
        });
        this.spinnerStatus = true;
    }

    stopSpinner(): void {
        this.spinner.hide(this.spinnerName);
        this.spinnerStatus = false;
    }
    
    showSuccess(message: string, title: string = undefined): void {
        this.toastr.success(message, title);
    }
    
    showMessageUsingTranslationKeys(props: {
        messageKey: string,
        titleKey?: string,
        defaultMessageText?: string,
        defaultTitleText?: string,
        isSuccess: boolean,
    }): void {
        const message$ = props.messageKey ? this.getTranslatedValue(props.messageKey, props.defaultMessageText) : of(props.defaultMessageText);
        const title$ = this.getTranslatedValue(props.titleKey || (props.isSuccess ? 'tp-common-success' : 'tp-common-error'),
            props.defaultTitleText || (props.isSuccess ? 'Success' : 'Error'));
        
        combineLatest([message$, title$]).pipe(take(1)).subscribe(([message, title]) => {
            if (props.isSuccess) {
                this.showSuccess(message, title);
            } else {
                this.showError(message, title);
            }
        });
    }
    
    
    showError(message: string, title: string): void {
        this.toastr.error(message, title);
    }
    showInfo(message: string, title: string): void {
        this.toastr.info(message, title);
    }

    showConfirmCancelDialog(model: NotificationAlertModel): void {
        const yes = this.translateService.instant('tp-yes', { defaultText: 'Yes' });
        const no = this.translateService.instant('tp-no', { defaultText: 'No' });
        Swal.fire({
            title: model.title,
            text: model.text,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: model.confirmButtonText ?? yes,
            cancelButtonText: model.cancelButtonText ?? no,
            reverseButtons: true,
            confirmButtonColor: '#0B4E83',
        }).then((result) => {
            if (result.value) {
                if (model.confirmClicked != null) model.confirmClicked();
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                if (model.cancelClicked != null) model.cancelClicked();
            }
        });
    }
    
    showConfirmCancelDialogUsingTranslationKeys(model: NotificationAlertModelUsingObservables): void {
        const title$ = this.getTranslatedValue(model.titleTranslationKey, model.titleDefaultText);
        const text$ = this.getTranslatedValue(model.textTranslationKey, model.textDefaultText);
        const cancel$ = this.getTranslatedValue(model.cancelButtonTextTranslationKey || 'tp-common-no', model.cancelButtonDefaultText || 'No');
        const confirm$ = this.getTranslatedValue(model.confirmButtonTextTranslationKey || 'tp-common-yes', model.confirmButtonDefaultText || 'Yes');
        
        combineLatest([title$, text$, cancel$, confirm$]).pipe(take(1)).subscribe(([title, text, cancel, confirm]) => {
            this.showConfirmCancelDialog({
                title,
                text,
                confirmButtonText: confirm,
                cancelButtonText: cancel,
                confirmClicked: model.confirmClicked,
                cancelClicked: model.cancelClicked,
            });
        });
    }
    
    private getTranslatedValue(key: string, defaultText?: string): Observable<string> {
        return defaultText
            ? this.translateService.get(key, { defaultText: defaultText }) as Observable<string>
            : this.translateService.get(key) as Observable<string>;
    }
}