import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatSelectModule } from '@angular/material/select'
import { TranslateModule } from '@ngx-translate/core'
import { TpMultiSelectDropDownComponent } from 'app/components/tp-multiselect-dropdown/tp-multiselect-dropdown.component'
import { ClickOutsideModule } from 'ng-click-outside'

@NgModule({
    declarations: [TpMultiSelectDropDownComponent],
    imports: [
        CommonModule,
        TranslateModule,

        FormsModule,
        ReactiveFormsModule,

        MatSelectModule,
        MatFormFieldModule,

        ClickOutsideModule,
    ],
    exports: [TpMultiSelectDropDownComponent],
})
export class TpMultiselectDropdownModule {}