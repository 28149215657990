import { NgModule } from '@angular/core';
import { FormatDatePipe } from 'app/pipes/format-date/format-date.pipe';

@NgModule({
    declarations: [FormatDatePipe],
    providers: [FormatDatePipe],
    exports: [
        FormatDatePipe,
    ],
})
export class FormatDatePipeModule {
}