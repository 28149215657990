import { Pipe, PipeTransform } from '@angular/core';
import { GlobalConfiguration } from 'app/models/general/global-configuration';
import { RefDataService } from 'app/services/general/refData.service';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
    name: 'formatNumberWithCurrency',
})
export class FormatNumberWithCurrencyPipe implements PipeTransform {
    constructor(private refDataService: RefDataService,
                private globalConfiguration:GlobalConfiguration) {
    }
    
    transform(value: number, currency = '', decimalPlaces?): Observable<string> {
        value = Number(value);
        if (value || value === 0) {
            const symbol = this.globalConfiguration.companyBranchSetting.currencySymbol;
            const valueRounded = parseFloat(value.toFixed(decimalPlaces >= 0 ? decimalPlaces : this.globalConfiguration.companyBranchSetting.noOfDecimals ?? 0));
            
            return of(symbol
                ? `${symbol} ${valueRounded.toLocaleString()}`
                : valueRounded.toLocaleString());
        } else {
            return of('---');
        }
    }
}