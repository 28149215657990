import { Injectable } from '@angular/core'

@Injectable({
    providedIn: 'root',
})
export class FormatSearchCriteriasService {
    constructor() {}

    formatFilters(filters) {
        const formatedFilters = {}
        for (const elem in filters) {
            if (filters[elem]) {
                if (Array.isArray(filters[elem]) && !filters[elem].length) {
                    continue
                }
                if (typeof filters[elem] === 'boolean' && filters[elem]) {
                    formatedFilters[elem] = filters[elem]
                    continue
                }
                if (typeof filters[elem] === 'string' && filters[elem]) {
                    formatedFilters[elem] = String(filters[elem])
                    continue
                }
                formatedFilters[elem] = isNaN(Number(filters[elem]))
                    ? filters[elem]
                    : Number(filters[elem])
            }
        }

        return formatedFilters
    }
}