<div class="file-list-container" [@fileListAnimation]="files.length">
    <div class="d-flex align-items-center file-content w-100 py-2" *ngFor="let file of files | filter : fileType; let index = index">
        <div *ngIf="allowSelecting" class="pr-2">
            <tp-checkbox [(ngModel)]="file.selected" (ngModelChange)="onSelectedFilesChange()"></tp-checkbox>
        </div>
        <div class="pr-3 cursor-pointer" (click)="clickFile(file)" [matTooltipDisabled]="!allowDownloading" matTooltip="{{'tp-download' | translate : '{defaultText:\'Download\'}'}}">
            <i class="fas {{file.icon}}"></i>
        </div>
        <div class="w-100">
            <div>
                <span class="mr-2">{{file.description}}</span>
                <mat-icon *ngIf="allowDeleting" class="file-delete-icon cursor-pointer" matTooltip="{{'tp-delete' | translate : '{defaultText:\'Delete\'}'}}" (click)="deleteFile(file,index); $event.stopPropagation()">close</mat-icon>
            </div>
            <div *ngIf="file.progress && file.status === FileStatusTypes.Uploading; else uploaded">
                <div class="file-progress py-1">
                    <mat-progress-bar mode="determinate" [value]="file.loaded" color="primary"></mat-progress-bar>
                </div>
                <div>
                    <span class="mr-3">{{uploadedSize(file)}} of {{file.size}} KB</span>
                    <span class="float-right">{{file.loaded}}%</span>
                </div>
            </div>
            <ng-template #uploaded>
                <div>
                    <span class="mr-3">{{file.size}} KB</span>
                </div>
            </ng-template>
        </div>
    </div>
</div>