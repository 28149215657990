import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { TranslateModule } from '@ngx-translate/core'
import { TpTextBoxComponent } from 'app/components/tp-text-box/tp-text-box.component'
import {ClickOutsideModule} from "ng-click-outside";
import {AutoFocusModule} from "../../directives/input-focus/auto-focus.module";
import {TpButtonModule} from "../tp-button/tp-button.module";
import {AutoClickModule} from "../../directives/auto-click/auto-click.module";
import {MatTooltipModule} from "@angular/material/tooltip";

@NgModule({
    declarations: [TpTextBoxComponent],
    imports: [
        CommonModule,
        ClickOutsideModule,
        MatInputModule,
        MatFormFieldModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        MatIconModule,
        AutoFocusModule,
        TpButtonModule,
        AutoClickModule,
        MatTooltipModule,
    ],
    exports: [TpTextBoxComponent],
})
export class TpTextBoxModule {}