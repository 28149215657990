import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { TranslateModule } from '@ngx-translate/core'
import { TpAutoCompleteDropDownComponent } from 'app/components/tp-autocomplete-dropdown/tp-autocomplete-dropdown.component'
import {ClickOutsideModule} from "ng-click-outside";

@NgModule({
    declarations: [TpAutoCompleteDropDownComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatInputModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        TranslateModule,
        FormsModule,
        ClickOutsideModule
    ],
    exports: [TpAutoCompleteDropDownComponent],
})
export class TpAutocompleteDropdownModule {}