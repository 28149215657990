import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddActionsComponent } from './add-actions.component';
import {TpButtonModule} from "../../tp-button/tp-button.module";
import {
    CheckPermissionsDirectiveModule
} from "../../../directives/check-permission-directive/check-permission-directive-module";
@NgModule({
    declarations: [
        AddActionsComponent
    ],
    imports: [
        CommonModule,
        TpButtonModule,
        CheckPermissionsDirectiveModule
    ],
    exports: [
        AddActionsComponent
    ]
})
export class AddActionsModule { }