<router-outlet></router-outlet>

<tp-modal modalClass="small" #timeout>
    <div header>
        <span class="font-weight-bold large-text" [translate]="'tp-session-timed-out'" [translateParams]="{ defaultText: 'Session timed out.' }"></span>
    </div>
    <div body class="px-2">
        <span class="font-weight-bold medium-text">
            <span [translate]="'tp-you-will-be-logged-off-in'" [translateParams]="{ defaultText: 'You will be logged off in ' }"></span>
            {{idleState}}
            <span [translate]="'tp-seconds'" [translateParams]="{ defaultText: 'seconds ' }"></span>
        </span>
    </div>
    <div footer class="text-right">
        <tp-button buttonStyle="cancel" (buttonClicked)="logoutIdleTimeOut()"
            [label]="'tp-logout' | translate: '{ defaultText : \'Logout\'}'"></tp-button>
        <tp-button buttonStyle="submit" (buttonClicked)="stay()" [label]="'tp-stay' | translate: '{ defaultText : \'Stay\'}'">
        </tp-button>
    </div>
</tp-modal>

<ngx-spinner type="ball-circus" name="tpSpinner" template="<img width='500px' src='assets/spinner/tp-spinner.gif'/>"></ngx-spinner>