import {createReducer, on } from '@ngrx/store';
import {ResponseServiceData} from "../../../admin/manage-services/models/response-models/response-service-data";
import {ResponseServiceGridData} from "../../../admin/manage-services/models/response-models/response-service-grid-data";
import {
    createEntity,
    EntityAdditional,
    entityInitial,
    EntitySearchAdditional,
    entitySearchInitial
} from "../../../../store/models";
import {pmPortalStateFeatureSelector} from "../features";
import {sortedGrid} from "../../../../utills";
export const servicesFeatureKey = 'services';
export const serviceGridFeatureKey = 'serviceGrid';

export const servicesEntity = createEntity<ResponseServiceData, EntityAdditional>(
    pmPortalStateFeatureSelector,
    servicesFeatureKey,
    entityInitial({}),
);
export const serviceGridEntity = createEntity<ResponseServiceGridData, EntitySearchAdditional<any>>(
    pmPortalStateFeatureSelector,
    serviceGridFeatureKey,
    entitySearchInitial( { searchCriteria: { }, searchFormValue: { }, sorted: sortedGrid })
);