import {Injectable} from '@angular/core'
import {HttpClient, HttpHeaders} from '@angular/common/http'
import {AppConfigService} from 'app/services/app.settings.service'
import {Observable} from 'rxjs'
import {UnitModel} from 'app/main/admin/unit/models'
import {StatusTypes} from "../../../../utills";
import {BaseResultModel} from "../../../../models";
import {DropDownItem} from "../../../../models/general/dropdownItem.model";

@Injectable({
    providedIn: 'root',
})
export class UnitServiceService {
    /**
     * Path uri.
     * @type {string}
     * @private
     */
    private _uri = ''

    /**
     * Url to endpoint api.
     * @type {string}
     */
    private endpoint = '/system'

    /**
     * Component constructor and DI injection point.
     * @param {HttpClient} http
     * @param {AppConfigService} config
     */
    private headers = new HttpHeaders({ 'Content-Type': 'application/json' })
    constructor(private http: HttpClient, configService: AppConfigService) {
        if (configService) {
            this._uri = configService.getAPIUrl();
        }
    }

    search<T>(): Observable<UnitModel> {
        return this.http.get<UnitModel>(`${this._uri}${this.endpoint}/units`)
    }

    public getUnits(): Observable<BaseResultModel<UnitModel[]>> {
        return this.http.get<BaseResultModel<UnitModel[]>>(`${this._uri}${this.endpoint}/unit/grid`);
    }

    public getUnitsByStatusId(statusId: StatusTypes = StatusTypes.active): Observable<BaseResultModel<UnitModel[]>> {
        return this.http.get<BaseResultModel<UnitModel[]>>(`${this._uri}${this.endpoint}/unit/${statusId}`);
    }

    public getUnitsDropDown(statusId: StatusTypes = StatusTypes.active): Observable<BaseResultModel<DropDownItem[]>> {
        return this.http.get<BaseResultModel<DropDownItem[]>>(`${this._uri}${this.endpoint}/unit/${statusId}/dropdown`);
    }

    public getUnitsByCategoryIdDropdown(categoryId: number): Observable<BaseResultModel<DropDownItem>> {
        return this.http.get<BaseResultModel<DropDownItem>>(`${this._uri}${this.endpoint}/unit/category/${categoryId}/dropdown`);
    }

    public getUnitById(id: number): Observable<BaseResultModel<UnitModel>> {
        const endpoint = `${this._uri}${this.endpoint}/unit/${id}`
        return this.http.get<BaseResultModel<UnitModel>>(`${endpoint}`);
    }

    public addUnit(unit: UnitModel): Observable<BaseResultModel<UnitModel>> {
        const endpoint = `${this._uri}${this.endpoint}/unit`;
        return this.http.post<BaseResultModel<UnitModel>>(`${endpoint}`, unit);
    }

    deleteUnit(id: number): Observable<BaseResultModel<boolean>> {
        const endpoint = `${this._uri}${this.endpoint}/unit/${id}`
        return this.http.delete<BaseResultModel<boolean>>(`${endpoint}`);
    }

    saveUnit(id: number, model: UnitModel): Observable<BaseResultModel<UnitModel>> {
        if (id != null) {
            const endpoint = `${this._uri}${this.endpoint}/unit`
            return this.http.put<BaseResultModel<UnitModel>>(`${endpoint}`, model)
        } else {
            return this.addUnit(model);
        }
    }
}