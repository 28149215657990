import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { differenceInDays, differenceInMonths, differenceInYears, getYear } from 'date-fns';

@Pipe({
    name: 'dateToDiffFromNow',
})
export class DateToDiffFromNowPipe implements PipeTransform {

    constructor(private readonly translateService: TranslateService) {
    }

    transform(date: number | Date): string {
        if (!date || getYear(new Date(date)) < 1950) {
            return '---';
        }

        let diffInDays = differenceInDays(new Date(Date.now()), new Date(date));

        const dateIsInTheFuture = diffInDays < 0;

        let relativeToTodaySuffix = dateIsInTheFuture
            ? this.translateService.instant('tp-from-now', { defaultText: 'from now' }) as string
            : this.translateService.instant('tp-ago', { defaultText: 'ago' }) as string;

        if (dateIsInTheFuture) {
            diffInDays = differenceInDays(new Date(date), new Date(Date.now()));
        }

        if (diffInDays === 0) {

            return this.translateService.instant('tp-today', { defaultText: 'Today' }) as string;

        } else if (diffInDays <= 31) {
            if (dateIsInTheFuture) {
                diffInDays += 1; // differenceInDays excludes the last day, need to account for it
            }

            const daysTranslation = this.translateService.instant('tp-days', { defaultText: 'days' }) as string;
            return `${diffInDays.toString()} ${daysTranslation} ${relativeToTodaySuffix}`;

        } else if (diffInDays <= 365) {

            const diffInMonths = differenceInMonths(new Date(Date.now()), new Date(date));
            const monthsTranslation = this.translateService.instant('tp-months', { defaultText: 'months' }) as string;
            return `${diffInMonths.toString()} ${monthsTranslation} ${relativeToTodaySuffix}`;

        } else {

            const diffInYears = differenceInYears(new Date(Date.now()), new Date(date));
            const yearsTranslation = this.translateService.instant('tp-years', { defaultText: 'years' }) as string;
            return `${diffInYears.toString()} ${yearsTranslation} ${relativeToTodaySuffix}`;

        }
    }
}