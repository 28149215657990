import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatSelectModule } from '@angular/material/select'
import { TranslateModule } from '@ngx-translate/core'
import { TpDropDownComponent } from 'app/components/tp-dropdown/tp-dropdown.component'
import { ClickOutsideModule } from 'ng-click-outside'
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import {ScrollingModule} from "@angular/cdk/scrolling";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatRippleModule} from "@angular/material/core";
import {TpToggleModule} from "../tp-toggle/tp-toggle.module";
import {AutoFocusModule} from "../../directives/input-focus/auto-focus.module";
import {TpStarRatingModule} from "../tp-star-rating/tp-star-rating.module";

@NgModule({
    declarations: [TpDropDownComponent],
    imports: [
        CommonModule,

        MatSelectModule,
        MatFormFieldModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        NgxMatSelectSearchModule,
        ClickOutsideModule,
        ScrollingModule,
        MatCheckboxModule,
        MatRippleModule,
        TpToggleModule,
        AutoFocusModule,
        TpStarRatingModule,
    ],
    exports: [TpDropDownComponent],
})
export class TpDropdownModule {}