import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {catchError, map, concatMap, withLatestFrom, filter, switchMap, mergeMap, share} from 'rxjs/operators';
import { Observable, EMPTY, of } from 'rxjs';
import * as ResourceActions from '../../actions/resource-actions/resource.actions';
import {
    resourceDropdownEntity, resourceFilesEntity, resourceRatesEntity, resourceGridEntity,
    resourcesEntity
} from "../../reducers";
import {Store} from "@ngrx/store";
import {State} from "../../../../../store";
import {ResourceService} from "../../../services/resources/resource.service";
import {NotificationService} from "../../../../../services/notification.service";
import {PostResourceFile} from "../../../resources/models/post-models/post-resource-file";
import {createFormData, removeNullProperties} from "../../../../../utills";
import _ from "lodash";
import {
    mapResourceDataToDropdown,
    mapResourceDataToResourceGridData,
    normalizeResourceData, normalizeResourceGridData,
    mapSearchProps
} from "../../../../../store/utils";
import {UserService} from "../../../../../services/general/user.service";
import {getPrimaryEmail} from "../../../../../services/helpers/person-helper";
import {ResponsePersonData} from "../../../../../models/general/person/response-models/response-person-data";

@Injectable()
export class ResourceEffects {
    resourceGridSearch$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ResourceActions.searchResourceGrid),
            withLatestFrom(this.store.select(resourceGridEntity.selectors.selectState)),
            filter(([action, resourceGrid]) => !(JSON.stringify(resourceGrid.criteria) === JSON.stringify(action)) || !resourceGrid.firstLoad),
            switchMap(([action, resourceGrid]) => {
                this.store.dispatch(resourceGridEntity.actions.updateAdditional({updates: {isLoading: true}}))
                return this.resourceService.search(action).pipe(
                    map(response => {
                        this.store.dispatch(resourceGridEntity.actions.updateAdditional({updates: mapSearchProps(action, response?.response?.count ?? 0)}));
                        const normalized = normalizeResourceGridData(response.response?.result);
                        this.store.dispatch(resourceRatesEntity.actions.upsertMany({entities: normalized.rates}));
                        return resourceGridEntity.actions.setAll({entities: normalized?.gridData ?? []});
                    }),
                    catchError(error => of(resourceGridEntity.actions.updateAdditional({updates: {isLoading: false, error: error}}))),
                )}
            )
        );
    });
    getResource$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ResourceActions.getResource),
            mergeMap((action) => of(action).pipe(withLatestFrom(this.store.select(resourcesEntity.selectors.selectById(action.id))))),
            filter(([action, resource]) => !resource),
            switchMap(([action, resource]) =>
                this.resourceService.getResource(action.id).pipe(
                    map(response => {
                        const normalized = normalizeResourceData(response.response);
                        this.store.dispatch(resourceFilesEntity.actions.upsertMany({entities: normalized.files}));
                        this.store.dispatch(resourceRatesEntity.actions.upsertMany({entities: normalized.rates}));
                        return resourcesEntity.actions.upsertOne({entity: normalized.data});
                    }),
                    catchError(error => {
                        return of(resourcesEntity.actions.updateAdditional({updates:{ error } }));
                    })
                )
            ),
            share()
        );
    });
    addResource$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ResourceActions.addResource),
            concatMap((action) => {
                let formData: FormData = createFormData(_.cloneDeep(action.resource));
                if (!action.resource?.id && action.files.length > 0) {
                    let resourceFileData: PostResourceFile[] = [];
                    let resourceFiles: File[] = [];
                    _.cloneDeep(action.files).forEach((resourceFile, resourceFileIndex) => {
                        if (resourceFile.file) {
                            resourceFiles.push(resourceFile.file);
                            resourceFile.fileIndex = resourceFiles.length - 1;
                        } else resourceFile.fileIndex = null;
                        resourceFile = removeNullProperties(resourceFile);
                        resourceFileData.push(removeNullProperties({...{...resourceFile}, ...{file: null}}));
                    });
                    if (resourceFiles.length > 0) {
                        resourceFiles.forEach(file => {
                            formData.append('resourceFiles', file);
                        });
                    }
                    formData.append('resourceFilesJSON', JSON.stringify(resourceFileData));
                }
               return this.resourceService.addResourceWithFiles(formData).pipe(
                    map(response => {
                        if(action?.sendCredentials) this.sendCredentials(response.response.person);
                        const normalized = normalizeResourceData(response.response);
                        this.notificationService.showSuccess('Saved', 'Resource Saved Successfully');
                        this.store.dispatch(resourceDropdownEntity.actions.addOne({entity: mapResourceDataToDropdown(normalized.data)}));
                        this.store.dispatch(resourceFilesEntity.actions.upsertMany({entities: normalized.files}));
                        this.store.dispatch(resourceRatesEntity.actions.upsertMany({entities: normalized.rates}));
                        this.store.dispatch(resourceGridEntity.actions.unshift({entities: [mapResourceDataToResourceGridData(normalized.data)]}));
                        return resourcesEntity.actions.addOne({entity: normalized.data});
                    }),
                    catchError(error => {
                        return of(resourcesEntity.actions.updateAdditional({updates: { error }}));
                    })
                )}
            ),
            share()
        );
    });
    updateResource$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ResourceActions.updateResource),
            concatMap((action) => {
                return this.resourceService.updateResource(createFormData(_.cloneDeep(action.resource))).pipe(
                    map(response => {
                        if(action?.sendCredentials) this.sendCredentials(response.response.person);
                        this.notificationService.showSuccess('Saved', 'Resource Saved Successfully');
                        this.store.dispatch(resourceGridEntity.actions.updateOne({update: {id: response.response.id, changes: mapResourceDataToResourceGridData(response.response)}}))
                        this.store.dispatch(resourceDropdownEntity.actions.updateOne({update: {id: response.response.id, changes: mapResourceDataToDropdown(response.response)}}))
                        return resourcesEntity.actions.updateOne({update: {id: response.response.id, changes: response.response}});
                    }),
                    catchError(error => {
                        return of(resourcesEntity.actions.updateAdditional({updates: { error }}));
                    })
                )}
            ),
            share()
        );
    });
    saveResourceCatCount$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ResourceActions.saveResourceCatCount),
            concatMap((action) => {
                return this.resourceService.saveResourceCatCount(action).pipe(
                    map(response => {
                        this.notificationService.showSuccess('Saved', 'Resource Cat Count Discounts Saved Successfully');
                        return resourcesEntity.actions.updateOne({update: {id: action.resourceId, changes: {resourceCatCount: [response.response]}}});
                    }),
                    catchError(error => {
                        return of(resourcesEntity.actions.updateAdditional({updates: { error }}));
                    })
                )}
            ),
            share()
        );
    });
    deleteResource$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ResourceActions.deleteResource),
            concatMap((action) =>
                this.resourceService.deleteResources(action.ids).pipe(
                    map(response => {
                        this.notificationService.showSuccess('Deleted', 'Resource Deleted Successfully');
                        this.store.dispatch(resourceGridEntity.actions.removeMany(action));
                        this.store.dispatch(resourceFilesEntity.actions.removePredicate({predicate: (entity) =>  action.ids.includes(entity.resourceId)}));
                        this.store.dispatch(resourceRatesEntity.actions.removePredicate({predicate: (entity) =>  action.ids.includes(entity.resourceId)}));
                        this.store.dispatch(resourceDropdownEntity.actions.removeMany(action));
                        return resourcesEntity.actions.removeMany(action);
                    }),
                    catchError(error => {
                        return of(resourcesEntity.actions.updateAdditional({updates: { error }}));
                    })
                )
            ),
            share()
        );
    });
    sendCredentials(person: ResponsePersonData): void {
        const email = getPrimaryEmail(person);
        if(email) this.userService.sendResetPassword([email]).subscribe();
    }


  constructor(
      private actions$: Actions,
      private store: Store<State>,
      private resourceService: ResourceService,
      private notificationService: NotificationService,
      private userService: UserService
  ) {}

}