import {Injectable} from '@angular/core'
import {HttpClient, HttpHeaders} from '@angular/common/http'
import {AppConfigService} from 'app/services/app.settings.service'
import {Observable} from 'rxjs'
import {BaseResultModel, SearchCriteriaModel, SearchResult} from 'app/models'
import {ManageServicesModel} from '../manage-services/models'
import {StatusTypes} from '../../../utills';
import {DropDownItem} from "../../../models/general/dropdownItem.model";
import {PostServiceWithPriceList} from "../manage-services/models/post-models/post-service-with-price-list";
import {ResponseServiceGridData} from "../manage-services/models/response-models/response-service-grid-data";
import {ResponseServiceData} from "../manage-services/models/response-models/response-service-data";

@Injectable({
    providedIn: 'root',
})

export class ManageServicesService {

    /**
     * Path uri.
     * @type {string}
     * @private
     */
    private _uri = '';

    /**
     * Url to endpoint api.
     * @type {string}
     */
    private endpoint = '/system';

    /**
     * Component constructor and DI injection point.
     * @param {HttpClient} http
     * @param {AppConfigService} config
     */

    private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    constructor(private http: HttpClient, configService: AppConfigService) {
        if (configService) {
            this._uri = configService.getAPIUrl();
        }
    }

    public search<T>(model: SearchCriteriaModel<T>): Observable<BaseResultModel<SearchResult<ResponseServiceGridData>>> {
        return this.http.post<BaseResultModel<SearchResult<ResponseServiceGridData>>>(
            `${this._uri}${this.endpoint}/service/search`,
            model
        )
    }

    public getServicesGridData(): Observable<BaseResultModel<ResponseServiceGridData[]>> {
        return this.http.get<BaseResultModel<ResponseServiceGridData[]>>(`${this._uri}${this.endpoint}/service/grid`);
    }

    public getServicesByStatusId(statusId: StatusTypes = StatusTypes.active): Observable<BaseResultModel<ManageServicesModel[]>> {
        return this.http.get<BaseResultModel<ManageServicesModel[]>>(`${this._uri}${this.endpoint}/service/status/${statusId}`);
    }

    public getServicesDropDown(statusId: StatusTypes = StatusTypes.active): Observable<BaseResultModel<DropDownItem[]>> {
        return this.http.get<BaseResultModel<DropDownItem[]>>(`${this._uri}${this.endpoint}/service/status/${statusId}/dropdown`);
    }

    public getParentServiceById(parentId: number): Observable<BaseResultModel<ManageServicesModel>> {
        return this.http.get<BaseResultModel<ManageServicesModel>>(`${this._uri}${this.endpoint}/service/parent/${parentId}`);
    }

    public getParentServices(): Observable<BaseResultModel<ManageServicesModel[]>> {
        return this.http.get<BaseResultModel<ManageServicesModel[]>>(`${this._uri}${this.endpoint}/service/parent/0`);
    }

    public getServiceById(id: string): Observable<BaseResultModel<ResponseServiceData>> {
        const endpoint = `${this._uri}${this.endpoint}/service/${id}/data`;
        return this.http.get<BaseResultModel<ResponseServiceData>>(`${endpoint}`);
    }

    public deleteService(id: string): Observable<BaseResultModel<boolean>> {
        const endpoint = `${this._uri}${this.endpoint}/service/${id}`;
        return this.http.delete<BaseResultModel<boolean>>(`${endpoint}`);
    }

    public addService(service: PostServiceWithPriceList): Observable<BaseResultModel<ResponseServiceData>> {
        const endpoint = `${this._uri}${this.endpoint}/service-with-pricelist`;
        return this.http.post<BaseResultModel<ResponseServiceData>>(`${endpoint}`, service);
    }

    public updateService(id: string, model: PostServiceWithPriceList): Observable<BaseResultModel<PostServiceWithPriceList>> {
        if (id != null && id != '') {
            const endpoint = `${this._uri}${this.endpoint}/service-with-pricelist`;
            return this.http.put<BaseResultModel<PostServiceWithPriceList>>(`${endpoint}`, model);
        } else {
            return this.addService(model);
        }
    }
}