import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ReactiveFormsModule } from '@angular/forms'
import { TranslateModule } from '@ngx-translate/core'
import { TpDropdownModule } from 'app/components/tp-dropdown/tp-dropdown.module'
import { TpMultiselectDropdownModule } from 'app/components/tp-multiselect-dropdown/tp-multiselect-dropdown.module'
import { TpProjectManagersDropDownComponent } from 'app/components/tp-projectManagers-dropdown/tp-projectManagers-dropdown.component'

@NgModule({
    declarations: [TpProjectManagersDropDownComponent],
    imports: [
        CommonModule,

        TpDropdownModule,
        TpMultiselectDropdownModule,

        ReactiveFormsModule,
        TranslateModule,
    ],
    exports: [TpProjectManagersDropDownComponent],
})
export class TpProjectManagersDropdownModule {}