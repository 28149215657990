<div class="grid-container db-table-scroll custom-scrollbar w-100" *ngIf="{
        paginatedTableContent: allowPaging == true ? 
            (_tableContent
            | paginate
                : {
                      id: paginationId,
                      itemsPerPage: pageSize,
                      currentPage: currentPage,
                      totalItems: totalCount
                  }) : _tableContent,
        colspanForFullWidthCell:
            displayedColumns?.length +
            (tableConfig.containsActionColumn ? 1 : 0)
    } as subs">
    <ng-container *ngIf="{
            tableDataExists: subs.paginatedTableContent?.length > 0
        } as context">
        <table class="table card-table db-table sortable" matSort (matSortChange)="sortData($event)">
            <thead class="bg-theme first-table-head">
                <tr>
                    <ng-container *ngFor="let columnHead of displayedColumns">
                        <th *ngIf="columnHead.sortable !== false" [class.centered-header]="columnHead.centered">
                            <div fxLayout="row">
                                <tp-checkbox (ngModelChange)="selectAll = $event; onSelectAllChange(selectAll, columnHead)"
                                    [(ngModel)]="selectAll" [ngModelOptions]="{ standalone: true }"
                                    *ngIf="columnHead.containsCheckBox" name="list_name" class="mr-2"></tp-checkbox>

                                <span class="tp_grid_header_text" [mat-sort-header]="columnHead.dataField">{{
                                    columnHead.title | async }}</span>
                            </div>
                        </th>
                        <th *ngIf="columnHead.sortable === false" [class.centered-header]="columnHead.centered">
                            <div fxLayout="row">
                                <tp-checkbox [(ngModel)]="selectAll" [ngModelOptions]="{ standalone: true }"
                                    (ngModelChange)="selectAll = $event; onSelectAllChange(selectAll, columnHead)"
                                    *ngIf="columnHead.containsCheckBox" name="list_name"></tp-checkbox>

                                <span class="tp_grid_header_text">{{ columnHead.title | async }}</span>
                            </div>
                        </th>
                    </ng-container>

                    <ng-container *ngIf="
                        tableConfig.containsActionColumn == null ||
                        tableConfig.containsActionColumn == true
                    ">
                        <th class="small-column-size text-right">
                            <!-- {{'tp-action' | translate: '{ defaultText : \'Action\'}'}} -->
                        </th>
                    </ng-container>
                </tr>
            </thead>
            <tbody class="table-body" *ngIf="context.tableDataExists" [id]="tableConfig.firstLevelTableReference" cdkDropList (cdkDropListDropped)="drop($event)" [cdkDropListDisabled]="!tableConfig?.order?.allow">
                <ng-container *ngFor="let columnData of subs.paginatedTableContent;let idx = index">
                    <tr @gridAnimation [@.disabled]="disableAnimation" (click)="onSelected(subs.paginatedTableContent, idx, columnData)" class="table-row" [ngClass]="{'disabled-ordering': !tableConfig?.order?.allow}" [id]="tableConfig.firstLevelTableReference + idx?.toString()" cdkDrag [cdkDragBoundary]="tableConfig?.order?.boundary ? '.table-body' : ''" cdkDragPreviewClass="cdk-drag-preview-table-row" (cdkDragStarted)="onStartedDraggingRow()" (cdkDragEnded)="onEndedDraggingRow()" >
                        <td [class.centered-cell]="columnHead.centered" *ngFor="let columnHead of displayedColumns"
                            [class]="columnHead.cssClass">
                            <ng-container *ngIf="
                                !columnHead.isTemplate;
                                else template_content
                            ">
                                <div class="column-inline" fxLayout="row" fxLayoutGap="10px"
                                     fxLayoutAlign="start center">
                                    <tp-checkbox [(ngModel)]="columnData.isSelected"
                                                 (ngModelChange)="columnData.isSelected = $event; itemSelectToggled()"
                                                 [ngModelOptions]="{ standalone: true }" *ngIf="columnHead.containsCheckBox"
                                                 [disabled]="columnHead?.disableCheckBox ? (columnHead.disableCheckBox(columnData.rowData) | async) : false"
                                                 matTooltip="{{'tp-currency-miss-match' | translate : '{defaultText:\'Currency miss match\'}'}}"
                                                 [matTooltipDisabled]="columnHead?.disableCheckBox ? !(columnHead.disableCheckBox(columnData.rowData) | async) : true">
                                    </tp-checkbox>

                                    <span class="db-show-child" (click)="toggleCollapse(columnData)" [class.cursor-default]="!columnData.hasChildRows && !loadOnExpand"
                                          *ngIf="showExpandCollapseIcon && columnHead.isExpandable || loadOnExpand && columnHead.isExpandable">
                                        <i [className]="!columnData.expanded? 'ion-ios-arrow-forward mx-2': 'ion-ios-arrow-down mx-2'" class="ion"
                                           [style]="!columnData.hasChildRows && !loadOnExpand ? 'color: transparent': ''">
                                        </i>
                                    </span>

                                    <div *ngIf="columnHead.columnTextTooltip != null">
                                                                            <span *ngIf="!columnHead.hasParentProject"
                                                                                  class="text-ellipsis"
                                                                                  [matTooltip]="columnHead.columnTextTooltip(columnData.rowData) | async">
                                                                                    {{ columnHead.dataText(columnData.rowData) | async }}
                                                                            </span>
                                    </div>
                                    <div *ngIf="columnHead.columnTextTooltip == null">
                                                                            <span *ngIf="!columnHead.hasParentProject"
                                                                                  class="text-ellipsis">
                                                                                    {{ columnHead.dataText(columnData.rowData) | async }}
                                                                            </span>
                                    </div>

                                    <span *ngIf="columnHead.hasParentProject" class="text-ellipsis">
                                        <a href="{{baseUrl}}{{columnHead.parentProjectId(columnData.rowData) | async}}">
                                            {{columnHead.dataText(columnData.rowData) | async}}
                                        </a>
                                    </span>
                                </div>
                            </ng-container>

                            <ng-template #template_content>
                                <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
                                    <tp-checkbox (ngModelChange)="columnData.isSelected = $event; itemSelectToggled()"
                                                 *ngIf="columnHead.containsCheckBox" [(ngModel)]="columnData.isSelected"
                                                 [ngModelOptions]="{ standalone: true }" name="list_name"
                                                 class="mr-2 select-all"
                                                 [disabled]="columnHead?.disableCheckBox ? (columnHead.disableCheckBox(columnData.rowData) | async) : false"
                                                 matTooltip="{{'tp-currency-miss-match' | translate : '{defaultText:\'Currency miss match\'}'}}"
                                                 [matTooltipDisabled]="columnHead?.disableCheckBox ? !(columnHead.disableCheckBox(columnData.rowData) | async) : true">
                                    </tp-checkbox>

                                    <span class="db-show-child" (click)="toggleCollapse(columnData)" [class.cursor-default]="!columnData.hasChildRows && !loadOnExpand"
                                        *ngIf="showExpandCollapseIcon && columnHead.isExpandable || loadOnExpand && columnHead.isExpandable">
                                        <i [className]="!columnData.expanded ? 'ion-ios-arrow-forward mx-2' : 'ion-ios-arrow-down mx-2'"
                                            class="ion" [style]="!columnData.hasChildRows && !loadOnExpand ? 'color: transparent' : ''">
                                        </i>
                                    </span>

                                    <ng-container [ngTemplateOutlet]="mainGridTemplate" [ngTemplateOutletContext]="{
                                    $implicit: columnHead,
                                    rowData: columnData.rowData,
                                    idx: idx,
                                    row: columnData
                                }">
                                    </ng-container>
                                </div>


                            </ng-template>
                        </td>

                        <ng-container *ngIf="
                            tableConfig.containsActionColumn == null ||
                            tableConfig.containsActionColumn == true
                        ">
                            <td class="small-column-size text-right p-1">
                                <button *ngIf="tableConfig.actionItems?.length > 0" [disabled]="!hasActionToDisplay(columnData.rowData)" mat-icon-button
                                    [matMenuTriggerFor]="menu" aria-label="Action">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu">
                                    <div *ngFor="
                                        let action of tableConfig.actionItems
                                    ">
                                        <button class="d-flex px-2 align-items-center" *ngIf="action.display$ != null ? (action.display$( columnData.rowData, undefined) | async) : action.display( columnData.rowData, undefined)"
                                            mat-menu-item (click)="
                                            action.click(
                                                columnData.rowData,
                                                columnData.rowData,
                                                idx,
                                                columnData,
                                                columnData.rowData
                                            )
                                        ">
                                            <mat-icon *ngIf="action.icon">{{
                                                action.icon
                                                }}</mat-icon>
                                            <span>{{ action.title | async }}</span>
                                        </button>
                                    </div>
                                </mat-menu>
                            </td>
                        </ng-container>
                    </tr>

                    <!-- FOR LEVEL 2 -->
                    <ng-container *ngIf="columnData.expanded && columnData.hasChildRows || loadOnExpand && columnData.expanded">
                        <tr class="for-expand">
                            <td [attr.colspan]="subs.colspanForFullWidthCell">
                                <table class="table card-table db-table nested-table">
                                    <thead class="bg-theme second-table-head">
                                        <tr>
                                            <ng-container *ngFor="
                                                let subColumnHead of tableConfig.level2columns
                                            ">
                                                <th [class.centered-header]="subColumnHead.centered">
                                                    {{
                                                    subColumnHead.title | async
                                                    }}
                                                </th>
                                            </ng-container>

                                            <ng-container *ngIf="
                                                tableConfig.containsLevel2ActionColumn ==
                                                    null ||
                                                tableConfig.containsLevel2ActionColumn ==
                                                    true
                                            ">
                                                <th class="small-column-size text-right">
                                                    <!-- {{'tp-action' | translate: '{ defaultText : \'Action\'}'}} -->
                                                </th>
                                            </ng-container>
                                        </tr>
                                    </thead>
                                    <tbody class="table-body-level2" cdkDropList (cdkDropListDropped)="dropLevel2($event, idx)" [cdkDropListDisabled]="!tableConfig?.level2order?.allow">
                                        <ng-container *ngFor="
                                            let subColumnData of columnData.childRows;
                                            let subIdx = index
                                        ">
                                            <tr @gridAnimation [@.disabled]="disableAnimation" (click)="onSelected(columnData.childRows, subIdx, subColumnData, columnData)" class="table-row" [ngClass]="{'disabled-ordering': !tableConfig?.level2order?.allow}" cdkDrag [cdkDragBoundary]="tableConfig?.level2order?.boundary ? '.table-body-level2' : ''" cdkDragPreviewClass="cdk-drag-preview-table-row" (cdkDragStarted)="onStartedDraggingRow()" (cdkDragEnded)="onEndedDraggingRow()">
                                                <td [class.centered-cell]="subColumnHead.centered" *ngFor="
                                                    let subColumnHead of tableConfig.level2columns
                                                " [class]="subColumnHead.cssClass">
                                                    <div class="w-100 d-flex justify-content-start">
                                                        <ng-container *ngIf="
                                                            !subColumnHead.isTemplate;
                                                            else template_content
                                                        ">
                                                            <tp-checkbox (ngModelChange)="
                                                                subColumnData.isSelected = $event; subItemSelectToggled()
                                                            " *ngIf="
                                                                subColumnHead.containsCheckBox
                                                            " [(ngModel)]="
                                                                subColumnData.isSelected
                                                            " [ngModelOptions]="{ standalone: true }"
                                                                class="mr-2 select-all"></tp-checkbox>

                                                            <span class="db-show-child" [class.cursor-default]="
                                                                !columnData.hasChildRows
                                                            " (click)="
                                                                toggleCollapse(
                                                                    subColumnData
                                                                )
                                                            " *ngIf="
                                                                subColumnHead.isExpandable &&
                                                                subColumnData.hasChildRows
                                                            ">
                                                                <i [className]="
                                                                    !subColumnData.expanded
                                                                        ? 'ion-ios-arrow-forward mx-2'
                                                                        : 'ion-ios-arrow-down mx-2'
                                                                " class="ion" [style]="
                                                                    !columnData.hasChildRows
                                                                        ? 'color: transparent'
                                                                        : ''
                                                                ">
                                                                </i>
                                                            </span>

                                                            {{
                                                            subColumnHead.dataText(
                                                            subColumnData.rowData,
                                                            columnData.rowData
                                                            ) | async
                                                            }}
                                                        </ng-container>

                                                        <ng-template #template_content>
                                                            <span class="db-show-child" [class.cursor-default]="
                                                                !columnData.hasChildRows
                                                            " (click)="
                                                                toggleCollapse(
                                                                    subColumnData
                                                                )
                                                            " *ngIf="
                                                                subColumnHead.isExpandable &&
                                                                subColumnData.hasChildRows
                                                            ">
                                                                <i [className]="
                                                                    !subColumnData.expanded
                                                                        ? 'ion-ios-arrow-forward mx-2'
                                                                        : 'ion-ios-arrow-down mx-2'
                                                                " class="ion" [style]="
                                                                    !columnData.hasChildRows
                                                                        ? 'color: transparent'
                                                                        : ''
                                                                ">
                                                                </i>
                                                            </span>

                                                            <tp-checkbox (ngModelChange)="
                                                                subColumnData.isSelected = $event; subItemSelectToggled()
                                                            " *ngIf="
                                                                subColumnHead.containsCheckBox
                                                            " [(ngModel)]="
                                                                subColumnData.isSelected
                                                            " [ngModelOptions]="{ standalone: true }"
                                                                class="mr-2 select-all"></tp-checkbox>

                                                            <ng-container [ngTemplateOutlet]="
                                                                level2GridTemplate
                                                            " [ngTemplateOutletContext]="{
                                                                $implicit: subColumnHead,
                                                                rowData: subColumnData.rowData,
                                                                parentData: columnData.rowData,
                                                                idx: idx,
                                                                row: subColumnData
                                                            }">
                                                            </ng-container>
                                                        </ng-template>
                                                    </div>
                                                </td>

                                                <ng-container *ngIf="
                                                    tableConfig.containsLevel2ActionColumn ===
                                                    true
                                                ">
                                                    <td class="small-column-size text-right p-1">
                                                        <button *ngIf="
                                                            tableConfig
                                                                .level2ActionItems
                                                                ?.length > 0
                                                        " mat-icon-button [matMenuTriggerFor]="
                                                            menu
                                                        " aria-label="Action">
                                                            <mat-icon>more_vert
                                                            </mat-icon>
                                                        </button>

                                                        <mat-menu #menu="matMenu">
                                                            <div *ngFor="
                                                                let action of tableConfig.level2ActionItems
                                                            ">
                                                                <button class="d-flex px-2 align-items-center" *ngIf="action.display$ != null ? (action.display$( subColumnData.rowData, columnData.rowData) | async) : action.display( subColumnData.rowData, columnData.rowData)"
                                                                    mat-menu-item (click)="
                                                                    action.click(
                                                                        subColumnData.rowData,
                                                                        columnData.rowData,
                                                                        idx,
                                                                        subColumnData,
                                                                        columnData.rowData
                                                                    )
                                                                ">
                                                                    <mat-icon *ngIf="
                                                                        action.icon !=
                                                                        null
                                                                    ">{{
                                                                        action.icon
                                                                        }}</mat-icon>
                                                                    <span>{{
                                                                        action.title
                                                                        | async
                                                                        }}</span>
                                                                </button>
                                                            </div>
                                                        </mat-menu>
                                                    </td>
                                                </ng-container>
                                            </tr>

                                            <!-- LEVEL 3 -->
                                            <ng-container *ngIf="
                                                subColumnData.expanded &&
                                                subColumnData.hasChildRows
                                            ">
                                                <tr class="for-expand">
                                                    <td [attr.colspan]="
                                                        tableConfig
                                                            ?.level2columns
                                                            ?.length +
                                                        (tableConfig.containsLevel2ActionColumn
                                                            ? 1
                                                            : 0)
                                                    ">
                                                        <table class="table card-table db-table nested-table">
                                                            <thead class="bg-theme third-table-head">
                                                                <tr>
                                                                    <ng-container *ngFor="
                                                                        let subSubColumnHead of tableConfig.level3columns
                                                                    ">
                                                                        <th
                                                                            [class.centered-header]="subSubColumnHead.centered">
                                                                            {{
                                                                            subSubColumnHead.title
                                                                            | async
                                                                            }}
                                                                        </th>
                                                                    </ng-container>

                                                                    <ng-container *ngIf="
                                                                        tableConfig.containsLevel3ActionColumn ==
                                                                            null ||
                                                                        tableConfig.containsLevel3ActionColumn ==
                                                                            true
                                                                    ">
                                                                        <th class="small-column-size text-right">
                                                                            <!-- {{'tp-action' | translate: '{ defaultText : \'Action\'}'}} -->
                                                                        </th>
                                                                    </ng-container>
                                                                </tr>
                                                            </thead>
                                                            <tbody class="table-body-level3" cdkDropList (cdkDropListDropped)="dropLevel3($event, idx,subIdx)" [cdkDropListDisabled]="!tableConfig?.level3order?.allow">
                                                                <ng-container *ngFor="
                                                                    let subSubColumnData of subColumnData.childRows;
                                                                    let subSubIdx = index
                                                                ">
                                                                    <tr @gridAnimation [@.disabled]="disableAnimation" (click)="onSelected(subColumnData.childRows, subSubIdx, subSubColumnData, subColumnData)" class="table-row" [ngClass]="{'disabled-ordering': !tableConfig?.level3order?.allow}" cdkDrag [cdkDragBoundary]="tableConfig?.level3order?.boundary ? '.table-body-level3' : ''" cdkDragPreviewClass="cdk-drag-preview-table-row" (cdkDragStarted)="onStartedDraggingRow()" (cdkDragEnded)="onEndedDraggingRow()">
                                                                        <td [class.centered-cell]="subSubColumnHead.centered"
                                                                            *ngFor="
                                                                            let subSubColumnHead of tableConfig.level3columns
                                                                        " [class]="
                                                                            subSubColumnHead.cssClass
                                                                        ">
                                                                            <div>
                                                                                <ng-container *ngIf="
                                                                                    !subSubColumnHead.isTemplate;
                                                                                    else template_content
                                                                                ">
                                                                                    {{
                                                                                    subSubColumnHead.dataText(
                                                                                    subSubColumnData.rowData,
                                                                                    subColumnData.rowData,
                                                                                    columnData.rowData
                                                                                    )
                                                                                    | async
                                                                                    }}
                                                                                </ng-container>

                                                                                <ng-template #template_content>
                                                                                    <ng-container [ngTemplateOutlet]="
                                                                                        level3GridTemplate
                                                                                    " [ngTemplateOutletContext]="{
                                                                                        $implicit: subSubColumnHead,
                                                                                        rowData: subSubColumnData.rowData,
                                                                                        parentData: subColumnData.rowData,
                                                                                        idx: subIdx,
                                                                                        grandParentData: columnData.rowData,
                                                                                        row: subSubColumnData
                                                                                    }">
                                                                                    </ng-container>
                                                                                </ng-template>
                                                                            </div>
                                                                        </td>

                                                                        <ng-container *ngIf="
                                                                            tableConfig.containsLevel3ActionColumn ==
                                                                            true
                                                                        ">
                                                                            <td
                                                                                class="small-column-size text-right p-1">
                                                                                <button *ngIf="
                                                                                    tableConfig
                                                                                        .level3ActionItems
                                                                                        ?.length >
                                                                                    0
                                                                                " mat-icon-button [matMenuTriggerFor]="
                                                                                    menu
                                                                                " aria-label="Action">
                                                                                    <mat-icon>more_vert
                                                                                    </mat-icon>
                                                                                </button>

                                                                                <mat-menu #menu="matMenu">
                                                                                    <div *ngFor="
                                                                                        let action of tableConfig.level3ActionItems
                                                                                    ">
                                                                                        <button class="d-flex px-2 align-items-center" *ngIf="action.display$ != null ? (action.display$( subSubColumnData.rowData, subColumnData.rowData) | async) : action.display( subSubColumnData.rowData, subColumnData.rowData)"
                                                                                            mat-menu-item (click)="
                                                                                            action.click(
                                                                                                subSubColumnData.rowData,
                                                                                                subColumnData.rowData,
                                                                                                subIdx,
                                                                                                subSubColumnData,
                                                                                                columnData.rowData
                                                                                            )
                                                                                        ">
                                                                                            <mat-icon *ngIf="
                                                                                                action.icon !=
                                                                                                null
                                                                                            ">{{
                                                                                                action.icon
                                                                                                }}</mat-icon>
                                                                                            <span>{{
                                                                                                action.title
                                                                                                | async
                                                                                                }}</span>
                                                                                        </button>
                                                                                    </div>
                                                                                </mat-menu>
                                                                            </td>
                                                                        </ng-container>
                                                                    </tr>
                                                                </ng-container>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </ng-container>
                                            <!-- LEVEL 3 Ends -->
                                        </ng-container>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </ng-container>
                </ng-container>
            </tbody>

            <tfoot *ngIf="!context.tableDataExists">
                <tr>
                    <td class="text-center" [attr.colspan]="subs.colspanForFullWidthCell">
                        <span class="mx-3" *ngIf="noDataPlaceholderText">{{
                            noDataPlaceholderText
                            }}</span>
                        <span class="mx-3" *ngIf="!noDataPlaceholderText" [translate]="'tp-nothing-here-yet!'"
                            [translateParams]="{
                            defaultText: 'Nothing here yet!'
                        }">
                        </span>
                    </td>
                </tr>
            </tfoot>
        </table>

        <ng-container *ngIf="
            allowPaging && !rowsSelectable;
            then paginationFloatRightFooter;
            else typicalFooter
        "></ng-container>

        <ng-template #paginationFloatRightFooter>
            <div fxLayout="row" fxLayoutAlign="end center" class="py-2 px-3">
                <tp-pagination *ngIf="allowPaging" (changePage)="pageChanged($event)"
                    [options]="overridePaginationOptions" [name]="paginationId"
                    [selectedCountOfItems]="pageSize"></tp-pagination>
            </div>
        </ng-template>

        <ng-template #typicalFooter>
            <div fxLayout="row" fxLayoutAlign="space-between center" class="py-2 px-3"
                *ngIf="rowsSelectable || allowPaging">
                <tp-button buttonStyle="outline" *ngIf="rowsSelectable && _tableContent?.length > 0"
                    (buttonClicked)="clearSelectionList()" [label]="
                    'tp-clear'
                        | translate: '{defaultText: \'Clear\'}'
                "></tp-button>

                <tp-pagination *ngIf="allowPaging" (changePage)="pageChanged($event)"
                    [options]="overridePaginationOptions" [name]="paginationId"
                    [selectedCountOfItems]="pageSize"></tp-pagination>
            </div>
        </ng-template>
    </ng-container>

</div>