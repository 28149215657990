import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TpAutocompleteDropdownModule } from 'app/components/tp-autocomplete-dropdown/tp-autocomplete-dropdown.module';
import { TpDropdownModule } from 'app/components/tp-dropdown/tp-dropdown.module';
import { TpMultiselectDropdownModule } from 'app/components/tp-multiselect-dropdown/tp-multiselect-dropdown.module';
import { LeadContactSelectorComponent } from 'app/main/pm/crm/shared/lead-contact-search/lead-contact-selector.component';

@NgModule({
    declarations: [LeadContactSelectorComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TpAutocompleteDropdownModule,
        TranslateModule,
        TpDropdownModule,
        TpMultiselectDropdownModule,
    ],
    exports: [
        LeadContactSelectorComponent,
    ],
})
export class LeadContactSearchModule {
}