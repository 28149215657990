export class PostQuoteJobCatCount {
    insertUserId?: number ;
    catCountFor?: string ;
    xtranslatedPerc?: number ;
    repetitionsPerc?: number ;
    match100Perc?: number ;
    match9599Perc?: number ;
    match8594Perc?: number ;
    match7584Perc?: number ;
    match5074Perc?: number ;
    noMatchPerc?: number ;
    crossFileRepetitionsPerc?: number ;
    match101Perc?: number ;
    contextExact?: number ;
    quoteJobId?: number ;
    catToolId?: number ;
    totalWordsCount?: number;
    totalDiscountedWords?: number;
    xtranslatedDiscount?: number;
    repetitionsDiscount?: number;
    match100Discount?: number;
    match101Discount?: number;
    match9599Discount?: number;
    match5074Discount?: number;
    match7584Discount?: number;
    match8594Discount?:number;
    noMatchDiscount?: number;
    crossFileRepetitionsDiscount?: number;
    contextExactDiscount?: number;
}