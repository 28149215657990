import { Injectable } from '@angular/core';
import {Observable, Subject} from "rxjs";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AppConfigService} from "../../../../services/app.settings.service";
import {BaseResultModel} from "../../../../models";
import {ResponseResourceNoteData} from "../models/response-models/response-resource-note-data";
import {PostResourceNote} from "../models/post-models/post-resource-note";

@Injectable({
  providedIn: 'root'
})
export class ResourceNoteService {
    private readonly _uri: string;
    refreshResourceNotes$ = new Subject<ResponseResourceNoteData>();

    constructor(
        private http: HttpClient, private configService: AppConfigService) {
        this._uri = configService.getAPIUrl() + '/resource';
    }

    getResourceNotes(resourceId: number): Observable<BaseResultModel<ResponseResourceNoteData[]>> {
        return this.http.get<BaseResultModel<ResponseResourceNoteData[]>>(this._uri + '/resourceNote/'+ resourceId +'/resource');
    }
    getResourceNote(noteId: number): Observable<BaseResultModel<ResponseResourceNoteData>> {
        return this.http.get<BaseResultModel<ResponseResourceNoteData>>(this._uri + '/resourceNote/'+ noteId);
    }
    saveResourceNote(model: PostResourceNote): Observable<BaseResultModel<ResponseResourceNoteData>> {
        return (model?.id) ? this.updateResourceNote(model) : this.addResourceNote(model);
    }
    addResourceNote(model: PostResourceNote): Observable<BaseResultModel<ResponseResourceNoteData>> {
        return this.http.post<BaseResultModel<ResponseResourceNoteData>>(this._uri + '/resourceNote/add', model);
    }
    updateResourceNote(model: PostResourceNote): Observable<BaseResultModel<ResponseResourceNoteData>> {
        return this.http.put<BaseResultModel<ResponseResourceNoteData>>(this._uri + '/resourceNote/update', model);
    }
    deleteResourceNote(noteId: number): Observable<BaseResultModel<boolean>> {
        return this.http.delete<BaseResultModel<boolean>>(this._uri + '/resourceNote/delete',{params: new HttpParams().set('resourceNoteIds',`${noteId}`)});
    }
}
