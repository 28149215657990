import { Pipe, PipeTransform } from '@angular/core';
import { ClientService } from 'app/main/pm/services/clients/client.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
    name: 'clientName',
})
export class ClientNamePipe implements PipeTransform {
    
    constructor(private clientService: ClientService) {
    }
    
    transform(clientId: number): Observable<string> {
        return this.clientService.getRefClients()
            .pipe(map(clients => {
                const client = clients?.find((f) => f.value === clientId);
                return client?.text || '---';
            }));
    }
}