import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { TranslateModule } from '@ngx-translate/core';
import { TpButtonModule } from 'app/components/tp-button/tp-button.module';
import { TpCurrencyDropdownModule } from 'app/components/tp-currency-dropdown/tp-currency-dropdown.module';
import { TpDatePickerModule } from 'app/components/tp-date-picker/tp-date-picker.module';
import { TpGridSearchWrapperModule } from 'app/components/tp-grid-search-wrapper/tp-grid-search-wrapper.module';
import { TpGridModule } from 'app/components/tp-grid/tp-grid.module';
import { TpModalModule } from 'app/components/tp-modal/tp-modal.module';
import { TpServiceLanguageDropdownModule } from 'app/components/tp-service-language-dropdown/tp-service-language-dropdown.module';
import { TpServicesDropdownModule } from 'app/components/tp-services-dropdown/tp-services-dropdown.module';
import { TpTextAreaModule } from 'app/components/tp-text-area/tp-text-area.module';
import { TpTextBoxModule } from 'app/components/tp-text-box/tp-text-box.module';
import { ClientContractsGridComponent } from 'app/main/pm/clients/shared/client-contracts/client-contracts-grid/client-contracts-grid.component';
import { ClientContractsSearchBarComponent } from 'app/main/pm/clients/shared/client-contracts/client-contracts-search-bar/client-contracts-search-bar.component';
import { ClientContractsComponent } from 'app/main/pm/clients/shared/client-contracts/client-contracts.component';
import { ClientContractsService } from 'app/main/pm/clients/shared/client-contracts/client-contracts.service';
import { CreateClientContractComponent } from 'app/main/pm/clients/shared/client-contracts/create-client-contract/create-client-contract.component';
import { FormatDatePipeModule } from 'app/pipes/format-date/format-date-pipe.module';
import { FormatNumberWithCurrencyPipeModule } from 'app/pipes/format-number-with-currency-pipe/format-number-with-currency-pipe.module';
import { ServiceNamePipeModule } from 'app/pipes/service-name-pipe/service-name-pipe.module';
import { UcFirstPipeModule } from 'app/pipes/uc-first/uc-first-pipe.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TpCheckboxModule } from '../../../../../components/tp-checkbox/tp-checkbox.module';
import { CategoryNamePipeModule } from '../../../../../pipes/category-name/category-name-pipe.module';
import {TpDropdownModule} from "../../../../../components/tp-dropdown/tp-dropdown.module";
import { DragAndDropFileModule } from 'app/components/drag-and-drop-file/drag-and-drop-file.module';
import { FileListModule } from 'app/main/sharedComponents/file-list/file-list.module';

@NgModule({
    declarations: [
        ClientContractsComponent,
        CreateClientContractComponent,
        ClientContractsSearchBarComponent,
        ClientContractsGridComponent,
    ],
    imports: [
        CommonModule,
        FlexModule,
        ReactiveFormsModule,
        NgxSpinnerModule,
        MatCardModule,
        TpGridSearchWrapperModule,
        TpModalModule,
        TpButtonModule,
        TranslateModule,
        TpGridModule,
        TpTextAreaModule,
        TpTextBoxModule,
        TpCurrencyDropdownModule,
        FormatNumberWithCurrencyPipeModule,
        FormatDatePipeModule,
        UcFirstPipeModule,
        ServiceNamePipeModule,
        TpDatePickerModule,
        TpServicesDropdownModule,
        TpServiceLanguageDropdownModule,
        CategoryNamePipeModule,
        TpCheckboxModule,
        TpDropdownModule,
        DragAndDropFileModule,
        FileListModule,
    ],
    providers: [
        ClientContractsService,
    ],
    exports: [
        ClientContractsComponent,
    ],
})
export class ClientContractsModule {
}