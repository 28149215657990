import {ResponseServiceData} from "../../../admin/manage-services/models/response-models/response-service-data";
import {PostServicePrice} from "../../../admin/manage-services/models/post-models/post-service-with-price-list";
import {ResponseClientPricingData} from "../../clients/models/client-pricing";
import {ServiceTypes} from "../../../../utills/enums/service-types";

export function filterServicePriceList(serviceData: ResponseServiceData, priceListData: { sourceLanguage: number, targetLanguages: number[], unit: number, sectors: number[], rate?: number }): PostServicePrice[] {
    const hasQuoteSectors: boolean = priceListData.sectors?.length > 0;
    let filteredServicePriceList: PostServicePrice[] = serviceData.priceList.filter(priceList => {
        const compareService: boolean = priceList.serviceId == serviceData.id;
        const compareUnits: boolean = priceList.units.map(unit => unit.id).includes(priceListData.unit);
        if(compareService && compareUnits ){ return priceList; }
    });

    if(serviceData.serviceTypeId == ServiceTypes.Bilingual) {
        filteredServicePriceList = filteredServicePriceList.filter(priceList => {
            const compareSourceLanguage: boolean = priceList.sourceLanguageId == priceListData.sourceLanguage;
            const compareTargetLanguage: boolean = priceList.targetLanguages.map(tl => tl.id).filter(tlId => priceListData.targetLanguages.includes(tlId)).length > 0;
            if(compareSourceLanguage && compareTargetLanguage ){ return priceList; }
        });
    }

    if(priceListData?.rate || priceListData?.rate == 0) {
        filteredServicePriceList = filteredServicePriceList.filter(priceList => {
            const compareRate: boolean = priceList.rate == priceListData.rate;
            if(compareRate){return priceList;}
        });
    }

    if (hasQuoteSectors && filteredServicePriceList.length > 0) {
        const filteredServicePriceListWithSector =  filteredServicePriceList.filter( priceList => priceList.sectors.filter(sector => priceListData.sectors.includes(sector.id)).length > 0);
        return (filteredServicePriceListWithSector.length > 0) ? filteredServicePriceListWithSector : filteredServicePriceList;
    } else {
        const filteredServicePriceListWithoutSector = filteredServicePriceList.filter( priceList => priceList.sectors?.length == 0 || !priceList.sectors);
        return (filteredServicePriceListWithoutSector.length > 0) ? filteredServicePriceListWithoutSector : filteredServicePriceList;
    }
}

export function filterClientPriceList(serviceData: ResponseServiceData , clientPriceList: ResponseClientPricingData[], priceListData: { sourceLanguage: number, targetLanguages: number[], unit: number, sectors: number[], rate?: number }): ResponseClientPricingData[] {
    const hasQuoteSectors: boolean = priceListData.sectors?.length > 0;
    let filteredClientPriceList: ResponseClientPricingData[] = clientPriceList.filter(x => {
        const compareService: boolean = x.serviceId == serviceData.id;
        const compareUnit: boolean = x.unitId == priceListData.unit;
        if(compareService && compareUnit ){ return x; }
    });

    if(serviceData.serviceTypeId == ServiceTypes.Bilingual) {
        filteredClientPriceList = filteredClientPriceList.filter(x => {
            const compareSourceLanguage: boolean = x.sourceLanguageId == priceListData.sourceLanguage;
            const compareTargetLanguage: boolean = priceListData.targetLanguages.filter(y=>y==x.targetLanguageId)?.length>0;
            if(compareSourceLanguage && compareTargetLanguage ){ return x; }
        });
    }

    if(priceListData?.rate || priceListData?.rate == 0) {
        filteredClientPriceList = filteredClientPriceList.filter(x => {
            const compareRate: boolean = x.rate == priceListData.rate;
            if(compareRate){return x;}
        });
    }

    if (hasQuoteSectors && filteredClientPriceList.length > 0) {
        const filteredClientPriceListWithSector =  filteredClientPriceList.filter( priceList => priceListData.sectors.includes(priceList.tagCategoryId));
        return (filteredClientPriceListWithSector.length > 0) ? filteredClientPriceListWithSector : filteredClientPriceList;
    } else {
        const filteredClientPriceListWithoutSector = filteredClientPriceList.filter( priceList => !priceList.tagCategoryId);
        return (filteredClientPriceListWithoutSector.length > 0) ? filteredClientPriceListWithoutSector : filteredClientPriceList;
    }
}