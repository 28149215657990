import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserNotesComponent } from './user-notes.component';
import { UserNotesModalComponent } from './user-notes-modal/user-notes-modal.component';
import {ComponentsModule} from "../../../../../components/components.module";
import {TpButtonModule} from "../../../../../components/tp-button/tp-button.module";
import {TranslateModule} from "@ngx-translate/core";
import { UserNoteComponent } from './user-note/user-note.component';
import {TpRichTextBoxModule} from "../../../../../components/tp-rich-textbox/tp-rich-text-box.module";
import {ClickOutsideModule} from "ng-click-outside";
import {
    CheckPermissionsDirectiveModule
} from "../../../../../directives/check-permission-directive/check-permission-directive-module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatIconModule} from "@angular/material/icon";
import {TpCheckboxModule} from "../../../../../components/tp-checkbox/tp-checkbox.module";
import {MatTooltipModule} from "@angular/material/tooltip";
import {AutoFocusModule} from "../../../../../directives/input-focus/auto-focus.module";
import {DragDropModule} from "@angular/cdk/drag-drop";

@NgModule({
    declarations: [
        UserNotesComponent,
        UserNotesModalComponent,
        UserNoteComponent
    ],
    exports: [
        UserNotesModalComponent,
        UserNotesComponent
    ],
    imports: [
        CommonModule,
        ComponentsModule,
        TpButtonModule,
        TranslateModule,
        TpRichTextBoxModule,
        ClickOutsideModule,
        CheckPermissionsDirectiveModule,
        ReactiveFormsModule,
        MatIconModule,
        TpCheckboxModule,
        MatTooltipModule,
        AutoFocusModule,
        FormsModule,
        DragDropModule
    ]
})
export class UserNotesModule { }