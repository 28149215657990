import {Component, ComponentRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ModalSizes, TpModalComponent} from "../../../../../components/tp-modal/tp-modal.component";
import {CatCountAnalyseComponent} from "../cat-count-analyse.component";
import { PostFile } from 'app/models/general/system-models/post-file';
import { PostCatToolAnalysis } from '../../models/cat-tool-analysis';
import { NotificationService } from 'app/services/notification.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'tp-cat-count-analyse-modal',
  templateUrl: './cat-count-analyse-modal.component.html',
  styleUrls: ['./cat-count-analyse-modal.component.scss']
})
export class CatCountAnalyseModalComponent implements OnInit {
    @ViewChild('modal') modal: TpModalComponent;
    @Output() saved = new EventEmitter<PostCatToolAnalysis>();    
    @Output() enterManually = new EventEmitter<boolean>();

    _resourceId:number;
    modalComponentRef: ComponentRef<CatCountAnalyseComponent>;
    files?:PostFile[]=[];
    postCatAnalysis:PostCatToolAnalysis=new PostCatToolAnalysis();

    constructor(
        private _notificationService:NotificationService
    ) { }

    ngOnInit(): void {}
    /**
     * @function show
     * @description show cat count analyse modal
     * @return void
     * */
    show(): void {
        this.modal.showComponents(
            CatCountAnalyseComponent, (component) => {  
                this.modalComponentRef = component;               
                this.files=component.instance.files;                
                component.instance.outCatAnalysis.subscribe(res=>{
                    this.postCatAnalysis=res;
                });                               
            },
            ModalSizes.small,
        );
    }
    /**
     * @function hide
     * @description hide modal
     * @return void
     * */
    hide(): void {
        this.modal.hide();
    }
    /**
     * @function save
     * @description save handler
     * @return void
     * */
    save(): void {

        //check if files and object were properly added
        if(this.postCatAnalysis?.catToolId){
            this.saved.emit(this.postCatAnalysis);
            this.hide();  
        }
        else{
            this._notificationService.showError('You must select the cat schema','Warning');
            return;
        }              
    }

}