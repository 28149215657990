export enum TagTypes{
    Sector = 1,
    ProjectQuote = 2,
    Resource = 3,
    Client = 4,
    Expenses = 5,
    LeadResource = 6,
    CatTool = 7,
    AbsenceType = 8,
    PaymentType = 9,
    BankAccount = 10,
    ResourceTaskType = 11,
    ResourceTeam = 12,
    ClientResource = 13,
    EventType = 14,
    LeadPriority = 15,
    CampaignType = 16,
    ClientContractType = 17,
    Software = 18,
    InvoiceType = 19,
    Topic = 20,
    ChangeReason = 21,
    EndClient = 23
}