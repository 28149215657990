import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserLoginsComponent } from './user-logins.component';
import {ComponentsModule} from "../../../../../components/components.module";
import {TpButtonModule} from "../../../../../components/tp-button/tp-button.module";
import {TpGridModule} from "../../../../../components/tp-grid/tp-grid.module";
import {
  TpGridSearchWrapperModule
} from "../../../../../components/tp-grid-search-wrapper/tp-grid-search-wrapper.module";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  declarations: [
      UserLoginsComponent
  ],
  imports: [
      CommonModule,
    ComponentsModule,
    TpButtonModule,
    TpGridModule,
    TpGridSearchWrapperModule,
    TranslateModule
  ],
  exports:[
      UserLoginsComponent
  ]
})
export class UserLoginsModule { }
