import {CommonModule, DatePipe} from '@angular/common';
import { NgModule } from '@angular/core';
import { ExtendedModule, FlexModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { TpButtonModule } from 'app/components/tp-button/tp-button.module';
import { TpCalendarModule } from 'app/components/tp-calendar/tp-calendar.module';
import { TpCategoryDropdownModule } from 'app/components/tp-category-dropdown/tp-category-dropdown.module';
import { TpCurrencyDropdownModule } from 'app/components/tp-currency-dropdown/tp-currency-dropdown.module';
import { TpDatePickerModule } from 'app/components/tp-date-picker/tp-date-picker.module';
import { TpDropdownModule } from 'app/components/tp-dropdown/tp-dropdown.module';
import { TpDynamicColumnsModule } from 'app/components/tp-dynamic-columns/tp-dynamic-columns.module';
import { TpGridSearchWrapperModule } from 'app/components/tp-grid-search-wrapper/tp-grid-search-wrapper.module';
import { TpGridModule } from 'app/components/tp-grid/tp-grid.module';
import { TpModalModule } from 'app/components/tp-modal/tp-modal.module';
import { TpProjectManagersDropdownModule } from 'app/components/tp-projectManagers-dropdown/tp-projectManagers-dropdown.module';
import { TpSalespersonsDropdownModule } from 'app/components/tp-salesperson-dropdown/tp-salespersons-dropdown.module';
import { TpServiceLanguageDropdownModule } from 'app/components/tp-service-language-dropdown/tp-service-language-dropdown.module';
import { TpServicesDropdownModule } from 'app/components/tp-services-dropdown/tp-services-dropdown.module';
import { TpSliderRangeModule } from 'app/components/tp-slider-range/tp-slider-range.module';
import { TpTextAreaModule } from 'app/components/tp-text-area/tp-text-area.module';
import { TpTextBoxModule } from 'app/components/tp-text-box/tp-text-box.module';
import { TpToggleModule } from 'app/components/tp-toggle/tp-toggle.module';
import { LeadContactSearchModule } from 'app/main/pm/crm/shared/lead-contact-search/lead-contact-search.module';
import { QuoteJobModule } from 'app/main/pm/quotes/shared/quote-job/quote-job.module';
import { QuoteStatusDropdownModule } from 'app/main/pm/quotes/shared/quote-status-dropdown/quote-status-dropdown.module';
import { ClientAndLeadSearchModule } from 'app/main/sharedComponents/client-and-lead-search/client-and-lead-search.module';
import { ClientContactSelectorModule } from 'app/main/sharedComponents/client-contact-selector/client-contact-selector.module';
import { ClientSearchModule } from 'app/main/sharedComponents/client-search/client-search.module';
import { ContactModalModule } from 'app/main/sharedComponents/contact-modal/contact-modal.module';
import { EditClientModule } from 'app/main/sharedComponents/edit-client/edit-client.module';
import { LeadSearchModule } from 'app/main/sharedComponents/lead-search/lead-search.module';
import { PaperworkTemplateSelectorModule } from 'app/main/sharedComponents/paperwork-template-selector/paperwork-template-selector.module';
import { QuoteProbabilitySelectorModule } from 'app/main/sharedComponents/quote-probability-selector/quote-probability-selector.module';
import { CategoryNamePipeModule } from 'app/pipes/category-name/category-name-pipe.module';
import { CharacterLimitPipeModule } from 'app/pipes/character-limit-pipe/character-limit-pipe.module';
import { FormatDatePipeModule } from 'app/pipes/format-date/format-date-pipe.module';
import { FormatNumberWithCurrencyPipeModule } from 'app/pipes/format-number-with-currency-pipe/format-number-with-currency-pipe.module';
import { ProjectManagerNamePipeModule } from 'app/pipes/project-manager-name/project-manager-name-pipe.module';
import { SalesPersonNamePipeModule } from 'app/pipes/sales-person-name-pipe/sales-person-name-pipe.module';
import { ServiceLanguageNamePipeModule } from 'app/pipes/service-language-name-pipe/service-language-name-pipe.module';
import { ServiceNamePipeModule } from 'app/pipes/service-name-pipe/service-name-pipe.module';
import { UcFirstPipeModule } from 'app/pipes/uc-first/uc-first-pipe.module';
import { UnitNamePipeModule } from 'app/pipes/unit-name-pipe/unit-name-pipe.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ClientNamePipeModule } from '../../../../../pipes/client-name/client-name-pipe.module';
import { CreateEditLeadModule } from '../../../../sharedComponents/create-edit-lead/create-edit-lead.module';
import { CreateEditQuoteComponent } from './create-edit-quote.component';
import { QuoteJobsBulkEditComponent } from './quote-jobs-bulk-edit/quote-jobs-bulk-edit.component';
import {TpRadioButtonModule} from "../../../../../components/tp-radio-button/tp-radio-button.module";
import {TpProjectStatusButtonModule} from "../../../../../components/tp-project-status-button/tp-project-status-button.module";
import { CheckPermissionsDirectiveModule } from 'app/directives/check-permission-directive/check-permission-directive-module';
import {ClientNotesIconModule} from "../../../../sharedComponents/client-profile/client-notes-icon/client-notes-icon.module";
import {TotalsModule} from "../../../../../components/totals/totals.module";
import {MyProfileModalModule} from "../../../modals/my-profile-modal/my-profile-modal.module";
import {QuoteHistoryModule} from "./quote-history/quote-history.module";
import {MatTabsModule} from "@angular/material/tabs";
import {QuoteFilesModule} from "./quote-files/quote-files.module";
@NgModule({
    declarations: [
        CreateEditQuoteComponent,
        QuoteJobsBulkEditComponent,
    ],
    imports: [
        CommonModule,
        TranslateModule,
        ReactiveFormsModule,
        ClientSearchModule,
        LeadSearchModule,
        LeadContactSearchModule,
        ClientContactSelectorModule,
        TpModalModule,
        TpButtonModule,
        TpGridModule,
        FlexModule,
        MatExpansionModule,
        QuoteProbabilitySelectorModule,
        TpTextBoxModule,
        TpSalespersonsDropdownModule,
        TpDatePickerModule,
        TpDropdownModule,
        MatRadioModule,
        TpProjectManagersDropdownModule,
        TpCurrencyDropdownModule,
        PaperworkTemplateSelectorModule,
        TpCategoryDropdownModule,
        TpTextAreaModule,
        QuoteStatusDropdownModule,
        FormsModule,
        ExtendedModule,
        MatDividerModule,
        MatCardModule,
        NgxSpinnerModule,
        FormatDatePipeModule,
        ServiceLanguageNamePipeModule,
        ServiceNamePipeModule,
        ProjectManagerNamePipeModule,
        FormatNumberWithCurrencyPipeModule,
        UnitNamePipeModule,
        SalesPersonNamePipeModule,
        CategoryNamePipeModule,
        ClientNamePipeModule,
        TpSliderRangeModule,
        TpToggleModule,
        TpServiceLanguageDropdownModule,
        TpServicesDropdownModule,
        TpGridSearchWrapperModule,
        MatIconModule,
        UcFirstPipeModule,
        MatTooltipModule,
        CharacterLimitPipeModule,
        TpDynamicColumnsModule,
        MatButtonModule,
        TpCalendarModule,
        QuoteJobModule,
        ContactModalModule,
        EditClientModule,
        ClientAndLeadSearchModule,
        CreateEditLeadModule,
        TpRadioButtonModule,
        TpProjectStatusButtonModule,
        CheckPermissionsDirectiveModule,
        ClientNotesIconModule,
        TotalsModule,
        MyProfileModalModule,
        QuoteHistoryModule,
        MatTabsModule,
        QuoteFilesModule,
    ],
    exports: [
        CreateEditQuoteComponent,
    ],
    providers:[DatePipe]
})
export class CreateEditQuoteModule {
}