import {DropDownItem} from "../general/dropdownItem.model";

export class WorkflowDropdownItem extends DropDownItem{
    entityId: any;
    entity: WorkflowDropdownItemEntity
}

export class WorkflowDropdownValue{
    workflows: number[];
    workflowSteps: number[];
}

export enum WorkflowDropdownItemEntity{
    Workflow = 'workflow',
    WorkflowStep = 'workflowStep'
}

