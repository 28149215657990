import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {TPStorageService} from "../services/storage/tp-storage.service.ts";
import {StorageKeys} from "../utills/enums/storage-keys";
import {MenuTypes} from "../utills/enums/menu-types";

@Injectable({
    providedIn: 'root'
})
export class LayoutService {
  private display$ = new BehaviorSubject<boolean>(false);
  public isDisplayed = this.display$.asObservable();
  private verticalSideNavView$ = new BehaviorSubject<boolean>(true);
  public verticalSideNavView = this.verticalSideNavView$.asObservable();
  private verticalSideNavStatus$ = new BehaviorSubject<boolean>(false);
  public verticalSideNavStatus = this.verticalSideNavStatus$.asObservable();
  private sideNavCollapsed$ = new BehaviorSubject<boolean>(true);
  public sideNavCollapsed = this.sideNavCollapsed$.asObservable();
  constructor(
    private zone: NgZone,
    private tpStorageService: TPStorageService
    ) {}


  private get layoutHelpers() {
    return window['layoutHelpers'];
  }

  private exec(fn) {
    return this.layoutHelpers && this.zone.runOutsideAngular(fn);
  }

  public getLayoutSidenav() {
    return this.exec(() => this.layoutHelpers.getLayoutSidenav()) || null;
  }

  public getSidenav() {
    return this.exec(() => this.layoutHelpers.getSidenav()) || null;
  }

  public getLayoutNavbar() {
    return this.exec(() => this.layoutHelpers.getLayoutNavbar()) || null;
  }

  public getLayoutFooter() {
    return this.exec(() => this.layoutHelpers.getLayoutFooter()) || null;
  }

  public getLayoutContainer() {
    return this.exec(() => this.layoutHelpers.getLayoutContainer()) || null;
  }

  public isSmallScreen() {
    return this.exec(() => this.layoutHelpers.isSmallScreen());
  }

  public isLayout1() {
    return this.exec(() => this.layoutHelpers.isLayout1());
  }

  public isCollapsed() {
    return this.exec(() => this.layoutHelpers.isCollapsed());
  }

  public isFixed() {
    return this.exec(() => this.layoutHelpers.isFixed());
  }

  public isOffcanvas() {
    return this.exec(() => this.layoutHelpers.isOffcanvas());
  }

  public isNavbarFixed() {
    return this.exec(() => this.layoutHelpers.isNavbarFixed());
  }

  public isFooterFixed() {
    return this.exec(() => this.layoutHelpers.isFooterFixed());
  }

  public isReversed() {
    return this.exec(() => this.layoutHelpers.isReversed());
  }

  public setCollapsed(collapsed, animate = true) {
    this.exec(() => this.layoutHelpers.setCollapsed(collapsed, animate));
  }

  public toggleCollapsed(animate = true) {
    this.exec(() => this.layoutHelpers.toggleCollapsed(animate));
  }

  public toggleDarkMode(isDarkMode: boolean) {
      this.exec(() => this.layoutHelpers.toggleDarkMode(isDarkMode));
  }

  public setPosition(fixed, offcanvas) {
    this.exec(() => this.layoutHelpers.setPosition(fixed, offcanvas));
  }

  public setNavbarFixed(fixed) {
    this.exec(() => this.layoutHelpers.setNavbarFixed(fixed));
  }

  public setFooterFixed(fixed) {
    this.exec(() => this.layoutHelpers.setFooterFixed(fixed));
  }

  public setReversed(reversed) {
    this.exec(() => this.layoutHelpers.setReversed(reversed));
  }

  public update() {
    this.exec(() => this.layoutHelpers.update());
  }

  public setAutoUpdate(enable) {
    this.exec(() => this.layoutHelpers.setAutoUpdate(enable));
  }

  public on(event, callback) {
    this.exec(() => this.layoutHelpers.on(event, callback));
  }

  public off(event) {
    this.exec(() => this.layoutHelpers.off(event));
  }

  public init() {
      const menu =  this.tpStorageService.readLocalStorage(StorageKeys.Menu);
      if(menu === MenuTypes.Horizontal) this.changeVerticalSideNavView(false);
      else if(menu === MenuTypes.Vertical) this.changeVerticalSideNavView(true);
      this.exec(() => this.layoutHelpers.init());
  }

  public destroy() {
    this.exec(() => this.layoutHelpers.destroy());
  }

  public _redrawLayoutSidenav() {
    this.exec(() => this.layoutHelpers._redrawLayoutSidenav());
  }

  public _removeClass(cls) {
    this.exec(() => this.layoutHelpers._removeClass(cls));
  }

  public displayNavbar(state:boolean){
      this.display$.next(state);
  }

  public changeVerticalSideNavView(status: boolean): void{
      if(status) this.tpStorageService.writeLocalStorage(StorageKeys.Menu, MenuTypes.Vertical);
      else this.tpStorageService.writeLocalStorage(StorageKeys.Menu, MenuTypes.Horizontal);
      this.verticalSideNavView$.next(status);
  }

  public changeVerticalSideNavStatus(status: boolean): void{
      this.verticalSideNavStatus$.next(status);
  }

  public changeSideNavCollapsed(status: boolean): void{
      this.sideNavCollapsed$.next(status);
  }

  public toggleVerticalSideNavStatus(): void{
      this.verticalSideNavStatus$.next(!this.verticalSideNavStatusValue);
  }

  public toggleSideNavCollapsed(value?: boolean): void{
      this.sideNavCollapsed$.next( value ?? !this.sideNavCollapsed$.value);
  }
  public showSideNav(): void{
      this.sideNavCollapsed$.next(false);
  }
  public hideSideNav(): void{
      this.sideNavCollapsed$.next(true);
  }

  public get verticalSideNavStatusValue(): boolean {return this.verticalSideNavStatus$.value};
}