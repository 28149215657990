import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TpButtonModule } from 'app/components/tp-button/tp-button.module';
import { TpCalendarModule } from 'app/components/tp-calendar/tp-calendar.module';
import { TpCategoryDropdownModule } from 'app/components/tp-category-dropdown/tp-category-dropdown.module';
import { TpCountryDropdownModule } from 'app/components/tp-country-dropdown/tp-country-dropdown.module';
import { TpDropdownModule } from 'app/components/tp-dropdown/tp-dropdown.module';
import { TpGridSearchWrapperModule } from 'app/components/tp-grid-search-wrapper/tp-grid-search-wrapper.module';
import { TpGridModule } from 'app/components/tp-grid/tp-grid.module';
import { TpModalModule } from 'app/components/tp-modal/tp-modal.module';
import { TpSalespersonsDropdownModule } from 'app/components/tp-salesperson-dropdown/tp-salespersons-dropdown.module';
import { TpServicesDropdownModule } from 'app/components/tp-services-dropdown/tp-services-dropdown.module';
import { TpTextBoxModule } from 'app/components/tp-text-box/tp-text-box.module';
import { LeadPriorityDropdownModule } from 'app/main/pm/crm/shared/lead-priority-dropdown/lead-priority-dropdown.module';
import { LeadContactModalModule } from 'app/main/sharedComponents/lead-contact-modal/lead-contact-modal.module';
import { CreateEditLeadContactDetailComponent } from './create-edit-lead-contact-detail/create-edit-lead-contact-detail.component';
import { CreateEditLeadComponent } from './create-edit-lead.component';
import { TpDatePickerModule } from 'app/components/tp-date-picker/tp-date-picker.module';
import { TpProjectManagersDropdownModule } from 'app/components/tp-projectManagers-dropdown/tp-projectManagers-dropdown.module';

@NgModule({
    declarations: [
        CreateEditLeadComponent,
        CreateEditLeadContactDetailComponent,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        NgxSpinnerModule,
        MatTabsModule,
        TranslateModule,
        MatExpansionModule,
        TpTextBoxModule,
        TpCountryDropdownModule,
        TpCategoryDropdownModule,
        TpSalespersonsDropdownModule,
        TpDropdownModule,
        MatButtonModule,
        TpCalendarModule,
        TpDatePickerModule,
        TpButtonModule,
        TpGridModule,
        MatMenuModule,
        TpModalModule,
        LeadContactModalModule,
        TpGridSearchWrapperModule,
        MatCardModule,
        LeadPriorityDropdownModule,
        TpServicesDropdownModule,
        TpProjectManagersDropdownModule
    ],
    exports: [
        CreateEditLeadComponent,
        CreateEditLeadContactDetailComponent,
    ],
})
export class CreateEditLeadModule {
}