import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { Observable, EMPTY, of } from 'rxjs';

import * as ProjectActions from '../actions/project.actions';

@Injectable()
export class ProjectEffects {

  constructor(private actions$: Actions) {}

}