import { createAction, props } from '@ngrx/store';
import {EntityAdditional} from "../../../../../store/models";
import {PostClientPricing, ResponseClientPricingData} from "../../../clients/models/client-pricing";

export enum ClientRateActionTypes {
    getClientRates = '[ClientRates / Api] Get Client Rates',
    addClientRate = '[ClientRate / Api] Add Client Rate',
    updateClientRate = '[ClientRate / Api] Update Client Rate',
    updateClientRates = '[ClientRate / Api] Update Client Rates',
    deleteClientRate = '[ClientRate / Api] Delete Client Rate',
}
export const getClientRates = createAction(
    ClientRateActionTypes.getClientRates,
    props<{clientId: number}>()
);
export const addClientRate = createAction(
    ClientRateActionTypes.addClientRate,
    props<PostClientPricing>()
);
export const updateClientRate = createAction(
    ClientRateActionTypes.updateClientRate,
    props<PostClientPricing>()
);
export const updateClientRates = createAction(
    ClientRateActionTypes.updateClientRates,
    props<{ rates : PostClientPricing[] }>()
);

export const deleteClientRate = createAction(
    ClientRateActionTypes.deleteClientRate,
    props<{id: number}>()
);