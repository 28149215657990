import { Injectable } from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {NotificationService} from "../../../../services/notification.service";
import {Store} from "@ngrx/store";
import {State} from "../../../../store";
import * as WorkflowActions from "../actions/workflow.actions";
import {catchError, concatMap, filter, map, share, withLatestFrom} from "rxjs/operators";
import {of} from "rxjs";
import {WorkflowService} from "../../../admin/workflows/services/workflow.service";
import {workflowDropdownEntity, workflowsEntity} from "../reducers";


@Injectable()
export class WorkflowEffects {
  getWorkflows$ = createEffect(() => {
    return this.actions$.pipe(
        ofType(WorkflowActions.getWorkflows),
        withLatestFrom(this.store.select(workflowsEntity.selectors.selectState)),
        filter(([action, state]) => !state.firstLoad),
        concatMap(() => {
          this.store.dispatch(workflowsEntity.actions.updateAdditional({updates:{isLoading: true}}));
          return this.workflowService.getWorkflows().pipe(
              map(response => workflowsEntity.actions.setAll({entities: response.response})),
              catchError(error => of(workflowsEntity.actions.updateAdditional({updates: {error}})))
          )}
        )
    );
  });
  addWorkflow$ = createEffect(() => {
    return this.actions$.pipe(
        ofType(WorkflowActions.addWorkflow),
        concatMap((action) =>
            this.workflowService.add(action).pipe(
                map(response => {
                  this.notificationService.showSuccess('Saved', 'Workflow saved successfully');
                  this.store.dispatch(workflowDropdownEntity.actions.addOne({
                      entity:{
                          id: response.response.id,
                          name: response.response.description,
                          isActive: response.response.isActive
                      }
                  }));
                  return workflowsEntity.actions.unshift({entities: [response.response]});
                }),
                catchError(error => {
                  return of(workflowsEntity.actions.updateAdditional({updates: {error}}));
                })
            )
        ),
        share()
    );
  });
  updateWorkflow$ = createEffect(() => {
    return this.actions$.pipe(
        ofType(WorkflowActions.updateWorkflow),
        concatMap((action) =>
            this.workflowService.update(action).pipe(
                map(response => {
                    this.notificationService.showSuccess('Saved', 'Workflow saved successfully');
                    this.store.dispatch(workflowDropdownEntity.actions.updateOne({
                        update:{
                            id: response.response.id,
                            changes:{
                                name: response.response.description,
                                isActive: response.response.isActive
                            }
                        }
                    }));
                    return workflowsEntity.actions.updateOne({update: {id: response.response.id, changes:response.response}});
                }),
                catchError(error => {
                    return of(workflowsEntity.actions.updateAdditional({updates: {error}}));
                })
            )
        ),
        share()
    );
  });
  deleteWorkflows$ = createEffect(() => {
    return this.actions$.pipe(
        ofType(WorkflowActions.deleteWorkflows),
        concatMap((action) =>
            this.workflowService.delete(action.ids).pipe(
                map(data => {
                    this.notificationService.showSuccess('Deleted', 'Workflows deleted successfully');
                    this.store.dispatch(workflowDropdownEntity.actions.removeMany({ids: action.ids}));
                    return workflowsEntity.actions.removeMany({ids: action.ids});
                }),
                catchError(error => {
                  return of(workflowsEntity.actions.updateAdditional({updates: {error}}));
                })
            )
        ),
        share()
    );
  });

  constructor(
      private actions$: Actions,
      private notificationService: NotificationService,
      private store: Store<State>,
      private workflowService: WorkflowService
  ) {}

}
