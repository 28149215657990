<div class="mat-form-field tp-toggle-form {{classes}}">
    <mat-slide-toggle
        *ngIf="control"
        [formControl]="control"
        [required]="required"
        >{{ label }}</mat-slide-toggle
    >
    
    <mat-slide-toggle
        *ngIf="!control"
        [(ngModel)]="latestWrittenValue" (ngModelChange)="writeValue($event)" (change)="onChange($event)"
        [ngModelOptions]="{ standalone: true }"
        [required]="required"
    >{{ label }}</mat-slide-toggle
    >

    <ng-container
        *ngIf="
            filteredErrors?.length > 0;
            then backendErrors;
            else frontendErrors
        "
    ></ng-container>

    <ng-template #backendErrors>
        <mat-error *ngIf="filteredErrors[0] as err">
            <span style="color: red">
                <ng-container *ngIf="useTranslation">
                    {{ err.errorCode | translate: err.errorParameters }}
                </ng-container>
                <ng-container *ngIf="!useTranslation">
                    {{ err.errorDescription }}
                </ng-container>
            </span>
        </mat-error>
    </ng-template>

    <ng-template #frontendErrors>
        <mat-error *ngIf="showFormError">
            <span style="color: red">{{ formErrors[0] }}</span>
        </mat-error>
    </ng-template>
</div>