import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TpBreadcrumbComponent } from "./tp-breadcrumb.component";

@NgModule({
  declarations: [TpBreadcrumbComponent],
  imports: [
    CommonModule
  ],
    exports: [
        TpBreadcrumbComponent
    ]
})
export class TpBreadcrumbModule { }