import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {AppConfigService} from "../../../../services/app.settings.service";
import {BehaviorSubject, Observable, Subject} from "rxjs";
import {BaseResultModel} from "../../../../models";
import {ResponseClientNoteData} from "../models/response-models/response-client-note-data";
import {PostClientNote} from "../models/post-models/post-client-note";

@Injectable({
  providedIn: 'root'
})
export class ClientNotesService {
    private readonly _uri: string;

    refreshClientNotes$ = new Subject<ResponseClientNoteData>();

    constructor(
        private http: HttpClient, private configService: AppConfigService) {
        this._uri = configService.getAPIUrl() + '/client';
    }

    getClientNotes(clientId: number): Observable<BaseResultModel<ResponseClientNoteData[]>> {
        return this.http.get<BaseResultModel<ResponseClientNoteData[]>>(this._uri + '/clientNote/'+ clientId +'/client');
    }
    getClientNote(noteId: number): Observable<BaseResultModel<ResponseClientNoteData>> {
        return this.http.get<BaseResultModel<ResponseClientNoteData>>(this._uri + '/clientNote/'+ noteId);
    }
    saveClientNote(model: PostClientNote): Observable<BaseResultModel<ResponseClientNoteData>> {
        return (model?.id) ? this.updateClientNote(model) : this.addClientNote(model);
    }
    addClientNote(model: PostClientNote): Observable<BaseResultModel<ResponseClientNoteData>> {
        return this.http.post<BaseResultModel<ResponseClientNoteData>>(this._uri + '/clientNote/add', model);
    }
    updateClientNote(model: PostClientNote): Observable<BaseResultModel<ResponseClientNoteData>> {
        return this.http.put<BaseResultModel<ResponseClientNoteData>>(this._uri + '/clientNote/update', model);
    }
    deleteClientNote(noteId: number): Observable<BaseResultModel<boolean>> {
        return this.http.delete<BaseResultModel<boolean>>(this._uri + '/clientNote/delete',{params: new HttpParams().set('clientNoteIds',`${noteId}`)});
    }
}