import {Directive, ElementRef, EventEmitter, HostBinding, HostListener, Input, Output} from '@angular/core';

@Directive({
    selector: '[tpDragAndDropFileArea]',
    host: {}
})
export class DragAndDropFileAreaDirective {
    @HostBinding('class.drag-over-area') dragOverArea: boolean = false;
    @HostBinding('style.border-radius') _borderRadius: string = '';
    @HostBinding('class.system-border-radius') _systemBorderRadius: boolean = false;
    @Output() droppedFiles: EventEmitter<FileList> = new EventEmitter<FileList>();
    @Input() set systemBorderRadius(borderRadius: boolean) {
        this._systemBorderRadius = borderRadius;
    }
    constructor(private element: ElementRef<HTMLElement>) {}

    @HostListener('dragover', ['$event']) onDragOver(event){
        event.preventDefault();
        event.stopPropagation();
        this.dragOverArea = true;
    }
    @HostListener('dragleave', ['$event']) onDragLeave(event){
        event.preventDefault();
        event.stopPropagation();
        this.dragOverArea = false;
    }
    @HostListener('drop', ['$event']) onDrop(event){
        event.preventDefault();
        event.stopPropagation();
        this.dragOverArea = false;
        const files =  event.dataTransfer.files;
        if(files.length > 0) this.droppedFiles.emit(files);
    }
}