/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core'
import { TpProgressBarModule } from '../tp-progress-bar/tp-progress-bar.module';
import { CustomFileUploadComponent } from './custom-file-upload.component';
import {MatButtonModule} from "@angular/material/button";

@NgModule({
    declarations: [CustomFileUploadComponent],
    imports: [
        CommonModule,
        MatFormFieldModule,
        TranslateModule,
        ReactiveFormsModule,
        FlexModule,
        MatIconModule,
        TpProgressBarModule,
        MatButtonModule
    ],
    exports: [CustomFileUploadComponent],
})
export class CustomFileUploadModule {}