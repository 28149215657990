import { Injectable } from '@angular/core';
import moment from "moment/moment";
import { GlobalConfiguration } from "../../models/general/global-configuration";
import { CompanyTime } from "../../models/date/company-time";
import { setHours, setMinutes } from "date-fns";
@Injectable({
    providedIn: 'root'
})
export class DateService {
    constructor(
        private globalConfiguration: GlobalConfiguration
    ) { }

    userRolesWithCurrentTimeZone = ['Freelance Resource', "Inhouse Resource", "Client", "Client Contact"];
    /**
     * @function diffDays
     * @description find how many days difference between two dates
     * @return boolean
     */
    diffDays(fromDate: string | Date, toDate: string | Date): number {
        if (fromDate && toDate) {
            const momentFromDate = moment(fromDate);
            const momentToDate = moment(toDate);
            return momentToDate.diff(momentFromDate, 'days');
        }
        return null;
    }
    /**
     * @function diffHours
     * @description find how many hours difference between two dates
     * @return boolean
     */
    diffHours(fromDate: string | Date, toDate: string | Date): number {
        if (fromDate && toDate) {
            const momentFromDate = moment(fromDate);
            const momentToDate = moment(toDate);
            return momentToDate.diff(momentFromDate, 'hours');
        }
        return null;
    }
    /**
     * @function duration
     * @description find duration between two dates
     * @return {hours: number, minutes: number}
     */
    duration(fromDate: string | Date, toDate: string | Date): { hours: number, minutes: number } {
        if (fromDate && toDate) {
            const momentFromDate = moment(fromDate);
            const momentToDate = moment(toDate);
            const momentDuration = moment.duration(momentToDate.diff(momentFromDate));
            const hours = momentDuration.asHours();
            const minutes = momentDuration.asMinutes() % 60;
            return { hours, minutes };
        }
        return null;
    }
    /**
     * @function hasDatePassed
     * @description check if date has passed, compared with current date
     * @return boolean
     */
    hasDatePassed(date: string | Date): boolean {
        if (date) {
            const dueDate = moment(date);
            const currentDate = moment(new Date());
            const diffDays = dueDate.diff(currentDate, 'days');
            return diffDays < 0;
        }
        return null;
    }

    toUTC(date: string | Date) {
        if (date) {
            const originalDate = new Date(date);
            const dateMinusOffset = new Date(originalDate.getTime() - this.getOffsetInMilliseconds())
            const momentDate = moment(dateMinusOffset).format("YYYY-MM-DDTHH:mm:ss")

            return momentDate;
        }
        return date;
    }

    /**
     * @function toTimeZone
     * @param date: string
     * @description converts date to local time zone
     * @return Date
     */
    toTimeZone(date: string | Date): Date {
        if (date instanceof Date) return new Date(date);
        else if (date as any instanceof moment) return new Date(date);
        else if (date && !date?.includes('Z')) {
            return new Date(new Date(date).getTime() + this.getOffsetInMilliseconds());
        }
        else if (date) return new Date(date);
        else return null;
    }

    getOffsetInMilliseconds() {
        if (this.userRolesWithCurrentTimeZone.includes(this.globalConfiguration.currentUser.userRoleDescription)) {
            let offset = new Date().getTimezoneOffset()
            if (this.isDaylightSavingTime()) {
                offset = offset + 60;
            }
            return -(offset * 60 * 1000);
        }
        const offset = this.globalConfiguration?.companyBranchSetting?.timezoneOffsetDescription;
        // Parse the offset string into hours and minutes
        const sign = offset[0] === '-' ? -1 : 1;
        const [hours, minutes] = offset.slice(1).split(':').map(Number);

        // Calculate the offset in milliseconds
        return sign * (hours * 60 + minutes) * 60 * 1000;
    }

    getCurrentTimezoneOffsetToDisplay() {
        if (!this.userRolesWithCurrentTimeZone.includes(this.globalConfiguration.currentUser.userRoleDescription)) {
            return this.globalConfiguration?.companyBranchSetting?.timezoneOffsetDescription;
        }
        let offsetMinutes = new Date().getTimezoneOffset();
        const sign = (offsetMinutes > 0) ? "-" : "+";
        const hours = Math.floor(Math.abs(offsetMinutes) / 60);
        const minutes = Math.abs(offsetMinutes) % 60;

        // Format the offset as "+hh:mm" or "-hh:mm"
        return sign + ("0" + hours).slice(-2) + ":" + ("0" + minutes).slice(-2);
    }

    isDaylightSavingTime() {
        const currentDate = new Date();
        const januaryDate = new Date(currentDate.getFullYear(), 0, 1);
        const julyDate = new Date(currentDate.getFullYear(), 6, 1);

        return januaryDate.getTimezoneOffset() !== julyDate.getTimezoneOffset();
    }

    /**
     * @function addTimeZoneOffset
     * @param date: string
     * @description add time zone offset to date
     * @return Date
     */
    addTimeZoneOffset(date: Date | string): Date {
        if (!date) return null;
        const dateResult = new Date(date);
        dateResult.setMinutes(dateResult.getMinutes() - (dateResult.getTimezoneOffset()));
        return dateResult;
    }
    getMomentFormat(format: string): string {
        return (format) ? format.replace('dd', 'DD').replace('mm', 'MM').replace('yyyy', 'YYYY') : '';
    }
    getDateRangePickerFormat(format: string): string {
        return (format) ? format.replace('DD', 'dd').replace('mm', 'MM').replace('YYYY', 'yy') : '';
    }
    getCompanyTime(): CompanyTime {
        const startTime = this.globalConfiguration?.companyBranchSetting?.startTimeValue ?? null;
        const endTime = this.globalConfiguration?.companyBranchSetting?.endTimeValue ?? null;
        let companyTime = new CompanyTime();
        if (startTime && startTime.includes(':')) {
            const startTimes = startTime.split(':');
            companyTime.startHours = Number(startTimes[0]);
            companyTime.startMinutes = Number(startTimes[1]);
        }
        if (endTime && endTime.includes(':')) {
            const endTimes = endTime.split(':');
            companyTime.endHours = Number(endTimes[0]);
            companyTime.endMinutes = Number(endTimes[1]);
        }
        return companyTime;
    }
    setCompanyStartTime(date: Date = new Date()): Date {
        const companyTime = this.getCompanyTime();
        return setHours(setMinutes(date, companyTime.startMinutes), companyTime.startHours);
    }
    setCompanyEndTime(date: Date = new Date()): Date {
        const companyTime = this.getCompanyTime();
        return setHours(setMinutes(date, companyTime.endMinutes), companyTime.endHours);
    }
}
