import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {QuoteFilesComponent} from "./quote-files.component";
import {FileTabsModule} from "../../../../../sharedComponents/file-tabs/file-tabs.module";
import {DragAndDropFileModule} from "../../../../../../components/drag-and-drop-file/drag-and-drop-file.module";
import {FileListModule} from "../../../../../sharedComponents/file-list/file-list.module";

@NgModule({
    declarations: [QuoteFilesComponent],
    imports: [
        CommonModule,
        FileTabsModule,
        DragAndDropFileModule,
        FileListModule
    ],
    exports: [
        QuoteFilesComponent
    ]
})
export class QuoteFilesModule { }
