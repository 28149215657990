import { NgModule } from '@angular/core';
import { DaysBetweenDatesExcludingGlobalHolidaysPipe } from 'app/pipes/days-between-dates-excluding-global-holidays/days-between-dates-excluding-global-holidays.pipe';


@NgModule({
    declarations: [DaysBetweenDatesExcludingGlobalHolidaysPipe],
    providers: [DaysBetweenDatesExcludingGlobalHolidaysPipe],
    exports: [
        DaysBetweenDatesExcludingGlobalHolidaysPipe,
    ],
})
export class DaysBetweenDatesExcludingGlobalHolidaysPipeModule {
}