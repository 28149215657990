import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TpCategoryDropDownComponent } from 'app/components/tp-category-dropdown/tp-category-dropdown.component'
import { TpDropdownModule } from 'app/components/tp-dropdown/tp-dropdown.module'
import { TpMultiselectDropdownModule } from 'app/components/tp-multiselect-dropdown/tp-multiselect-dropdown.module'
import { RefDataService } from 'app/services/general/refData.service'

@NgModule({
    declarations: [TpCategoryDropDownComponent],
	imports: [
		CommonModule,
		TpDropdownModule,
		ReactiveFormsModule,
		TpMultiselectDropdownModule,
		FormsModule,
	],
    providers: [RefDataService],
    exports: [TpCategoryDropDownComponent],
})
export class TpCategoryDropdownModule {}