import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AppConfigService } from 'app/services/app.settings.service';
import { environment } from 'environments/environment';
import { Observable, of, Subject, throwError } from 'rxjs';
import { SearchCriteriaModel, SearchResult, TpValidationError } from 'app/models';
import { BankAccountSearchCriteriaModel } from '../models/bank-account-search-criteria.model';
import { BankAccountModel } from '../models/bank-account.model';
import { catchError, map, startWith, take } from 'rxjs/operators';
import { CORE_SESSION_STORAGE } from 'app/services/storage/tp-storage.service.ts';
import { StorageService } from 'ngx-webstorage-service';
import { DropDownOptionsModel } from 'app/models/general';

@Injectable()
export class BankAccountService {
    
    private readonly _apiUri: string;
    private refreshBankAccountsObservers$ = new Subject();
    private refreshBankAccountsLock: boolean;
    private refBankAccountsStorageKey : string = "refBankAccounts";

    constructor(private http: HttpClient, configService: AppConfigService,
        @Inject(CORE_SESSION_STORAGE) private sessionStorage: StorageService) {
            if (configService) {
            this._apiUri = configService.getAPIUrl();
        }
    }
    
    searchBankAccounts(model: SearchCriteriaModel<BankAccountSearchCriteriaModel>): Observable<SearchResult<BankAccountModel>> {
        return this.http.post<SearchResult<BankAccountModel>>(`${this._apiUri}${environment.accounting.bankAccounts.getBankAccounts}`, model);
    }

    saveBankAccount(account: BankAccountModel): Observable<BankAccountModel> {
        if (account.bankAccountId) {
            return this.http.put<BankAccountModel>(`${this._apiUri}${environment.accounting.bankAccounts.updateBankAccount.replace(environment.accounting.bankAccounts.idPlaceholder, account.bankAccountId)}`, account);
        } else {
            return this.http.post<BankAccountModel>(`${this._apiUri}${environment.accounting.bankAccounts.createBankAccount}`, account);
        }
    }

    deleteBankAccount(bankAccountId: string): Observable<BankAccountModel> {
        return this.http.delete<null>(`${this._apiUri}${environment.accounting.bankAccounts.deleteBankAccounts}?ids=${bankAccountId}`);
    }

    getRefBankAccounts(): Observable<DropDownOptionsModel[]> {
        return this.refreshBankAccountsObservers$.pipe(startWith(undefined))
            .pipe(map(() => {
                const persistedData: string = this.sessionStorage.get(this.refBankAccountsStorageKey) as string;
                if (persistedData) {
                    return JSON.parse(persistedData) as DropDownOptionsModel[];
                } else {
                    this.refreshBankAccounts().pipe(take(1)).subscribe();
                    const persistedDataNew: string = this.sessionStorage.get(this.refBankAccountsStorageKey) as string;
                    if (persistedDataNew) {
                        return JSON.parse(persistedDataNew) as DropDownOptionsModel[];
                    }
                }
            }));
    }

    refreshBankAccounts(): Observable<any> {
        if (this.refreshBankAccountsLock) {
            return of();
        } else {
            this.refreshBankAccountsLock = true;
            return this.searchBankAccounts({
                requiresCounts: true,
                showAll: true
            })
            .pipe(map((result) => {
                this.refreshBankAccountsLock = false;
                var dropDownResult = result.result?.map(
                    (m) =>
                        <DropDownOptionsModel>{
                            text: m.accountName,
                            value: m.bankAccountId,
                        },
                )

                this.sessionStorage.set(this.refBankAccountsStorageKey, JSON.stringify(dropDownResult));
                this.refreshBankAccountsObservers$.next();
                return;
            }),
            catchError(err => {
                this.refreshBankAccountsLock = false;
                return throwError(err);
            }));
            
        }
    }
}