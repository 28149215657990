/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseResultModel, SearchCriteriaModel, SearchResult, SendEmailModel, TotalsByCurrencyModel } from 'app/models';
import { ExportTypes } from 'app/utills/enums/export-types';
import { FilePreviewModel } from 'app/models/general';
import { PostQuote } from 'app/models/general/quote/post-models/post-quote';
import {
    PostQuoteJob, PostQuoteJobFile,
    PostQuoteJobRejection,
    PostQuoteStatus
} from 'app/models/general/quote/post-models/post-quote-job';
import { PutQuote } from 'app/models/general/quote/put-models/put-quote';
import { ResponseQuoteData } from 'app/models/general/quote/response-models/response-quote-data';
import { ResponseQuoteGridData } from 'app/models/general/quote/response-models/response-quote-grid-data';
import { TimeFrameEnumForTotals, TotalsModel } from 'app/models/general/totals.model';
import { AppConfigService } from 'app/services/app.settings.service';
import { TpAuthService } from 'app/services/auth/tp-auth.service';
import { ApirequestService, HttpVerb } from 'app/services/general/api-service.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { ResponseProjectData } from '../../projects/models/response-models/response-project-data';
import { QuoteJob, QuoteModel, QuotesSearchCriteria } from '../../quotes/models';
import { PostQuoteChase, ResponseQuoteChaseData } from '../../quotes/models/quote-chase';
import {ResponseQuoteJobData} from "../../../../models/general/quote/response-models/response-quote-job-data";
import { ResponseQuoteJobCatCount } from 'app/models/general/quote/response-models/response-quote-job-cat-count';
import { PostQuoteJobCatCount } from 'app/models/general/quote/post-models/post-quote-job-cat-count';
import { PutQuoteJobCatCount } from 'app/models/general/quote/put-models/put-quote-job-cat-count';
import {QuoteStatusTypes} from "../../quotes/utils";
import {PostQuoteFile} from "../../quotes/models/post-quote-file";

@Injectable({
    providedIn: 'root',
})
export class QuoteService {

    private readonly _uri: string = '';

    private endpoint = '/quote';

    constructor(configService: AppConfigService,
                private http: HttpClient,
                private translate: TranslateService,
                private authService: TpAuthService,
                private api:ApirequestService) {
        if (configService) {
            this._uri = configService.getAPIUrl();
        }
    }

    buildFileUploadPathForQuotes(props: {
        clientName: string,
        quoteName: string,
        quoteCode: string
    }): string {
        if (props.clientName && props.quoteName && props.quoteCode) {
            return `/Clients/Quotes/${props.clientName}/${props.quoteName} (${props.quoteCode})`;
        } else {
            return undefined;
        }
    }

    search(model: SearchCriteriaModel<QuotesSearchCriteria>): Observable<BaseResultModel<SearchResult<ResponseQuoteGridData>>> {
        return this.api.ApiRequest<BaseResultModel<SearchResult<ResponseQuoteGridData>>>(environment.apiUrl + this.endpoint + '/search',model,new HttpHeaders(),HttpVerb.post,'json');         
    }
    /**
     * @function searchQuoteJobs
     * @param {SearchCriteriaModel<QuotesSearchCriteria>} model
     * @description search for quote jobs
     * @return Observable<BaseResultModel<SearchResult<ResponseQuoteJobData>>>
     * */
    searchQuoteJobs(model: SearchCriteriaModel<QuotesSearchCriteria>): Observable<BaseResultModel<SearchResult<ResponseQuoteJobData>>> {
        return this.api.ApiRequest<BaseResultModel<SearchResult<ResponseQuoteJobData>>>(environment.apiUrl + '/quoteJob/search',model,new HttpHeaders(),HttpVerb.post,'json');
    }

    validateQuote(model: QuoteModel): Observable<QuoteModel> {
        const endpoint = `${this._uri}${this.endpoint}/validateQuote`;
        return this.http.post<QuoteModel>(`${endpoint}`, model);
    }
    saveQuote(model: PostQuote): Observable<BaseResultModel<ResponseQuoteData>> {
        return this.http.post<BaseResultModel<ResponseQuoteData>>(environment.apiUrl + this.endpoint ,model);
    }
    updateQuote(model: ResponseQuoteData): Observable<BaseResultModel<ResponseQuoteData>> {
        return this.http.put<BaseResultModel<ResponseQuoteData>>(environment.apiUrl + this.endpoint , model);
    }
    saveQuoteWithFiles(formData:any): Observable<BaseResultModel<ResponseQuoteData>> {
        return this.http.post<BaseResultModel<ResponseQuoteData>>(environment.apiUrl + this.endpoint + '/add/with-files',formData); 
    }
    updateQuoteWithFiles(formData: FormData): Observable<BaseResultModel<ResponseQuoteData>> {
        return this.http.put<BaseResultModel<ResponseQuoteData>>(environment.apiUrl + this.endpoint + '/update/with-files', formData);
    }
    saveAndSendQuote(model: PostQuote): Observable<BaseResultModel<ResponseQuoteData>> {
        return this.api.ApiRequest<BaseResultModel<ResponseQuoteData>>(environment.apiUrl + this.endpoint + '/save-send',model,new HttpHeaders(),HttpVerb.post,'json');        
    }
    updateQuoteAndSend(model: ResponseQuoteData): Observable<BaseResultModel<ResponseQuoteData>> {
        return this.api.ApiRequest<BaseResultModel<ResponseQuoteData>>(environment.apiUrl + this.endpoint + '/save-send',model,new HttpHeaders(),HttpVerb.put,'json');        
    }
    saveAndSendQuoteWithFiles(formData: FormData): Observable<BaseResultModel<ResponseQuoteData>> {
        return this.http.post<BaseResultModel<ResponseQuoteData>>(environment.apiUrl + this.endpoint + '/save-send/with-files', formData);
    }
    updateQuoteAndSendWithFiles(formData: FormData): Observable<BaseResultModel<ResponseQuoteData>> {
        return this.http.put<BaseResultModel<ResponseQuoteData>>(environment.apiUrl + this.endpoint + '/save-send/with-files', formData);
    }

    deleteQuote(id: number): Observable<any> {
        const endpoint = `${this._uri}${this.endpoint}/${id}`;
        return this.http.delete<any>(`${endpoint}`);
    }

    calculateTotals(model: QuoteModel): Observable<QuoteModel> {
        const endpoint = `${this._uri}${this.endpoint}/calculateTotals`;
        return this.http.post<QuoteModel>(`${endpoint}`, model);
    }

    getQuote(id: number): Observable<BaseResultModel<ResponseQuoteData>> {
        return this.api.ApiRequest<BaseResultModel<ResponseQuoteData>>(environment.apiUrl + this.endpoint + '/' + id,'',new HttpHeaders(),HttpVerb.get,'json');
    }

    getQuoteFiles(quoteId: number): Observable<BaseResultModel<PostQuoteFile[]>> {
        return this.api.ApiRequest<BaseResultModel<PostQuoteFile[]>>(environment.apiUrl + this.endpoint + '/id/' + quoteId + '/files','',new HttpHeaders(),HttpVerb.get,'json');
    }
    downloadQuoteFile(fileId: number): Observable<BaseResultModel<string>> {
        return this.api.ApiRequest<BaseResultModel<string>>(environment.apiUrl + this.endpoint + '/file/' + fileId ,'',new HttpHeaders(),HttpVerb.get,'json');
    }
    deleteQuoteFile(fileId: number): Observable<BaseResultModel<string>> {
        return this.api.ApiRequest<BaseResultModel<string>>(environment.apiUrl + this.endpoint + '/file/' + fileId ,'',new HttpHeaders(),HttpVerb.delete,'json');
    }
    uploadQuoteFile(payload: PostQuoteFile): Observable<BaseResultModel<PostQuoteFile>> {
        return this.api.ApiRequest<BaseResultModel<PostQuoteFile>>(environment.apiUrl + this.endpoint + '/file/' ,payload, new HttpHeaders(),HttpVerb.post,'json');
    }

    getQuoteByToken(token: string): Observable<BaseResultModel<ResponseQuoteData>> {
        return this.api.ApiRequest<BaseResultModel<ResponseQuoteData>>(environment.apiUrl + this.endpoint + '/token/' + token,'',new HttpHeaders(),HttpVerb.get,'json');
    }

    approveRejectQuote(choice:string,token: string): Observable<BaseResultModel<ResponseQuoteData>> {
        return this.api.ApiRequest<BaseResultModel<ResponseQuoteData>>(environment.apiUrl + this.endpoint + '/approve-reject/' + choice + '/token/' + token,'',new HttpHeaders(),HttpVerb.post,'json');
    }

    approveRejectQuoteJob(choice:string,jobId: number): Observable<BaseResultModel<boolean>> {
        return this.api.ApiRequest<BaseResultModel<boolean>>(environment.apiUrl + '/quotejob/approve-reject/job' + jobId + '/' + choice,'',new HttpHeaders(),HttpVerb.post,'json');
    }

    getTotalsByCurrency<T>(
        model: SearchCriteriaModel<T>,
    ): Observable<TotalsByCurrencyModel[]> {
        return this.http.post<TotalsByCurrencyModel[]>(
            `${this._uri}${this.endpoint}/totalsByCurrency`,
            model,
        );
    }

    validateQuoteJob(model: QuoteJob): Observable<QuoteJob> {
        const endpoint = `${this._uri}${this.endpoint}/validateQuoteJob`;
        return this.http.post<QuoteJob>(`${endpoint}`, model);
    }

    addQuoteJob(model: PostQuoteJob): Observable<any> {
        const endpoint = `${this._uri}${this.endpoint}/QuoteJob`;
        return this.http.post<any>(`${endpoint}`, model);
    }

    updateQuoteJob(model: PostQuoteJob): Observable<BaseResultModel<PostQuoteJob>> {
        return this.http.put<BaseResultModel<PostQuoteJob>>(this._uri + '/QuoteJob', model);
    }

    getByProjectId(projectId: number): Observable<BaseResultModel<ResponseQuoteGridData[]>> {
        return this.api.ApiRequest<BaseResultModel<ResponseQuoteGridData[]>>(environment.apiUrl + '/project/' + projectId,'',new HttpHeaders(),HttpVerb.get,'json');        
    }
    /***
     * @function deleteQuoteJobFile
     * @description delete quote job file based on fileId
     * @param fileId: string
     * @returns Observable<BaseResultModel<boolean>>
     */
    deleteQuoteJobFile(fileId: string): Observable<BaseResultModel<boolean>> {
        return this.http.delete<BaseResultModel<boolean>>(this._uri + '/quoteJob/file/' + fileId);
    }
    /***
     * @function downloadQuoteJobFile
     * @description download quote job file based on fileId
     * @param fileId: string
     * @returns Observable<BaseResultModel<any>>
     */
    downloadQuoteJobFile(fileId: string): Observable<BaseResultModel<string>> {
        return this.http.get<BaseResultModel<string>>(this._uri + '/quoteJob/file/' + fileId);
    }
    /***
     * @function uploadQuoteJobFile
     * @description upload quote job file
     * @param payload: PostQuoteJobFile
     * @returns Observable<BaseResultModel<string>>
     */
    uploadQuoteJobFile(payload: PostQuoteJobFile): Observable<BaseResultModel<string>> {
        return this.http.post<BaseResultModel<string>>(this._uri + '/quoteJob/file', payload);
    }

    /***
     * @description gets linked quotes to project by project Id
     * @param projectId: number
     * @returns BaseResultModel<ResponseQuoteGridData[]>
     */
     getQuotesByProjectId(projectId: number): Observable<BaseResultModel<ResponseQuoteGridData[]>> {
        return this.api.ApiRequest<BaseResultModel<ResponseQuoteGridData[]>>(environment.apiUrl + '/quote/project/' + projectId,'',new HttpHeaders(),HttpVerb.get,'json');        
    }

    /***
     * @description adds new quote based on project Id
     * @param projectId: number
     * @returns BaseResultModel<ResponseQuoteGridData>
     */
    createQuoteFromProjectId(projectId: number): Observable<BaseResultModel<ResponseQuoteGridData>> {
        const model = {
            projectId: projectId,
        }
        return this.api.ApiRequest<BaseResultModel<ResponseQuoteGridData>>(environment.apiUrl + '/quote/project/createFromProjectId',model,new HttpHeaders(),HttpVerb.post,'json');
    }

    createProject(quoteId: number): Observable<BaseResultModel<ResponseProjectData>> {
        return this.api.ApiRequest<BaseResultModel<ResponseProjectData>>(environment.apiUrl + '/project/' + quoteId + '/createProject','',new HttpHeaders(),HttpVerb.post,'json');        
    }

    /***
     * @description previews a quote before gets saved in the db by submitting all quote data and the rendering type 
     * @param model: QuoteModel
     * @param previewType: string
     * @returns BaseResultModel<ResponseQuoteGridData>
     */
    previewQuote(model: ResponseQuoteData, previewType: string): Observable<BaseResultModel<FilePreviewModel>> {
        if(previewType=='HTML'){
            return this.api.ApiRequest<BaseResultModel<FilePreviewModel>>(environment.apiUrl + '/preview/quote/not-saved/html',model,new HttpHeaders(),HttpVerb.post,'json');        
        }else{
            return this.api.ApiRequest<BaseResultModel<FilePreviewModel>>(environment.apiUrl + '/preview/quote/temporary/pdf',model,new HttpHeaders(),HttpVerb.post,'json');
        }
    }

    /***
     * @description previews a quote by it's id and the rendering type
     * @param quoteId: number
     * @param previewType: string
     * @param previewType: string
     * @returns BaseResultModel<ResponseQuoteGridData>
     */
    previewQuoteById(quoteId: number, previewType: string): Observable<BaseResultModel<FilePreviewModel>> {
        if(previewType=='HTML'){
            return this.api.ApiRequest<BaseResultModel<FilePreviewModel>>(environment.apiUrl + '/preview/quote/' + quoteId + '/html','',new HttpHeaders(),HttpVerb.get,'json');        
        }
        else{
            return this.api.ApiRequest<BaseResultModel<FilePreviewModel>>(environment.apiUrl + '/preview/quote/' + quoteId + '/pdf','',new HttpHeaders(),HttpVerb.get,'json');        
        }        
    }

    /***
     * @description previews a quote by it's id and the rendering type
     * @param quoteId: number
     * @param previewType: string
     * @param previewType: string
     * @returns BaseResultModel<ResponseQuoteGridData>
     */
    previewPDFQuoteById(quoteId: number): Observable<BaseResultModel<FilePreviewModel>> {
        return this.api.ApiRequest<BaseResultModel<FilePreviewModel>>(environment.apiUrl + '/preview/quote/' + quoteId + '/pdf/test','',new HttpHeaders(),HttpVerb.get,'json');             
    }

    changeQuotesStatus(
        quoteIds: number[],
        statusId: number,
        statusChangeReason?: string,
        notes?: string,
    ): Observable<boolean> {
        const endpoint = `${this._uri}${this.endpoint}/changeStatus`;
        return this.http.put<boolean>(`${endpoint}`, {
            entityIds: quoteIds,
            status,
            statusChangeReason,
            notes,
        });
    }

    changeQuotesStatuses(model: PostQuoteStatus): Observable<BaseResultModel<boolean>> {
        return this.api.ApiRequest<BaseResultModel<boolean>>(environment.apiUrl + this.endpoint + '/changeStatus',model,new HttpHeaders(),HttpVerb.put,'json');
    }
    changeClientQuotesStatuses(model: PostQuoteStatus): Observable<BaseResultModel<boolean>> {
        return this.api.ApiRequest<BaseResultModel<boolean>>(environment.apiUrl + this.endpoint + '/changeStatus/client',model,new HttpHeaders(),HttpVerb.put,'json');
    }

    saveQuoteJobRejectionReason(model: PostQuoteJobRejection): Observable<BaseResultModel<PostQuoteJobRejection>> {
        return this.api.ApiRequest<BaseResultModel<PostQuoteJobRejection>>(environment.apiUrl + '/quoteJob/quoteJob-rejection',model,new HttpHeaders(),HttpVerb.post,'json');
    }

    markQuotesAsSent(ids: number[]): Observable<BaseResultModel<boolean>> {
        const model = {
            QuoteIds: ids,
        }
        return this.api.ApiRequest<BaseResultModel<boolean>>(environment.apiUrl + this.endpoint + '/markAsSent',model,new HttpHeaders(),HttpVerb.post,'json');
    }

    /***
     * @description creates a new quote by an existing one
     * @param quoteId: number
     * @returns 
     */
    copyQuoteById(quoteId: number): Observable<BaseResultModel<ResponseQuoteGridData>> {
        return this.api.ApiRequest<BaseResultModel<ResponseQuoteChaseData>>(environment.apiUrl + this.endpoint + '/' + quoteId + '/copyQuote','',new HttpHeaders(),HttpVerb.post,'json');
    }


    /***
     * @description deletes submited quotes by their id
     * @param ids:number[]
     * @returns BaseResultModel<boolean>
     */
    deleteQuotesByIds(ids: number[]): Observable<BaseResultModel<boolean>> {
        return this.api.ApiRequest<BaseResultModel<boolean>>(environment.apiUrl + this.endpoint + '/delete/quotes',ids,new HttpHeaders(),HttpVerb.post,'json');
    }

    getOpenQuotesTotals(timeFrame: TimeFrameEnumForTotals): Observable<TotalsModel> {
        return this.http.get<TotalsModel>(`${this._uri}${this.endpoint}/percChangeOpenQuotes?timeFrameEnumForTotals=${timeFrame}`);
    }

    getQuotesValueTotals(timeFrame: TimeFrameEnumForTotals): Observable<TotalsModel> {
        return this.http.get<TotalsModel>(`${this._uri}${this.endpoint}/percChangeQuotesValue?timeFrameEnumForTotals=${timeFrame}`);
    }

    getQuotesWinRatioTotals(timeFrame: TimeFrameEnumForTotals): Observable<TotalsModel> {
        return this.http.get<TotalsModel>(`${this._uri}${this.endpoint}/percChangeQuotesWin?timeFrameEnumForTotals=${timeFrame}`);
    }
    getQuotesAverageRevenueTotals(timeFrame: TimeFrameEnumForTotals): Observable<TotalsModel> {
        return this.http.get<TotalsModel>(`${this._uri}${this.endpoint}/averageRevenue?timeFrameEnumForTotals=${timeFrame}`);
    }

    exportQuotes(model: QuotesSearchCriteria, type: ExportTypes): Observable<FilePreviewModel> {
        const searchModel: SearchCriteriaModel<QuotesSearchCriteria> = {
            showAll: true,
            searchCriteria: model,
        };
        return this.http.post<FilePreviewModel>(`${this._uri}${this.endpoint}/export/${type}`, searchModel);
    }

    /**
     * @description exports multiple quotes in lines
     * @param ids
     * @param type 
     */
    exportQuotesByType(ids: number[], type: ExportTypes): Observable<BaseResultModel<FilePreviewModel>> {
        if(type==ExportTypes.XLSX){
            return this.api.ApiRequest<BaseResultModel<FilePreviewModel>>(environment.apiUrl + '/preview/quotes/xlsx',ids,new HttpHeaders(),HttpVerb.post,'json');
        }
        else if(type==ExportTypes.PDF){
            return this.api.ApiRequest<BaseResultModel<FilePreviewModel>>(environment.apiUrl + '/preview/quotes/pdf',ids,new HttpHeaders(),HttpVerb.post,'json');
        }
        else if(type==ExportTypes.CSV){

        }
    }

    getSendQuoteEmail(quoteId: number): Observable<SendEmailModel> {
        return this.http.get<SendEmailModel>(`${this._uri}${environment.emailManager.apiSection}/${environment.emailManager.sendQuoteEmailEndpoint}?${environment.emailManager.quoteIdQueryParam}=${quoteId}`);
    }

    /***
     * @description registers a manula quote chase in the system
     * @param postQuoteChase: PostQuoteChase
     * @returns BaseResultModel<ResponseQuoteChaseData>
     */
    logManualQuoteChase(postQuoteChase: PostQuoteChase): Observable<BaseResultModel<ResponseQuoteChaseData>> {
        return this.api.ApiRequest<BaseResultModel<ResponseQuoteChaseData>>(environment.apiUrl + this.endpoint + '/logManualChase',postQuoteChase,new HttpHeaders(),HttpVerb.post,'json');
    }

    getChaseQuoteEmail(quoteId: number): Observable<BaseResultModel<boolean>> {
        return this.api.ApiRequest<BaseResultModel<boolean>>(environment.apiUrl + this.endpoint + '/' + quoteId + '/send-chase','',new HttpHeaders(),HttpVerb.post,'json');
    }

    updateChaseCounter(quoteIds: number[]): Observable<any> {
        const qString = `ids=${quoteIds.join('&ids=')}`;
        const endpoint = `${this._uri}${this.endpoint}/chaseCounter?${qString}`;
        return this.http.put<any>(`${endpoint}`, null);
    }

    chaseQuotesByIds(quoteIds: number[]): Observable<BaseResultModel<boolean>> {
        return this.http.post<BaseResultModel<boolean>>( this._uri + this.endpoint + '/send-chase', {quoteIds});
    }

    downloadQuotesByIds(quoteIds: number[]): Observable<any> {
        return this.authService.userLoad()
            .pipe(
                take(1),
                switchMap(profile => {
                    const endpoint = `${this._uri}${this.endpoint}/downloadQuotes`;
                    return this.http.post(endpoint, {
                        entityIds: quoteIds,
                        emailAddress: profile.userName,
                    });
                }),
            );
    }

    updateQuotesDateSent(quoteIds: number[]): Observable<any> {
        const qString = `ids=${quoteIds.join('&ids=')}`;
        const endpoint = `${this._uri}${this.endpoint}/markAsSent?${qString}`;
        return this.http.put<any>(endpoint, {});
    }

    addQuoteStatus(model): Observable<BaseResultModel<any>> {
        return this.api.ApiRequest<BaseResultModel<any>>(environment.apiUrl + this.endpoint + '/quoteStatus/add',model,new HttpHeaders(),HttpVerb.post,'json');
    }

    updateQuoteStatus(model): Observable<BaseResultModel<any>> {
        return this.api.ApiRequest<BaseResultModel<any>>(environment.apiUrl + this.endpoint + '/quoteStatus/update',model,new HttpHeaders(),HttpVerb.put,'json');
    }

    addQuoteJobStatus(model): Observable<BaseResultModel<any>> {
        return this.api.ApiRequest<BaseResultModel<any>>(environment.apiUrl + '/quoteJob/quoteJobStatus/add',model,new HttpHeaders(),HttpVerb.post,'json');
    }

    updateQuoteJobStatus(model): Observable<BaseResultModel<any>> {
        return this.api.ApiRequest<BaseResultModel<any>>(environment.apiUrl + '/quoteJob/quoteJobStatus/update',model,new HttpHeaders(),HttpVerb.put,'json');
    }

    getQuoteStatus(id): Observable<BaseResultModel<any>> {
        return this.api.ApiRequest<BaseResultModel<any>>(environment.apiUrl + '/quote/quoteStatus/' + id,'',new HttpHeaders(),HttpVerb.get,'json');
    }

    getQuoteJobStatus(id): Observable<BaseResultModel<any>> {
        return this.api.ApiRequest<BaseResultModel<any>>(environment.apiUrl + '/quoteJob/quoteJobStatus/' + id,'',new HttpHeaders(),HttpVerb.get,'json');
    }

    deleteStatusById(id: string): Observable<any> {
        return this.api.ApiRequest<BaseResultModel<any>>(environment.apiUrl + '/quote/quoteStatus/' + id,'',new HttpHeaders(),HttpVerb.delete,'json');
    }

    deleteQuoteJobStatusById(id: string): Observable<any> {
        return this.api.ApiRequest<BaseResultModel<any>>(environment.apiUrl + '/quoteJob/quoteJobStatus/' + id,'',new HttpHeaders(),HttpVerb.delete,'json');
    }    

    /***
     * @description gets stored quote job cat count figures
     * @param quoteJobId: number
     * @returns BaseResultModel<ResponseQuoteJobCatCount>
     */
    getQuoteJobCatCount(quoteJobId: number): Observable<BaseResultModel<ResponseQuoteJobCatCount>> {
        return this.api.ApiRequest<BaseResultModel<ResponseQuoteJobCatCount>>(environment.apiUrl + '/quotejob/id/' + quoteJobId + '/cat-count','',new HttpHeaders(),HttpVerb.get,'json');        
    }

    /***
     * @description stores new quote job cat count figures
     * @param model: PostQuoteJobCatCount
     * @returns BaseResultModel<ResponseQuoteJobCatCount>
     */
    addQuoteJobCatCount(model: PostQuoteJobCatCount): Observable<BaseResultModel<ResponseQuoteJobCatCount>> {
        return this.api.ApiRequest<BaseResultModel<ResponseQuoteJobCatCount>>(environment.apiUrl + '/quotejob/cat-count',model,new HttpHeaders(),HttpVerb.post,'json');        
    }
    
    /***
     * @description updates a quote job cat count figures
     * @param model: PutQuoteJobCatCount
     * @returns BaseResultModel<ResponseQuoteJobCatCount>
     */
    updateQuoteJobCatCount(model: PutQuoteJobCatCount): Observable<BaseResultModel<ResponseQuoteJobCatCount>> {
        return this.api.ApiRequest<BaseResultModel<ResponseQuoteJobCatCount>>(environment.apiUrl + '/quotejob/cat-count',model,new HttpHeaders(),HttpVerb.put,'json');        
    }
}