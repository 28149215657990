import {AfterViewInit, Directive, ElementRef, Input} from '@angular/core';

@Directive({
  selector: '[tpAutoFocus]'
})
export class AutoFocusDirective implements AfterViewInit{
    @Input() set allowAutofocus(allow: boolean){
        this._allowAutofocus = allow;
        if (this.allowAutofocus) setTimeout(() => this.element.nativeElement.focus(), 0);
    }
    get allowAutofocus(): boolean{ return this._allowAutofocus};
    private _allowAutofocus: boolean
    constructor(public element: ElementRef<HTMLElement>) {}
    ngAfterViewInit(): void {
        if (this.allowAutofocus) {
            setTimeout(() => this.element.nativeElement.focus(), 0);
        }
    }
}