import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TpAutocompleteDropdownModule } from 'app/components/tp-autocomplete-dropdown/tp-autocomplete-dropdown.module';
import { LeadSearchComponent } from 'app/main/sharedComponents/lead-search/lead-search.component';

@NgModule({
    declarations: [LeadSearchComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TpAutocompleteDropdownModule,
        TranslateModule,
    ],
    exports: [
        LeadSearchComponent,
    ],
})
export class LeadSearchModule {
}