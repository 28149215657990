import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { GlobalConfiguration } from 'app/models/general/global-configuration';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { LoadRefData } from 'app/ngxsState/ref-data/ref-data.actions';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private globalConfiguration: GlobalConfiguration,
        private store: Store
    ) {}
    canActivate(): Observable<boolean> {
        return Observable.create(observer => {
            if(this.globalConfiguration?.currentUser?.id) {
                this.store.dispatch(new LoadRefData(false));
                observer.next(true);
            }
            else observer.next(false);
            observer.complete();
        });  
    }
}