import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActionsComponent } from './actions.component';
import {TpButtonModule} from "../tp-button/tp-button.module";
import {MatMenuModule} from "@angular/material/menu";
import {MatListModule} from "@angular/material/list";
import {TpModalModule} from "../tp-modal/tp-modal.module";
import {TranslateModule} from "@ngx-translate/core";
import {SupportLinksModule} from "./support-links/support-links.module";
import {AddActionsModule} from "./add-actions/add-actions.module";
@NgModule({
    declarations: [
      ActionsComponent
    ],
    imports: [
        CommonModule,
        TpButtonModule,
        MatMenuModule,
        MatListModule,
        TpModalModule,
        TranslateModule,
        SupportLinksModule,
        AddActionsModule
    ],
    exports: [
        ActionsComponent
    ]
})
export class ActionsModule { }