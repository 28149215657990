import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { RefDataService } from 'app/services/general/refData.service';


@Injectable()
export class RefDataResolver implements Resolve<any> {
    constructor(private refDataService: RefDataService) {
    }
    
    resolve(_route: ActivatedRouteSnapshot): Observable<any> {
        return of();
        // return this.refDataService.getRefData();
    }
}  