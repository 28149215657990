<tp-modal #modal>
    <div header>
        <h5 class="modal-title">
            <span [translate]="'tp-cat-count'" [translateParams]="{ defaultText: 'CAT Count' }"></span>
        </h5>
    </div>
    <div body></div>
    <div footer>
        <div class="float-right">
            <tp-button class="mr-2" buttonStyle="outline" (buttonClicked)="enterManually.emit(true)"
                       [label]="'tp-enter-manually' | translate: '{ defaultText : \'Enter Manually\'}'">
            </tp-button>
            <tp-button buttonStyle="submit" (buttonClicked)="save()"
                       [label]="'tp-analyse' | translate: '{ defaultText : \'Analyse\'}'">
            </tp-button>
        </div>
    </div>
</tp-modal>