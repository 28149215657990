import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileTabsComponent } from './file-tabs.component';
import {MatRippleModule} from "@angular/material/core";
import {MatIconModule} from "@angular/material/icon";

@NgModule({
    declarations: [
        FileTabsComponent
    ],
    exports: [
        FileTabsComponent
    ],
    imports: [
        CommonModule,
        MatRippleModule,
        MatIconModule
    ]
})
export class FileTabsModule { }