import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TpCountryDropdownModule } from 'app/components/tp-country-dropdown/tp-country-dropdown.module';
import { TpDropdownModule } from 'app/components/tp-dropdown/tp-dropdown.module';
import { TpLanguagesSupportedDropdownModule } from 'app/components/tp-languages-supported-dropdown/tp-languages-supported-dropdown.module';
import { TpTextAreaModule } from 'app/components/tp-text-area/tp-text-area.module';
import { TpTextBoxModule } from 'app/components/tp-text-box/tp-text-box.module';
import { ContactModalComponent } from 'app/main/sharedComponents/contact-modal/contact-modal.component';
import {TpCheckboxModule} from "../../../components/tp-checkbox/tp-checkbox.module";
import { AddContactComponent } from './add-contact/add-contact.component';
import {ComponentsModule} from "../../../components/components.module";
import {MatTooltipModule} from "@angular/material/tooltip";
import {LeadContactModalModule} from "../lead-contact-modal/lead-contact-modal.module";
import {MatTabsModule} from "@angular/material/tabs";
import {TpUserPermissionListModule} from "../../../components/tp-user-permission-list/tp-user-permission-list.module";
import {EditClientModule} from "../edit-client/edit-client.module";
import {MatBadgeModule} from "@angular/material/badge";
import {UserLoginsModule} from "../../admin/users/shared/user-logins/user-logins.module";
import {MatExpansionModule} from "@angular/material/expansion";


@NgModule({
    declarations: [ContactModalComponent, AddContactComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TranslateModule,
        TpTextBoxModule,
        TpLanguagesSupportedDropdownModule,
        TpTextAreaModule,
        TpDropdownModule,
        TpCountryDropdownModule,
        TpCheckboxModule,
        ComponentsModule,
        MatTooltipModule,
        LeadContactModalModule,
        MatTabsModule,
        TpUserPermissionListModule,
        MatBadgeModule,
        UserLoginsModule,
        MatExpansionModule,
    ],
    exports: [
        ContactModalComponent,
        AddContactComponent,
    ],
})
export class ContactModalModule {
}