import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CompanyAnnouncementService } from 'app/main/admin/services/company-announcement.service';

@Injectable({
    providedIn: 'root'
})
export class MessagesService {
    private messagesSubject: BehaviorSubject<{ [key: string]: string }> = new BehaviorSubject<{ [key: string]: string }>(null);
    public data$: Observable<any> = this.messagesSubject.asObservable();

    constructor(private announcementService: CompanyAnnouncementService) { }

    fetchData(): void {
        this.announcementService.get().subscribe({
            next: (data) => {
                let messages: { [key: string]: string } = {};
                data.forEach(item => {
                    messages[item.title] = item.message;
                });

                this.messagesSubject.next(messages);
            }
        })
    }

    updateMessages(updatedData: any): void {
        this.messagesSubject.next(updatedData);
    }


    initializeApp(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.fetchData(); // Call the method to fetch initial data
            resolve(null); // Resolve the promise
        });
    }
}