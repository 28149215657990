<div>
    <form class="px-2 mat-expansion-panel-padding" [formGroup]="editQuoteJobForm">
        <mat-tab-group animationDuration="0ms">
            <mat-tab>
                <ng-template mat-tab-label>
                    <span [translate]="'tp-core'" [translateParams]="{ defaultText: 'Core' }"></span>
                </ng-template>
                <div class="pt-3">
                <div class="row">
                    <div class="col-sm-6 col-md-6 col-lg-6" id="quoteJobName">
                        <tp-text-box maxLength="200" [errors]="validationErrors" [fields]="['JobName']" formControlName="name" [required]="true" [attr.data-cy]="editQuoteJobForm.controls.name"
                                     label="{{'tp-name' | translate: '{ defaultText : \'Name\'}'}}">
                        </tp-text-box>
                    </div>
                    
                    <div class="col-sm-6 col-md-6 col-lg-6" id="quoteJobService">
                        <tp-dropdown formControlName="service" [errors]="validationErrors" [required]="true" [options]="displayServices" [attr.data-cy]="editQuoteJobForm.controls.service"
                                     label="{{'tp-service' | translate: '{ defaultText : \'Service\'}'}}">
                        </tp-dropdown>
                    </div>
                </div>

                <div class="row">
                    <div *ngIf="isMonolingual || isBilingual" 
                    [ngClass]="isMonolingual ? 'col-12' : 'col-sm-6'"
                     id="quoteJobSourceLanguage">
                        <tp-dropdown formControlName="sourceLanguage" [required]="true" [options]="languages" [attr.data-cy]="editQuoteJobForm.controls.sourceLanguage"
                                     label="{{'tp-source-language' | translate: '{ defaultText : \'Source Language\'}'}}">
                        </tp-dropdown>
                    </div>
                    
                    <div class="col-sm-6" *ngIf="isBilingual" id="quoteJobTargetLanguage">
                        <tp-dropdown formControlName="targetLanguages" [required]="true" [multiple]="true" [selectAll]="false" [options]="languages" [attr.data-cy]="editQuoteJobForm.controls.targetLanguages"
                                     label="{{'tp-target-languages' | translate: '{ defaultText : \'Target Languages\'}'}}">
                        </tp-dropdown>
                    </div>

                    <div class="col-sm-6 col-md-6 col-lg-6" id="quoteJobWorkflowSteps">
                        <tp-workflow-dropdown #workflowDropdown formControlName="workflowSteps" [selectedServiceId]="selectedServiceId"></tp-workflow-dropdown>
                    </div>

                    <div class="col-sm-6 col-md-3 col-lg-3" id="quoteJobVolume">
                        <tp-text-box inputType="number" [errors]="validationErrors" [fields]="['UnitValue']"
                                     formControlName="volume" [required]="true" [attr.data-cy]="editQuoteJobForm.controls.volume"
                                     label="{{'tp-volume' | translate: '{ defaultText : \'Volume\'}'}}">
                        </tp-text-box>
                    </div>
                    
                    <div class="col-sm-6 col-md-3 col-lg-3" id="quoteJobUnit">
                        <tp-dropdown formControlName="volumeUnit" [errors]="validationErrors" [fields]="['UnitId']" [required]="true" [options]="units" [attr.data-cy]="editQuoteJobForm.controls.volumeUnit" (valueChanged)="unitChanged($event)"
                                     label="{{'tp-unit' | translate: '{ defaultText : \'Unit\'}'}}">
                        </tp-dropdown>
                    </div>
                    
                    <div class="col-lg-6 col-md-6 col-sm-6" id="quoteJobRateType">
                        <tp-dropdown formControlName="rateTypeId" [required]="true" [options]="rateTypes" [attr.data-cy]="editQuoteJobForm.controls.rateTypeId" (valueChanged)="rateTypeChanged($event)"
                                     label="{{'tp-rate-type' | translate: '{ defaultText : \'Rate Type\'}'}}">
                        </tp-dropdown>
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-6" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" id="quoteJobRateCost">
                        <tp-text-box [errors]="validationErrors" [fields]="['UnitRate']" inputType="number" formControlName="rateCost" [required]="true"  [prefix]="{ content: quoteJob?.currencySymbol }" [attr.data-cy]="editQuoteJobForm.controls.rateCost"
                                     [label]="rateTypeId == 2 ? ('tp-fixed-amount' | translate: '{ defaultText : \'Fixed Amount\'}') :
                                      ('tp-rate' | translate: '{ defaultText : \'Rate\'}')">
                        </tp-text-box>
                        <span id="quoteJobTotalAmount" class="font-weight-bold mr-0 mat-form-field" *ngIf="!isBilingual">{{'tp-total' | translate :'{defaultText:\'Total\'}'}} {{quoteJob?.currencySymbol}} {{quoteJob?.totalAmount | formatNumber | async}}</span>
                        <tp-button class="mb-2 ml-2" buttonStyle="add" color="primary" 
                                   (buttonClicked)="onClickAddPriceToAllCombinations()"
                                   [disabled]="isRatePositive()"
                                   [matTooltip]="rateAddable ?
                                    (('tp-add-this-rate-to-the-selected-client-price-list' | translate: '{ defaultText : \'Add this rate to the selected client price list.\'}') +
                                    (!this.isSelectedCurrencyMatchingWithClientCurrency() ? ' Note: Unmatched with client currency.' : ''))
                                   : ('tp-select-service,-rate-and-currency-first' | translate: '{ defaultText : \'Select service, rate and currency first.\'}')">
                        </tp-button>
                    </div>

                    <div class="col-sm-6 col-md-3 col-lg-3" id="quoteJobTax">
                        <tp-text-box [errors]="validationErrors" inputType="number" [attr.data-cy]="editQuoteJobForm.controls.tax"
                                     formControlName="tax"
                                     label="{{'tp-vat-(%)' | translate: '{ defaultText : \'VAT (%)\'}'}}">
                        </tp-text-box>
                    </div>

                    <div class="col-sm-6 col-md-3 col-lg-3" id="quoteJobDiscountOrSurcharge">
                        <tp-text-box [disabled]="disabledDiscount" matTooltip="{{'tp-discount/surcharge-info' | translate}}" [errors]="validationErrors" [fields]="['Discount', 'Surcharge']" inputType="number" formControlName="discountOrSurcharge" [attr.data-cy]="editQuoteJobForm.controls.discountOrSurcharge"
                                     label="{{'tp-discount/surcharge-(%)' | translate: '{ defaultText : \'Discount/Surcharge (%)\'}'}}">
                        </tp-text-box>
                    </div>

                    <div class="col-sm-6 col-md-6 col-lg-6" id="quoteJobDiscountOrSurchargeReason">
                        <tp-text-box [errors]="validationErrors" [fields]="['DiscountReason', 'SurchargeReason']" formControlName="discountOrSurchargeReason" [attr.data-cy]="editQuoteJobForm.controls.discountOrSurchargeReason"
                                     label="{{'tp-discount/surcharge-reason' | translate: '{ defaultText : \'Discount/Surcharge reason\'}'}}">
                        </tp-text-box>
                    </div>

                    <div class="col-12" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px" *ngIf="{
                            catCountButtonDisabled: !editQuoteJobForm.controls.sourceLanguage.value
                                && (!editQuoteJobForm.controls.targetLanguage.value || !editQuoteJobForm.controls.targetLanguages.value)
                        } as localContext">

                        <tp-button *ngIf="this.isBilingual" buttonStyle="outline" [disabled]="localContext.catCountButtonDisabled" (click)="showCatCountAnalysisData()"
                                   [matTooltip]="'tp-select-source-and-target-languages-first' | translate: '{ defaultText : \'Select source and target languages first\'}'"
                                   [matTooltipDisabled]="!localContext.catCountButtonDisabled"
                                   [label]="'tp-cat-analysis' | translate: '{ defaultText : \'CAT Analysis\'}'">
                        </tp-button>
                    </div>
                </div>

                <div class="row" *ngIf="isBilingual && quoteJobs.length > 0">
                    <div class="col-sm-12 col-md-12 col-lg-12 combinations-container pb-2">
                        <mat-accordion>
                            <mat-expansion-panel class="mat-elevation-z0">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <div class="combinations-title d-flex align-items-center">
                                            <span class="font-weight-bold pr-3">
                                                {{quoteJobs?.length || 0}}
                                                <span *ngIf="quoteJobs?.length == 1; else moreThanOneCombination">{{'tp-combination' | translate : '{defaultText:\'Combination\'}'}}</span>
                                                <ng-template #moreThanOneCombination>{{'tp-combinations' | translate : '{defaultText:\'Combinations\'}'}}</ng-template>
                                            </span>
                                            <mat-icon matTooltip="{{'tp-refresh-price-list' | translate : '{defaulText:\'Refresh price list\'}'}}" (click)="$event.stopPropagation(); onRefreshCombinationsPriceList()">autorenew</mat-icon>
                                        </div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <tp-job-combinations-grid
                                    #jobCombinationGrid
                                    [quoteJobs]="quoteJobs"
                                    [client]="client"
                                    (changedUnit)="onChangedUnitCombination($event)"
                                    (changedRate)="onChangedRateCombination($event)"
                                    (addPrice)="onClickAddPriceToCombination($event)">
                                </tp-job-combinations-grid>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12" id="duration">
                        <tp-radio-button formControlName="durationType" [options]="dateTypes" [attr.data-cy]="editQuoteJobForm.controls.durationType"></tp-radio-button>
                    </div>
                    
                    <div class="col-lg-3 col-md-3 col-sm-12" id="overallTime">
                        <tp-text-box [errors]="validationErrors"
                                     [fields]="['OverallDurationNo']" [required]="true"
                                     inputType="number" formControlName="estimatedDuration" [attr.data-cy]="editQuoteJobForm.controls.estimatedDuration"
                                     label="{{'tp-overall-timeline' | translate: '{ defaultText : \'Overall Timeline\'}'}}">
                        </tp-text-box>
                    </div>
                    
                    <div class="col-lg-3 col-md-3 col-sm-12" id="timelineUnit">
                        <tp-dropdown label="{{'tp-unit' | translate: '{ defaultText : \'Unit\'}'}}"
                                     formControlName="estimatedDurationUnit" [required]="true" [attr.data-cy]="editQuoteJobForm.controls.estimatedDurationUnit"
                                     [options]="timeUnits">
                        </tp-dropdown>
                    </div>
                    
                    <div class="col-lg-3 col-md-3 col-sm-12" id="startDate">
                        <tp-calendar [errors]="validationErrors" [showTime]="true"
                                     formControlName="startDate" [attr.data-cy]="editQuoteJobForm.controls.startDate"
                                     [label]="'tp-start-date' | translate: '{ defaultText : \'Start date\'}'">
                        </tp-calendar>
                    </div>
                    
                    <div class="col-lg-3 col-md-3 col-sm-12" id="endDate">
                        <tp-calendar [errors]="validationErrors" [showTime]="true"
                                     formControlName="endDate" [attr.data-cy]="editQuoteJobForm.controls.endDate"
                                     [label]="'tp-end-date' | translate: '{ defaultText : \'End date\'}'">
                        </tp-calendar>
                    </div>
                    
                    <div class="col-lg-12 col-md-12 col-sm-12" id="comments">
                        <tp-rich-textbox formControlName="jobComments" [errors]="validationErrors"
                                         [format]="'html'"
                                         [showToolBar]="true"
                                         [fields]="['JobComments']" [attr.data-cy]="editQuoteJobForm.controls.jobComments"
                                         label="{{'tp-comments' | translate: '{ defaultText : \'Comments\'}'}}">
                        </tp-rich-textbox>
                    </div>
                </div>
                </div>
            </mat-tab>
            
            <mat-tab>
                <ng-template mat-tab-label>
                    <span [translate]="'tp-files'" [translateParams]="{defaultText: 'Files'}"></span>
                    <span *ngIf="editQuoteJobForm.value.fileIds?.length > 0">({{editQuoteJobForm.value.fileIds?.length}})</span>
                </ng-template>
                <div class="pt-3">
                    <div class="row">
                        <div class="col-lg-3 col-md-3 col-sm-3 border-right">
                            <tp-file-tabs #fileTabs [displayTabs]="['Source','Reference','Target']" [files]="files"></tp-file-tabs>
                        </div>
                        <div class="col-lg-9 col-md-9 col-sm-9">
                            <div class="quote-job-upload-files-content">
                                <tp-drag-and-drop-file [allowSelectButton]="(quoteFiles.length > 0)"
                                                       [selectButtonLabel]="'tp-select-quote-files' | translate: '{ defaultText : \'Select quote files\'}'"
                                                       [autoUpload]="autoUpload"
                                                       [(files)]="files"
                                                       (selectButtonClicked)="selectQuoteFilesModal.show(); selectQuoteFiles.clearSelectedFiles()"
                                                       [fileType]="fileTabs.activeTab">
                                </tp-drag-and-drop-file>
                            </div>
                            <div></div>
                            <div class="py-2">
                                <tp-file-list [(files)]="files" [fileType]="fileTabs.activeTab" [endpoint]="autoUpload.endpoint"></tp-file-list>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </form>

    <tp-modal #selectQuoteFilesModal>
        <div header>
            <h5 class="modal-title">
                <span>{{'tp-select-files-from-quote' | translate : '{defaultText:\'Select files from quote\'}'}}</span>
            </h5>
        </div>
        <div body>
            <div class="px-2">
                <tp-quote-files #selectQuoteFiles [select]="true" [files]="quoteFiles"></tp-quote-files>
            </div>
        </div>
        <div footer>
            <div class="d-flex justify-content-end">
                <tp-button buttonStyle="submit" (buttonClicked)="addSelectedFiles(selectQuoteFiles.selectedFiles, fileTabs.activeTab); selectQuoteFilesModal.hide()"
                           [label]="'tp-save' | translate: '{ defaultText : \'Save\'}'">
                </tp-button>
            </div>
        </div>
    </tp-modal>
    <tp-cat-count-analyse-modal #catCountAnalyseModal (saved)="uploadCsvFile($event)" (enterManually)="enterManually()"></tp-cat-count-analyse-modal>
    <tp-cat-count-analysis-modal #catCountAnalysisModal (saved)="getDiscountedWords($event)"></tp-cat-count-analysis-modal>

</div>