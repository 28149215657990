import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer
} from '@ngrx/store';
import { environment } from '../../../../environments/environment';
import {
    clientsFeatureKey,
    clientsEntity,
    clientGridFeatureKey,
    clientGridEntity,
    clientNotesFeatureKey,
    clientNotesEntity,
    clientContactsFeatureKey,
    clientContactsEntity,
    clientFilesFeatureKey,
    clientFilesEntity,
    clientRatesFeatureKey,
    clientRatesEntity,
    resourcesFeatureKey,
    resourceGridFeatureKey,
    resourceFilesFeatureKey,
    resourceRatesFeatureKey,
    resourcesEntity,
    resourceGridEntity,
    resourceFilesEntity,
    resourceRatesEntity,
    quoteGridFeatureKey,
    quoteGridEntity,
    servicesFeatureKey,
    servicesEntity,
    unitsFeatureKey, unitsEntity, serviceGridFeatureKey, serviceGridEntity, resourceNotesFeatureKey, resourceNotesEntity
} from "./reducers";
import {EntityState} from "@ngrx/entity";
import {ClientSearchCriteria, ResponseClientData} from "../clients/models";
import {ClientStateReport} from "../../../store/models/client/client-state-report";
import {EntityAdditional, EntitySearchAdditional} from "../../../store/models";
import {ResponseClientGridData, ResponseClientPOCData} from "../clients/models/client.model";
import {ResponseClientNoteData} from "../clients/models/response-models/response-client-note-data";
import {ResponseClientFile} from "../clients/models/response-models/response-client-file";
import {ResponseClientPricingData} from "../clients/models/client-pricing";
import {ResponseResourceData} from "../resources/models/response-models/response-resource-data";
import {ResponseResourceGridData} from "../resources/models/response-models/response-resource-grid-data";
import {ResourceSearch} from "../../../models/general/system-models/search-models/resource/resource-search";
import {ResponseResourceFile} from "../resources/models/response-models/response-resource-file";
import {ResponseResourceServiceRateData} from "../resources/models/response-models/response-resource-service-rate-data";
import {ResponseQuoteGridData} from "../../../models/general/quote/response-models/response-quote-grid-data";
import {QuotesSearchCriteria} from "../quotes/models";
import {ResponseServiceData} from "../../admin/manage-services/models/response-models/response-service-data";
import {UnitModel} from "../../admin/unit/models";
import {ResponseServiceGridData} from "../../admin/manage-services/models/response-models/response-service-grid-data";
import {ResponseResourceNoteData} from "../resources/models/response-models/response-resource-note-data";
import {workflowsEntity, workflowsFeatureKey} from "./reducers/workflow.reducer";
import {ResponseWorkflowData} from "../../admin/workflows/models/response-workflow-data";

export * from './features';

export interface PmPortalState {
    // clients
    [clientsFeatureKey]: EntityState<ResponseClientData & ClientStateReport> & EntityAdditional;
    [clientGridFeatureKey]: EntityState<ResponseClientGridData> & EntitySearchAdditional<ClientSearchCriteria>;
    [clientNotesFeatureKey]: EntityState<ResponseClientNoteData> & EntityAdditional;
    [clientContactsFeatureKey]: EntityState<ResponseClientPOCData> & EntityAdditional;
    [clientFilesFeatureKey]: EntityState<ResponseClientFile> & EntityAdditional;
    [clientRatesFeatureKey]: EntityState<ResponseClientPricingData> & EntityAdditional;
    //resources
    [resourcesFeatureKey]: EntityState<ResponseResourceData> & EntityAdditional,
    [resourceGridFeatureKey]: EntityState<ResponseResourceGridData> & EntitySearchAdditional<ResourceSearch>,
    [resourceNotesFeatureKey]: EntityState<ResponseResourceNoteData> & EntityAdditional,
    [resourceFilesFeatureKey]: EntityState<ResponseResourceFile> & EntityAdditional,
    [resourceRatesFeatureKey]: EntityState<ResponseResourceServiceRateData> & EntityAdditional,
    //quotes
    [quoteGridFeatureKey]: EntityState<ResponseQuoteGridData> & EntitySearchAdditional<QuotesSearchCriteria>,
    //services
    [servicesFeatureKey]:  EntityState<ResponseServiceData> & EntityAdditional,
    [serviceGridFeatureKey]:  EntityState<ResponseServiceGridData> & EntitySearchAdditional<any>,
    //units
    [unitsFeatureKey]: EntityState<UnitModel> & EntityAdditional,
    //workflows
    [workflowsFeatureKey]:  EntityState<ResponseWorkflowData> & EntityAdditional

}

export const pmPortalReducers: ActionReducerMap<PmPortalState> = {
    //clients
    [clientsFeatureKey]: clientsEntity.reducer,
    [clientGridFeatureKey]: clientGridEntity.reducer,
    [clientNotesFeatureKey]: clientNotesEntity.reducer,
    [clientContactsFeatureKey]: clientContactsEntity.reducer,
    [clientFilesFeatureKey]: clientFilesEntity.reducer,
    [clientRatesFeatureKey]: clientRatesEntity.reducer,
    //resources
    [resourcesFeatureKey]:  resourcesEntity.reducer,
    [resourceGridFeatureKey]: resourceGridEntity.reducer,
    [resourceNotesFeatureKey]: resourceNotesEntity.reducer,
    [resourceFilesFeatureKey]: resourceFilesEntity.reducer,
    [resourceRatesFeatureKey]: resourceRatesEntity.reducer,
    //quotes
    [quoteGridFeatureKey]: quoteGridEntity.reducer,
    //services
    [servicesFeatureKey]: servicesEntity.reducer,
    [serviceGridFeatureKey]: serviceGridEntity.reducer,
    //units
    [unitsFeatureKey]: unitsEntity.reducer,
    //workflows
    [workflowsFeatureKey]: workflowsEntity.reducer
};

export const pmPortalMetaReducers: MetaReducer<PmPortalState>[] = !environment.production ? [] : [];