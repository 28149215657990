import { Pipe, PipeTransform } from '@angular/core';
import { ServiceNamePipe } from 'app/pipes/service-name-pipe/service-name.pipe';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
    name: 'servicesNames',
})
export class ServicesNamesPipe implements PipeTransform {

    constructor(private serviceNamePipe: ServiceNamePipe) {
    }

    transform(
        serviceIds: number[],
    ): Observable<string> {
        if (serviceIds?.length > 0) {
            return combineLatest(serviceIds?.map(s => this.serviceNamePipe.transform(s)))
                .pipe(map(services => services.join(', ')));
        } else {
            return of('---');
        }
    }
}