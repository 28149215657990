import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseResultModel, CountryModel, ServiceLanguageModel } from 'app/models';
import { DropDownItem } from 'app/models/general/dropdownItem.model';
import { environment } from 'environments/environment';
import { StorageService } from 'ngx-webstorage-service';
import { Observable, of, Subject } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { Constants } from '../../shared.constants';

import { CORE_SESSION_STORAGE } from '../storage/tp-storage.service.ts';
import { ApirequestService, HttpVerb } from './api-service.service';

@Injectable({
    providedIn: 'root',
})
export class CommonService {
    /**
     * Endpoint request headers.
     * @type {HttpHeaders}
     */
    private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    
    /**
     * Component constructor and DI injection point.
     * @param {HttpClient} http
     * @param {AppConfigService} config
     */
    constructor(
        private http: HttpClient,
        private translate: TranslateService,
        @Inject(CORE_SESSION_STORAGE) private sessionStorage: StorageService,
        private api:ApirequestService
    ) {
       
    }
    
    
}