export enum FileTypes{
    All = 0,
    Source = 1,
    Target = 2,
    Reference = 3,
    Working = 4,
    GuideLine = 5,
    General = 6,
    SourceLink = 7,
    ReferenceLink = 8,
    Deliverables=9
}

export enum FileExtensionTypes{
    Csv = 'csv',
    Doc = 'doc',
    Docx = 'docx',
    Html = 'html',
    Idml = 'idml',
    Json = 'json',
    Mxliff = 'mxliff',
    Mqout = 'mqout',
    Mxqxliff = 'mxqxliff',
    Odp = 'odp',
    Odt = 'odt',
    Php = 'php',
    Po = 'po',
    Ppt = 'ppt',
    Pptx = 'pptx',
    Rtf = 'rtf',
    Sdlppx = 'sdlppx',
    Sdlxlf = 'sdlxlf',
    Sdlxliff = 'sdlxliff',
    Srt = 'srt',
    Strings = 'strings',
    Ttx = 'ttx',
    Txt = 'txt',
    Vsdm = 'vsdm',
    Vtt = 'vtt',
    Wix = 'wix',
    Xlf = 'xlf',
    Xliff = 'xliff',
    Xls = 'xls',
    Xlsx = 'xlsx',
    Xltm = 'xltm',
    Xml = 'xml',
    Yaml = 'yaml',
    Zip = 'zip',
    Rar = 'rar',
    Mp3 = 'mp3',
    Mp4= 'mp4',
    Pdf= 'pdf'
}
const acceptFileType = [

];
