import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { TpButtonModule } from 'app/components/tp-button/tp-button.module';
import { TpCalendarModule } from 'app/components/tp-calendar/tp-calendar.module';
import { TpDatePickerModule } from 'app/components/tp-date-picker/tp-date-picker.module';
import { TpTextBoxModule } from 'app/components/tp-text-box/tp-text-box.module';
import { BulkEditProjectJobsComponent } from 'app/main/pm/projects/shared/bulk-edit-project-jobs/bulk-edit-project-jobs.component';
import { ResourceSearchModule } from 'app/main/sharedComponents/resource-search/resource-search.module';
import { ServiceNamePipeModule } from 'app/pipes/service-name-pipe/service-name-pipe.module';
import { TpGridModule } from '../../../../../components/tp-grid/tp-grid.module';
import {TpAutocompleteDropdownModule} from "../../../../../components/tp-autocomplete-dropdown/tp-autocomplete-dropdown.module";
import {TpDropdownModule} from "../../../../../components/tp-dropdown/tp-dropdown.module";
import {ServiceDescriptionModule} from "../../../../../pipes/service-description/service-description.module";


@NgModule({
    declarations: [BulkEditProjectJobsComponent],
    imports: [
        CommonModule,
        TpTextBoxModule,
        TranslateModule,
        FormsModule,
        TpCalendarModule,
        TpButtonModule,
        MatTabsModule,
        ServiceNamePipeModule,
        ResourceSearchModule,
        TpDatePickerModule,
        MatCardModule,
        TpGridModule,
        MatDividerModule,
        FlexModule,
        MatTooltipModule,
        MatIconModule,
        TpAutocompleteDropdownModule,
        TpDropdownModule,
        ServiceDescriptionModule,
    ],
    exports: [
        BulkEditProjectJobsComponent,
    ],
})
export class BulkEditProjectJobsModule {
}