import { NgModule } from '@angular/core';
import { ProjectManagerNamePipe } from 'app/pipes/project-manager-name/project-manager-name.pipe';
import { ProjectManagerInitialsPipe } from './project-manager-initials.pipe';

@NgModule({
    declarations: [ProjectManagerNamePipe, ProjectManagerInitialsPipe],
    providers: [ProjectManagerNamePipe, ProjectManagerInitialsPipe],
    exports: [
        ProjectManagerNamePipe,
        ProjectManagerInitialsPipe,
    ],
})
export class ProjectManagerNamePipeModule {
}