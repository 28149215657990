<tp-modal #modal [modalConfig]="{maxHeight: '100vh', height: '100vh'}" [modalType]="ModalTypes.DialogRightSide">
    <div header>
        <h5 class="modal-title">
            <span [translate]="'tp-analysis-results'" [translateParams]="{ defaultText: 'Analysis Results' }"></span>
        </h5>
    </div>
    <div body></div>
    <div footer>
        <div class="d-flex justify-content-between align-items-center">
            <tp-button buttonStyle="outline" (buttonClicked)="newAnalysis()"
                       [label]="'tp-new-analysis' | translate: '{ defaultText : \'New Analysis\'}'">
            </tp-button>
            <tp-button buttonStyle="submit" (buttonClicked)="save()"
                       [label]="'tp-update' | translate: '{ defaultText : \'Update\'}'">
            </tp-button>
        </div>
    </div>
</tp-modal>