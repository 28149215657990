import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChild, ElementRef, EventEmitter, Input, Output, SimpleChanges, TemplateRef,} from '@angular/core';
import { Sort } from '@angular/material/sort';
import { TpPaginationPageChangedEvent } from 'app/components/tp-pagination/tp-pagination.component';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { ArrayHelpersService } from '../../services/helpers/array-helpers.service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {animate, style, transition, trigger} from "@angular/animations";

export type TpGridActionIconType =
    | 'edit'
    | 'check'
    | 'star_rate'
    | 'delete'
    | 'file_copy'
    | 'email'
    | 'arrow_downward'
    | 'arrow_upward'
    | 'search'
    | 'visibility'
    | 'highlight_off'
    | 'euro_symbol'
    | 'block'
    | 'add_task'
    | 'add'
    | 'textsms'

export interface TpGridTableColumnMetaData {
    title: Observable<string>
    dataField: string
    sortable?: boolean
    dataText?: (data: any) => Observable<string>
    isTemplate?: boolean
    centered?: boolean
    containsCheckBox?: boolean
    disableCheckBox?: (data: any) => Observable<boolean>
    cssClass?: string
    isExpandable?: boolean
    id?: (data: any) => any
    hasParentProject?: boolean
    parentProjectId?: (data:any) => Observable<string>,
    display?: boolean
    columnTextTooltip?: (data: any) => Observable<string>
}

export interface TpGridTableSubColumnMetaData {
    title: Observable<string>
    dataField: string
    dataText?: (data: any, parent?: any, greatParent?: any) => Observable<string>
    isTemplate?: boolean
    centered?: boolean
    cssClass?: string
    isExpandable?: boolean
    containsCheckBox?: boolean
    id?: (data: any) => any
}

export interface TpGridTableMetaData {
    columns: TpGridTableColumnMetaData[]
    actionItems?: TpGridAction[]
    containsActionColumn?: boolean
    displayedColumns: string[]
    containsLevel2?: boolean
    level2columns?: TpGridTableSubColumnMetaData[]
    level2ActionItems?: TpGridAction[]
    level2Property?: string
    emitLevel2RowOnSelectionChange?: boolean
    containsLevel2ActionColumn?: boolean
    containsLevel3?: boolean
    level3columns?: TpGridTableSubColumnMetaData[]
    level3ActionItems?: TpGridAction[]
    level3Property?: string
    containsLevel3ActionColumn?: boolean
    gridId?: string
    firstLevelTableReference?: string;
    order?: TpGridOrder;
    level2order?: TpGridOrder;
    level3order?: TpGridOrder;
}

export interface TpGridAction {
    title: Observable<string>
    icon?: TpGridActionIconType
    click: (rowData: any, parentRowData: any, idx?: number, row?: TpGridRow, greatParentRowData?: any) => void
    display?: (rowData: any, parentRowData: any) => boolean
    display$?: (rowData: any, parentRowData: any) => Observable<boolean>
}

export interface TpGridRow {
    id: any
    rowData: any
    hasChildRows: boolean
    childRows: TpGridRow[]
    isSelected?: boolean
    expanded?: boolean
    showDeletedJobs : number
}

export interface TpGridOrder{
    allow: boolean;
    boundary?: boolean;
}

export interface TpGridOrderDropped{
    event: CdkDragDrop<string[]>;
    rowData: any;
}

@Component({
    selector: 'tp-grid',
    templateUrl: './tp-grid.component.html',
    styleUrls: ['./tp-grid.component.scss'],
    animations: [
        trigger('gridAnimation', [
            transition(':enter', [
                style({ opacity: '0' }),
                animate(500, style({ opacity: '1' }))
            ]),
            transition(':leave', [
                style({ opacity: '1' }),
                animate(0, style({ opacity: '0' }))
            ]),
        ]),
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TpGridComponent implements AfterViewInit {
    @Input() noDataPlaceholderText: string;
    showExpandCollapseIcon: boolean;
    showDeletedJobsIcon: boolean;

    @Input() set tableConfig(value: TpGridTableMetaData) {
        if (!value.firstLevelTableReference) {
            value.firstLevelTableReference = '';
        }
        this._tableConfig = value;
        this.rowsSelectable = Boolean(this.lvl1IdFunc) || Boolean(this.lvl2IdFunc);
        this.rebuildGridRows();
    }

    get tableConfig(): TpGridTableMetaData {
        return this._tableConfig;
    }

    @Input() set tableContent(value: any[]) {
        this._rawTableContent = value ?? [];
        this.rebuildGridRows();
        if(this._defaultSelected) this.defaultSelected = true;
    }

    @Input() rebuild = true;
    @Input() allowPaging = false;
    @Input() paginationId: string;
    @Input() currentPage = 1;

    @Input() totalCount = 0;

    @Input() overridePaginationOptions: number[];

    @Input() expanded;

    @Input() deletedJobs;

    @Input() pageSize = 20;

    @Input() loadOnExpand: boolean = false;
    @Input() disableAnimation: boolean = true;

    @Input() set defaultSelected (status: boolean){
        this._defaultSelected = status;
        if (status) {
            this.selectAll = true;
            this.onSelectAllChange();
        } else {
            this.selectAll = false;
            this.onSelectAllChange();
        }
    }
    _defaultSelected: boolean = false;

    @Output() startRowAction = new EventEmitter<{}>();
    @Output() changePage = new EventEmitter<TpPaginationPageChangedEvent>();
    @Output() changeSort = new EventEmitter<Sort>();

    @Output() tableSelectionChanged = new EventEmitter<any[]>();
    @Output() tableLevel2SelectionChanged = new EventEmitter<any[]>();

    @Output() expandRow = new EventEmitter<TpGridRow>();
    @Output() ordered = new EventEmitter<TpGridOrderDropped>();
    @Output() level2Ordered = new EventEmitter<TpGridOrderDropped>();
    @Output() level3Ordered = new EventEmitter<TpGridOrderDropped>();

    @ContentChild('mainGridTemplate') mainGridTemplate: TemplateRef<ElementRef>;
    @ContentChild('mainGridActionTemplate')
    mainGridActionTemplate: TemplateRef<ElementRef>;

    @ContentChild('level2GridTemplate')
    level2GridTemplate: TemplateRef<ElementRef>;
    @ContentChild('level2GridActionTemplate')
    level2GridActionTemplate: TemplateRef<ElementRef>;

    @ContentChild('level3GridTemplate')
    level3GridTemplate: TemplateRef<ElementRef>;
    @ContentChild('level3GridActionTemplate')
    level3GridActionTemplate: TemplateRef<ElementRef>;

    _tableContent: TpGridRow[] = [];
    _selectedTableContent: TpGridRow[] = [];
    _selectedIdx: number;
    _selectedRow: TpGridRow;
    _selectedParentRow: TpGridRow;
    _tableConfig: TpGridTableMetaData;
    rowsSelectable: boolean;
    private pageChangedCount: number = 0;
    previousPageChangedEventData: TpPaginationPageChangedEvent;

    private get lvl1IdFunc(): (row: TpGridRow) => any {
        return this.tableConfig.columns?.find((f) => f.containsCheckBox)?.id;
    }

    private get lvl2IdFunc(): (row?: TpGridRow) => any {
        return this.tableConfig.level2columns?.find((f) => f?.containsCheckBox)?.id;
    }

    selectAll = false;
    otherPagesSelectionList: any[] = [];
    otherPagesSecondLevelSelectionList: any[] = [];
    previouslyEmittedTableSelection: any[];
    previouslyEmittedTableLevel2Selection: any[];

    getUrl = window.location;
    baseUrl = this.getUrl.protocol + "//" + this.getUrl.host + "/";

    private _rawTableContent: any[] = [];

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private readonly arrayHelpersService: ArrayHelpersService,
    ) {
    }

    ngAfterViewInit(): void {
        this.emitSelectionChange();
    }

    ngOnChanges(changes: SimpleChanges) {
        const { expanded } = changes;
        if (expanded !== undefined && expanded.currentValue !== expanded.previousValue) {
            this._tableContent.map(row => row.expanded = expanded.currentValue);
        }
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this._rawTableContent, event.previousIndex, event.currentIndex);
        this._rawTableContent = [].concat(this._rawTableContent);
        moveItemInArray(this._tableContent, event.previousIndex, event.currentIndex);
        this._tableContent = [].concat(this._tableContent);
        this.ordered.emit({event, rowData: this._rawTableContent[event.currentIndex]});
    }
    dropLevel2(event: CdkDragDrop<string[]>, firstLevelIndex: number) {
        moveItemInArray(this._rawTableContent[firstLevelIndex][this.tableConfig.level2Property], event.previousIndex, event.currentIndex);
        this._rawTableContent = [].concat(this._rawTableContent);
        moveItemInArray(this._tableContent[firstLevelIndex].childRows, event.previousIndex, event.currentIndex);
        this._tableContent = [].concat(this._tableContent);
        this.level2Ordered.emit({event, rowData: this._rawTableContent[firstLevelIndex][this.tableConfig.level2Property][event.currentIndex]});
    }
    dropLevel3(event: CdkDragDrop<string[]>,firstLevelIndex: number, secondLevelIndex: number) {
        moveItemInArray(this._rawTableContent[firstLevelIndex][this.tableConfig.level2Property][secondLevelIndex][this.tableConfig.level3Property], event.previousIndex, event.currentIndex);
        this._rawTableContent = [].concat(this._rawTableContent);
        moveItemInArray(this._tableContent[firstLevelIndex].childRows[secondLevelIndex].childRows, event.previousIndex, event.currentIndex);
        this._tableContent = [].concat(this._tableContent);
        this.level3Ordered.emit({event, rowData: this._rawTableContent[firstLevelIndex][this.tableConfig.level2Property][secondLevelIndex][this.tableConfig.level3Property][event.currentIndex]});
    }

    onStartedDraggingRow(): void{
        document.body.classList.add("dragging");
    }
    onEndedDraggingRow(): void{
        document.body.classList.remove("dragging");
    }
    /**
     * @function rebuildGridRows
     * @description rebuild grid rows
     * @return void
     * */
    rebuildGridRows(): void {
        if (!this.tableConfig) {return;}
        const newTableContent = this._rawTableContent?.map((row) => (this.buildRow(row)));
        if(!this.rebuild) this.updateTable(newTableContent, this._tableContent);
        else this._tableContent = newTableContent;
        this._selectedTableContent = this._tableContent;
        this.selectAll = this._tableContent?.length > 0 && this._tableContent.every((row) => row.isSelected);
        this.showExpandCollapseIcon = this._tableContent?.some(row => row.hasChildRows);
        this._tableContent?.forEach((row) => {
            if (row.id) {
                const indexOf = this.otherPagesSelectionList?.indexOf(row.id);
                if (indexOf >= 0) {
                    this.otherPagesSelectionList?.splice(indexOf, 1);
                }
            }
            row.childRows?.forEach((childRow) => {
                if (childRow.id) {
                    const indexOf = this.otherPagesSecondLevelSelectionList?.indexOf(
                        childRow.id,
                    );
                    if (indexOf >= 0) {
                        this.otherPagesSecondLevelSelectionList?.splice(
                            indexOf,
                            1,
                        );
                    }
                }
            });
        });
        this.changeDetectorRef.markForCheck();
        this.emitSelectionChange();
    }
    /**
     * @function updateTable
     * @param newTable TpGridRow[]
     * @param table TpGridRow[]
     * @description update existing table with a new table
     * @return void
     * */
    updateTable(newTable: TpGridRow[], table: TpGridRow[]): void {
        let newIds: number[] = newTable.filter(
            newTableRow => !table.map(tableRow => tableRow.rowData.id).includes(newTableRow.rowData.id) || !newTableRow.rowData.id
        ).map(newTableRow => newTableRow.rowData.id);
        let updateIds: number[] = newTable.filter(
            newTableRow => table.map(tableRow => tableRow.rowData.id).includes(newTableRow.rowData.id)
        ).map(newTableRow => newTableRow.rowData.id);
        let deleteIds: number[] = table.filter(
            tableRow => !newTable.map(newTableRow => newTableRow.rowData.id).includes(tableRow.rowData.id) || !tableRow.rowData.id
        ).map(tableRow => tableRow.rowData.id);

        deleteIds.forEach(id => { // deleted rows
            const rowIndex = table.findIndex(tableRow => tableRow.rowData.id === id);
            table.splice(rowIndex, 1);
        });
        newTable?.forEach((newTableRow, newTableRowIndex) => {
            if(newIds.includes(newTableRow.rowData.id)){ // new row
                table.splice(newTableRowIndex, 0, newTableRow);
            } else if (updateIds.includes(newTableRow.rowData.id)){ // update row
                table.map((tableRow,tableRowIndex) => {
                    if(newTableRow.rowData.id === tableRow.rowData.id){ // row to be updated
                        tableRow.id = newTableRow.id;
                        tableRow.rowData = newTableRow.rowData;
                        if(newTableRow.hasChildRows || tableRow.hasChildRows) {
                            this.updateTable(newTableRow.childRows ?? [], tableRow.childRows ?? []); // recursive call to update nested tables
                        }
                        tableRow.hasChildRows = newTableRow.hasChildRows;
                        if(!tableRow.hasChildRows) tableRow.expanded = false;
                    }
                });
                table.forEach((tableRow,tableRowIndex) => {
                    if(newTableRow.rowData.id === tableRow.rowData.id) { // row to be updated
                        moveItemInArray(table, tableRowIndex, newTableRowIndex);
                    }
                });
            }
        });
    }
    /**
     * @function buildRow
     * @param row sany
     * @description build row
     * @return TpGridRow
     * */
    buildRow(row: any): TpGridRow{
        return <TpGridRow>{
            id: this.lvl1IdFunc ? this.lvl1IdFunc(row) : null,
            rowData: row,
            hasChildRows: this.tableConfig.containsLevel2 && row[this.tableConfig.level2Property]?.length > 0,
            childRows: this.tableConfig.containsLevel2 ? (row[this.tableConfig.level2Property] as any[])?.map((subRow) => ({
                    id: this.lvl2IdFunc ? this.lvl2IdFunc(row) : null,
                    rowData: subRow,
                    hasChildRows: this.tableConfig.containsLevel3 && subRow[this.tableConfig.level3Property]?.length > 0,
                    childRows: this.tableConfig.containsLevel3 ? (subRow[this.tableConfig.level3Property] as any[])?.map((subSubRow) => ({
                            id: null,
                            rowData: subSubRow,
                            hasChildRows: false,
                            childRows: undefined,
                            isSelected: false,
                            expanded: subSubRow.expanded || false,
                        } as TpGridRow),
                    ) : undefined,
                    isSelected: subRow.isSelected || (this.lvl2IdFunc && this.lvl2IdFunc(row) && this.otherPagesSecondLevelSelectionList?.includes(this.lvl2IdFunc(row))) || false,
                    expanded: subRow.expanded || false,
                } as TpGridRow),
            ) : undefined,
            expanded: this.expanded === undefined ? (row.expanded || false) : this.expanded,
            isSelected: row.isSelected || (this.lvl1IdFunc && this.lvl1IdFunc(row) && this.otherPagesSelectionList?.includes(this.lvl1IdFunc(row),)) || false,
        }
    }

    get displayedColumns(): TpGridTableColumnMetaData[] {
        if (this.tableConfig && this.tableConfig.displayedColumns) {
            return this.tableConfig.columns.filter(
                (d: TpGridTableColumnMetaData) =>
                    this.tableConfig.displayedColumns?.indexOf(d.dataField) >= 0 && (d?.display != undefined ? d?.display : true),
            );
        }
        return null;
    }
    pageChanged(event: TpPaginationPageChangedEvent): void {
        this.selectAll = false;
        this.pageSize = event.count;
        this.pageChangedCount += 1;
        if (this.pageChangedCount > 1) {
            this.otherPagesSelectionList = _.uniq(
                _.concat(
                    [],
                    this.otherPagesSelectionList || [],
                    this.getCurrentPageSelectedIds(),
                ),
            );
            this.otherPagesSecondLevelSelectionList = _.uniq(
                _.concat(
                    [],
                    this.otherPagesSecondLevelSelectionList || [],
                    this.getCurrentPageSelectedSecondLevelIds(),
                ),
            );
        }
        this.changeDetectorRef.markForCheck();
        
        if(this.previousPageChangedEventData !=null && JSON.stringify(this.previousPageChangedEventData) != JSON.stringify(event) ){
            this.previousPageChangedEventData = event;
            this.changePage.emit(event);
        }
        else{
            this.previousPageChangedEventData = {page: this.currentPage, count: this.pageSize};
        }
    }

    sortData(event: Sort): void {
        this.changeSort.emit(event);
    }

    toggleCollapse(data: TpGridRow): void {
        if (data.hasChildRows || this.loadOnExpand) {
            data.expanded = !data.expanded;
            if(data?.expanded) this.expandRow.emit(data);
            this.changeDetectorRef.markForCheck();
        }
    }

    onSelectAllChange(newSelectAll?: boolean, column?: TpGridTableColumnMetaData): void {
        newSelectAll ??= this.selectAll ?? false;
        let isLastRow: boolean = false;
        this._tableContent?.forEach((row, index) => {
            isLastRow = (this._tableContent.length - 1) === index;
            if(column?.disableCheckBox){
                column?.disableCheckBox(row.rowData).toPromise().then(result => {
                    if(!result){
                        row.isSelected = newSelectAll;
                        if (row.hasChildRows) {
                            row.childRows?.forEach(
                                (subRow) => (subRow.isSelected = newSelectAll),
                            );
                        }
                    }
                    if(isLastRow) this.emitSelectionChange();
                });
            } else {
                row.isSelected = newSelectAll;
                if (row.hasChildRows) {
                    row.childRows?.forEach(
                        (subRow) => (subRow.isSelected = newSelectAll),
                    );
                }
                if(isLastRow) this.emitSelectionChange();
            }
        });
    }

    clearSelectionList(): void {
        this.selectAll = false;
        this.otherPagesSelectionList = [];
        this.otherPagesSecondLevelSelectionList = [];
        this.onSelectAllChange();
        this.changeDetectorRef.markForCheck();
    }

    itemSelectToggled(): void {
        this.emitSelectionChange();
    }

    subItemSelectToggled(): void {
        this.emitSelectionChange();
    }

    emitSelectionChange(): void {
        if (this.lvl1IdFunc) {
            const currPageLvl1 = this.getCurrentPageSelectedIds();
            const lvl1Selected = _.uniq(
                _.concat([], this.otherPagesSelectionList, currPageLvl1),
            );
            if (
                !this.arrayHelpersService.arraysEqual(
                    this.previouslyEmittedTableSelection,
                    lvl1Selected,
                )
            ) {
                this.tableSelectionChanged.emit(lvl1Selected);
                this.previouslyEmittedTableSelection = lvl1Selected;
            }
        } else if (this.lvl2IdFunc) {
            const currPageLvl2 = this.getCurrentPageSelectedSecondLevelIds();
            const lvl2Selected = _.uniq(
                _.concat(
                    [],
                    this.otherPagesSecondLevelSelectionList,
                    currPageLvl2,
                ),
            );
            if (
                !this.arrayHelpersService.arraysEqual(
                    this.previouslyEmittedTableLevel2Selection,
                    lvl2Selected,
                )
            ) {
                this.tableLevel2SelectionChanged.emit(lvl2Selected);
                this.previouslyEmittedTableLevel2Selection = lvl2Selected;
            }
        }

        this.changeDetectorRef.markForCheck();
    }

    refreshConfiguration(config?: TpGridTableMetaData): void {
        if (config) {
            this._tableConfig = config;
            this.rowsSelectable = Boolean(this.lvl1IdFunc) || Boolean(this.lvl2IdFunc);
        }
        this.showExpandCollapseIcon = this._tableContent?.some(row => row.hasChildRows);
        this.changeDetectorRef.markForCheck();
    }

    private getCurrentPageSelectedIds(): any[] {
        if (this._tableContent?.length > 0 && this.lvl1IdFunc) {
            return _.flatMap(
                this._tableContent
                    .filter((r) => r.isSelected)
                    ?.map((r) => this.lvl1IdFunc(r.rowData)),
            ).filter((id) => Boolean(id));
        } else {
            return [];
        }
    }

    private getCurrentPageSelectedSecondLevelIds(): any[] {
        if (
            this.tableConfig.containsLevel2 &&
            this.lvl2IdFunc &&
            this._tableContent?.length > 0
        ) {
            var selectedChildren:number[]=[];

            this._tableContent.forEach(r=>{
                if(r.hasChildRows){
                    r.childRows.forEach(cr=>{
                        if(cr.isSelected){
                            if (this._tableConfig.emitLevel2RowOnSelectionChange) {
                                selectedChildren=[...selectedChildren,cr?.rowData];
                            } else {
                                selectedChildren=[...selectedChildren,cr?.rowData?.id];
                            }
                        }
                    })
                }
            });
            return selectedChildren;
        } else {
            return [];
        }
    }

    hasActionToDisplay(rowData: any): boolean{
       let hasActionToDisplay: boolean = false;
        this.tableConfig.actionItems.forEach(action => {
            if (action.display$) {
                action.display$( rowData, undefined).subscribe(result => {
                    if(result) hasActionToDisplay = true;
                });
            } else {
                if(action.display( rowData, undefined)) hasActionToDisplay = true;
            }
        });
        return hasActionToDisplay;
    }
    /**
     * @function onSelected
     * @param source TpGridRow[]
     * @param index number
     * @param row TpGridRow
     * @param parentRow TpGridRow
     * @description on selected row
     * @return void
     * */
    onSelected(source: TpGridRow[], index: number, row?: TpGridRow, parentRow?: TpGridRow): void {
        this._selectedTableContent = source;
        this._selectedIdx = index;
        this._selectedRow = row;
        this._selectedParentRow = parentRow;
    }
    /**
     * @function addChildRows
     * @param event TpGridRow
     * @param dataSource any[]
     * @param property string
     * @param childProperty string = null
     * @description on expand invoice
     * @return void
     * */
    addChildRows(event: TpGridRow, dataSource: any[], property: string, childProperty: string = null): void{
        if(dataSource?.length > 0) {
            dataSource.forEach(row => {
                if(row){
                    this._selectedTableContent[this._selectedIdx].hasChildRows = true;
                    if(!this._selectedTableContent[this._selectedIdx].childRows) this._selectedTableContent[this._selectedIdx].childRows = [];
                    const rowData = this.buildRow(row);
                    this._selectedTableContent[this._selectedIdx].childRows.push(rowData);
                    if(childProperty){
                        row[childProperty]?.forEach(row => {
                            rowData.hasChildRows = true;
                            if(!rowData.childRows) rowData.childRows = [];
                            rowData.childRows.push(this.buildRow(row));
                        });
                    }
                }
            });
            this.refreshConfiguration();
        }
    }
    /**
     * @function addRow
     * @param rowData any
     * @param index number
     * @description add row at given source and index
     * @return void
     * */
    addRow(rowData: any,  index = 0): void {
        if(rowData){
            this._selectedTableContent.splice(index, 0, this.buildRow(rowData));
            this.refreshConfiguration();
        }
    }
    /**
     * @function updateRow
     * @param rowData any
     * @description update row
     * @return void
     * */
    updateRow(rowData: any): void {
        if(rowData){
            this._selectedTableContent[this._selectedIdx].rowData = Object.assign(this._selectedTableContent[this._selectedIdx].rowData, rowData);
            this.refreshConfiguration();
        }
    }
    /**
     * @function upsertRows
     * @param rowsData any
     * @description update row
     * @return void
     * */
    upsertRows(rowsData: any[]): void {
        if(rowsData?.length > 0){
            rowsData.forEach(row => {
                if(row){
                    if(this._selectedTableContent.find(item => item.rowData.id === row.id)){
                        this.updateRow(row);
                    } else this.addRow(row);
                }
            });
        }
    }
    /**
     * @function removeRow
     * @param index number
     * @description remove row at given source and index
     * @return void
     * */
    removeRow(index?: number): void {
        this._selectedTableContent.splice(index ?? this._selectedIdx, 1);
        if(this._selectedTableContent.length == 0 && this._selectedParentRow) this._selectedParentRow.hasChildRows = false;
        this.refreshConfiguration();
    }
}