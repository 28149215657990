import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CropImageModalComponent } from './crop-image-modal.component';
import {TpModalModule} from "../../tp-modal/tp-modal.module";
import {CropImageModule} from "../crop-image.module";
import {TpButtonModule} from "../../tp-button/tp-button.module";
import {TranslateModule} from "@ngx-translate/core";
@NgModule({
    declarations: [
        CropImageModalComponent
    ],
    imports: [
        CommonModule,
        TpModalModule,
        CropImageModule,
        TpButtonModule,
        TranslateModule
    ],
    exports: [
        CropImageModalComponent
    ]
})
export class CropImageModalModule { }