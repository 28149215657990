import {HttpClient, HttpEvent, HttpHeaders, HttpParams} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ClientContactGridData} from 'app/main/pm/clients/models/client-contact-grid-data';
import {BaseResultModel, PriceListModel, SearchCriteriaModel} from 'app/models';
import {DropDownOptionsModel, FilePreviewModel, TimeFrameEnumForTotals, TotalsModel} from 'app/models/general';
import {DropDownItem} from 'app/models/general/dropdownItem.model';
import {SearchResult} from 'app/models/general/search.results';
import {AppConfigService} from 'app/services/app.settings.service';
import {ApirequestService, HttpVerb} from 'app/services/general/api-service.service';
import {CORE_SESSION_STORAGE} from 'app/services/storage/tp-storage.service.ts';
import {ClientContactId, ClientId} from 'app/services/ui-flow/clients-ui.service';
import {Constants} from 'app/shared.constants';
import {StorageService} from 'ngx-webstorage-service';
import {Observable, of, Subject, throwError} from 'rxjs';
import {catchError, map, startWith, take} from 'rxjs/operators';
import {environment} from '../../../../../environments/environment';
import {
    ClientAgreedRateRequestModel,
    ClientAgreedRateResponseModel,
    ClientCatCountModel,
    ClientContactModel,
    ClientContactsSearchCriteria,
    ClientSearchCriteria,
    ClientSummaryBoxesTotal,
} from '../../clients/models';
import {ResponseClientPricingData} from '../../clients/models/client-pricing';
import {
    PostClient,
    PostClientContact,
    ResponseClientData,
    ResponseClientGridData,
    ResponseClientInfo,
    ResponseClientPOCData
} from '../../clients/models/client.model';
import {ClientStatusTypes} from "../../clients/utils";
import {StatusTypes} from "../../../../utills";
import {ResponseUserGridData} from 'app/main/admin/models/user.model';
import {ReportEntityTypes} from "../../reports/utils";
import {ResponseTopGridData} from "../../reports/models/response-models/response-top-grid-data";
import {PostSalesReportSearchCriteria} from "../../reports/models/post-models/post-sales-report-search-criteria";
import {
    ResponseSalesReportGridDataDetail
} from "../../reports/models/response-models/response-sales-report-grid-data-detail";
import {ResponseClientJobGridData} from "../../projects/models/response-models/response-client-job-grid-data";
import {ResponseClientJobMargin} from "../../client-jobs/models/response-client-job-margin";

@Injectable({
    providedIn: 'root',
})
export class ClientService {

    private readonly _uri: string = environment.apiUrl;

    private endpoint = '/client';
    private clientJobsEndpoint = '/clientjob';

    private refreshClientObservers$ = new Subject();
    private refreshClientsLock: boolean;

    constructor(
        private http: HttpClient,
        configService: AppConfigService,
        private translate: TranslateService,
        @Inject(CORE_SESSION_STORAGE) private sessionStorage: StorageService,
        private api:ApirequestService
    ) {
        if (configService) {
            this._uri = configService.getAPIUrl();
        }
    }

    search(model: SearchCriteriaModel<ClientSearchCriteria>): Observable<BaseResultModel<SearchResult<ResponseClientGridData>>> {
        return this.api.ApiRequest<BaseResultModel<SearchResult<ResponseClientGridData>>>(environment.apiUrl + this.endpoint + '/search',model,new HttpHeaders(),HttpVerb.post,'json');
    }

    updateAvatar(formData): Observable<BaseResultModel<ResponseClientData>>{
        return this.http.put<BaseResultModel<ResponseClientData>>(environment.apiUrl + this.endpoint + '/avatar/update',formData);
    }

    searchSalesReportData(model: SearchCriteriaModel<PostSalesReportSearchCriteria>): Observable<BaseResultModel<ResponseSalesReportGridDataDetail[]>>{
        return this.http.post<BaseResultModel<ResponseSalesReportGridDataDetail[]>>(this._uri + this.endpoint +'/project/sales-report', model);
    }

    getClientTopDataEntity(entity: keyof typeof ReportEntityTypes, clientId: number, howMany: number = 10): Observable<BaseResultModel<ResponseTopGridData[]>> {
        return this.http.get<BaseResultModel<ResponseTopGridData[]>>(this._uri + this.endpoint + '/top/' + howMany + '/' + entity + '/' + clientId);
    }

    searchClientJobs(model: SearchCriteriaModel<ClientSearchCriteria>): Observable<BaseResultModel<SearchResult<any>>> {
        return this.api.ApiRequest<BaseResultModel<SearchResult<any>>>(environment.apiUrl + this.clientJobsEndpoint + '/search',model,new HttpHeaders(),HttpVerb.post,'json');
    }
    getClientJobGridDataById(
        clientJobId: number
    ): Observable<BaseResultModel<ResponseClientJobGridData>>{
        return this.api.ApiRequest<BaseResultModel<ResponseClientJobGridData>>( environment.apiUrl + this.clientJobsEndpoint + '/' + clientJobId + '/grid','',new HttpHeaders(),HttpVerb.get,'json');
    }
    getClientJobMargin(): Observable<BaseResultModel<ResponseClientJobMargin>>{
        return this.api.ApiRequest<BaseResultModel<ResponseClientJobMargin>>( environment.apiUrl + this.clientJobsEndpoint + '/lowestMargin','',new HttpHeaders(),HttpVerb.get,'json');
    }

    changeStatus(clientIds: number[], statusId: ClientStatusTypes): Observable<BaseResultModel<boolean>>{
        return this.api.ApiRequest<BaseResultModel<boolean>>(environment.apiUrl + this.endpoint + '/changeClientStatus',{clientIds,statusId },new HttpHeaders(),HttpVerb.put,'json');
    }

    getClientById(clientId: number): Observable<BaseResultModel<ResponseClientData>> {
        return this.api.ApiRequest<BaseResultModel<ResponseClientData>>(environment.apiUrl + this.endpoint + '/' + clientId,'',new HttpHeaders(),HttpVerb.get,'json');
    }

    getClientGridDataById(clientId: number): Observable<BaseResultModel<ResponseClientGridData>> {
        return this.api.ApiRequest<BaseResultModel<ResponseClientGridData>>(environment.apiUrl + this.endpoint + '/' + clientId + '/grid','',new HttpHeaders(),HttpVerb.get,'json');
    }

    getClientInfoById(clientId: number): Observable<BaseResultModel<ResponseClientInfo>> {
        return this.api.ApiRequest<BaseResultModel<ResponseClientInfo>>(environment.apiUrl + this.endpoint + '/' + clientId + '/info','',new HttpHeaders(),HttpVerb.get,'json');
    }

    getClientsByStatusIdList(statusId: number): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + this.endpoint + '/status/' + statusId + '/dropdown','',new HttpHeaders(),HttpVerb.get,'json');
    }

    getClientsByTypeIdList(typeId: number): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + this.endpoint + '/type/' + typeId + '/dropdown','',new HttpHeaders(),HttpVerb.get,'json');
    }

    getClientTagsByClientIdList(clientId: number): Observable<BaseResultModel<DropDownItem>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem>>(environment.apiUrl + this.endpoint + '/' + clientId + '/clientTags','',new HttpHeaders(),HttpVerb.get,'json');
    }

    getClientPriceListByClientId(clientId: number): Observable<BaseResultModel<ResponseClientPricingData[]>> {
        return this.api.ApiRequest<BaseResultModel<ResponseClientPricingData[]>>(environment.apiUrl + this.endpoint + '/clientRate/' + clientId,'',new HttpHeaders(),HttpVerb.get,'json');
    }

    getClientPriceListByProjectId(projectId: number): Observable<BaseResultModel<ResponseClientPricingData[]>> {
        return this.api.ApiRequest<BaseResultModel<ResponseClientPricingData[]>>(environment.apiUrl + this.endpoint + '/clientRate/project/' + projectId,'',new HttpHeaders(),HttpVerb.get,'json');
    }

    addClientPriceList(model):Observable<BaseResultModel<ResponseClientPricingData[]>> {
        return this.api.ApiRequest<BaseResultModel<ResponseClientPricingData[]>>(environment.apiUrl + this.endpoint + '/clientRate/add',model,new HttpHeaders(),HttpVerb.post,'json');
    }

    updateClientPriceList(model): Observable<BaseResultModel<ResponseClientPricingData[]>> {
        return this.api.ApiRequest<any>(environment.apiUrl + this.endpoint + '/clientRate/update',model,new HttpHeaders(),HttpVerb.put,'json');
    }

    bulkUpdateClientPriceList(model): Observable<BaseResultModel<ResponseClientPricingData[]>> {
        return this.api.ApiRequest<any>(environment.apiUrl + this.endpoint + '/clientRate/bulkUpdate',model,new HttpHeaders(),HttpVerb.put,'json');
    }

    deleteClientPriceById(clientPriceId: number): Observable<BaseResultModel<boolean>> {
        return this.http.delete<BaseResultModel<boolean>>(environment.apiUrl + this.endpoint + '/clientRate/delete',{body: [clientPriceId]});
    }

    deleteClientByIds(ids: number[]): Observable<null> {
        if (ids?.length > 0) {
            const qString = `ids=${ids.join('&ids=')}`;
            const endpoint = `${this._uri}${this.endpoint}?${qString}`;
            return this.http.delete<null>(`${endpoint}`);
        } else {
            return of();
        }
    }

    deleteClientJobsByIds(ids: number[]): Observable<null> {
        if (ids?.length > 0) {
            // const qString = `ids=${ids.join('&ids=')}`;
            const endpoint = `${this._uri}${this.clientJobsEndpoint}/delete-jobs`;
            return this.http.post<null>(`${endpoint}`, ids);
        } else {
            return of();
        }
    }

    /***
     * @description post request to save a new client in the database along with his most vital info
     */
    saveClient(model: PostClient): Observable<BaseResultModel<ResponseClientData>> {
        return this.api.ApiRequest<BaseResultModel<ResponseClientData>>(environment.apiUrl + this.endpoint,model,new HttpHeaders(),HttpVerb.post,'json');
    }

    updateClient(model: ResponseClientData): Observable<BaseResultModel<ResponseClientData>> {
        return this.api.ApiRequest<BaseResultModel<ResponseClientData>>(environment.apiUrl + this.endpoint,model,new HttpHeaders(),HttpVerb.put,'json');
    }

    /***
     * @function uploadClientFiles
     * @param model: PostClientFile
     * @description upload files for client
     * @return Observable<BaseResultModel<string>>
     */
    uploadClientFiles(model: FormData): Observable<HttpEvent<BaseResultModel<string>>> {
        return this.http.post<BaseResultModel<string>>(environment.apiUrl + this.endpoint + '/file', model,{reportProgress: true, observe: 'events'});
    }

    bulkSaveJobs(model: any): Observable<BaseResultModel<ResponseClientData>> {
        return this.api.ApiRequest<BaseResultModel<ResponseClientData>>(environment.apiUrl + this.clientJobsEndpoint + '/bulkSaveJobs',model,new HttpHeaders(),HttpVerb.put,'json');
    }

    updateClientCatCount(
        model: ClientCatCountModel,
    ): Observable<ClientCatCountModel> {
        const endpoint = `${this._uri}${this.endpoint}/validateClientCatCount`;
        return this.http.post<ClientCatCountModel>(`${endpoint}`, model);
    }

    validateClientContact(
        model: ClientContactModel,
    ): Observable<ClientContactModel> {
        const endpoint = `${this._uri}${this.endpoint}/validateClientContact`;
        return this.http.post<ClientContactModel>(`${endpoint}`, model);
    }

    moveContacts(sourceClientId: ClientId, targetClientId: ClientId, contacts: ClientContactId[]): Observable<boolean> {
        if (!sourceClientId) {
            return throwError(new Error('sourceClientId is falsy in ClientService.moveContacts'));
        }

        if (!targetClientId) {
            return throwError(new Error('targetClientId is falsy in ClientService.moveContacts'));
        }

        const endpoint = `${this._uri}${this.endpoint}/moveContacts`;
        return this.http.post<boolean>(`${endpoint}`, {
            sourceClientId: sourceClientId.clientId,
            targetClientId: targetClientId.clientId,
            contactIds: contacts?.map(c => c.contactId),
        });
    }

    getClientContacts(clientId: number): Observable<ClientContactModel[]> {
        const endpoint = `${this._uri}${this.endpoint}/${clientId}/contacts`;
        return this.http.get<ClientContactModel[]>(`${endpoint}`)
            .pipe(
                map(contacts => {
                    return contacts.map(c => {
                        c.clientId = clientId;
                        return c;
                    });
                }),
            );
    }

    getClientContactsList(clientId: number): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + this.endpoint + '/contacts/' + clientId + '/dropdown','',new HttpHeaders(),HttpVerb.get,'json');
    }

    getAgreedRate(
        model: ClientAgreedRateRequestModel,
    ): Observable<ClientAgreedRateResponseModel> {
        const endpoint = `${this._uri}${this.endpoint}/agreedRate`;
        return this.http.post<ClientAgreedRateResponseModel>(
            `${endpoint}`,
            model,
        );
    }

    saveAgreedRate(clientId: number, model: PriceListModel): Observable<any> {
        const endpoint = `${this._uri}${this.endpoint}/${clientId}/savePrice`;
        return this.http.put<PriceListModel>(`${endpoint}`, model);
    }

    getCatCount(id: string): Observable<ClientCatCountModel> {
        const endpoint = `${this._uri}${this.endpoint}/${id}/catCount`;
        return this.http.get<ClientCatCountModel>(`${endpoint}`);
    }

    getSummaryBoxesTotals<T>(
        model: SearchCriteriaModel<T>,
    ): Observable<ClientSummaryBoxesTotal> {
        return this.http.post<ClientSummaryBoxesTotal>(
            `${this._uri}${this.endpoint}/clientSummaryBoxes`,
            model,
        );
    }

    getNoOfActiveClients(timeFrame: TimeFrameEnumForTotals): Observable<TotalsModel> {
        const endpoint = `${this._uri}${this.endpoint}/activeClients?timeFrameFiltersForTotals=${timeFrame}`;
        return this.http.get<TotalsModel>(`${endpoint}`);
    }

    getNoOfNewClients(timeFrame: TimeFrameEnumForTotals): Observable<TotalsModel> {
        const endpoint = `${this._uri}${this.endpoint}/newClients?timeFrameFiltersForTotals=${timeFrame}`;
        return this.http.get<TotalsModel>(`${endpoint}`);
    }

    getClientLogins( clientId: number): Observable<BaseResultModel<ResponseUserGridData[]>> {
        return this.api.ApiRequest<BaseResultModel<ResponseUserGridData[]>>(environment.apiUrl + this.endpoint + '/' + clientId + '/clientlogins','',new HttpHeaders(),HttpVerb.get,'json');
        
    }

    deleteClientLogin(clientId: number, userId: number): Observable<BaseResultModel<ResponseClientData>> {
        const endpoint = `${this._uri}${this.endpoint}/${clientId}/${userId}/clientLogin`;
        return this.http.delete<any>(`${endpoint}`);
    }

    getRefClients(): Observable<DropDownOptionsModel[]> {
        return this.refreshClientObservers$.pipe(startWith(undefined))
            .pipe(map(() => {
                const persistedData: string = this.sessionStorage.get(Constants.CLIENT_STORAGE_KEY) as string;
                if (persistedData) {
                    return JSON.parse(persistedData) as DropDownOptionsModel[];
                } else {
                    this.refreshRefClients().pipe(take(1)).subscribe();
                    return [] as DropDownOptionsModel[];
                }
            }));
    }


    getRefClientById(endClientId: string) {
        const persistedData: string = this.sessionStorage.get(Constants.CLIENT_STORAGE_KEY) as string;
        if (persistedData) {
            const refClients = JSON.parse(persistedData) as DropDownOptionsModel[];
            return refClients?.find(c => c.value === endClientId);
        } else {
            return null;
        }
    }

    refreshRefClients(): Observable<void> {
        if (this.refreshClientsLock) {
            return of();
        } else {
            this.refreshClientsLock = true;
            return this.http.get<DropDownOptionsModel[]>(`${this._uri}${this.endpoint}/refClients`)
                .pipe(map((result) => {
                        this.refreshClientsLock = false;
                        this.sessionStorage.set(Constants.CLIENT_STORAGE_KEY, JSON.stringify(result));
                        this.refreshClientObservers$.next();
                        return;
                    }),
                    catchError(err => {
                        this.refreshClientsLock = false;
                        return throwError(err);
                    }));
        }
    }

    getGrowthRate(timeFrame: TimeFrameEnumForTotals): Observable<TotalsModel> {
        const endpoint = `${this._uri}${this.endpoint}/clientGrowthRate?timeFrameFiltersForTotals=${timeFrame}`;
        return this.http.get<TotalsModel>(`${endpoint}`);
    }

    getRepeatCustomerRate(timeFrame: TimeFrameEnumForTotals): Observable<TotalsModel> {
        const endpoint = `${this._uri}${this.endpoint}/repeatCustomerRate?timeFrameFiltersForTotals=${timeFrame}`;
        return this.http.get<TotalsModel>(`${endpoint}`);
    }

    exportClientGrid<T>(search: SearchCriteriaModel<T>, previewType: string): Observable<FilePreviewModel> {
        const endpoint = `${this._uri}${this.endpoint}/export/${previewType}`;

        return this.http.post<FilePreviewModel>(`${endpoint}`, search);
    }

    saveContact(clientId: number, contact: PostClientContact): Observable<BaseResultModel<ResponseClientPOCData>> {
        const endpoint = `${this._uri}${this.endpoint}/client-contact`;
        return this.http.post<any>(`${endpoint}`, contact);
    }

    public deleteClientContactById(clientId: number, contactId: number): Observable<any> {
        return this.getClientById(clientId)
            // .pipe(
            //     switchMap(client => {
            //         // const idx = client.clientContacts?.findIndex(c => c.contactId === contactId);
            //         // if (idx >= 0) {
            //         //     client.clientContacts.splice(idx, 1);
            //         //     return this.saveClient(clientId, client);
            //         // } else {
            //         //     return of();
            //         // }
            //     }),
            // );
    }

    searchContacts(model: SearchCriteriaModel<ClientContactsSearchCriteria>): Observable<SearchResult<ClientContactGridData>> {
        return this.http.post<SearchResult<ClientContactGridData>>(
            `${this._uri}${this.endpoint}/clientContact/search`,
            model,
        );
    }

    getContactsForClients(clientIds: string[]): Observable<ClientContactGridData[]> {
        return this.searchContacts({
            showAll: true,
            searchCriteria: {
                clients: clientIds,
            },
        }).pipe(
            map(result => result.result),
        );
    }

    /**
     * @description returns client contact data by clientcontactId
     * @param clientContactId 
     * @returns BaseResultModel<ResponseClientPOCData>
     */
    getClientContactById(clientContactId: number): Observable<BaseResultModel<ResponseClientPOCData>> {
        return this.api.ApiRequest<BaseResultModel<ResponseClientPOCData>>(environment.apiUrl + this.endpoint + '/client-contact/' + clientContactId,'',new HttpHeaders(),HttpVerb.get,'json');        
    }

    public deleteClientContactsByIds(ids: number[]): any | Observable<null> {
        if (ids?.length > 0) {
            let httpParams = new HttpParams();
            ids.forEach(id => {
                httpParams = httpParams.append(environment.apiConstants.bulkIdsQueryParam, id.toString());
            });

            return this.http.delete<any>(`${this._uri}${this.endpoint}/contact`, { params: httpParams });
        } else {
            return of();
        }
    }

    /**
     * @description deletes clientcontact by its id
     * @param clientContactIds
     * @returns BaseResultModel<boolean>
     */
     deleteClientPOCById(clientContactIds: number[]): Observable<BaseResultModel<boolean>> {
        return this.http.delete<BaseResultModel<boolean>>(environment.apiUrl + this.endpoint + '/client-contact/delete', { params: new HttpParams().set('clientContactIds', `${clientContactIds}`) });
    }

    /**
     * updates a client contact
     * @param clientContact 
     * @returns BaseResultModel<ResponseClientPOCData>
     */
    updateClientPOC(clientContact:ResponseClientPOCData): Observable<BaseResultModel<ResponseClientPOCData>> {
        return this.api.ApiRequest<BaseResultModel<ResponseClientPOCData>>(environment.apiUrl + this.endpoint + '/client-contact',clientContact,new HttpHeaders(),HttpVerb.put,'json');        
    }

    /**
     * updates a client contact
     * @param clientContact 
     * @returns BaseResultModel<ResponseClientPOCData>
     */
     addNewClientPOC(clientContact:PostClientContact): Observable<BaseResultModel<ResponseClientPOCData>> {
        return this.api.ApiRequest<BaseResultModel<ResponseClientPOCData>>(environment.apiUrl + this.endpoint + '/client-contact',clientContact,new HttpHeaders(),HttpVerb.post,'json');        
    }

    addClientStatus(model): Observable<BaseResultModel<any>> {
        return this.api.ApiRequest<BaseResultModel<any>>(environment.apiUrl + this.endpoint + '/clientStatus/add',model,new HttpHeaders(),HttpVerb.post,'json');
    }

    updateClientStatus(model): Observable<BaseResultModel<any>> {
        return this.api.ApiRequest<BaseResultModel<any>>(environment.apiUrl + this.endpoint + '/clientStatus/update',model,new HttpHeaders(),HttpVerb.put,'json');
    }

    getClientStatus(id): Observable<BaseResultModel<any>> {
        return this.api.ApiRequest<BaseResultModel<any>>(environment.apiUrl + this.endpoint + '/clientStatus/' + id,'',new HttpHeaders(),HttpVerb.get,'json');
    }
        
    deleteClientStatusById(id: string): Observable<any> {
        return this.api.ApiRequest<BaseResultModel<any>>(environment.apiUrl + '/client/clientStatus/' + id,'',new HttpHeaders(),HttpVerb.delete,'json');
    }

    getClientStatuses(status: StatusTypes = StatusTypes.active): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + this.endpoint + '/clientStatus/'+status+'/dropdown','',new HttpHeaders(),HttpVerb.get,'json');
    }
    getClientsDropdown(statusId: StatusTypes = StatusTypes.active): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + this.endpoint + '/status/'+statusId+'/dropdown','',new HttpHeaders(),HttpVerb.get,'json');
    }

    updateClientJobStatus(model): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + '/clientJob/changeClientJobStatus', model,new HttpHeaders(),HttpVerb.put,'json');
    }

}