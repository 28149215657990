import {Component, EventEmitter, forwardRef, Input, OnInit, Optional, Output, ViewChild} from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'
import { BaseControlComponentDirective } from '../base-control-component.directive'
import {SelectionChange} from "ngx-quill/lib/quill-editor.component";
import {QuillEditorComponent} from "ngx-quill";

@Component({
    selector: 'tp-rich-textbox',
    templateUrl: './tp-rich-textbox.component.html',
    styleUrls: ['./tp-rich-textbox.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TpRichTextBoxComponent),
            multi: true,
        },
    ],
})
export class TpRichTextBoxComponent extends BaseControlComponentDirective implements OnInit {
    @ViewChild('editor') editor: QuillEditorComponent;
    @Output() onSelectionChanged: EventEmitter<SelectionChange> = new EventEmitter<SelectionChange>();
    modules = {
        toolbar: [
            ['bold', 'italic', 'underline'], // toggled buttons
            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
            [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            [{ font: [] }],
            [{ align: [] }],
            ['clean'], // remove formatting button
            ['link', 'image'], // link and image, video
        ]
    };
    @Input() set showToolBar(val: boolean) {
        this._showToolBar = val;
        if (!val) this.modules.toolbar = null;
    }
    _showToolBar: boolean = true;
    get showToolBar(): boolean{ return this._showToolBar };
    @Input() cssClass = 'editor';
    @Input() format: 'object' | 'html' | 'text' | 'json' = 'text';
    @Input() customToolbar: boolean = false;
    constructor(
        @Optional()  protected readonly controlContainer: ControlContainer,
        protected readonly translateService: TranslateService
    ) {
        super(controlContainer, translateService)
    }
}