import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field'
import { TranslateModule } from '@ngx-translate/core'
import { TpSliderRangeComponent } from 'app/components/tp-slider-range/tp-slider-range.component'
import { Ng5SliderModule } from 'ng5-slider'
import {TpTextBoxModule} from "../tp-text-box/tp-text-box.module";

@NgModule({
    declarations: [TpSliderRangeComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TranslateModule,

        MatFormFieldModule,

        Ng5SliderModule,
        FormsModule,
        TpTextBoxModule,
    ],
    exports: [TpSliderRangeComponent],
})
export class TpSliderRangeModule {}