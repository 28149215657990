import {Action, combineReducers, createFeatureSelector, createReducer, createSelector, on} from '@ngrx/store';
import {ResponseQuoteGridData} from "../../../../models/general/quote/response-models/response-quote-grid-data";
import {createEntity, EntitySearchAdditional, entitySearchInitial} from "../../../../store/models";
import {QuotesSearchCriteria} from "../../quotes/models";
import {QuoteStatusTypes} from "../../quotes/utils";
import {pmPortalStateFeatureSelector} from "../features";

export const quoteGridFeatureKey = 'quoteGrid';

export const quoteGridEntity = createEntity<ResponseQuoteGridData, EntitySearchAdditional<QuotesSearchCriteria>>(
    pmPortalStateFeatureSelector,
    quoteGridFeatureKey,
    entitySearchInitial( {searchCriteria: {statuses: [QuoteStatusTypes.OPEN]}, searchFormValue: { statuses: [{id:1, name:'OPEN'}] }}),
)