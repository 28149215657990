export function overwriteObject<T>(overwriteConfig: T, config: T, nested: boolean = false): T {
    let obj = {};
    if (overwriteConfig instanceof Object) {
        Object.keys(overwriteConfig).forEach(property => {
            if (overwriteConfig[property] instanceof Array) {
                obj[`${property}`] = overwriteArray(overwriteConfig[property], (config) ? config[property] : [] )
            } else if (overwriteConfig[property] instanceof Object) {
                obj[`${property}`] = {...(config) ? config[property]: {}, ...overwriteObject(overwriteConfig[property], (config) ? config[property] : {}, true)};
            } else {
                obj[`${property}`] = overwriteConfig[property];
            }
        });
    }
    return {...config, ...obj};
}

export function overwriteArray(overwriteConfig: any[], config: any[],): any[] {
    let arrayData = [];
    if (overwriteConfig instanceof Array) {
        overwriteConfig.forEach((item, index) => {
            if (overwriteConfig[index] instanceof Array) {
                arrayData.push(
                    overwriteArray(overwriteConfig[index], (config) ? config[index] : [])
                )
            } else if (overwriteConfig[index] instanceof Object) {
                arrayData.push(
                    overwriteObject(overwriteConfig[index], (config) ? config[index] : {}, true)
                )
            } else {
                arrayData.push(item);
            }
        });
    }
    return arrayData;
}