import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { TpDropdownModule } from 'app/components/tp-dropdown/tp-dropdown.module'
import { TpMultiselectDropdownModule } from 'app/components/tp-multiselect-dropdown/tp-multiselect-dropdown.module'
import { TpRobertTemplateDropdownComponent } from './tp-robert-template-dropdown.component';



@NgModule({
    declarations: [TpRobertTemplateDropdownComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TpDropdownModule,
        TpMultiselectDropdownModule,
        FormsModule,
        TranslateModule,
    ],
    exports: [TpRobertTemplateDropdownComponent],
})
export class TpRobertTemplateDropdownModule {}