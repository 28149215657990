import {Action, combineReducers, createReducer, on} from '@ngrx/store';
import {EntityState} from "@ngrx/entity";
import {DropDownItem} from "../../models/general/dropdownItem.model";
import {EntityAdditional} from "../models";
import {
    clientDropdownEntity,
    clientDropdownFeatureKey,
    clientStatusDropdownEntity,
    clientStatusDropdownFeatureKey,
    countryDropdownEntity,
    countryDropdownFeatureKey,
    currencyDropdownEntity,
    currencyDropdownFeatureKey,
    genderDropdownEntity,
    genderDropdownFeatureKey,
    invoiceStatusDropdownEntity,
    invoiceStatusDropdownFeatureKey,
    jobStatusDropdownEntity,
    jobStatusDropdownFeatureKey,
    languageDropdownEntity,
    languageDropdownFeatureKey,
    mimeTypeDropdownEntity,
    mimeTypeDropdownFeatureKey,
    projectManagerDropdownEntity,
    projectManagerDropdownFeatureKey,
    projectStatusDropdownEntity,
    projectStatusDropdownFeatureKey,
    purchaseOrderStatusDropdownEntity,
    purchaseOrderStatusDropdownFeatureKey,
    quoteJobStatusDropdownEntity,
    quoteJobStatusDropdownFeatureKey,
    quoteStatusDropdownEntity,
    quoteStatusDropdownFeatureKey,
    resourceDropdownEntity,
    resourceDropdownFeatureKey,
    resourceTeamDropdownEntity,
    resourceTeamDropdownFeatureKey,
    resourceTypeDropdownEntity,
    resourceTypeDropdownFeatureKey,
    roleDropdownEntity,
    roleDropdownFeatureKey,
    serviceDropdownEntity,
    serviceDropdownFeatureKey,
    serviceTypeDropdownEntity,
    serviceTypeDropdownFeatureKey,
    tagDropdownEntity,
    tagDropdownFeatureKey,
    templateDropdownEntity,
    templateDropdownFeatureKey,
    unitDropdownEntity,
    unitDropdownFeatureKey,
    userDropdownEntity,
    userDropdownFeatureKey,
    workflowDropdownEntity,
    workflowDropdownFeatureKey
} from "./dropdowns.reducer";
import {TemplateDropdownItem} from "../models/dropdowns/template-dropdown-item";
import {ServiceDropDownItem} from "../models/dropdowns/service-dropdown-item";
import {TagDropdownItem} from "../models/dropdowns/tag-dropdown-item";
import {ResourceDropDownItem} from "../models/dropdowns/resource-dropdown-item";

export interface GlobalState {
    [projectManagerDropdownFeatureKey]: EntityState<DropDownItem> & EntityAdditional,
    [languageDropdownFeatureKey]: EntityState<DropDownItem> & EntityAdditional,
    [currencyDropdownFeatureKey]: EntityState<DropDownItem> & EntityAdditional,
    [countryDropdownFeatureKey]: EntityState<DropDownItem> & EntityAdditional,
    [tagDropdownFeatureKey]: EntityState<TagDropdownItem> & EntityAdditional,
    [templateDropdownFeatureKey]: EntityState<TemplateDropdownItem> & EntityAdditional,
    [resourceTypeDropdownFeatureKey]: EntityState<DropDownItem> & EntityAdditional,
    [resourceTeamDropdownFeatureKey]: EntityState<DropDownItem> & EntityAdditional,
    [genderDropdownFeatureKey]: EntityState<DropDownItem> & EntityAdditional,
    [roleDropdownFeatureKey]: EntityState<DropDownItem> & EntityAdditional,
    [clientDropdownFeatureKey]: EntityState<DropDownItem> & EntityAdditional;
    [resourceDropdownFeatureKey]: EntityState<ResourceDropDownItem> & EntityAdditional,
    [serviceDropdownFeatureKey]: EntityState<ServiceDropDownItem> & EntityAdditional,
    [serviceTypeDropdownFeatureKey]: EntityState<DropDownItem> & EntityAdditional,
    [workflowDropdownFeatureKey]: EntityState<DropDownItem> & EntityAdditional,
    [unitDropdownFeatureKey]: EntityState<DropDownItem> & EntityAdditional,
    [quoteStatusDropdownFeatureKey]: EntityState<DropDownItem> & EntityAdditional,
    [projectStatusDropdownFeatureKey]: EntityState<DropDownItem> & EntityAdditional,
    [clientStatusDropdownFeatureKey]: EntityState<DropDownItem> & EntityAdditional;
    [jobStatusDropdownFeatureKey]: EntityState<DropDownItem> & EntityAdditional,
    [quoteJobStatusDropdownFeatureKey]: EntityState<DropDownItem> & EntityAdditional,
    [invoiceStatusDropdownFeatureKey]: EntityState<DropDownItem> & EntityAdditional,
    [purchaseOrderStatusDropdownFeatureKey]: EntityState<DropDownItem> & EntityAdditional
    [userDropdownFeatureKey]: EntityState<DropDownItem> & EntityAdditional
    [mimeTypeDropdownFeatureKey]: EntityState<DropDownItem> & EntityAdditional
}

export const globalReducer = combineReducers<GlobalState>({
    [projectManagerDropdownFeatureKey]: projectManagerDropdownEntity.reducer,
    [languageDropdownFeatureKey]: languageDropdownEntity.reducer,
    [currencyDropdownFeatureKey]: currencyDropdownEntity.reducer,
    [countryDropdownFeatureKey]: countryDropdownEntity.reducer,
    [tagDropdownFeatureKey]: tagDropdownEntity.reducer,
    [templateDropdownFeatureKey]: templateDropdownEntity.reducer,
    [resourceTypeDropdownFeatureKey]: resourceTypeDropdownEntity.reducer,
    [resourceTeamDropdownFeatureKey]: resourceTeamDropdownEntity.reducer,
    [genderDropdownFeatureKey]: genderDropdownEntity.reducer,
    [roleDropdownFeatureKey]: roleDropdownEntity.reducer,
    [clientDropdownFeatureKey]: clientDropdownEntity.reducer,
    [resourceDropdownFeatureKey]: resourceDropdownEntity.reducer,
    [serviceDropdownFeatureKey]: serviceDropdownEntity.reducer,
    [serviceTypeDropdownFeatureKey]: serviceTypeDropdownEntity.reducer,
    [workflowDropdownFeatureKey]: workflowDropdownEntity.reducer,
    [unitDropdownFeatureKey]: unitDropdownEntity.reducer,
    [quoteStatusDropdownFeatureKey]: quoteStatusDropdownEntity.reducer,
    [projectStatusDropdownFeatureKey]: projectStatusDropdownEntity.reducer,
    [clientStatusDropdownFeatureKey]: clientStatusDropdownEntity.reducer,
    [jobStatusDropdownFeatureKey]: jobStatusDropdownEntity.reducer,
    [quoteJobStatusDropdownFeatureKey]: quoteJobStatusDropdownEntity.reducer,
    [invoiceStatusDropdownFeatureKey]: invoiceStatusDropdownEntity.reducer,
    [purchaseOrderStatusDropdownFeatureKey]: purchaseOrderStatusDropdownEntity.reducer,
    [userDropdownFeatureKey]: userDropdownEntity.reducer,
    [mimeTypeDropdownFeatureKey]: mimeTypeDropdownEntity.reducer,
})