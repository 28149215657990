import {createSelector} from "@ngrx/store";
import {EntityAdapter, EntityState, Predicate,} from "@ngrx/entity";
import {MemoizedSelector} from "@ngrx/store/src/selector";
import _ from "lodash";
export const entitySelectors = <T, A> (featureSelector: MemoizedSelector<object, object>, featureKey: string, adapter: EntityAdapter<T>) => {
    const selectState = createSelector<object, object, EntityState<T> & A>(
        featureSelector,
        (state) => (featureKey) ? state[featureKey] : state
    );
    const adapterSelectors = adapter.getSelectors();
    const selectEntities = createSelector(
        selectState,
        adapterSelectors.selectEntities
    );
    const selectAll = createSelector(
        selectState,
        adapterSelectors.selectAll
    );
    const selectIds = createSelector(
        selectState,
        adapterSelectors.selectIds
    );
    const selectTotal = createSelector(
        selectState,
        adapterSelectors.selectTotal
    );
    const selectById = (id: number) => createSelector(
        selectAll,
        (data) => _.cloneDeep(data.find(item => (item as any)?.id  == id))
    )
    const selectByIds = (ids: number[]) => createSelector(
        selectAll,
        (data) => _.cloneDeep(data.filter(item => ids.includes((item as any)?.id)))
    )
    const selectByPredicate = (predicate: Predicate<T>) => createSelector(
        selectAll,
        (data) => _.cloneDeep(data.filter(data => predicate(data)))
    )
    const selectAllBySort = (property: string, direction: 'asc' | 'desc' = 'asc') => createSelector(
        selectAll,
        (data) => _.cloneDeep([...data].sort((a, b) => {return (direction === 'asc') ? a[property].toLowerCase() > b[property].toLowerCase() ? 1 : -1 : b[property].toLowerCase() > a[property].toLowerCase() ? 1 : -1}))
    )
    return {selectState, selectEntities, selectAll, selectIds, selectTotal, selectById, selectByIds, selectByPredicate, selectAllBySort};
}