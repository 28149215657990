import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {IntegrationCardsComponent} from "./integration-cards.component";
import {CharacterLimitPipeModule} from "../../../../pipes/character-limit-pipe/character-limit-pipe.module";
@NgModule({
    declarations: [IntegrationCardsComponent],
    imports: [
        CommonModule,
        CharacterLimitPipeModule
    ],
    exports: [
        IntegrationCardsComponent
    ]
})
export class IntegrationCardsModule { }