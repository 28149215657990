import { DropDownOptionsModel, JobCATCountModel } from 'app/models/general';

export class QuoteModel {
    id: number;
    name: string;
    code?: string;
    insertDate?: Date;
    insertUserId?: number;
    quoteStatusId?: number;
    clientId?: number;
    endClientId?: number;
    contactId?: number;
    projectManagerId?: number;
    currencyId?: number;
    htmlTemplateId?: number;
    comments?: string;
    dateSent?: Date;
    startDate?: Date;
    endDate?: Date;
    clientAcceptanceDate?: Date;
    vat?: number;
    extraCharge?: number;
    discount?: number;
    discountExtraChangeReason?: string;
    commission?: number;
    totalAmount?: number;
    winProbability?: number;
    quoteStatusDescription?: string;
    projectManagerName?: string;
    clientName?: string;
    contactName:string;
    endClientName:string;
    notes:string;
}

export enum QuoteDateTypes {
    estimated = 'DURATION',
    concrete = 'DATE',
}

export class QuoteJob {
    public id:number;
    public description:string;
    public code:string;
    public insertDate:Date;
    public insertUserId:number;
    public assignmentDate:Date;
    public dueDate:Date;
    public clientJobStatusId:number;
    public serviceId:number;
    public sourceLanguageId:number;
    public targetLanguageId:number;
    public comments:string;
    public priority:number;
    public projectId:number;
    public executionOrder:number;
    public currencyId?:number;
}