export class PostUser {
    id?: number;
    userName?: string;
    password?: string;
    insertDate?: Date;
    insertUserId?: number;
    personId?: number;
    projectManagerId?:number;
    entityId?: number;
    userRoleId?: number;
    lastLoginDate?: Date;
    isActive?: boolean;
    lName?: string;
    fName?: string;
    email?: string;
    companyBranchId?: number;
    avatar?: File;
    imagesOverText?: boolean;
    userPermissions?:number[];
}