import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatRadioModule } from '@angular/material/radio';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { TpButtonModule } from 'app/components/tp-button/tp-button.module';
import { TpCalendarModule } from 'app/components/tp-calendar/tp-calendar.module';
import { TpDropdownModule } from 'app/components/tp-dropdown/tp-dropdown.module';
import { TpModalModule } from 'app/components/tp-modal/tp-modal.module';
import { TpRadioButtonModule } from 'app/components/tp-radio-button/tp-radio-button.module';
import { TpRichTextBoxModule } from 'app/components/tp-rich-textbox/tp-rich-text-box.module';
import { TpServiceLanguageDropdownModule } from 'app/components/tp-service-language-dropdown/tp-service-language-dropdown.module';
import { TpServicesDropdownModule } from 'app/components/tp-services-dropdown/tp-services-dropdown.module';
import { TpTextBoxModule } from 'app/components/tp-text-box/tp-text-box.module';
import { TpUnitsDropdownModule } from 'app/components/tp-units-dropdown/tp-units-dropdown.module';
import { QuoteJobComponent } from 'app/main/pm/quotes/shared/quote-job/quote-job.component';
import { CurrencyNamePipeModule } from 'app/pipes/currency-name/currency-name-pipe.module';
import { TpCurrencyDropdownModule } from '../../../../../components/tp-currency-dropdown/tp-currency-dropdown.module';
import { TpDatePickerModule } from '../../../../../components/tp-date-picker/tp-date-picker.module';
import {TpGridModule} from "../../../../../components/tp-grid/tp-grid.module";
import {MatExpansionModule} from "@angular/material/expansion";
import {SharedModule} from "../../../shared/shared.module";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import { CheckPermissionsDirectiveModule } from 'app/directives/check-permission-directive/check-permission-directive-module';
import {DragAndDropFileModule} from "../../../../../components/drag-and-drop-file/drag-and-drop-file.module";
import {FileTabsModule} from "../../../../sharedComponents/file-tabs/file-tabs.module";
import {FileListModule} from "../../../../sharedComponents/file-list/file-list.module";
import {CatCountAnalyseModule} from "../../../../admin/cat-count/cat-count-analyse/cat-count-analyse.module";
import {
    CatCountAnalysisModule
} from "../../../../admin/cat-count/cat-count-analysis/cat-count-analysis.module";
import {FormatNumberPipeModule} from "../../../../../pipes/format-number-pipe/format-number-pipe.module";
import {QuoteFilesModule} from "../create-edit-quote/quote-files/quote-files.module";
import {WorkflowDropdownModule} from "../../../../../components/workflow-dropdown/workflow-dropdown.module";

@NgModule({
    declarations: [
        QuoteJobComponent,
    ],
    imports: [
        CommonModule,
        TranslateModule,
        MatTabsModule,
        ReactiveFormsModule,
        TpTextBoxModule,
        TpServicesDropdownModule,
        TpServiceLanguageDropdownModule,
        TpUnitsDropdownModule,
        TpRadioButtonModule,
        TpButtonModule,
        TpCalendarModule,
        TpDropdownModule,
        TpRichTextBoxModule,
        TpModalModule,
        CurrencyNamePipeModule,
        MatCardModule,
        FlexModule,
        MatTooltipModule,
        TpCurrencyDropdownModule,
        TpDatePickerModule,
        MatRadioModule,
        FormsModule,
        TpGridModule,
        MatExpansionModule,
        SharedModule,
        MatIconModule,
        MatButtonModule,
        CheckPermissionsDirectiveModule,
        DragAndDropFileModule,
        FileTabsModule,
        FileListModule,
        CatCountAnalyseModule,
        CatCountAnalysisModule,
        FormatNumberPipeModule,
        QuoteFilesModule,
        WorkflowDropdownModule,
    ],
    exports: [
        QuoteJobComponent,
    ],
})
export class QuoteJobModule {
}